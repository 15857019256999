/*
  perfectguide-single
--------------------------------------------- */

#perfectguide-single {
	.area_key {
		.area_tit {
			span {
				color: $cl-green;
			}
		}
	}
	.area_guide-book {
		width: 960px;
		padding-bottom: 100px;
		margin: auto;
		h3 {
			color: #fff;
			display: inline-block;
			background-color: $cl-green;
			margin-left: 100px;
			width: 100%;
			margin: auto;
			font-size: 1.06em;
		}
		h3:before {
			display: inline-block;
			background-color: #000;
			color: #fff;
			padding: 15px 35px;
			margin-right: 15px;
			font-family: $font-family-pt-sans;
		}
		h3.count1:before {
			content: "Chapter.1";
		}
		h3.count2:before {
			content: "Chapter.2";
		}
		h3.count3:before {
			content: "Chapter.3";
		}
		h3.count4:before {
			content: "Chapter.4";
		}
		h3.count5:before {
			content: "Chapter.5";
		}
		h3.count6:before {
			content: "Chapter.6";
		}
		h3.count7:before {
			content: "Chapter.7";
		}
		h3.count8:before {
			content: "Chapter.8";
		}
		h4 {
			font-size: 1.85em;
			color: $cl-green;
			padding: 80px 0 80px 40px;
		}
		.area_guide-book_cont {
			margin: auto;
			dl {
				padding-left: 80px;
				border-top: 1px #ddd solid;
				// border-bottom: 1px #ddd solid;
				padding-bottom: 50px;
				dt {
					padding: 50px 0 30px;
					font-size: $fs-24;
					padding-left: 50px;
					text-indent: -50px;

					&:before {
						display: inline-block;
					    vertical-align: middle;
					    content: "";
					    width: 30px;
					    height: 2px;
					    background: $cl-green;
					    margin-right: 20px;
					}
				}
			}
			// dl.cont_02 {
			// 	border-top: none;
			// }
			dl.cont_last{
				border-bottom: 1px #ddd solid;
				// border-top: none;
			}
		}
	}
  .pages_step02{
	& .area_guide-book {
        h3:before {
        content: "step.2";
      }
    }
  }
  .pages_step03{
    & .area_guide-book {
        h3:before {
        content: "step.3";
      }
    }
  }
  .pages_step04{
    & .area_guide-book {
        h3:before {
        content: "step.4";
      }
    }
  }
  .pages_step05{
    & .area_guide-book {
        h3:before {
        content: "step.5";
      }
    }
  }
  .pages_step06{
    & .area_guide-book {
        h3:before {
        content: "step.6";
      }
    }
  }
  .pages_step07{
    & .area_guide-book {
        h3:before {
        content: "step.7";
      }
    }
  }
  .pages_step08{
    & .area_guide-book {
        h3:before {
        content: "step.8";
      }
    }
  }
  .ordered_list {
  	list-style-type: decimal;
  	margin-left: 1.5em;

  	li {
  		padding-left: 0.5em;
  	}
  }
  .unordered_list {

  	li {
  		padding-left: 1em;
  		text-indent: -1em;

		&:before {
			content: "・";
		}
  	}
  }
  .square_list {

  	li {
  		padding-left: 1em;
  		text-indent: -1em;

		&:before {
			content: "■";
			color: $cl-green;
		}
  	}
  }
  .notes_list {

  	li {
  		padding-left: 1em;
  		text-indent: -1em;

		&:before {
			content: "※";
		}
  	}
  }

  .link_wrap {

  	a {
  		display: inline-block;
  	}
  }
}
