/*
  job-archive
--------------------------------------------- */

#job-archive {
	.area_key {
		.area_tit {
			span {
				color: $cl-orange;
			}
		}
		.job_category {
			max-width: 600px;
			border-top: 1px #ddd solid;
			margin-top: 20px;
			padding-top: 30px;
			text-align: center;
			font-size: $fs-18;
			margin: 0 auto;
			span {
				font-weight: bold;
			}
		}
	}
	.job_archive_cont {
//		max-width: 1000px;
//		margin: 0 auto 80px;
		width: 100%;
		overflow: hidden;
		&_box {
			width: 48%;
			border: 1px #ddd solid;
			border-top: none;
			margin-bottom: 60px;
			box-sizing: border-box;
			float: left;
			
			&.full {
				width: 100%;
			}
			
			&_inner {
				background: #f9f9f9;
				border-top: 2px $cl-orange solid;
//				padding: 58px 40px 31px;
				padding: 25px;
				border-bottom: 1px #ddd solid;
			}
			
			&:nth-child(even) {
				float: right;
			}
			.tit {
				font-size: $fs-16;
				font-weight: bold;
				padding-bottom: 15px;
			}
			.tag_list {
				display: table-cell;
				li {
					display: inline-block;
					margin: 0 8px 9px 0;
					a {
						background: #fff;
						display: inline-block;
						padding: 0 20px;
						line-height: 26px;
						font-size: $fs-13;
						text-decoration: none;
						text-align: center;
						border-radius: 14px;
						border: 2px $cl-yellow solid;
						
						&:hover {
							background: $cl-yellow;
							color: #fff;
						}
					}
				}
			}
			table {
				width: calc(100% - 80px);
				height: 100%;
				margin: 10px 40px 30px;
				tr {
					height: 100%;
					border-bottom: 1px #ddd solid;
				}
				th {
					width: 180px;
					padding: 30px 0;
					font-weight: bold;
				}
				td {
					padding: 10px 0;
					p{
						padding: 20px;
						border-left: 1px #ddd solid;
					}
				}
				.company_name.no-member {
					.caption {
						display: block;
						font-size: $fs-12;
						&:before {
							content: "";
							display: inline-block;
							width: 16px;
							height: 16px;
							background: url(../images/miraimo/job/ico-caption.png) no-repeat;
							background-size: contain;
							margin-right: 5px;
							vertical-align: middle;
						}
						a {
							color: $cl-orange;
						}
					}
				}
			}
			.btn_detail {
				@include btn-70;
				margin-bottom: 40px;
				a {
					width: 350px;
					font-size: $fs-15;
					color: $cl-orange;
					border: 2px $cl-orange solid;
					&:hover {
						background: $cl-orange;
					}
				}
			}
			
			& > a {
				display: block;
				text-decoration: none;
			}
		}
		
		&_dtl {
			padding: 25px;
		}
		
		.job_dtl_company {
			width: 100%;
			margin-bottom: 15px;
			letter-spacing: -.4em;
			
			dt,dd {
				display: inline-block;
				letter-spacing: normal;
			}
			
			dt {
				font-weight: bold;
			}
			
			dd {
				padding-left: 1em;
				
				&.caption {
					margin-top: .5em;
					padding-left: 1.8em;
					font-size: $fs-12;
					position: relative;
					&:before {
						content: "";
						display: inline-block;
						width: 16px;
						height: 16px;
						background: url(../images/miraimo/job/ico-caption.png) no-repeat;
						background-size: contain;
						position: absolute;
						top: .25em;
						left: 0;
					}
					a {
						color: $cl-orange;
					}
				}
			}
		}
		
		.job_dtl_info {
			padding-top: 15px;
			font-size: $fs-12;
			color: $cl-gray;
			letter-spacing: -.4em;
			background-image: linear-gradient(to right, #C6C6C6, #C6C6C6 1px, transparent 1px, transparent 3px);
			background-size: 4px 1px;
			background-position: top;
			background-repeat: repeat-x;
			
			li {
				font-style: italic;
				display: inline-block;
				letter-spacing: normal;
				
				&:not(:last-child)::after {
					content: '/';
					margin-right: .25em;
					padding-right: .25em;
				}
			}
		}
	}
	.page-nation {
		margin-bottom: 80px;
		letter-spacing: normal;
		ul {
			li {
				a {
					border: 1px $cl-orange solid;
					color: $cl-orange;
				}
				&.current,
				&:hover {
					a {
						color: #fff;
						background: $cl-orange;
					}
				}
			}
		}
	}
}
