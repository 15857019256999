/*
  archive blog
--------------------------------------------- */

#miraimoblog {
	.area_key {
		.area_tit {
			span {
				color: $cl-lightGreen;
			}
		}
	}
}

#miraimoblog.post-type-archive-voice,
#miraimoblog.post-type-archive-interview {
	.area_key {
		.area_tit {
			span {
				color: $cl-lightBlue;
			}
		}
	}
}

#miraimoblog.page-template-page-interview-archive {
	.area_tit {
		span {
			color: $cl-lightBlue;
		}
	}
	.archive_sidebar_cont {
		border-color: $cl-lightBlue;
		.tit {
			color: $cl-lightBlue;
		}
	}
	.archive_sidebar_cont ul li a {
		color: $cl-lightBlue;
		&:hover {
			background: $cl-lightBlue;
			color: #FFF;
		}
	}
	.area_blog_cont_box {
		.number {
			border-bottom: 0;
		}
		a {
		   &:hover {
			   color: $cl-lightBlue;
		   }
	   }
	}
	.page-nation ul li {
		a {
			border-color: $cl-lightBlue;
			color: $cl-lightBlue;
		}
		&:hover a {
			background: $cl-lightBlue;
			color: #FFF;
		}
	}
	.page-nation ul li span.current {
		display: block;
		text-align: center;
		width: 45px;
		line-height: 48px;
		border-radius: 4px;
		border: 1px solid $cl-lightBlue;
		background: $cl-lightBlue;
		color: #FFF;
		text-decoration: none;
	}
}

.archive_category {
	width: 100%;
	max-width: 600px;
	line-height: 43px;
	border-radius: 22.5px;
	border: 1px $cl-lightGreen solid;
	text-align: center;
	color: $cl-lightGreen;
	letter-spacing: .075em;
	font-size: $fs-16;
	margin: 0 auto;
}

#miraimoblog.post-type-archive-voice,
#miraimoblog.post-type-archive-interview {

	.archive_category {
		border: 1px $cl-lightBlue solid;
		color: $cl-lightBlue;
	}
}

.archive_cont {
	width: 75%;
	width: calc(100% - 304px);
	float: left;
	.area_blog_cont_box {
		padding-bottom: 60px;
	}
}

#miraimoblog.post-type-archive-voice,
#miraimoblog.post-type-archive-interview {
	.area_blog_cont_box {
		a {
			text-decoration: none;
			&:hover {
				color: $cl-lightBlue;
			}
		}
	}
}

.area_blog_cont_box {
	.number {
		margin: 0 10px;
		display: inline;
		color: $cl-lightBlue;
		font-family: $font-family-pt-sans;
		font-weight: bold;
		font-size: $fs-12;
		border-bottom: 2px $cl-lightBlue solid;
	}
	.company {
		font-size: $fs-12;
		padding: 10px 0 0;
		color: #9d9d9d;
		font-style: italic;
		// border-top: 1px dotted #c6c6c6;
		background-image: linear-gradient(to right, #C6C6C6, #C6C6C6 1px, transparent 1px, transparent 3px);
		background-size: 4px 1px;
		background-position: top;
		background-repeat: repeat-x;
		margin: 0 10px;
	}
}


/* ---------- sidebar ---------- */

.archive_sidebar {
	width: 274px;
	float: right;
	&_cont {
		background: #f9f9f9;
		border: 1px $cl-lightGreen solid;
		padding: 10px 10px 5px;
		margin-bottom: 30px;
		.tit {
			text-align: center;
			color: $cl-lightGreen;
			padding: 20px 0 30px;
		}
		ul {
			li {
				a {
					display: block;
					text-decoration: none;
					font-size: $fs-13;
					color: $cl-lightGreen;
					background: #fff url(../images/miraimo/common/ico-arw-green.png) no-repeat 96% center;
					line-height: 40px;
					padding: 0 18px;
					margin-bottom: 5px;
					&:hover {
						color: #fff;
						background: $cl-lightGreen;
					}
				}
			}
		}
	}
}

#miraimoblog.post-type-archive-voice,
#miraimoblog.post-type-archive-interview {
	.archive_sidebar {
		&_cont {
			border: 1px $cl-lightBlue solid;
			.tit {
				color: $cl-lightBlue;
			}
			ul {
				li {
					a {
						color: $cl-lightBlue;
						&:hover {
							color: #fff;
							background: $cl-lightBlue;
						}
					}
				}
			}
		}
	}
}

/* ---------- page nation ---------- */

.page-nation {
	letter-spacing: normal;
	ul {
		li {
			padding: 0 6px;
			display: inline-block;
			a {
				display: block;
				text-align: center;
				width: 45px;
				line-height: 48px;
				border-radius: 4px;
				border: 1px $cl-lightGreen solid;
				background: #fff;
				color: $cl-lightGreen;
				text-decoration: none;
			}
			.page-nation ul li span.current {
				display: block;
				text-align: center;
				width: 45px;
				line-height: 48px;
				border-radius: 4px;
				border: 1px #32cca6 solid;
				color: #fff;
				background: #32cca6;
				text-decoration: none;
			}
			&.current,
			&:hover {
				a {
					color: #fff;
					background: $cl-lightGreen;
				}
			}
			
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
			}
		}
	}
}

#miraimoblog.post-type-archive-voice,
#miraimoblog.post-type-archive-interview {
	.page-nation {
		letter-spacing: normal;
		ul {
			li {
				a {
					border: 1px $cl-lightBlue solid;
					color: $cl-lightBlue;
				}
				&.current,
				&:hover {
					a {
						color: #fff;
						background: $cl-lightBlue;
					}
				}
			}
		}
	}
}


/*
  single blog
--------------------------------------------- */

#single-miraimoblog {
	.single_cont {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
		padding-top: 105px;
		
		strong {
			font-weight: bold;
		}
		
		&_sub {
			padding-bottom: 42px;
			@include clearfix;
		}
		&_date {
			line-height: 40px;
			display: inline-block;
			font-weight: bold;
			font-size: $fs-13;
			padding-right: 26px;
		}
		&_report {
			display: inline-block;
			line-height: 26px;
			border: 1px $cl-lightGreen solid;
			border-radius: 14px;
			font-size: $fs-11;
			text-align: center;
			padding: 0 10px;
			color: $cl-lightGreen;
		}
		&_sns {
			float: right;
			text-align: right;
			ul {
				li {
					display: inline-block;
					padding-left: 6px;
					vertical-align: middle;
				}
			}
		}
		&_tit {
			font-size: $fs-32;
			padding-bottom: 40px;
		}
		&_tag {
			padding-bottom: 70px;
			li {
				margin-right: 10px;
				display: inline-block;
				a {
					display: inline-block;
					line-height: 23px;
					border: 1px #ddd solid;
					border-radius: 14px;
					font-size: $fs-12;
					text-align: center;
					padding: 0 20px;
					background: #f9f9f9;
					color: $cl-lightGray;
				}
			}
			.company {
				color: #9d9d9d;
			}
		}
		&_img {
			padding-bottom: 60px;
			img {
				width: 100%;
				height: auto;
			}
		}
		&_txt {
			padding-bottom: 85px;
			p {
				padding-bottom: 3em;
			}
			.sub-tit {
				font-size: $fs-24;
				padding-bottom: 20px;
				line-height: 1.5;
			    padding-left: 50px;
			    text-indent: -50px;
				&:before {
					display: inline-block;
					vertical-align: middle;
					content: "";
					width: 30px;
					height: 2px;
					background: $cl-lightGreen;
					margin-right: 20px;
				}
			}
			.list_number {
				padding-bottom: 30px;
				li {
					position: relative;
					line-height: 22px;
					padding: 0 0 10px 42px;
					&:nth-child(1):before {
						position: absolute;
						left: 0;
						display: inline-block;
						vertical-align: top;
						color: $cl-lightGreen;
						width: 22px;
						line-height: 22px;
						text-align: center;
						content: "1";
						border: 1px $cl-lightGreen solid;
						border-radius: 4px;
						margin: 0 20px 15px 0;
					}
					&:nth-child(2):before {
						position: absolute;
						left: 0;
						display: inline-block;
						vertical-align: top;
						color: $cl-lightGreen;
						width: 22px;
						line-height: 22px;
						text-align: center;
						content: "2";
						border: 1px $cl-lightGreen solid;
						border-radius: 4px;
						margin: 0 20px 15px 0;
					}
					&:nth-child(3):before {
						position: absolute;
						left: 0;
						display: inline-block;
						vertical-align: top;
						color: $cl-lightGreen;
						width: 22px;
						line-height: 22px;
						text-align: center;
						content: "3";
						border: 1px $cl-lightGreen solid;
						border-radius: 4px;
						margin: 0 20px 15px 0;
					}
				}
			}
			.list_mark {
				padding-bottom: 30px;
				li {
					padding: 0 0 10px 42px;
					position: relative;
					line-height: 22px;
					&:before {
						position: absolute;
						left: 0;
						display: inline-block;
						vertical-align: top;
						width: 22px;
						height: 22px;
						content: " ";
						padding: 0 20px 15px 0;
						background: url(../images/miraimo/common/ico-mark.png) no-repeat left top;
					}
				}
			}
			.caption {
				display: inline-block;
				position: relative;
				padding: 0 42px;
				&:before {
					position: absolute;
					left: 0;
					display: inline-block;
					width: 21px;
					height: 21px;
					content: " ";
					background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
					padding-left: 11px;
				}
				p {
					display: inline-block;
					vertical-align: top;
					line-height: 21px;
					padding-bottom: 0;
					color: $cl-lightGray;
					font-style: oblique;
				}
				&:after {
					position: absolute;
					right: 0;
					display: inline-block;
					width: 21px;
					height: 21px;
					content: " ";
					background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
					padding-right: 11px;
				}
			}

			img {
				width: 100%;
				height: auto;
			}
		}
		&_sns-bot {
			padding: 15px 0;
			text-align: center;
			border-top: 1px #ddd solid;
			border-bottom: 1px #ddd solid;
			ul {
				li {
					display: inline-block;
					padding-left: 6px;
					vertical-align: middle;
				}
			}
		}
		&_word {
			padding: 30px;
			background: #f9f9f9;
			ul {
				li {
					display: inline-block;
					margin-right: 2em;
					a {
						color: $cl-lightGreen;
					}
				}
			}
		}
		&_author {
			@include clearfix;
			padding-top: 60px;
			&_img {
				float: left;
				img {
					border-radius: 60px;
				}
			}
			&_box {
				width: 650px;
				float: right;
				&_tit {
					background: url(../images/miraimo/common/ico-pen.png) no-repeat;
					min-height: 30px;
					padding: 4px 0 6px 34px;
					color: $cl-lightGreen;
					border-bottom: 1px $cl-lightGreen solid;
					margin-bottom: 30px;
				}
				&_name {
					font-size: $fs-20;
					padding-bottom: 30px;
					span {
						padding-left: 20px;
						font-size: 13px;
					}
				}
				&_txt {
					color: $cl-lightGray;
				}
				&_link {
					padding-top: 30px;
					text-align: right;
					a {
						color: $cl-lightGreen;
					}
				}
				.career_area {
					margin-bottom: 1em;
					h4 {
						color: $cl-lightGreen;
						font-size: $fs-15;
						font-weight: bold;
					}
				}
				.message_area {
					h4 {
						color: $cl-lightGreen;
						font-size: $fs-15;
						font-weight: bold;
					}
				}
			}
		}
	}
	.area_blog {
		padding-top: 80px;
		.sub-tit {
			font-size: $fs-24;
			font-family: $font-family-sans;
			letter-spacing: .075em;
			text-align: center;
			padding: 20px 0;
			border-top: 1px #ddd solid;
		}
	}

	&.single-voice {

		.area_blog {
			padding-bottom: 0;
		}
	}
}

#single-miraimoblog.single-voice {
	.single_cont {
		&_report {
			border: 1px $cl-lightBlue solid;
			color: $cl-lightBlue;
		}
		&_txt {
			.sub-tit {
				&:before {
					background: $cl-lightBlue;
				}
			}
			.list_number {
				li {
					&:nth-child(1):before {
						color: $cl-lightBlue;
						border: 1px $cl-lightBlue solid;
					}
					&:nth-child(2):before {
						color: $cl-lightBlue;
						border: 1px $cl-lightBlue solid;
					}
					&:nth-child(3):before {
						color: $cl-lightBlue;
						border: 1px $cl-lightBlue solid;
					}
				}
			}
		}
		&_word {
			ul {
				li {
					a {
						color: $cl-lightBlue;
					}
				}
			}
		}
		&_author {
			&_box {
				&_tit {
					color: $cl-lightBlue;
					border-bottom: 1px $cl-lightBlue solid;
				}
				&_link {
					a {
						color: $cl-lightBlue;
					}
				}
			}
		}
	}
}
