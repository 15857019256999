@charset "utf-8";

.home{
  h2,
  h3{
    text-align: center;
    font-weight: 500;
    font-family: $font-family-sans;
    letter-spacing: -.04em;
  }
  h2{
    margin: 0 0 1em;
    @include fontsize(28);
    @media ( max-width : 767px ) {
      @include fontsize(22);
      line-height: 1.4;
    }
    &.text_white{
      color: $color_white;
    }

    span{
      display: block;
      font-weight: normal;
      color: green;
    }
  }
  h3{
    position: relative;
    margin: 0 0 1em;
    padding-left: 1.5em;
    @include fontsize(21);
    text-align: left;
    overflow: hidden;
    @media ( max-width : 767px ) {
      @include fontsize(16);
    }
    &:before,
    &:after{
      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      border-radius: 20px;
    }

    &:before{
      left: 0;
      width: 18px;
      height: 18px;
      margin: auto;
      @include bg_gradation_pink2();
    }
    &:after{
      left: 5px;
      width: 8px;
      height: 8px;
      background-color: $color_white;
      z-index: 1;
    }
  }
}
