//  -------------------- mixin
//　-----　font
@mixin fontsize($size, $base: 10) {
  font-size: $size + px;
  // font-size: ($size / $base) * 1rem;
}

@mixin list-center() {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

@mixin text-writing {
	-ms-writing-mode: tb-rl;
	writing-mode: tb-rl;
	-o-writing-mode: vertical-rl;
	-webkit-writing-mode: vertical-rl;
	writing-mode: vertical-rl;
}

@mixin position-center() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

//　-----　clearfix
@mixin clearfix {
	*zoom: 1;
	&:after {
		content: ".";
		display: block;
		clear: both;
		height: 0;
		visibility: hidden;
	}
}

// ----- btn
@mixin btn-70 {
	a {
		font-family: $font-family-sans;
		font-size: $fs-18;
		letter-spacing: .1em;
		text-decoration: none;
		text-align: center;
		display: block;
		background: #fff;
		line-height: 66px;
		border-radius: 35px;
		margin: 0 auto;
		&:hover {
			color: #fff;
		}
		@media (max-width: 767px) {
			font-size: $fs-12;
			line-height: 46px;
			border-radius: 25px;
		}
	}
}

// ----- title
@mixin tit-line-cont {
	position: relative;
	text-align: center;
	margin-bottom: 20px;
	&:before {
		width: 100%;
		content: "";
		border-top: 1px #ddd solid;
		@include wv-center;
		z-index: 1;
	}
	@media (max-width: 767px) {
		margin-bottom: 10px;
	}
}

// ----- color gradation

@mixin bg_gradation_pink {
  background-color: #ef717d;
  background-image: linear-gradient(90deg, #ef717d 0%, #f2986e 100%);
}

@mixin bg_gradation_pink2 {
  background-color: #ef717d;
  background-image: linear-gradient(180deg, #ef717d 25%, #f2986e 100%);
}

@mixin bg_gradation_blue {
  background-color: #1191d1;
  background-image: linear-gradient(90deg, #1191d1 0%, #15aba6 100%);
}







@mixin gr-blue {
	background: #c5f3e2;
	background: -moz-linear-gradient(left, #c5f3e2 0%, #d4e0fc 100%);
	background: -webkit-linear-gradient(left, #c5f3e2 0%, #d4e0fc 100%);
	background: linear-gradient(to right, #c5f3e2 0%, #d4e0fc 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c5f3e2', endColorstr='#d4e0fc', GradientType=1);
}

@mixin gr-pink {
	background: #b7ddf1;
	background: -moz-linear-gradient(left, #b7ddf1 0%, #fad4d8 100%);
	background: -webkit-linear-gradient(left, #b7ddf1 0%, #fad4d8 100%);
	background: linear-gradient(to right, #b7ddf1 0%, #fad4d8 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b7ddf1', endColorstr='#fad4d8', GradientType=1);
}

@mixin gr-green {
	background: #cdeedc;
	background: -moz-linear-gradient(left, #cdeedc 0%, #f2f3cc 100%);
	background: -webkit-linear-gradient(left, #cdeedc 0%, #f2f3cc 100%);
	background: linear-gradient(to right, #cdeedc 0%, #f2f3cc 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cdeedc', endColorstr='#f2f3cc', GradientType=1);
}

@mixin gr-btn-blue {
	background: #1090d2;
	background: -moz-linear-gradient(left, #1090d2 0%, #36b7b2 100%);
	background: -webkit-linear-gradient(left, #1090d2 0%, #36b7b2 100%);
	background: linear-gradient(to right, #1090d2 0%, #36b7b2 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1090d2', endColorstr='#36b7b2', GradientType=1);
}

@mixin gr-btn-pink {
	background: #ef707d;
	background: -moz-linear-gradient(left, #ef707d 0%, #f2986f 100%);
	background: -webkit-linear-gradient(left, #ef707d 0%, #f2986f 100%);
	background: linear-gradient(to right, #ef707d 0%, #f2986f 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef707d', endColorstr='#f2986f', GradientType=1);
}

@mixin gr-btn-green {
	background: #34c272;
	background: -moz-linear-gradient(left, #34c272 0%, #0bd05f 100%);
	background: -webkit-linear-gradient(left, #34c272 0%, #0bd05f 100%);
	background: linear-gradient(to right, #34c272 0%, #0bd05f 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#34c272', endColorstr='#0bd05f', GradientType=1);
}

@mixin gr-btn-orange {
	background: #ff9537;
	background: -moz-linear-gradient(left, #ff9537 0%, #ff6845 100%);
	background: -webkit-linear-gradient(left, #ff9537 0%, #ff6845 100%);
	background: linear-gradient(to right, #ff9537 0%, #ff6845 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9537', endColorstr='#ff6845', GradientType=1);
}


// placeholder
@mixin placeholder($color) {
	&::-webkit-input-placeholder {
		color:$color;
	}
	&::-moz-placeholder {
		color:$color;
	}
	&:-ms-input-placeholder {
		color:$color;
	}
}
