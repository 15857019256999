// ----- fonts

$font-family: Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;

$font-family-sans: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;

$font-family-pt-sans: 'PT Sans', sans-serif;

$font-family-yugothic: '游ゴシック', 'Yu Gothic', '游ゴシック体', 'YuGothic', 'ヒラギノ角ゴシック', 'Hiragino Sans', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

/* イレギュラーなfont-familyの設定 */
// $fontFamilyIrregular: '游ゴシック', 'Yu Gothic', '游ゴシック体', 'YuGothic', 'ヒラギノ角ゴシック', 'Hiragino Sans', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

$font-size: 14px;
$font-size-sp: 12px;

$line-height: 1.8;
$line-height-sp: 1.8;

$fs-10: .71em;
$fs-11: .78em;
$fs-12: .85em;
$fs-13: .92em;
$fs-15: 1.07em;
$fs-16: 1.14em;
$fs-17: 1.21em;
$fs-18: 1.28em;
$fs-19: 1.35em;
$fs-20: 1.42em;
$fs-21: 1.5em;
$fs-22: 1.57em;
$fs-23: 1.64em;
$fs-24: 1.71em;

$fs-28: 2em;

$fs-32: 2.28em;
$fs-48: 3.42em;

// ----- colors

$color: #2d2d2d;
$cl-purple: #9d56e4;
$cl-lightBlue: #00b9d8;
$cl-blue: #0c4f93;
$cl-lightGreen: #32cca6;
$cl-green: #02c255;
$cl-orange: #ff9537;
$cl-yellow: #ffd200;
$cl-red: #f85550;
$cl-lightGray: #666;
$cl-gray: #8a8a8a;
$color_white: #fff;

// ----- layout

$max-width: 1200px;
$min-width: 1040px;


// $container
$container: 1200;
$header_break_point: 1024;

// colors main
$color_primary: red;
$color_sub: #f5f5f5;

$color_text: #000;
$color_blue: #14a5ca;

// button
$button_radius: 0;
// $button-more-arrow: 'right';

$color_button_arrow: $color_white;
$color_button_text: $color_text;
$color_button_text_hover: $color_button_text;
$color_button_bg: $color_white;
$color_button_hover: $color_white;

$color_button_border: 1px solid #f4f4f4;
$color_button_border_inset: 0 0 0 2px #e1e1e1 inset;

//more
$color_more_text: $color_white;
$color_more_text_hover: $color_more_text;
$color_more_bg_hover: $color_sub;
