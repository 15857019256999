@charset "UTF-8";
@import url('//fonts.googleapis.com/css?family=PT+Sans');

@import "component/reset";
@import "component/miraimo/mixin";
@import "component/miraimo/config";
@import "component/base";

@import "component/miraimo/grid";
@import "component/miraimo/headline";
@import "component/miraimo/common";
@import "component/miraimo/button";
@import "component/miraimo/custom";

@import "component/miraimo/header";


@media (min-width: 768px) {
	@import "parts/miraimo/pc/pc-common";
	@import "parts/miraimo/pc/pc-index";
	@import "parts/miraimo/pc/pc-archive";
	@import "parts/miraimo/pc/pc-careeradvisor";
	@import "parts/miraimo/pc/pc-flow";
	@import "parts/miraimo/pc/pc-faq";
	@import "parts/miraimo/pc/pc-guidebook-archive";
	@import "parts/miraimo/pc/pc-guidebook-single";
	@import "parts/miraimo/pc/pc-job-archive";
	@import "parts/miraimo/pc/pc-job-single";
	@import "parts/miraimo/pc/pc-member";
 	@import "parts/miraimo/pc/pc-registnow";
	@import "parts/miraimo/pc/pc-interview";
	//add yuuki.u 20180828
 	@import "parts/miraimo/pc/pc-parts";
	//add ykit 20180326
	@import "parts/miraimo/pc/pc-lp1";
}

@media (max-width: 767px) {
	@import "parts/miraimo/sp/sp-common";
	@import "parts/miraimo/sp/sp-index";
	@import "parts/miraimo/sp/sp-archive";
	@import "parts/miraimo/sp/sp-careeradvisor";
	@import "parts/miraimo/sp/sp-flow";
	@import "parts/miraimo/sp/sp-faq";
	@import "parts/miraimo/sp/sp-guidebook-archive";
	@import "parts/miraimo/sp/sp-guidebook-single";
	@import "parts/miraimo/sp/sp-job-archive";
	@import "parts/miraimo/sp/sp-job-single";
	@import "parts/miraimo/sp/sp-member";
 	@import "parts/miraimo/sp/sp-registnow";
	@import "parts/miraimo/sp/sp-interview";
	//add yuuki.u 20180828
	@import "parts/miraimo/sp/sp-parts";
	//add ykit 20180326
	@import "parts/miraimo/sp/sp-lp1";
}
