/*
  flow
--------------------------------------------- */
#flow{
	.area_key{
		.area_tit{
			span{
				color: $cl-orange;
			}
		}
	}
}

.flow_cont {
	margin: 0 auto;
	.flow_box {
		border: solid 1px #ddd;
		background-color: #fff;
		margin-bottom: 30px;
		.float,
		.overflow {
			box-sizing: border-box;
		}
		.float {
			clear: both;
		}
		.flow_box_step {
			color: $cl-orange;
			font-family: $font-family-pt-sans;
			border-top: solid 5px $cl-orange;
			text-align: center;
			padding: 0;
			padding-top: 14px;
			.step {
				font-size: $fs-13;
			}
			.count {
				font-size: $fs-32;
				border-top: solid 2px $cl-orange;
				line-height: normal;
				display: inline;
			}
		}
		.overflow {
			overflow: hidden;
		}
		.flow_box_text {
			border-top: none;
			.text_area {
				border-left: none;
				border-top: solid 1px #ddd;
				padding: 0;
				padding-top: 14px;
				margin: 14px 4%;
				h3 {
					color: $cl-orange;
					font-weight: bold;
					font-size: $fs-18;
					text-align: center;
				}
				.sub_title {
					font-weight: bold;
					font-size: $fs-15;
				}
				p {
					font-size: $fs-12;
					margin-top: 10px;
					a {
						color: $cl-orange;
					}
				}
			}
		}
	}
	.flow_box:after {
		display: block;
		content: "";
		clear: both;
	}
	.flow_box:last-child {
		margin-bottom: 0;
	}
	.text_box {
		text-align: center;
		margin-bottom: 30px;
		p {
			margin-top: 10px;
		}
		.sub_title {
			font-weight: bold;
			font-size: $fs-15;
		}
		.sub_txt {
			font-size: $fs-12;
		}
	}
}

/* ---------- flow btn ---------- */
.flow_btn_box {
	font-size: 0;
	// padding: 0 0 50px;
	margin: 30px 0 50px;
	.flow_btn {
		// width: 50%;
		padding: 0 1%;
		// display: inline-block;
		font-size: $font-size;
		@include btn-70;
		a {
			width: 100%;
			font-size: $font-size;
			max-width: 400px;
			color: $cl-blue;
			font-size: $fs-12;
			border: 2px $cl-blue solid;
			&:hover {
				background: $cl-blue;
			}
		}
		&.about {
			margin-bottom: 15px;
			a {
				color: $cl-blue;
				border: 2px $cl-blue solid;
				&:hover {
					background: $cl-blue;
					color: #FFF;
				}
			}
		}
	}
}
