@charset "UTF-8";
@import url("//fonts.googleapis.com/css?family=PT+Sans");
/*
  reset
--------------------------------------------- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

input[type="button"], input[type="submit"] {
  -webkit-appearance: none; }

/* イレギュラーなfont-familyの設定 */
/*
  base
--------------------------------------------- */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

img {
  border-style: none;
  display: block;
  vertical-align: bottom; }

a,
a img,
button {
  transition: .4s; }

a img:hover {
  opacity: .7; }

a:link {
  color: #2d2d2d;
  text-decoration: none; }

a:visited {
  color: #2d2d2d; }

a:hover {
  color: #2d2d2d;
  text-decoration: none; }

.center {
  text-align: center; }

.row .col {
  margin-bottom: 2%; }
  @media (max-width: 767px) {
    .row .col {
      margin-bottom: 4%;
      width: 100%; } }

[class*="span_"] {
  float: left;
  min-height: 1px; }

@media (min-width: 768px) {
  .container:not(.gutter) .span_1 {
    width: calc(8.33333%); }
  .container:not(.gutter) .span_2 {
    width: calc(16.66667%); }
  .container:not(.gutter) .span_3 {
    width: calc(25%); }
  .container:not(.gutter) .span_4 {
    width: calc(33.33333%); }
  .container:not(.gutter) .span_5 {
    width: calc(41.66667%); }
  .container:not(.gutter) .span_6 {
    width: calc(50%); }
  .container:not(.gutter) .span_7 {
    width: calc(58.33333%); }
  .container:not(.gutter) .span_8 {
    width: calc(66.66667%); }
  .container:not(.gutter) .span_9 {
    width: calc(75%); }
  .container:not(.gutter) .span_10 {
    width: calc(83.33333%); }
  .container:not(.gutter) .span_11 {
    width: calc(90.66667%); }
  .container:not(.gutter) .span_12 {
    width: calc(100%); }
  .container:not(.gutter) .offset_1 {
    margin-left: calc(8.33333%); }
  .container:not(.gutter) .offset_2 {
    margin-left: calc(16.66667%); }
  .container:not(.gutter) .offset_3 {
    margin-left: calc(25%); }
  .container:not(.gutter) .offset_4 {
    margin-left: calc(33.33333%); }
  .container:not(.gutter) .offset_5 {
    margin-left: calc(41.66667%); }
  .container:not(.gutter) .offset_6 {
    margin-left: calc(50%); }
  .container:not(.gutter) .offset_7 {
    margin-left: calc(58.33333%); }
  .container:not(.gutter) .offset_8 {
    margin-left: calc(66.66667%); }
  .container:not(.gutter) .offset_9 {
    margin-left: calc(75%); }
  .container:not(.gutter) .offset_10 {
    margin-left: calc(83.33333%); }
  .container:not(.gutter) .offset_11 {
    margin-left: calc(90.66667%); }
  .container:not(.gutter) .offset_12 {
    margin-left: calc(100%); } }

@media (max-width: 767px) {
  .container:not(.gutter) .row.sp_column2 .col {
    width: 50%; }
  .container:not(.gutter) .row.sp_column3 .col {
    width: 33.33333%; } }

@media (min-width: 768px) {
  .gutter > .row {
    margin-left: -20px; } }

@media (max-width: 767px) {
  .gutter > .row.sp_column2, .gutter > .row.sp_column3 {
    margin-left: -1.2em; }
    .gutter > .row.sp_column2 [class*="span_"], .gutter > .row.sp_column3 [class*="span_"] {
      margin-left: 1.2em; } }

@media (min-width: 768px) {
  .gutter [class*="span_"] {
    margin-left: 20px; } }

@media (min-width: 768px) {
  .gutter .span_1 {
    width: calc(8.33333% - 20px); }
  .gutter .span_2 {
    width: calc(16.66667% - 20px); }
  .gutter .span_3 {
    width: calc(25% - 20px); }
  .gutter .span_4 {
    width: calc(33.33333% - 20px); }
  .gutter .span_5 {
    width: calc(41.66667% - 20px); }
  .gutter .span_6 {
    width: calc(50% - 20px); }
  .gutter .span_7 {
    width: calc(58.33333% - 20px); }
  .gutter .span_8 {
    width: calc(66.66667% - 20px); }
  .gutter .span_9 {
    width: calc(75% - 20px); }
  .gutter .span_10 {
    width: calc(83.33333% - 20px); }
  .gutter .span_11 {
    width: calc(90.66667% - 20px); }
  .gutter .span_12 {
    width: calc(100% - 20px); }
  .gutter .offset_1 {
    margin-left: calc(8.33333% + 20px); }
  .gutter .offset_2 {
    margin-left: calc(16.66667% + 20px); }
  .gutter .offset_3 {
    margin-left: calc(25% + 20px); }
  .gutter .offset_4 {
    margin-left: calc(33.33333% + 20px); }
  .gutter .offset_5 {
    margin-left: calc(41.66667% + 20px); }
  .gutter .offset_6 {
    margin-left: calc(50% + 20px); }
  .gutter .offset_7 {
    margin-left: calc(58.33333% + 20px); }
  .gutter .offset_8 {
    margin-left: calc(66.66667% + 20px); }
  .gutter .offset_9 {
    margin-left: calc(75% + 20px); }
  .gutter .offset_10 {
    margin-left: calc(83.33333% + 20px); }
  .gutter .offset_11 {
    margin-left: calc(90.66667% + 20px); }
  .gutter .offset_12 {
    margin-left: calc(100% + 20px); } }

@media (max-width: 767px) {
  .gutter .row.sp_column2 .col {
    width: calc(50% - 1.2em); }
  .gutter .row.sp_column3 .col {
    width: calc(33.33333% - 1.2em); } }

.home h2,
.home h3 {
  text-align: center;
  font-weight: 500;
  font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
  letter-spacing: -.04em; }

.home h2 {
  margin: 0 0 1em;
  font-size: 28px; }
  @media (max-width: 767px) {
    .home h2 {
      font-size: 22px;
      line-height: 1.4; } }
  .home h2.text_white {
    color: #fff; }
  .home h2 span {
    display: block;
    font-weight: normal;
    color: green; }

.home h3 {
  position: relative;
  margin: 0 0 1em;
  padding-left: 1.5em;
  font-size: 21px;
  text-align: left;
  overflow: hidden; }
  @media (max-width: 767px) {
    .home h3 {
      font-size: 16px; } }
  .home h3:before, .home h3:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 20px; }
  .home h3:before {
    left: 0;
    width: 18px;
    height: 18px;
    margin: auto;
    background-color: #ef717d;
    background-image: linear-gradient(180deg, #ef717d 25%, #f2986e 100%); }
  .home h3:after {
    left: 5px;
    width: 8px;
    height: 8px;
    background-color: #fff;
    z-index: 1; }

header,
footer {
  font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ヒラギノ角ゴシック", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }

header {
  position: relative; }
  @media (min-width: 1024px) {
    header.mr80 {
      margin-right: 80px; } }
  header > .container {
    position: relative;
    padding: 0; }

@media (min-width: 768px) and (max-width: 1023px) {
  .hdr_login.mr80 {
    margin-right: 80px; } }

@media (min-width: 1024px) {
  main.mr80 {
    margin-right: 80px; } }
  @media (min-width: 1024px) and (max-width: 1350px) {
    main.mr80 section > .container {
      width: 94% !important; } }

ul.list-unstyled {
  padding-left: 0;
  list-style: none; }
  ul.list-unstyled ol,
  ul.list-unstyled ul {
    padding-left: 2em; }

a.underline {
  text-decoration: underline; }

figure {
  margin: 0;
  text-align: center; }
  figure figcaption {
    margin-top: 0.5em;
    text-align: left; }

section > .container {
  padding: 0; }
  @media (max-width: 1023px) {
    section > .container {
      margin: auto;
      width: 90%; } }

.container {
  margin: 0 auto;
  width: 100%; }
  @media (min-width: 768px) {
    .container {
      width: 100%;
      max-width: 1200px; } }

img {
  vertical-align: middle;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; }
  img.circle {
    border-radius: 50%; }

.mb0 {
  margin-bottom: 0; }

.pa0 {
  padding: 0; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

@media (min-width: 768px) {
  .pc-text-center {
    text-align: center; } }

.container img {
  max-width: 100%; }

.highlight {
  background-color: #f5f5f5; }

.clearfix {
  clear: both; }

.pull-left {
  float: left; }

@media (max-width: 767px) {
  .pc-only {
    display: none !important; } }

@media (min-width: 768px) {
  .sp-only {
    display: none !important; } }

span.ofi {
  display: block;
  position: relative;
  overflow: hidden;
  padding-top: 75%; }

.ofi img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%; }

.add_arrow {
  position: relative;
  display: block; }
  .add_arrow:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 1.2em;
    bottom: 0;
    width: 11px;
    height: 11px;
    margin: auto;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: translate(0, 0) rotate(45deg); }

.more {
  margin: 0 auto;
  text-align: center;
  clear: both; }
  .more a {
    display: inline-block;
    margin: auto;
    padding: 1.1em;
    width: 100%;
    max-width: 390px;
    color: #fff;
    background-color: #1191d1;
    background-image: linear-gradient(90deg, #1191d1 0%, #15aba6 100%);
    border-radius: 0px;
    transition: .5s;
    font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
    font-size: 18px; }
    @media (max-width: 767px) {
      .more a {
        margin: 0 auto;
        min-width: 90%;
        font-size: 14px; } }
    .more a:hover {
      color: #000;
      background: inherit;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
      transition: .5s; }
      .more a:hover span:after {
        border-color: #000;
        transition: .5s; }
  .more span {
    position: relative; }
    .more span:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      right: -1.2em;
      bottom: 0;
      width: 5px;
      height: 5px;
      margin: auto;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: translate(0, 0) rotate(45deg);
      transition: .5s; }

.home section {
  padding: 6em 0; }
  @media (max-width: 767px) {
    .home section {
      padding: 3em 0; } }

.page_top {
  position: fixed;
  right: 1.1%;
  bottom: 260px;
  width: 30px;
  height: 38px;
  background-image: url(../images/miraimo/common/page_top.png);
  background-repeat: no-repeat;
  background-position: center 0;
  display: none;
  z-index: 1000; }
  @media (max-width: 767px) {
    .page_top {
      bottom: 6em; } }
  .page_top a {
    display: block;
    height: 40px;
    cursor: pointer;
    overflow: hidden; }
  .page_top span {
    opacity: 0; }

.main_visual {
  position: relative;
  overflow: hidden; }
  .main_visual::before {
    content: "";
    display: block;
    padding-top: calc(650 / 1360 * 100%); }
  @media (max-width: 767px) {
    .main_visual::before {
      padding-top: calc(909 / 750 * 100%); } }
  .main_visual .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }
  .main_visual .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0; }
  .main_visual .slick-list:focus {
    outline: none; }
  .main_visual .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }
  .main_visual .slick-slider .slick-track,
  .main_visual .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .main_visual .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .main_visual .slick-track:before,
  .main_visual .slick-track:after {
    display: table;
    content: ''; }
  .main_visual .slick-track:after {
    clear: both; }
  .main_visual .slick-loading .slick-track {
    visibility: hidden; }
  .main_visual .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px; }
  .main_visual [dir='rtl'] .slick-slide {
    float: right; }
  .main_visual .slick-slide img {
    display: block; }
  .main_visual .slick-slide.slick-loading img {
    display: none; }
  .main_visual .slick-slide.dragging img {
    pointer-events: none; }
  .main_visual .slick-initialized .slick-slide {
    display: block; }
  .main_visual .slick-loading .slick-slide {
    visibility: hidden; }
  .main_visual .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }
  .main_visual .slick-arrow.slick-hidden {
    display: none; }
  .main_visual .main_visual_slider {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .main_visual .main_visual_slide img {
    width: 100%;
    height: auto; }
  .main_visual .main_visual_copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
  .main_visual .main_copy {
    color: #ef7f7f;
    font-size: 52px;
    text-align: center;
    line-height: 1;
    letter-spacing: .05em;
    white-space: nowrap; }
    .main_visual .main_copy > span {
      font-weight: bold; }
    @media (max-width: 767px) {
      .main_visual .main_copy {
        font-size: 32px;
        line-height: 41px; } }
  .main_visual .main_copy_sub {
    color: #fff;
    font-size: 24px;
    line-height: 1.75;
    text-align: center;
    white-space: nowrap;
    margin-top: 1em; }
    @media (max-width: 767px) {
      .main_visual .main_copy_sub {
        font-size: 16px;
        line-height: 1.625; } }

.slider_logo {
  overflow: hidden;
  height: 100px;
  background-color: #fff; }
  .slider_logo .row {
    clear: both; }
    .slider_logo .row .col {
      float: left;
      text-align: center; }
  .slider_logo img {
    width: 100%;
    height: auto; }

.sec_job {
  overflow: hidden; }
  .sec_job h3 {
    margin-top: 2em; }

.sec_job_search_hotword {
  font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ヒラギノ角ゴシック", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }
  .sec_job_search_hotword dt,
  .sec_job_search_hotword dd {
    display: inline-block; }
    @media (max-width: 767px) {
      .sec_job_search_hotword dt,
      .sec_job_search_hotword dd {
        font-size: 14px; } }
  .sec_job_search_hotword dt {
    position: relative;
    padding: 0 1em 0 1.6em;
    font-size: 18px; }
    .sec_job_search_hotword dt:before {
      display: block;
      content: '';
      position: absolute;
      top: auto;
      left: 0;
      bottom: 0;
      width: 22px;
      height: 28px;
      margin: auto;
      background-image: url(../images/miraimo/hot_word.png);
      background-repeat: no-repeat;
      background-position: center 0;
      background-size: 16px auto; }
  .sec_job_search_hotword dd {
    font-size: 16px;
    color: #14a5ca; }
    .sec_job_search_hotword dd a {
      color: #14a5ca;
      font-size: 14px; }
      .sec_job_search_hotword dd a > span {
        text-decoration: underline; }
      .sec_job_search_hotword dd a + a {
        position: relative;
        margin-left: 10px; }
        .sec_job_search_hotword dd a + a:before {
          display: block;
          content: ',';
          position: absolute;
          top: -6px;
          left: -10px;
          bottom: 0;
          margin: auto; }
  .sec_job_search_hotword a + a,
  .sec_job_search_hotword a + u,
  .sec_job_search_hotword u + a,
  .sec_job_search_hotword u + u {
    margin-left: .5em; }

.sec_job_news,
.sec_job_search {
  padding: 0 !important; }

.sec_job_search .row {
  overflow: hidden; }
  @media (min-width: 768px) {
    .sec_job_search .row {
      margin-left: -20px; } }
  .sec_job_search .row .col {
    position: relative;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 4px; }
    @media (min-width: 768px) {
      .sec_job_search .row .col {
        margin-top: 2em;
        margin-bottom: 1.5em; } }
    .sec_job_search .row .col:not(.sec_job_search_text):after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      right: 1em;
      bottom: 0;
      width: 5px;
      height: 5px;
      margin: auto;
      border-top: 2px solid #1299c3;
      border-right: 2px solid #1299c3;
      transform: translate(0, 0) rotate(45deg);
      transition: .5s; }
      @media (max-width: 767px) {
        .sec_job_search .row .col:not(.sec_job_search_text):after {
          right: 1.2em;
          width: 7px;
          height: 7px;
          transform: translate(0, 0) rotate(135deg); } }

@media (min-width: 768px) {
  .sec_job_search .box {
    margin: 0 auto;
    padding: 0 2em 1.5em; } }

@media (max-width: 767px) {
  .sec_job_search .highlight {
    position: relative;
    padding: 7% 0; }
    .sec_job_search .highlight:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: -2em;
      width: 150%;
      height: 100%;
      margin: auto;
      background-color: #f5f5f5;
      z-index: -1; } }

.sec_job_search select {
  padding: 1em; }

.sec_job_search select,
.sec_job_search input {
  width: 100%;
  font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ヒラギノ角ゴシック", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 16px;
  cursor: pointer;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  background: transparent;
  background-image: none;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none; }
  @media (max-width: 767px) {
    .sec_job_search select,
    .sec_job_search input {
      font-size: 14px; } }

.sec_job_search .sec_job_search_text input {
  padding: 1.1em 2em 1.1em 1em; }

.sec_job_search .sec_job_search_text button {
  position: absolute;
  top: 0;
  right: 1%;
  bottom: 0;
  border: 0;
  background-color: #fff; }
  .sec_job_search .sec_job_search_text button img {
    width: 20px;
    height: 20px; }

.sec_job_news {
  padding: 0; }
  .sec_job_news .ul {
    margin-bottom: 4em;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    overflow: hidden; }
    @media (max-width: 767px) {
      .sec_job_news .ul {
        margin-bottom: 2em; } }
    .sec_job_news .ul li {
      position: relative; }
      .sec_job_news .ul li > a {
        display: block;
        padding: 2em; }
        @media (max-width: 767px) {
          .sec_job_news .ul li > a {
            padding: 1em; } }
        .sec_job_news .ul li > a .sec_job_news_title,
        .sec_job_news .ul li > a .sec_job_news_more {
          color: #14a5ca;
          transition: .5s; }
        .sec_job_news .ul li > a .sec_job_news_title_about {
          color: #000; }
        .sec_job_news .ul li > a:hover .sec_job_news_title,
        .sec_job_news .ul li > a:hover .sec_job_news_more {
          color: #000;
          transition: .5s; }
        .sec_job_news .ul li > a:hover .sec_job_news_more span:after {
          right: -1em;
          border-color: #000;
          transition: .22s; }
      .sec_job_news .ul li + li {
        border-top: 1px solid #e5e5e5; }
  .sec_job_news .underline span {
    text-decoration: underline; }
    .sec_job_news .underline span + span {
      position: relative;
      margin-left: 10px; }
      .sec_job_news .underline span + span:before {
        display: block;
        content: '、';
        position: absolute;
        top: -5px;
        left: -10px;
        bottom: 0;
        margin: auto; }
  .sec_job_news .sec_job_news_title {
    font-size: 18px;
    font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
    font-weight: 500; }
    @media (min-width: 768px) {
      .sec_job_news .sec_job_news_title {
        width: calc(100% - 120px); } }
    @media (max-width: 767px) {
      .sec_job_news .sec_job_news_title {
        font-size: 16px; } }
    .sec_job_news .sec_job_news_title > a {
      color: #14a5ca;
      transition: .5s; }
      .sec_job_news .sec_job_news_title > a:hover {
        color: #000;
        transition: .5s; }
    .sec_job_news .sec_job_news_title .news {
      margin-left: 1em;
      padding: .3em .8em;
      color: #fff;
      font-weight: normal;
      background-color: #ef717d;
      background-image: linear-gradient(90deg, #ef717d 0%, #f2986e 100%);
      border-radius: 20px;
      font-size: 12px;
      vertical-align: text-top; }
      @media (max-width: 767px) {
        .sec_job_news .sec_job_news_title .news {
          font-size: 8.4px; } }
  .sec_job_news .sec_job_news_title_about {
    margin-top: .7em;
    font-size: 14px;
    font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ヒラギノ角ゴシック", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-weight: normal; }
    @media (max-width: 767px) {
      .sec_job_news .sec_job_news_title_about {
        font-size: 12px; } }
  .sec_job_news .sec_job_news_more {
    position: absolute;
    top: 0;
    left: auto;
    right: 3.5em;
    bottom: 0;
    margin: auto;
    height: 15px; }
    @media (max-width: 767px) {
      .sec_job_news .sec_job_news_more {
        display: none; } }
    .sec_job_news .sec_job_news_more span {
      position: relative;
      font-size: 14px;
      font-family: "PT Sans", sans-serif;
      font-weight: bold; }
      .sec_job_news .sec_job_news_more span:after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        right: -1.2em;
        bottom: 0;
        width: 5px;
        height: 5px;
        margin: auto;
        border-top: 2px solid #14a5ca;
        border-right: 2px solid #14a5ca;
        transform: translate(0, 0) rotate(45deg);
        transition: .22s; }

.sec_blog {
  position: relative; }
  .sec_blog:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    background-image: url(../images/miraimo/bg_02.png);
    background-repeat: no-repeat;
    background-position: right 45px; }
  .sec_blog .col {
    margin-bottom: 3.5%; }
    .sec_blog .col > a {
      display: block; }
      .sec_blog .col > a .sec_blog_text_title {
        color: #14a5ca;
        transition: .5s; }
      .sec_blog .col > a:hover .sec_blog_text_title {
        color: #000;
        transition: .5s; }
  .sec_blog .sec_blog_img,
  .sec_blog .sec_blog_text {
    float: left; }
  .sec_blog .sec_blog_img {
    width: 40%; }
    .sec_blog .sec_blog_img .ofi img {
      max-width: 130%;
      max-height: 130%; }
  .sec_blog .sec_blog_text {
    padding: 0 1em;
    width: 60%;
    font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif; }
  .sec_blog .sec_blog_text_data {
    display: block;
    margin-bottom: .7em;
    font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ヒラギノ角ゴシック", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 14px; }
    @media (max-width: 767px) {
      .sec_blog .sec_blog_text_data {
        margin-bottom: .3em;
        font-size: 12px; } }
  .sec_blog .sec_blog_text_title {
    line-height: 1.4;
    font-size: 18px;
    font-weight: 500; }
    @media (max-width: 767px) {
      .sec_blog .sec_blog_text_title {
        font-size: 16px; } }

.sec_guide .text-center {
  font-size: 16px;
  line-height: 26px; }
  @media (max-width: 767px) {
    .sec_guide .text-center {
      font-size: 13px;
      line-height: 24px; } }

.sec_guide .row {
  margin-top: 3em; }

@media (min-width: 768px) and (max-width: 1200px) {
  .sec_guide .row > .col {
    width: calc((100% / 3) - 20px); } }

.sec_guide .sec_guide_link {
  position: relative;
  display: block;
  transition: .5s;
  background-color: #f5f5f5;
  border-radius: 12px; }
  @media (min-width: 768px) {
    .sec_guide .sec_guide_link {
      height: 100%;
      min-height: 190px; } }
  .sec_guide .sec_guide_link:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    border-radius: 12px; }
    .sec_guide .sec_guide_link:hover .sec_guide_box_text {
      color: #000;
      transition: .5s; }
      .sec_guide .sec_guide_link:hover .sec_guide_box_text::after {
        border-color: #000;
        transition: .5s; }

.sec_guide .sec_guide_box {
  width: 100%; }
  @media (min-width: 768px) {
    .sec_guide .sec_guide_box {
      padding: 2.8em 2em;
      text-align: center; } }

.sec_guide .sec_guide_box_title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
  font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif; }
  @media (max-width: 767px) {
    .sec_guide .sec_guide_box_title {
      font-size: 16px; } }

.sec_guide .sec_guide_box_text {
  color: #14a5ca;
  font-size: 16px;
  line-height: 1.5;
  font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
  margin-top: .75em; }
  .sec_guide .sec_guide_box_text::after {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-right: 2px solid #14a5ca;
    border-bottom: 2px solid #14a5ca;
    transform: rotate(-45deg);
    position: relative;
    bottom: 2px; }
  @media (max-width: 767px) {
    .sec_guide .sec_guide_box_text {
      font-size: 16px;
      line-height: 1.4; } }

@media (max-width: 767px) {
  .sec_guide .sec_guide_box {
    padding: 1.5em .5em; }
  .sec_guide .sec_guide_box_title {
    font-size: 14px;
    line-height: 1.3;
    height: auto; }
  .sec_guide .sec_guide_box_text {
    font-size: 12px;
    text-align: center;
    margin-top: .5em; }
    .sec_guide .sec_guide_box_text::after {
      width: 4px;
      height: 4px; } }

.sec_intro {
  background-image: url(../images/miraimo/bg_01.png);
  background-repeat: no-repeat;
  background-position: right bottom -60px; }
  .sec_intro p {
    font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ヒラギノ角ゴシック", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 18px;
    line-height: 42px;
    text-align: center; }
  @media (max-width: 767px) {
    .sec_intro {
      margin: auto;
      width: 90%; }
      .sec_intro p {
        font-size: 13px;
        line-height: 24px; } }

.sec_advice {
  position: relative;
  background-color: #ef717d;
  background-image: linear-gradient(90deg, #ef717d 0%, #f2986e 100%); }
  .sec_advice:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    background-image: url(../images/miraimo/bg_03.png);
    background-repeat: no-repeat;
    background-position: 0 bottom; }
  .sec_advice p,
  .sec_advice a {
    color: #fff; }
  .sec_advice h2 {
    position: relative; }
    .sec_advice h2 span {
      display: inline-block;
      color: #fff;
      font-weight: bold; }
  .sec_advice p:not(.more) {
    font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ヒラギノ角ゴシック", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 16px;
    line-height: 2; }
    @media (max-width: 767px) {
      .sec_advice p:not(.more) {
        font-size: 12px; } }
  .sec_advice .more a {
    background: inherit;
    border: 1px solid #fff; }
    .sec_advice .more a:hover {
      background-color: #f5f5f5; }
  .sec_advice .sec_advice_img {
    margin: .7em 0;
    width: 100%;
    text-align: center;
    letter-spacing: -.4em;
    overflow: hidden; }
    @media (max-width: 767px) {
      .sec_advice .sec_advice_img {
        margin: 1em 0; } }
    .sec_advice .sec_advice_img li {
      display: inline-block;
      letter-spacing: normal;
      text-align: center; }
      .sec_advice .sec_advice_img li:last-child {
        margin-right: 0; }
      @media (max-width: 767px) {
        .sec_advice .sec_advice_img li {
          width: 25%; }
          .sec_advice .sec_advice_img li figure {
            width: 90%; }
          .sec_advice .sec_advice_img li:nth-child(even) {
            margin-right: 0; } }
      @media (min-width: 768px) {
        .sec_advice .sec_advice_img li {
          margin-right: 20px; } }
    @media (max-width: 767px) {
      .sec_advice .sec_advice_img figure {
        margin: 0 auto;
        width: 90%; } }

.sec_interview {
  position: relative; }
  .sec_interview:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    background-image: url(../images/miraimo/bg_01.png);
    background-repeat: no-repeat;
    background-position: 0 0; }
  .sec_interview .col > a {
    display: block;
    height: 100%;
    transition: .5s; }
    .sec_interview .col > a .sec_interview_text {
      color: #14a5ca;
      transition: .5s; }
    .sec_interview .col > a:hover {
      background-color: #fff;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
      transition: .5s; }
      .sec_interview .col > a:hover .sec_interview_text {
        color: #000;
        transition: .5s; }
  .sec_interview .sec_interview_img {
    position: relative; }
    .sec_interview .sec_interview_img img {
      width: 100%; }
    .sec_interview .sec_interview_img .sec_interview_img_tag {
      position: absolute;
      top: 12px;
      right: 12px;
      padding: .2em .8em;
      color: #fff;
      background-color: #14a5ca;
      border-radius: 20px;
      font-size: 10px; }
  .sec_interview .sec_interview_text {
    padding: 1em;
    line-height: 1.4;
    font-weight: 500;
    font-size: 18px;
    font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif; }
    @media (max-width: 767px) {
      .sec_interview .sec_interview_text {
        font-size: 14px; } }
  .sec_interview .sec_interview_text_category {
    display: block;
    margin-top: .7em;
    color: #000;
    font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ヒラギノ角ゴシック", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    font-size: 14px;
    letter-spacing: -0.08em; }
    @media (max-width: 767px) {
      .sec_interview .sec_interview_text_category {
        font-size: 12px;
        letter-spacing: -0.06em; } }
  @media (max-width: 767px) {
    .sec_interview .row {
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5; }
      .sec_interview .row .col {
        margin-bottom: 0;
        padding: 1.5em 0; }
        .sec_interview .row .col + .col {
          border-top: 1px solid #e5e5e5; }
      .sec_interview .row.sp_left_img {
        margin-bottom: 2.5em;
        clear: both; }
        .sec_interview .row.sp_left_img .sec_interview_img,
        .sec_interview .row.sp_left_img .sec_interview_text {
          float: left; }
        .sec_interview .row.sp_left_img .sec_interview_img {
          width: 45%; }
        .sec_interview .row.sp_left_img .sec_interview_text {
          margin-left: .6em;
          padding: 1em 0;
          width: calc(55% - .6em); } }

/*
*	add 2019.08
*/
.sec_access {
  background-color: #f5f5f5; }
  @media (max-width: 767px) {
    .sec_access .row .col {
      margin-bottom: 40px; }
      .sec_access .row .col:last-child {
        margin-bottom: 0; } }

.access_head {
  margin-bottom: 10px; }

.home h3.access_ttl {
  margin-bottom: .4em;
  padding-left: 1.5rem;
  font-size: 21px; }
  @media (max-width: 767px) {
    .home h3.access_ttl {
      padding-left: 20px;
      font-size: 16px;
      font-weight: bold; } }
  .home h3.access_ttl::before, .home h3.access_ttl::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: inherit; }
  .home h3.access_ttl::before {
    left: 0;
    width: 15px;
    height: 22px;
    margin: auto;
    background-color: inherit;
    background-image: inherit;
    background: url("../images/miraimo/common/ico_plot.png") left center no-repeat;
    background-size: contain; }
  .home h3.access_ttl::after {
    content: none; }

.access_text {
  font-size: 16px; }
  @media (max-width: 767px) {
    .access_text {
      font-size: 14px; } }

.access_map {
  width: 100%;
  height: 0;
  padding-top: 40.2%;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  @media (max-width: 767px) {
    .access_map {
      padding-top: 56.25%; } }
  .access_map iframe {
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
    z-index: 1; }

.access_map_link {
  font-size: 11px;
  text-align: right; }
  .access_map_link a {
    padding-right: 32px;
    position: relative; }
    .access_map_link a::before {
      content: '';
      margin: auto;
      width: 27px;
      height: 27px;
      background-color: #ef717d;
      background-image: linear-gradient(90deg, #ef717d 25%, #f2986e 100%);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1; }
    .access_map_link a::after {
      content: '';
      margin: auto;
      width: 15px;
      height: 15px;
      margin: auto;
      background: url("../images/miraimo/common/ico_zoom.png") left center no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      right: 5px;
      bottom: 0;
      z-index: 2; }

.access_notes {
  margin-top: 15px;
  font-size: 12px; }
  .access_notes .caution_txt + .caution_txt {
    margin-top: 0; }

.footer_subscribe {
  width: 100%;
  padding: 5px 0;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  transition: all .3s ease-in-out;
  left: 0;
  bottom: -100%;
  z-index: 1000; }
  .footer_subscribe.is-active {
    bottom: 0;
    display: block; }
    @media (min-width: 768px) {
      .footer_subscribe.is-active {
        display: none; } }
  .footer_subscribe a {
    width: 100%;
    max-width: 260px;
    margin: 0 auto;
    padding: 16px 0;
    color: #fff;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    background-color: #ef717d;
    background-image: linear-gradient(90deg, #ef717d 25%, #f2986e 100%);
    z-index: 1111;
    border-radius: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center; }
    .footer_subscribe a span {
      position: relative;
      padding-left: 30px; }
      .footer_subscribe a span:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0px;
        right: auto;
        bottom: 0;
        width: 25px;
        height: 25px;
        margin: auto;
        background-image: url(../images/miraimo/common/icon_signup.png);
        background-repeat: no-repeat;
        background-position: center 0;
        background-size: contain; }

/*
*	add 2021.01
*/
.post-password-form input {
  font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ヒラギノ角ゴシック", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 16px;
  cursor: pointer;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  background: transparent;
  background-image: none;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none; }
  @media (max-width: 767px) {
    .post-password-form input {
      font-size: 14px; } }

.post-password-form input[type="password"] {
  width: 16em;
  height: 40px;
  margin-left: 10px;
  font-family: Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
  padding: 18px 30px;
  border-radius: 4px 0 0 4px;
  border: 1px #ddd solid; }
  .post-password-form input[type="password"]:focus::-webkit-input-placeholder {
    color: transparent; }
  .post-password-form input[type="password"]:focus::-moz-placeholder {
    color: transparent; }
  .post-password-form input[type="password"]:focus:-ms-input-placeholder {
    color: transparent; }
  @media (max-width: 767px) {
    .post-password-form input[type="password"] {
      width: 55%;
      margin-left: 5px;
      padding: 10px 15px;
      font-size: 0.85em; } }

.post-password-form input[type="submit"] {
  width: auto;
  padding: 0 32px;
  border: 1px #ffd200 solid;
  color: #ffd200;
  font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 1.07em;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  vertical-align: bottom;
  line-height: 38px;
  cursor: pointer;
  color: #000;
  background: #ffc000; }
  .post-password-form input[type="submit"]:hover {
    opacity: .6; }
  @media (max-width: 767px) {
    .post-password-form input[type="submit"] {
      padding: 0 16px; } }

.custom-password-message {
  color: #cd1212;
  font-size: 12px;
  font-weight: bold; }

.header .container img {
  max-width: none; }

.hdr_signup a {
  font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ヒラギノ角ゴシック", "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }

@media (max-width: 767px) {
  .hdr_login {
    display: none; } }

@media (min-width: 1024px) {
  .list_login,
  .list_logout {
    display: none; } }

@media (max-width: 1023px) {
  .list_login span,
  .list_logout span {
    position: relative;
    padding-left: 1.7em; } }

.list_login span:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 17px;
  height: 17px;
  margin: auto;
  background-image: url(../images/miraimo/common/icon_login.png);
  background-repeat: no-repeat;
  background-position: center 0; }

.list_logout span:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 17px;
  height: 20px;
  margin: auto;
  background-image: url(../images/miraimo/common/icon_logout.png);
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: 17px auto; }

.home .header {
  background-repeat: no-repeat; }
  @media (min-width: 1024px) {
    .home .header {
      background-image: url(../images/miraimo/common/hdr_bg.jpg);
      background-position: 90% 0; } }

@media (min-width: 1024px) {
  .global_nav_list,
  .global_nav_sns {
    display: none; }
  .menu_icon {
    display: none; }
  .header {
    height: 70px; }
    .header .row {
      display: table;
      margin: 0 2%;
      width: 96%;
      height: 70px;
      overflow: inherit; }
    .header .header_logo {
      padding: 0 0 10px;
      display: table-cell;
      width: 118px;
      vertical-align: middle; }
      .header .header_logo img {
        pointer-events: none;
        width: 118px;
        height: 31px; }
    .header .global_nav {
      display: table-cell;
      width: calc(94vw - 118px);
      vertical-align: middle; }
      .header .global_nav > ul {
        margin-left: 1em;
        padding: 0; }
        .header .global_nav > ul li {
          float: left; }
          .header .global_nav > ul li + li {
            position: relative; }
            .header .global_nav > ul li + li:before {
              display: block;
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              width: 1px;
              height: 20px;
              margin: auto;
              background-color: #e5e5e5; }
      .header .global_nav a {
        display: block;
        padding: 1em 1.5em; } }
      @media (min-width: 1024px) and (max-width: 1300px) {
        .header .global_nav a {
          padding: 1em .7em; } }

@media (min-width: 1024px) {
    .header .hdr_signup {
      position: fixed;
      top: 0;
      right: 0;
      height: auto;
      background-color: #ef717d;
      background-image: linear-gradient(180deg, #ef717d 25%, #f2986e 100%);
      z-index: 1111; }
      .header .hdr_signup a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 160px;
        -ms-writing-mode: tb-rl;
        writing-mode: tb-rl;
        -o-writing-mode: vertical-rl;
        -webkit-writing-mode: vertical-rl;
        writing-mode: vertical-rl;
        color: #fff;
        font-size: 16px; }
        .header .hdr_signup a:before {
          display: block;
          content: '';
          width: 27px;
          height: 27px;
          background-image: url(../images/miraimo/common/icon_signup.png);
          background-repeat: no-repeat;
          background-position: center 0;
          background-size: 27px auto; }
        .header .hdr_signup a span {
          line-height: 1;
          margin-top: 1em;
          writing-mode: vertical-rl;
          white-space: nowrap; } }

@media all and (-ms-high-contrast: none) and (min-width: 1024px) {
  /* IE11 */
  *::-ms-backdrop, .header .hdr_signup a span {
    position: relative;
    right: 3px; } }

.hdr_logout,
.hdr_login {
  position: absolute;
  top: 50%;
  left: auto;
  right: 20px;
  transform: translateY(-50%);
  margin: auto;
  z-index: 1; }
  @media (max-width: 1023px) {
    .hdr_logout,
    .hdr_login {
      display: none; } }
  .hdr_logout span,
  .hdr_login span {
    position: relative;
    padding-left: 1.7em; }
    .hdr_logout span:before,
    .hdr_login span:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      background-image: url(../images/miraimo/common/icon_login.png);
      background-repeat: no-repeat;
      background-position: center 0; }

.hdr_login span:before {
  width: 17px;
  height: 17px;
  background-image: url(../images/miraimo/common/icon_login.png); }

.hdr_logout span:before {
  width: 17px;
  height: 20px;
  background-image: url(../images/miraimo/common/icon_logout.png);
  background-size: 17px auto; }

@media (max-width: 1023px) {
  body.menu_open {
    position: fixed;
    width: 100%;
    top: 0px; }
    body.menu_open .global_nav {
      top: 0;
      visibility: visible;
      opacity: 1;
      z-index: 1000;
      transform: translateX(0); } }
    @media (max-width: 1023px) and (min-width: 768px) {
      body.menu_open .global_nav {
        width: calc(100% + 80px); } }

@media (max-width: 1023px) {
  .global_nav_list {
    padding-top: 2em;
    border-top: 1px solid #e5e5e5; }
  .header {
    position: relative; }
    .header .container > .row {
      position: relative; }
      .header .container > .row:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        margin: auto;
        background-color: #fff;
        z-index: 2; }
    .header .hdr_signup {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #ef717d;
      background-image: linear-gradient(180deg, #ef717d 25%, #f2986e 100%);
      z-index: 1111; }
      .header .hdr_signup a {
        display: block;
        padding: 1.35em 1em;
        color: #fff;
        font-size: 12px;
        letter-spacing: .1em; }
      .header .hdr_signup i {
        display: none; }
  .header_logo {
    position: relative;
    margin: 0 auto;
    width: 150px;
    padding: 10px 1.3em;
    z-index: 99; }
    .header_logo img {
      width: 100%;
      height: auto;
      text-align: center;
      margin: 0 auto; }
  .global_nav {
    transform: translateX(-20%);
    background: #fff;
    height: 100vh;
    top: 0px;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    width: 100%;
    z-index: 1;
    text-align: center;
    transition: all .3s ease-in-out;
    overflow-y: scroll;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    opacity: 0; }
    .global_nav ul {
      text-align: center; }
      .global_nav ul li {
        line-height: 2.5; }
  .global_nav_menu {
    margin: 100px 0 2em; }
    .global_nav_menu a {
      font-weight: bold;
      font-size: 15px; }
  .global_nav_list {
    margin-bottom: 2em; }
  .global_nav_sns {
    display: inline-block;
    clear: both; }
    .global_nav_sns li {
      margin-left: 15px;
      float: left; }
  .menu_icon {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    z-index: 1001; }
    .menu_icon a {
      position: relative;
      display: block;
      width: 22px;
      height: 16px;
      padding: 0;
      transition: transform .3s;
      overflow: hidden; }
      .menu_icon a:before, .menu_icon a:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 2px;
        background: #000;
        transition: transform .5s; }
      .menu_icon a:before {
        top: 0; }
      .menu_icon a:after {
        bottom: 0; }
      .menu_icon a span {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 2px;
        background: #000;
        transition: transform .5s;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap; }
      .menu_icon a.close {
        height: 22px; }
        .menu_icon a.close:before {
          bottom: 0;
          transform: translate(0, 0) rotate(45deg); }
        .menu_icon a.close:after {
          top: 0;
          transform: translate(0, 0) rotate(-45deg); }
        .menu_icon a.close span {
          display: none; } }

@media (min-width: 768px) {
  /*
  layout
--------------------------------------------- */
  body {
    line-height: 1.8;
    font-size: 14px;
    height: 100%;
    font-family: Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
    position: relative;
    font-kerning: normal;
    box-sizing: border-box;
    min-width: 1024px; }
  html {
    height: 100%; }
  .sp_only {
    display: none; }
  .inner {
    padding: 0 10px;
    box-sizing: content-box;
    max-width: 1200px;
    margin: 0 auto;
    position: relative; }
  .inner_min {
    padding: 0 10px;
    box-sizing: content-box;
    max-width: 1040px;
    margin: 0 auto;
    position: relative; }
  .container {
    *zoom: 1;
    padding: 0 10px;
    box-sizing: content-box;
    max-width: 1200px;
    margin: 0 auto;
    position: relative; }
    .container:after {
      content: ".";
      display: block;
      clear: both;
      height: 0;
      visibility: hidden; }
  .error404 .btn_normal {
    margin: 80px 0 100px; }
  /*
  header
--------------------------------------------- */
  /*
  global nav
--------------------------------------------- */
  .global_nav_menu li a {
    transition: .5s; }
    .global_nav_menu li a:hover {
      color: #aaaa;
      transition: .3s; }
  .header .g_nav {
    border-bottom: 1px #ddd solid; }
  .g_nav {
    position: relative;
    width: 100%;
    background: #fff;
    z-index: 999999; }
    .g_nav ul {
      width: 100%;
      display: table;
      table-layout: fixed; }
      .g_nav ul li {
        display: table-cell;
        text-align: center;
        border-right: 1px #fff solid; }
        .g_nav ul li a {
          display: block;
          line-height: 74px;
          text-decoration: none;
          font-weight: bold; }
        .g_nav ul li:nth-child(1) a {
          border-top: 6px #9d56e4 solid; }
          .g_nav ul li:nth-child(1) a:hover {
            color: #fff;
            background: #9d56e4; }
        .g_nav ul li:nth-child(2) a {
          border-top: 6px #00b9d8 solid; }
          .g_nav ul li:nth-child(2) a:hover {
            color: #fff;
            background: #00b9d8; }
        .g_nav ul li:nth-child(3) a {
          border-top: 6px #32cca6 solid; }
          .g_nav ul li:nth-child(3) a:hover {
            color: #fff;
            background: #32cca6; }
        .g_nav ul li:nth-child(4) a {
          border-top: 6px #02c255 solid; }
          .g_nav ul li:nth-child(4) a:hover {
            color: #fff;
            background: #02c255; }
        .g_nav ul li:nth-child(5) a {
          border-top: 6px #ff9537 solid; }
          .g_nav ul li:nth-child(5) a:hover {
            color: #fff;
            background: #ff9537; }
        .g_nav ul li:nth-child(6) {
          border-right: none; }
          .g_nav ul li:nth-child(6) a {
            border-top: 6px #f85550 solid; }
            .g_nav ul li:nth-child(6) a:hover {
              color: #fff;
              background: #f85550; }
  .nav_fix {
    position: absolute;
    top: 0; }
  /*
  footer
--------------------------------------------- */
  .footer {
    text-align: center;
    margin: 0 auto; }
    .footer_sns {
      width: 50%;
      float: left; }
      .footer_sns ul {
        margin-right: 1em;
        text-align: right; }
        .footer_sns ul li {
          display: inline-block;
          padding: 1em 10px;
          text-align: center; }
          .footer_sns ul li img {
            margin: 0 auto; }
    .footer_nav {
      display: inline-block;
      width: 50%;
      float: left; }
      .footer_nav ul {
        margin: 1em;
        text-align: left; }
        .footer_nav ul li {
          font-size: 0.92em;
          display: inline-block; }
          .footer_nav ul li:after {
            content: "　｜　";
            color: #e5e5e5; }
          .footer_nav ul li:last-child:after {
            content: none; }
          .footer_nav ul li a {
            text-decoration: none; }
            .footer_nav ul li a:hover {
              text-decoration: underline; }
    .footer .footer_logo {
      margin: 0 auto;
      text-align: center;
      clear: both; }
      .footer .footer_logo a {
        display: inline-block;
        text-align: center; }
      .footer .footer_logo img {
        width: 178px; }
    .footer .copyright {
      color: #8a8a8a;
      font-size: 0.85em;
      padding: 40px 0 30px; }
  /*
  common area
--------------------------------------------- */
  .area_tit {
    text-align: center;
    font-size: 2.28em;
    font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
    letter-spacing: .075em;
    line-height: 1.6;
    padding: 70px 0 60px; }
    .area_tit span {
      display: block;
      font-size: 16px;
      font-family: "PT Sans", sans-serif;
      letter-spacing: .1em; }
  /* ---------- area guide ---------- */
  .area_guide {
    background: #cdeedc;
    background: -moz-linear-gradient(left, #cdeedc 0%, #f2f3cc 100%);
    background: -webkit-linear-gradient(left, #cdeedc 0%, #f2f3cc 100%);
    background: linear-gradient(to right, #cdeedc 0%, #f2f3cc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cdeedc', endColorstr='#f2f3cc', GradientType=1); }
    .area_guide .area_tit span {
      color: #02c255; }
    .area_guide_cont {
      font-size: 0; }
    .area_guide_step {
      width: 49.5%;
      display: inline-block;
      margin: 0 1% 12px 0; }
      .area_guide_step a {
        display: block;
        background: #fff;
        border-radius: 4px;
        padding: 20px 16px;
        font-size: 14px;
        text-decoration: none;
        border: 2px #fff solid; }
        .area_guide_step a:hover {
          color: #02c255;
          border: 2px #02c255 solid;
          background: rgba(255, 255, 255, 0.7); }
      .area_guide_step:nth-child(even) {
        margin-right: 0; }
      .area_guide_step_point {
        color: #02c255;
        font-family: "PT Sans", sans-serif;
        display: inline-block;
        background: url(../images/miraimo/common/step-line.png) no-repeat right center;
        line-height: 23px;
        padding-right: 24px;
        margin-right: 20px; }
      .area_guide_step_txt {
        display: inline-block; }
      .area_guide_step_btn {
        padding: 33px 0 85px; }
        .area_guide_step_btn a {
          font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-size: 1.07em;
          width: 450px;
          text-align: center;
          text-decoration: none;
          display: block;
          color: #fff;
          background: #34c272;
          background: -moz-linear-gradient(left, #34c272 0%, #0bd05f 100%);
          background: -webkit-linear-gradient(left, #34c272 0%, #0bd05f 100%);
          background: linear-gradient(to right, #34c272 0%, #0bd05f 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#34c272', endColorstr='#0bd05f', GradientType=1);
          line-height: 70px;
          border-radius: 35px;
          margin: 0 auto; }
          .area_guide_step_btn a:hover {
            opacity: .6; }
          .area_guide_step_btn a img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px; }
  /* ---------- area job-information ---------- */
  .area_job-information .area_tit span {
    color: #ff9537; }
  .area_job-information_cont {
    font-size: 0;
    margin: 20px 0 100px;
    border-left: 1px #ddd solid;
    border-right: 1px #ddd solid; }
    .area_job-information_cont_box {
      width: 50%;
      max-width: 520px;
      display: inline-block;
      font-size: 14px;
      vertical-align: top; }
      .area_job-information_cont_box:nth-child(odd) {
        padding-right: 80px;
        border-right: 1px #ddd solid; }
      .area_job-information_cont_box:nth-child(even) {
        padding-left: 80px; }
      .area_job-information_cont_box:nth-child(1), .area_job-information_cont_box:nth-child(2) {
        padding-bottom: 40px; }
      .area_job-information_cont_box .tit {
        font-size: 1.14em;
        line-height: 1.5;
        font-weight: bold; }
        .area_job-information_cont_box .tit a {
          text-decoration: none; }
          .area_job-information_cont_box .tit a:hover {
            color: #ff9537; }
      .area_job-information_cont_box .category {
        padding: 20px 20px 0 0;
        display: inline-block;
        margin-bottom: 0.5em; }
        .area_job-information_cont_box .category a {
          display: inline-block;
          font-size: 0.78em;
          border: 1px #00b9d8 solid;
          line-height: 26px;
          border-radius: 13px;
          padding: 0 1em;
          min-width: 70px;
          text-align: center;
          text-decoration: none; }
          .area_job-information_cont_box .category a:hover {
            background: #00b9d8;
            color: #fff; }
      .area_job-information_cont_box .tag {
        display: inline-block;
        margin-bottom: 0.5em; }
        .area_job-information_cont_box .tag ul li {
          display: inline-block;
          line-height: 26px;
          padding-right: 1em; }
          .area_job-information_cont_box .tag ul li a {
            display: inline-block;
            font-size: 0.78em;
            border: 1px #ffd200 solid;
            line-height: 26px;
            border-radius: 13px;
            padding: 0 1em;
            min-width: 70px;
            text-align: center;
            text-decoration: none; }
            .area_job-information_cont_box .tag ul li a:hover {
              background: #ffd200;
              color: #fff; }
  /*
  brand_logo
--------------------------------------------- */
  .area_brand_logo .inner {
    padding-bottom: 100px; }
    .area_brand_logo .inner ul {
      text-align: center;
      margin: 0 auto;
      font-size: 0; }
      .area_brand_logo .inner ul li {
        display: inline-block;
        width: calc(100% / 5);
        border: 1px solid #eee;
        margin-left: -1px;
        margin-top: -1px; }
        .area_brand_logo .inner ul li img {
          max-width: 100%;
          height: auto; }
  /* ---------- area search ---------- */
  .area_search {
    background: #f9f9f9;
    padding-bottom: 60px; }
    .area_search .area_sub-tit {
      font-size: 1.71em;
      text-align: center;
      font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
      letter-spacing: .075em;
      position: relative;
      top: -22px; }
    .area_search_cont {
      width: 100%;
      display: table; }
      .area_search_cont .tit {
        width: 170px;
        font-weight: bold;
        display: table-cell;
        vertical-align: top;
        background: url(../images/miraimo/common/ico-arw-right-gray.png) no-repeat right 24px;
        padding: 17px 27px 16px 10px; }
      .area_search_cont_input {
        display: table-cell;
        padding: 0 25px 30px;
        *zoom: 1; }
        .area_search_cont_input:after {
          content: ".";
          display: block;
          clear: both;
          height: 0;
          visibility: hidden; }
      .area_search_cont_list {
        display: table-cell;
        padding: 16px 25px; }
        .area_search_cont_list li {
          display: inline-block;
          margin: 0 8px 9px 0; }
          .area_search_cont_list li a {
            background: #fff;
            display: inline-block;
            padding: 0 30px;
            line-height: 28px;
            font-size: 0.92em;
            text-decoration: none;
            text-align: center;
            border-radius: 14px; }
      .area_search_cont_box {
        *zoom: 1;
        display: table-cell;
        padding: 16px 25px; }
        .area_search_cont_box:after {
          content: ".";
          display: block;
          clear: both;
          height: 0;
          visibility: hidden; }
      .area_search_cont_sub-tit {
        min-width: 8em;
        font-weight: bold;
        font-size: 0.92em;
        float: left;
        padding: 0 20px 0 0;
        width: 124px; }
        .area_search_cont_sub-tit a {
          background: #00C44F;
          border-radius: 3px;
          color: #FFF;
          display: block;
          line-height: 30px;
          text-decoration: none;
          text-align: center; }
          .area_search_cont_sub-tit a:hover {
            opacity: .6; }
      .area_search_cont_list-min {
        float: left;
        width: calc(100% - 124px);
        margin-bottom: 15px; }
        .area_search_cont_list-min li {
          display: inline-block;
          margin: 0 8px 9px 0; }
          .area_search_cont_list-min li a {
            background: #fff;
            display: inline-block;
            padding: 0 30px;
            line-height: 28px;
            font-size: 0.92em;
            text-decoration: none;
            text-align: center;
            border-radius: 14px; }
      .area_search_cont_list-min:last-of-type {
        margin-bottom: 0; }
    .area_search_cont.freeword {
      padding-top: 40px; }
      .area_search_cont.freeword input[type="text"] {
        height: 58px;
        font-family: Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
        padding: 18px 30px;
        border-radius: 4px 0 0 4px;
        border-top: 1px #ddd solid;
        border-bottom: 1px #ddd solid;
        border-left: 1px #ddd solid;
        width: 86%;
        box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.1) inset; }
        .area_search_cont.freeword input[type="text"]:focus::-webkit-input-placeholder {
          color: transparent; }
        .area_search_cont.freeword input[type="text"]:focus::-moz-placeholder {
          color: transparent; }
        .area_search_cont.freeword input[type="text"]:focus:-ms-input-placeholder {
          color: transparent; }
      .area_search_cont.freeword button {
        height: 58px;
        background: #ff9537;
        background: -moz-linear-gradient(left, #ff9537 0%, #ff6845 100%);
        background: -webkit-linear-gradient(left, #ff9537 0%, #ff6845 100%);
        background: linear-gradient(to right, #ff9537 0%, #ff6845 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9537', endColorstr='#ff6845', GradientType=1);
        text-align: center;
        margin: 0 auto;
        border-radius: 0 4px 4px 0;
        padding: 20px 0;
        border: none;
        float: right;
        width: 14%;
        cursor: pointer; }
        .area_search_cont.freeword button img {
          text-align: center;
          margin: 0 auto; }
    .area_search_cont.job-category .area_search_cont_list li a {
      border: 1px #00b9d8 solid; }
      .area_search_cont.job-category .area_search_cont_list li a:hover {
        background: #00b9d8;
        color: #fff; }
    .area_search_cont.job-area .area_search_cont_list-min li a {
      border: 1px #02c255 solid; }
      .area_search_cont.job-area .area_search_cont_list-min li a:hover {
        background: #02c255;
        color: #fff; }
    .area_search_cont.job-tag .area_search_cont_list li a {
      border: 1px #ffd200 solid; }
      .area_search_cont.job-tag .area_search_cont_list li a:hover {
        background: #ffd200;
        color: #fff; }
  /* ---------- area other ---------- */
  .area_other {
    font-size: 0;
    padding: 56px 0; }
    .area_other .area_other_btn {
      margin: 0 1%;
      display: inline-block;
      font-size: 14px;
      width: 48%; }
      .area_other .area_other_btn a {
        font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
        font-size: 1.28em;
        letter-spacing: .1em;
        text-decoration: none;
        text-align: center;
        display: block;
        background: #fff;
        line-height: 66px;
        border-radius: 35px;
        margin: 0 auto; }
        .area_other .area_other_btn a:hover {
          color: #fff; } }
      @media (min-width: 768px) and (max-width: 767px) {
        .area_other .area_other_btn a {
          font-size: 0.85em;
          line-height: 46px;
          border-radius: 25px; } }

@media (min-width: 768px) {
      .area_other .area_other_btn a {
        max-width: 400px;
        color: #ff9537;
        font-size: 1.07em;
        border: 2px #ff9537 solid; }
        .area_other .area_other_btn a:hover {
          background: #ff9537; }
  /* ---------- area detail ---------- */
  .area_detail {
    background: #b7ddf1;
    background: -moz-linear-gradient(left, #b7ddf1 0%, #fad4d8 100%);
    background: -webkit-linear-gradient(left, #b7ddf1 0%, #fad4d8 100%);
    background: linear-gradient(to right, #b7ddf1 0%, #fad4d8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b7ddf1', endColorstr='#fad4d8', GradientType=1);
    font-size: 0;
    padding: 70px 0; }
    .area_detail_cont {
      font-size: 14px;
      display: inline-block;
      vertical-align: top;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 4px;
      padding: 40px 50px;
      width: 49%;
      position: relative; }
      .area_detail_cont .icon img {
        text-align: center;
        margin: 0 auto; }
      .area_detail_cont .tit {
        font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
        font-size: 1.71em;
        letter-spacing: .05em;
        text-align: center;
        padding: 20px 0 40px; }
      .area_detail_cont .sub-tit {
        font-weight: bold;
        text-align: center;
        padding-bottom: 4px; }
      .area_detail_cont .txt {
        padding-bottom: 106px; }
        .area_detail_cont .txt.pb0 {
          padding-bottom: 0; }
      .area_detail_cont .btn {
        position: absolute;
        bottom: 40px;
        left: 50%;
        margin-left: -150px; }
        .area_detail_cont .btn a {
          width: 300px;
          display: block;
          line-height: 70px;
          border-radius: 35px;
          text-align: center;
          color: #fff;
          text-decoration: none;
          margin: 0 auto; }
          .area_detail_cont .btn a:hover {
            opacity: .6; }
        .area_detail_cont .btn span {
          width: 300px;
          display: block;
          line-height: 70px;
          border-radius: 35px;
          text-align: center;
          color: #fff;
          text-decoration: none;
          margin: 0 auto; }
    .area_detail_cont.career-advice {
      margin-right: 1%; }
      .area_detail_cont.career-advice .btn a {
        background: #1090d2;
        background: -moz-linear-gradient(left, #1090d2 0%, #36b7b2 100%);
        background: -webkit-linear-gradient(left, #1090d2 0%, #36b7b2 100%);
        background: linear-gradient(to right, #1090d2 0%, #36b7b2 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1090d2', endColorstr='#36b7b2', GradientType=1); }
    .area_detail_cont.member {
      margin-left: 1%; }
      .area_detail_cont.member .btn a {
        background: #ef707d;
        background: -moz-linear-gradient(left, #ef707d 0%, #f2986f 100%);
        background: -webkit-linear-gradient(left, #ef707d 0%, #f2986f 100%);
        background: linear-gradient(to right, #ef707d 0%, #f2986f 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef707d', endColorstr='#f2986f', GradientType=1); }
      .area_detail_cont.member .btn span {
        background: #ddd; }
  /* ---------- area access ---------- */
  .area_access {
    background: #f1f1f1;
    font-size: 0;
    padding: 70px 0; }
    .area_access_cont {
      font-size: 14px;
      display: inline-block;
      vertical-align: top;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 4px;
      padding: 40px 50px;
      width: 49%;
      position: relative; }
      .area_access_cont .icon img {
        text-align: center;
        margin: 0 auto; }
      .area_access_cont .tit {
        font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
        font-size: 1.71em;
        letter-spacing: .05em;
        text-align: center;
        padding: 20px 0 40px; }
      .area_access_cont .sub-tit {
        font-weight: bold;
        text-align: center;
        padding-bottom: 4px; }
      .area_access_cont .txt {
        padding-bottom: 106px; }
        .area_access_cont .txt.pb0 {
          padding-bottom: 0; }
      .area_access_cont .btn {
        position: absolute;
        bottom: 40px;
        left: 50%;
        margin-left: -150px; }
        .area_access_cont .btn a {
          width: 300px;
          display: block;
          line-height: 70px;
          border-radius: 35px;
          text-align: center;
          color: #fff;
          text-decoration: none;
          margin: 0 auto; }
        .area_access_cont .btn span {
          width: 300px;
          display: block;
          line-height: 70px;
          border-radius: 35px;
          text-align: center;
          color: #fff;
          text-decoration: none;
          margin: 0 auto; }
    .area_access_cont.osaka {
      margin-top: 20px;
      margin-right: 1%; }
      .area_access_cont.osaka .btn a {
        border: 2px solid #110051;
        color: #110051; }
        .area_access_cont.osaka .btn a:hover {
          background: #110051;
          color: #FFF; }
    .area_access_cont.tokyo {
      margin-top: 20px;
      margin-left: 1%; }
      .area_access_cont.tokyo .btn a {
        border: 2px solid #110051;
        color: #110051; }
        .area_access_cont.tokyo .btn a:hover {
          background: #110051;
          color: #FFF; }
    .area_access_cont.full {
      width: 100%; }
      .area_access_cont.full .btn {
        margin: 50px auto 0;
        position: static; }
        .area_access_cont.full .btn a {
          border: 2px solid #110051;
          color: #110051; }
      .area_access_cont.full .detail {
        width: 80%;
        margin: 0 auto; }
  /* ---------- area btn_more ---------- */
  .area_btn_more {
    position: absolute;
    top: 100px;
    right: 10px; }
    .area_btn_more a {
      font-size: 0.92em;
      padding-right: 40px;
      background: url(../images/miraimo/common/ico-arw.png) no-repeat right center;
      text-decoration: none; }
      .area_btn_more a:hover {
        text-decoration: underline; }
  /* ---------- area blog ---------- */
  .area_blog {
    padding-bottom: 70px; }
    .area_blog .area_tit span {
      color: #32cca6; }
    .area_blog_cont {
      font-size: 0;
      margin: 0 -10px; }
      .area_blog_cont_box {
        vertical-align: top;
        font-size: 14px;
        text-align: left;
        display: inline-block;
        margin: 0 5px;
        width: calc(25% - 10px);
        max-width: 292px; }
        .area_blog_cont_box .img {
          padding-bottom: 20px; }
          .area_blog_cont_box .img img {
            width: 100%; }
        .area_blog_cont_box .date {
          font-size: 0.85em;
          font-weight: bold;
          line-height: 28px;
          display: inline-block;
          padding: 0 10px; }
        .area_blog_cont_box .tag {
          font-size: 0.78em;
          text-align: center;
          padding: 0 12px;
          color: #32cca6;
          display: inline-block;
          line-height: 26px;
          border: 1px #32cca6 solid;
          border-radius: 14px; }
        .area_blog_cont_box .tit {
          padding: 16px 10px;
          line-height: 1.5; }
        .area_blog_cont_box a {
          text-decoration: none; }
          .area_blog_cont_box a:hover {
            color: #32cca6; }
  .single-voice .area_blog_cont_box a:hover {
    color: #00b9d8; }
  #miraimoblog .area_blog_cont_box {
    width: calc(33.33% - 10px); }
  /*
  page
--------------------------------------------- */
  .pages {
    background: url(../images/miraimo/common/head_bg.png) no-repeat center top;
    background-size: contain; }
    .page-template-template-miraimo-lp-thankyou .pages {
      border-bottom: 1px solid #dcdcdc; }
    .pages .area_key {
      padding: 20px 0 100px; }
      .pages .area_key .area_tit {
        padding: 70px 0 40px; }
  .center {
    text-align: center; }
  .btn_normal {
    margin: 20px auto 100px;
    text-align: center; }
    .btn_normal a {
      min-width: 180px;
      display: inline-block;
      text-align: center;
      background: #fff;
      line-height: 26px;
      border-radius: 15px;
      text-decoration: none;
      border: 2px #ff9537 solid;
      color: #ff9537; }
      .btn_normal a:hover {
        color: #fff;
        background: #ff9537; }
  /*
  index header
--------------------------------------------- */
  .header_btn {
    position: absolute;
    top: 40px;
    right: 40px; }
    .header_btn ul li {
      display: inline-block;
      margin-left: 10px; }
      .header_btn ul li a {
        width: 114px;
        text-align: center;
        display: block;
        background: #fff;
        line-height: 26px;
        border-radius: 15px;
        text-decoration: none; }
    .header_btn ul .sign-up a {
      border: 2px #f85550 solid;
      color: #f85550; }
      .header_btn ul .sign-up a:hover {
        color: #fff;
        background: #f85550; }
    .header_btn ul .login a {
      border: 2px #ff9537 solid;
      color: #ff9537; }
      .header_btn ul .login a:hover {
        color: #fff;
        background: #ff9537; }
  /*
  hero
--------------------------------------------- */
  .hero {
    background: url(../images/miraimo/common/hero_bg.png) no-repeat;
    background-size: cover;
    height: 100vh;
    height: calc(100vh - 86px);
    overflow: hidden;
    position: relative;
    min-height: 900px; }
    .hero .hero_inner {
      min-width: 1200px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .hero video {
      position: absolute;
      top: 0;
      left: 0;
      width: auto;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      max-width: inherit;
      z-index: -1; }
    .hero_logo {
      padding: 0 10px 40px 0;
      text-align: center;
      margin: 0 auto; }
      .hero_logo img {
        text-align: center;
        margin: 0 auto; }
    .hero_copy {
      font-size: 3.42em;
      font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
      letter-spacing: .25em;
      text-align: center;
      color: #393939; }
    .hero_sub-copy {
      font-size: 1.42em;
      font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
      letter-spacing: .2em;
      text-align: center;
      color: #393939; }
      .hero_sub-copy span {
        font-weight: bold; }
    .hero_txt {
      font-size: 1.14em;
      letter-spacing: .1em;
      text-align: center;
      padding: 40px 0 60px; }
    .hero_btn {
      text-align: center;
      margin: 0 auto; }
      .hero_btn_about {
        display: inline-block;
        margin: 0 15px 15px; }
        .hero_btn_about a {
          font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-size: 1.28em;
          letter-spacing: .1em;
          text-decoration: none;
          text-align: center;
          display: block;
          background: #fff;
          line-height: 66px;
          border-radius: 35px;
          margin: 0 auto; }
          .hero_btn_about a:hover {
            color: #fff; } }
        @media (min-width: 768px) and (max-width: 767px) {
          .hero_btn_about a {
            font-size: 0.85em;
            line-height: 46px;
            border-radius: 25px; } }

@media (min-width: 768px) {
        .hero_btn_about a {
          width: 300px;
          color: #9d56e4;
          border: 2px #9d56e4 solid; }
          .hero_btn_about a:hover {
            background: #9d56e4; }
      .hero_btn_job {
        display: inline-block;
        margin: 0 15px 15px; }
        .hero_btn_job a {
          font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-size: 1.28em;
          letter-spacing: .1em;
          text-decoration: none;
          text-align: center;
          display: block;
          background: #fff;
          line-height: 66px;
          border-radius: 35px;
          margin: 0 auto; }
          .hero_btn_job a:hover {
            color: #fff; } }
        @media (min-width: 768px) and (max-width: 767px) {
          .hero_btn_job a {
            font-size: 0.85em;
            line-height: 46px;
            border-radius: 25px; } }

@media (min-width: 768px) {
        .hero_btn_job a {
          width: 300px;
          color: #ff9537;
          border: 2px #ff9537 solid; }
          .hero_btn_job a:hover {
            background: #ff9537; }
  /*
  index area about
--------------------------------------------- */
  .area_about {
    margin-top: -100px;
    padding: 205px 0 140px;
    text-align: center; }
    .area_about_tit {
      padding-bottom: 60px;
      width: 230px;
      margin: 0 auto; }
      .area_about_tit img {
        width: 100%;
        height: auto;
        text-align: center;
        margin: 0 auto; }
    .area_about p {
      font-size: 1.14em;
      letter-spacing: .1em;
      margin-bottom: 1.6em; }
    .area_about_btn {
      margin: 0 1%;
      display: inline-block;
      width: 48%;
      letter-spacing: normal; }
      .area_about_btn a {
        font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
        font-size: 1.28em;
        letter-spacing: .1em;
        text-decoration: none;
        text-align: center;
        display: block;
        background: #fff;
        line-height: 66px;
        border-radius: 35px;
        margin: 0 auto; }
        .area_about_btn a:hover {
          color: #fff; } }
      @media (min-width: 768px) and (max-width: 767px) {
        .area_about_btn a {
          font-size: 0.85em;
          line-height: 46px;
          border-radius: 25px; } }

@media (min-width: 768px) {
      .area_about_btn a {
        width: 400px;
        color: #0c4f93;
        border: 2px #0c4f93 solid; }
        .area_about_btn a:hover {
          background: #0c4f93; }
      .area_about_btn.flow a {
        color: #ff9537;
        border: 2px #ff9537 solid; }
        .area_about_btn.flow a:hover {
          background: #ff9537;
          color: #FFF; }
    .area_about .inner_min:last-child {
      padding-top: 40px;
      letter-spacing: -0.4em; }
  /*
  index area interview
--------------------------------------------- */
  .area_interview {
    background: #c5f3e2;
    background: -moz-linear-gradient(left, #c5f3e2 0%, #d4e0fc 100%);
    background: -webkit-linear-gradient(left, #c5f3e2 0%, #d4e0fc 100%);
    background: linear-gradient(to right, #c5f3e2 0%, #d4e0fc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c5f3e2', endColorstr='#d4e0fc', GradientType=1);
    padding-bottom: 85px; }
    .area_interview .area_tit span {
      color: #00b9d8; }
    .area_interview_tablist {
      overflow: hidden;
      margin-bottom: 40px; }
    .area_interview_tab {
      cursor: pointer;
      float: left;
      width: 50%;
      box-sizing: border-box;
      text-align: center;
      font-size: 1.14em;
      font-weight: bold;
      padding: 15px 0;
      border-bottom: 2px solid #00b9d8; }
      .area_interview_tab.is-active {
        color: #00b9d8;
        border-top: 2px solid #00b9d8;
        border-right: 2px solid #00b9d8;
        border-left: 2px solid #00b9d8;
        border-bottom: 0;
        border-radius: 10px 10px 0 0;
        padding: 13px 0 17px; }
    .area_interview_wrap {
      display: none; }
      .area_interview_wrap.is-visible {
        display: block; }
    .area_interview_cont {
      font-size: 0;
      margin: 0 -5px; }
      .area_interview_cont_box {
        width: calc(25% - 10px);
        max-width: 292px;
        background: #fff;
        display: inline-block;
        font-size: 14px;
        text-align: left;
        margin: 0 5px 10px;
        vertical-align: top; }
        .area_interview_cont_box .img {
          padding-bottom: 20px; }
          .area_interview_cont_box .img img {
            width: 100%; }
        .area_interview_cont_box .number {
          margin: 0 24px;
          display: inline;
          color: #00b9d8;
          font-family: "PT Sans", sans-serif;
          font-weight: bold;
          font-size: 0.85em; }
        .area_interview_cont_box .tit {
          padding: 10px 24px;
          line-height: 1.5; }
        .area_interview_cont_box .company {
          font-size: 0.85em;
          padding: 10px 0 20px;
          color: #9d9d9d;
          font-style: italic;
          margin: 0 24px;
          background-image: linear-gradient(to right, #C6C6C6, #C6C6C6 1px, transparent 1px, transparent 3px);
          background-size: 4px 1px;
          background-position: top;
          background-repeat: repeat-x; }
        .area_interview_cont_box a {
          text-decoration: none; }
          .area_interview_cont_box a:hover {
            color: #00b9d8; }
    .area_interview_btn {
      padding-top: 30px;
      text-align: center; }
      .area_interview_btn a {
        font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
        font-size: 1.28em;
        letter-spacing: .1em;
        text-decoration: none;
        text-align: center;
        display: block;
        background: #fff;
        line-height: 66px;
        border-radius: 35px;
        margin: 0 auto; }
        .area_interview_btn a:hover {
          color: #fff; } }
      @media (min-width: 768px) and (max-width: 767px) {
        .area_interview_btn a {
          font-size: 0.85em;
          line-height: 46px;
          border-radius: 25px; } }

@media (min-width: 768px) {
      .area_interview_btn a {
        color: #00b9d8;
        border: 2px #00b9d8 solid;
        width: 346px; }
        .area_interview_btn a:hover {
          background: #00b9d8;
          color: #FFF; }
  /*
  archive blog
--------------------------------------------- */
  #miraimoblog .area_key .area_tit span {
    color: #32cca6; }
  #miraimoblog.post-type-archive-voice .area_key .area_tit span,
  #miraimoblog.post-type-archive-interview .area_key .area_tit span {
    color: #00b9d8; }
  #miraimoblog.page-template-page-interview-archive .area_tit span {
    color: #00b9d8; }
  #miraimoblog.page-template-page-interview-archive .archive_sidebar_cont {
    border-color: #00b9d8; }
    #miraimoblog.page-template-page-interview-archive .archive_sidebar_cont .tit {
      color: #00b9d8; }
  #miraimoblog.page-template-page-interview-archive .archive_sidebar_cont ul li a {
    color: #00b9d8; }
    #miraimoblog.page-template-page-interview-archive .archive_sidebar_cont ul li a:hover {
      background: #00b9d8;
      color: #FFF; }
  #miraimoblog.page-template-page-interview-archive .area_blog_cont_box .number {
    border-bottom: 0; }
  #miraimoblog.page-template-page-interview-archive .area_blog_cont_box a:hover {
    color: #00b9d8; }
  #miraimoblog.page-template-page-interview-archive .page-nation ul li a {
    border-color: #00b9d8;
    color: #00b9d8; }
  #miraimoblog.page-template-page-interview-archive .page-nation ul li:hover a {
    background: #00b9d8;
    color: #FFF; }
  #miraimoblog.page-template-page-interview-archive .page-nation ul li span.current {
    display: block;
    text-align: center;
    width: 45px;
    line-height: 48px;
    border-radius: 4px;
    border: 1px solid #00b9d8;
    background: #00b9d8;
    color: #FFF;
    text-decoration: none; }
  .archive_category {
    width: 100%;
    max-width: 600px;
    line-height: 43px;
    border-radius: 22.5px;
    border: 1px #32cca6 solid;
    text-align: center;
    color: #32cca6;
    letter-spacing: .075em;
    font-size: 1.14em;
    margin: 0 auto; }
  #miraimoblog.post-type-archive-voice .archive_category,
  #miraimoblog.post-type-archive-interview .archive_category {
    border: 1px #00b9d8 solid;
    color: #00b9d8; }
  .archive_cont {
    width: 75%;
    width: calc(100% - 304px);
    float: left; }
    .archive_cont .area_blog_cont_box {
      padding-bottom: 60px; }
  #miraimoblog.post-type-archive-voice .area_blog_cont_box a,
  #miraimoblog.post-type-archive-interview .area_blog_cont_box a {
    text-decoration: none; }
    #miraimoblog.post-type-archive-voice .area_blog_cont_box a:hover,
    #miraimoblog.post-type-archive-interview .area_blog_cont_box a:hover {
      color: #00b9d8; }
  .area_blog_cont_box .number {
    margin: 0 10px;
    display: inline;
    color: #00b9d8;
    font-family: "PT Sans", sans-serif;
    font-weight: bold;
    font-size: 0.85em;
    border-bottom: 2px #00b9d8 solid; }
  .area_blog_cont_box .company {
    font-size: 0.85em;
    padding: 10px 0 0;
    color: #9d9d9d;
    font-style: italic;
    background-image: linear-gradient(to right, #C6C6C6, #C6C6C6 1px, transparent 1px, transparent 3px);
    background-size: 4px 1px;
    background-position: top;
    background-repeat: repeat-x;
    margin: 0 10px; }
  /* ---------- sidebar ---------- */
  .archive_sidebar {
    width: 274px;
    float: right; }
    .archive_sidebar_cont {
      background: #f9f9f9;
      border: 1px #32cca6 solid;
      padding: 10px 10px 5px;
      margin-bottom: 30px; }
      .archive_sidebar_cont .tit {
        text-align: center;
        color: #32cca6;
        padding: 20px 0 30px; }
      .archive_sidebar_cont ul li a {
        display: block;
        text-decoration: none;
        font-size: 0.92em;
        color: #32cca6;
        background: #fff url(../images/miraimo/common/ico-arw-green.png) no-repeat 96% center;
        line-height: 40px;
        padding: 0 18px;
        margin-bottom: 5px; }
        .archive_sidebar_cont ul li a:hover {
          color: #fff;
          background: #32cca6; }
  #miraimoblog.post-type-archive-voice .archive_sidebar_cont,
  #miraimoblog.post-type-archive-interview .archive_sidebar_cont {
    border: 1px #00b9d8 solid; }
    #miraimoblog.post-type-archive-voice .archive_sidebar_cont .tit,
    #miraimoblog.post-type-archive-interview .archive_sidebar_cont .tit {
      color: #00b9d8; }
    #miraimoblog.post-type-archive-voice .archive_sidebar_cont ul li a,
    #miraimoblog.post-type-archive-interview .archive_sidebar_cont ul li a {
      color: #00b9d8; }
      #miraimoblog.post-type-archive-voice .archive_sidebar_cont ul li a:hover,
      #miraimoblog.post-type-archive-interview .archive_sidebar_cont ul li a:hover {
        color: #fff;
        background: #00b9d8; }
  /* ---------- page nation ---------- */
  .page-nation {
    letter-spacing: normal; }
    .page-nation ul li {
      padding: 0 6px;
      display: inline-block; }
      .page-nation ul li a {
        display: block;
        text-align: center;
        width: 45px;
        line-height: 48px;
        border-radius: 4px;
        border: 1px #32cca6 solid;
        background: #fff;
        color: #32cca6;
        text-decoration: none; }
      .page-nation ul li .page-nation ul li span.current {
        display: block;
        text-align: center;
        width: 45px;
        line-height: 48px;
        border-radius: 4px;
        border: 1px #32cca6 solid;
        color: #fff;
        background: #32cca6;
        text-decoration: none; }
      .page-nation ul li.current a, .page-nation ul li:hover a {
        color: #fff;
        background: #32cca6; }
      .page-nation ul li:first-child {
        padding-left: 0; }
      .page-nation ul li:last-child {
        padding-right: 0; }
  #miraimoblog.post-type-archive-voice .page-nation,
  #miraimoblog.post-type-archive-interview .page-nation {
    letter-spacing: normal; }
    #miraimoblog.post-type-archive-voice .page-nation ul li a,
    #miraimoblog.post-type-archive-interview .page-nation ul li a {
      border: 1px #00b9d8 solid;
      color: #00b9d8; }
    #miraimoblog.post-type-archive-voice .page-nation ul li.current a, #miraimoblog.post-type-archive-voice .page-nation ul li:hover a,
    #miraimoblog.post-type-archive-interview .page-nation ul li.current a,
    #miraimoblog.post-type-archive-interview .page-nation ul li:hover a {
      color: #fff;
      background: #00b9d8; }
  /*
  single blog
--------------------------------------------- */
  #single-miraimoblog .single_cont {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding-top: 105px; }
    #single-miraimoblog .single_cont strong {
      font-weight: bold; }
    #single-miraimoblog .single_cont_sub {
      padding-bottom: 42px;
      *zoom: 1; }
      #single-miraimoblog .single_cont_sub:after {
        content: ".";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden; }
    #single-miraimoblog .single_cont_date {
      line-height: 40px;
      display: inline-block;
      font-weight: bold;
      font-size: 0.92em;
      padding-right: 26px; }
    #single-miraimoblog .single_cont_report {
      display: inline-block;
      line-height: 26px;
      border: 1px #32cca6 solid;
      border-radius: 14px;
      font-size: 0.78em;
      text-align: center;
      padding: 0 10px;
      color: #32cca6; }
    #single-miraimoblog .single_cont_sns {
      float: right;
      text-align: right; }
      #single-miraimoblog .single_cont_sns ul li {
        display: inline-block;
        padding-left: 6px;
        vertical-align: middle; }
    #single-miraimoblog .single_cont_tit {
      font-size: 2.28em;
      padding-bottom: 40px; }
    #single-miraimoblog .single_cont_tag {
      padding-bottom: 70px; }
      #single-miraimoblog .single_cont_tag li {
        margin-right: 10px;
        display: inline-block; }
        #single-miraimoblog .single_cont_tag li a {
          display: inline-block;
          line-height: 23px;
          border: 1px #ddd solid;
          border-radius: 14px;
          font-size: 0.85em;
          text-align: center;
          padding: 0 20px;
          background: #f9f9f9;
          color: #666; }
      #single-miraimoblog .single_cont_tag .company {
        color: #9d9d9d; }
    #single-miraimoblog .single_cont_img {
      padding-bottom: 60px; }
      #single-miraimoblog .single_cont_img img {
        width: 100%;
        height: auto; }
    #single-miraimoblog .single_cont_txt {
      padding-bottom: 85px; }
      #single-miraimoblog .single_cont_txt p {
        padding-bottom: 3em; }
      #single-miraimoblog .single_cont_txt .sub-tit {
        font-size: 1.71em;
        padding-bottom: 20px;
        line-height: 1.5;
        padding-left: 50px;
        text-indent: -50px; }
        #single-miraimoblog .single_cont_txt .sub-tit:before {
          display: inline-block;
          vertical-align: middle;
          content: "";
          width: 30px;
          height: 2px;
          background: #32cca6;
          margin-right: 20px; }
      #single-miraimoblog .single_cont_txt .list_number {
        padding-bottom: 30px; }
        #single-miraimoblog .single_cont_txt .list_number li {
          position: relative;
          line-height: 22px;
          padding: 0 0 10px 42px; }
          #single-miraimoblog .single_cont_txt .list_number li:nth-child(1):before {
            position: absolute;
            left: 0;
            display: inline-block;
            vertical-align: top;
            color: #32cca6;
            width: 22px;
            line-height: 22px;
            text-align: center;
            content: "1";
            border: 1px #32cca6 solid;
            border-radius: 4px;
            margin: 0 20px 15px 0; }
          #single-miraimoblog .single_cont_txt .list_number li:nth-child(2):before {
            position: absolute;
            left: 0;
            display: inline-block;
            vertical-align: top;
            color: #32cca6;
            width: 22px;
            line-height: 22px;
            text-align: center;
            content: "2";
            border: 1px #32cca6 solid;
            border-radius: 4px;
            margin: 0 20px 15px 0; }
          #single-miraimoblog .single_cont_txt .list_number li:nth-child(3):before {
            position: absolute;
            left: 0;
            display: inline-block;
            vertical-align: top;
            color: #32cca6;
            width: 22px;
            line-height: 22px;
            text-align: center;
            content: "3";
            border: 1px #32cca6 solid;
            border-radius: 4px;
            margin: 0 20px 15px 0; }
      #single-miraimoblog .single_cont_txt .list_mark {
        padding-bottom: 30px; }
        #single-miraimoblog .single_cont_txt .list_mark li {
          padding: 0 0 10px 42px;
          position: relative;
          line-height: 22px; }
          #single-miraimoblog .single_cont_txt .list_mark li:before {
            position: absolute;
            left: 0;
            display: inline-block;
            vertical-align: top;
            width: 22px;
            height: 22px;
            content: " ";
            padding: 0 20px 15px 0;
            background: url(../images/miraimo/common/ico-mark.png) no-repeat left top; }
      #single-miraimoblog .single_cont_txt .caption {
        display: inline-block;
        position: relative;
        padding: 0 42px; }
        #single-miraimoblog .single_cont_txt .caption:before {
          position: absolute;
          left: 0;
          display: inline-block;
          width: 21px;
          height: 21px;
          content: " ";
          background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
          padding-left: 11px; }
        #single-miraimoblog .single_cont_txt .caption p {
          display: inline-block;
          vertical-align: top;
          line-height: 21px;
          padding-bottom: 0;
          color: #666;
          font-style: oblique; }
        #single-miraimoblog .single_cont_txt .caption:after {
          position: absolute;
          right: 0;
          display: inline-block;
          width: 21px;
          height: 21px;
          content: " ";
          background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
          padding-right: 11px; }
      #single-miraimoblog .single_cont_txt img {
        width: 100%;
        height: auto; }
    #single-miraimoblog .single_cont_sns-bot {
      padding: 15px 0;
      text-align: center;
      border-top: 1px #ddd solid;
      border-bottom: 1px #ddd solid; }
      #single-miraimoblog .single_cont_sns-bot ul li {
        display: inline-block;
        padding-left: 6px;
        vertical-align: middle; }
    #single-miraimoblog .single_cont_word {
      padding: 30px;
      background: #f9f9f9; }
      #single-miraimoblog .single_cont_word ul li {
        display: inline-block;
        margin-right: 2em; }
        #single-miraimoblog .single_cont_word ul li a {
          color: #32cca6; }
    #single-miraimoblog .single_cont_author {
      *zoom: 1;
      padding-top: 60px; }
      #single-miraimoblog .single_cont_author:after {
        content: ".";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden; }
      #single-miraimoblog .single_cont_author_img {
        float: left; }
        #single-miraimoblog .single_cont_author_img img {
          border-radius: 60px; }
      #single-miraimoblog .single_cont_author_box {
        width: 650px;
        float: right; }
        #single-miraimoblog .single_cont_author_box_tit {
          background: url(../images/miraimo/common/ico-pen.png) no-repeat;
          min-height: 30px;
          padding: 4px 0 6px 34px;
          color: #32cca6;
          border-bottom: 1px #32cca6 solid;
          margin-bottom: 30px; }
        #single-miraimoblog .single_cont_author_box_name {
          font-size: 1.42em;
          padding-bottom: 30px; }
          #single-miraimoblog .single_cont_author_box_name span {
            padding-left: 20px;
            font-size: 13px; }
        #single-miraimoblog .single_cont_author_box_txt {
          color: #666; }
        #single-miraimoblog .single_cont_author_box_link {
          padding-top: 30px;
          text-align: right; }
          #single-miraimoblog .single_cont_author_box_link a {
            color: #32cca6; }
        #single-miraimoblog .single_cont_author_box .career_area {
          margin-bottom: 1em; }
          #single-miraimoblog .single_cont_author_box .career_area h4 {
            color: #32cca6;
            font-size: 1.07em;
            font-weight: bold; }
        #single-miraimoblog .single_cont_author_box .message_area h4 {
          color: #32cca6;
          font-size: 1.07em;
          font-weight: bold; }
  #single-miraimoblog .area_blog {
    padding-top: 80px; }
    #single-miraimoblog .area_blog .sub-tit {
      font-size: 1.71em;
      font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
      letter-spacing: .075em;
      text-align: center;
      padding: 20px 0;
      border-top: 1px #ddd solid; }
  #single-miraimoblog.single-voice .area_blog {
    padding-bottom: 0; }
  #single-miraimoblog.single-voice .single_cont_report {
    border: 1px #00b9d8 solid;
    color: #00b9d8; }
  #single-miraimoblog.single-voice .single_cont_txt .sub-tit:before {
    background: #00b9d8; }
  #single-miraimoblog.single-voice .single_cont_txt .list_number li:nth-child(1):before {
    color: #00b9d8;
    border: 1px #00b9d8 solid; }
  #single-miraimoblog.single-voice .single_cont_txt .list_number li:nth-child(2):before {
    color: #00b9d8;
    border: 1px #00b9d8 solid; }
  #single-miraimoblog.single-voice .single_cont_txt .list_number li:nth-child(3):before {
    color: #00b9d8;
    border: 1px #00b9d8 solid; }
  #single-miraimoblog.single-voice .single_cont_word ul li a {
    color: #00b9d8; }
  #single-miraimoblog.single-voice .single_cont_author_box_tit {
    color: #00b9d8;
    border-bottom: 1px #00b9d8 solid; }
  #single-miraimoblog.single-voice .single_cont_author_box_link a {
    color: #00b9d8; }
  /*
  careeradvisor
--------------------------------------------- */
  #careeradvisor .area_key .area_tit span {
    color: #0c4f93; }
  #careeradvisor .container {
    margin-bottom: 70px; }
    #careeradvisor .container .area_advisor {
      width: 1000px;
      margin: 0 auto; }
      #careeradvisor .container .area_advisor .area_advisor_cont {
        width: 484px;
        display: inline-block;
        border: solid 1px #ddd;
        margin: 0 0 30px 0; }
        #careeradvisor .container .area_advisor .area_advisor_cont:nth-child(odd) {
          float: left; }
        #careeradvisor .container .area_advisor .area_advisor_cont:nth-child(even) {
          float: right; }
        #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box {
          background: #ebf4fc;
          border-top: solid 4px #0c4f93;
          height: 100%; }
          #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box_upper {
            background: #fff;
            padding-top: 40px; }
          #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .img {
            border-radius: 50%;
            overflow: hidden;
            width: 150px;
            margin: 0 auto 35px; }
          #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box img {
            border-radius: 50%; }
          #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .name {
            text-align: center;
            margin: auto;
            font-size: 1.57em;
            border-top: 1px solid black;
            margin: 23px 0 0;
            text-align: center;
            padding: 0;
            margin: 0 40px;
            height: 24px; }
          #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .btn {
            padding-top: 10px;
            margin: 0 auto; }
            #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .btn a {
              display: block;
              font-size: 0.85em;
              border: 1px #0c4f93 solid;
              color: #0c4f93;
              line-height: 26px;
              border-radius: 13px;
              padding: 0 1em;
              width: 60%;
              margin: 0 auto 20px;
              text-align: center;
              text-decoration: none; }
              #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .btn a:hover {
                background: #0c4f93;
                color: #fff; }
          #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box span {
            position: relative;
            top: -20px;
            padding: 0 20px;
            margin: 40px 40px 0;
            background: white; }
          #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .career_area {
            padding: 10px 40px 30px; }
            #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .career_area p {
              color: #666; }
            #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .career_area h4 {
              position: relative;
              color: #0c4f93;
              padding-left: 40px;
              margin-left: -40px; }
            #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .career_area h4:before {
              content: "";
              width: 30px;
              height: 1px;
              background: #0c4f93;
              position: absolute;
              left: 0;
              top: 10px; }
          #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .message_area {
            padding: 30px 40px 40px;
            border-top: solid 1px #8a8a8a;
            background-color: #ebf4fc; }
            #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .message_area .message {
              color: #0c4f93; }
            #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .message_area p {
              color: #666; }
  /*
  flow
--------------------------------------------- */
  #flow .area_key .area_tit span {
    color: #ff9537; }
  .flow_cont {
    max-width: 1000px;
    margin: 0 auto; }
    .flow_cont .flow_box {
      border: solid 1px #ddd;
      background-color: #fff;
      margin-bottom: 50px; }
      .flow_cont .flow_box .float, .flow_cont .flow_box .overflow {
        box-sizing: border-box; }
      .flow_cont .flow_box .float {
        float: left; }
      .flow_cont .flow_box .flow_box_step {
        color: #ff9537;
        font-family: "PT Sans", sans-serif;
        border-top: solid 5px #ff9537;
        text-align: center;
        padding: 25px; }
        .flow_cont .flow_box .flow_box_step .step {
          font-size: 1.42em;
          transform: scale(1, 1.2); }
        .flow_cont .flow_box .flow_box_step .count {
          font-size: 3.42em;
          border-top: solid 2px #ff9537;
          line-height: normal;
          font-family: "PT Sans", sans-serif;
          transform: scale(1, 1.2); }
      .flow_cont .flow_box .overflow {
        overflow: hidden; }
      .flow_cont .flow_box .flow_box_text {
        border-top: solid 5px #8a8a8a; }
        .flow_cont .flow_box .flow_box_text .text_area {
          border-left: solid 1px #ddd;
          padding-left: 25px;
          margin: 25px 25px 25px 0; }
          .flow_cont .flow_box .flow_box_text .text_area h3 {
            color: #ff9537;
            font-weight: bold;
            font-size: 1.28em; }
          .flow_cont .flow_box .flow_box_text .text_area .sub_title {
            font-weight: bold;
            font-size: 1.28em; }
          .flow_cont .flow_box .flow_box_text .text_area p {
            margin-top: 10px; }
            .flow_cont .flow_box .flow_box_text .text_area p a {
              color: #ff9537; }
    .flow_cont .flow_box:after {
      display: block;
      content: "";
      clear: both; }
    .flow_cont .flow_box:last-child {
      margin-bottom: 0; }
    .flow_cont .text_box {
      text-align: center;
      margin-bottom: 50px; }
      .flow_cont .text_box p {
        margin-top: 10px; }
      .flow_cont .text_box .sub_title {
        font-weight: bold;
        font-size: 1.28em; }
  /* ---------- flow btn ---------- */
  .flow_btn_box {
    font-size: 0;
    margin: 70px 0; }
    .flow_btn_box .flow_btn {
      margin: 0 1%;
      display: inline-block;
      font-size: 14px;
      width: 48%; }
      .flow_btn_box .flow_btn a {
        font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
        font-size: 1.28em;
        letter-spacing: .1em;
        text-decoration: none;
        text-align: center;
        display: block;
        background: #fff;
        line-height: 66px;
        border-radius: 35px;
        margin: 0 auto; }
        .flow_btn_box .flow_btn a:hover {
          color: #fff; } }
      @media (min-width: 768px) and (max-width: 767px) {
        .flow_btn_box .flow_btn a {
          font-size: 0.85em;
          line-height: 46px;
          border-radius: 25px; } }

@media (min-width: 768px) {
      .flow_btn_box .flow_btn a {
        max-width: 400px;
        color: #0c4f93;
        font-size: 1.07em;
        border: 2px #0c4f93 solid; }
        .flow_btn_box .flow_btn a:hover {
          background: #0c4f93;
          color: #FFF; }
      .flow_btn_box .flow_btn.about a {
        color: #0c4f93;
        border: 2px #0c4f93 solid; }
        .flow_btn_box .flow_btn.about a:hover {
          background: #0c4f93;
          color: #FFF; }
  /*
  faq
--------------------------------------------- */
  #faq .area_key .area_tit span {
    color: #ff9537; }
  .faq_cont {
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 70px; }
    .faq_cont .faq_box {
      margin-top: -200px;
      margin-bottom: 50px;
      padding-top: 200px; }
      .faq_cont .faq_box dt {
        margin-bottom: 20px;
        border-bottom: solid 1px #ddd;
        padding-bottom: 20px;
        color: #ff9537;
        font-size: 1.28em; }
        .faq_cont .faq_box dt:before {
          content: "Q";
          padding: 6px 12px;
          border-radius: 4px;
          color: #fff;
          background-color: #ff9537;
          font-size: 1.28em;
          margin-right: 0.6em; }
      .faq_cont .faq_box dd p {
        padding-left: 4.3em;
        text-indent: -4.27em; }
      .faq_cont .faq_box dd p:before {
        content: "A";
        padding: 6px 12px;
        border-radius: 4px;
        color: #ff9537;
        border: solid 2px #ff9537;
        font-size: 1.28em;
        margin-right: 1em; }
  /*
  perfectguide-archive
--------------------------------------------- */
  #perfectguide .area_key p {
    width: 800px;
    margin: auto; }
  #perfectguide .area_key .area_tit span {
    color: #02c255; }
  #perfectguide .area_guide-book {
    margin-bottom: 100px; }
    #perfectguide .area_guide-book .area_guide_cont {
      font-size: 0; }
    #perfectguide .area_guide-book .area_guide_step {
      width: 49.5%;
      display: inline-block;
      margin: 0 1% 12px 0; }
      #perfectguide .area_guide-book .area_guide_step a {
        display: block;
        background: #fff;
        border-radius: 4px;
        padding: 20px 16px;
        font-size: 14px;
        text-decoration: none;
        border: 2px #02c255 solid; }
        #perfectguide .area_guide-book .area_guide_step a:hover {
          color: #02c255;
          border: 2px #02c255 solid;
          background: rgba(255, 255, 255, 0.7); }
      #perfectguide .area_guide-book .area_guide_step:nth-child(even) {
        margin-right: 0; }
      #perfectguide .area_guide-book .area_guide_step_point {
        color: #02c255;
        font-family: "PT Sans", sans-serif;
        display: inline-block;
        background: url(../images/miraimo/common/step-line.png) no-repeat right center;
        line-height: 23px;
        padding-right: 24px;
        margin-right: 20px; }
      #perfectguide .area_guide-book .area_guide_step_txt {
        display: inline-block; }
      #perfectguide .area_guide-book .area_guide_step_btn {
        padding: 33px 0 85px; }
        #perfectguide .area_guide-book .area_guide_step_btn a {
          font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-size: 1.07em;
          width: 450px;
          text-align: center;
          text-decoration: none;
          display: block;
          color: #fff;
          background: #34c272;
          background: -moz-linear-gradient(left, #34c272 0%, #0bd05f 100%);
          background: -webkit-linear-gradient(left, #34c272 0%, #0bd05f 100%);
          background: linear-gradient(to right, #34c272 0%, #0bd05f 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#34c272', endColorstr='#0bd05f', GradientType=1);
          line-height: 70px;
          border-radius: 35px;
          margin: 0 auto; }
          #perfectguide .area_guide-book .area_guide_step_btn a:hover {
            opacity: .6; }
  /*
  perfectguide-single
--------------------------------------------- */
  #perfectguide-single .area_key .area_tit span {
    color: #02c255; }
  #perfectguide-single .area_guide-book {
    width: 960px;
    padding-bottom: 100px;
    margin: auto; }
    #perfectguide-single .area_guide-book h3 {
      color: #fff;
      display: inline-block;
      background-color: #02c255;
      margin-left: 100px;
      width: 100%;
      margin: auto;
      font-size: 1.06em; }
    #perfectguide-single .area_guide-book h3:before {
      display: inline-block;
      background-color: #000;
      color: #fff;
      padding: 15px 35px;
      margin-right: 15px;
      font-family: "PT Sans", sans-serif; }
    #perfectguide-single .area_guide-book h3.count1:before {
      content: "Chapter.1"; }
    #perfectguide-single .area_guide-book h3.count2:before {
      content: "Chapter.2"; }
    #perfectguide-single .area_guide-book h3.count3:before {
      content: "Chapter.3"; }
    #perfectguide-single .area_guide-book h3.count4:before {
      content: "Chapter.4"; }
    #perfectguide-single .area_guide-book h3.count5:before {
      content: "Chapter.5"; }
    #perfectguide-single .area_guide-book h3.count6:before {
      content: "Chapter.6"; }
    #perfectguide-single .area_guide-book h3.count7:before {
      content: "Chapter.7"; }
    #perfectguide-single .area_guide-book h3.count8:before {
      content: "Chapter.8"; }
    #perfectguide-single .area_guide-book h4 {
      font-size: 1.85em;
      color: #02c255;
      padding: 80px 0 80px 40px; }
    #perfectguide-single .area_guide-book .area_guide-book_cont {
      margin: auto; }
      #perfectguide-single .area_guide-book .area_guide-book_cont dl {
        padding-left: 80px;
        border-top: 1px #ddd solid;
        padding-bottom: 50px; }
        #perfectguide-single .area_guide-book .area_guide-book_cont dl dt {
          padding: 50px 0 30px;
          font-size: 1.71em;
          padding-left: 50px;
          text-indent: -50px; }
          #perfectguide-single .area_guide-book .area_guide-book_cont dl dt:before {
            display: inline-block;
            vertical-align: middle;
            content: "";
            width: 30px;
            height: 2px;
            background: #02c255;
            margin-right: 20px; }
      #perfectguide-single .area_guide-book .area_guide-book_cont dl.cont_last {
        border-bottom: 1px #ddd solid; }
  #perfectguide-single .pages_step02 .area_guide-book h3:before {
    content: "step.2"; }
  #perfectguide-single .pages_step03 .area_guide-book h3:before {
    content: "step.3"; }
  #perfectguide-single .pages_step04 .area_guide-book h3:before {
    content: "step.4"; }
  #perfectguide-single .pages_step05 .area_guide-book h3:before {
    content: "step.5"; }
  #perfectguide-single .pages_step06 .area_guide-book h3:before {
    content: "step.6"; }
  #perfectguide-single .pages_step07 .area_guide-book h3:before {
    content: "step.7"; }
  #perfectguide-single .pages_step08 .area_guide-book h3:before {
    content: "step.8"; }
  #perfectguide-single .ordered_list {
    list-style-type: decimal;
    margin-left: 1.5em; }
    #perfectguide-single .ordered_list li {
      padding-left: 0.5em; }
  #perfectguide-single .unordered_list li {
    padding-left: 1em;
    text-indent: -1em; }
    #perfectguide-single .unordered_list li:before {
      content: "・"; }
  #perfectguide-single .square_list li {
    padding-left: 1em;
    text-indent: -1em; }
    #perfectguide-single .square_list li:before {
      content: "■";
      color: #02c255; }
  #perfectguide-single .notes_list li {
    padding-left: 1em;
    text-indent: -1em; }
    #perfectguide-single .notes_list li:before {
      content: "※"; }
  #perfectguide-single .link_wrap a {
    display: inline-block; }
  /*
  job-archive
--------------------------------------------- */
  #job-archive .area_key .area_tit span {
    color: #ff9537; }
  #job-archive .area_key .job_category {
    max-width: 600px;
    border-top: 1px #ddd solid;
    margin-top: 20px;
    padding-top: 30px;
    text-align: center;
    font-size: 1.28em;
    margin: 0 auto; }
    #job-archive .area_key .job_category span {
      font-weight: bold; }
  #job-archive .job_archive_cont {
    width: 100%;
    overflow: hidden; }
    #job-archive .job_archive_cont_box {
      width: 48%;
      border: 1px #ddd solid;
      border-top: none;
      margin-bottom: 60px;
      box-sizing: border-box;
      float: left; }
      #job-archive .job_archive_cont_box.full {
        width: 100%; }
      #job-archive .job_archive_cont_box_inner {
        background: #f9f9f9;
        border-top: 2px #ff9537 solid;
        padding: 25px;
        border-bottom: 1px #ddd solid; }
      #job-archive .job_archive_cont_box:nth-child(even) {
        float: right; }
      #job-archive .job_archive_cont_box .tit {
        font-size: 1.14em;
        font-weight: bold;
        padding-bottom: 15px; }
      #job-archive .job_archive_cont_box .tag_list {
        display: table-cell; }
        #job-archive .job_archive_cont_box .tag_list li {
          display: inline-block;
          margin: 0 8px 9px 0; }
          #job-archive .job_archive_cont_box .tag_list li a {
            background: #fff;
            display: inline-block;
            padding: 0 20px;
            line-height: 26px;
            font-size: 0.92em;
            text-decoration: none;
            text-align: center;
            border-radius: 14px;
            border: 2px #ffd200 solid; }
            #job-archive .job_archive_cont_box .tag_list li a:hover {
              background: #ffd200;
              color: #fff; }
      #job-archive .job_archive_cont_box table {
        width: calc(100% - 80px);
        height: 100%;
        margin: 10px 40px 30px; }
        #job-archive .job_archive_cont_box table tr {
          height: 100%;
          border-bottom: 1px #ddd solid; }
        #job-archive .job_archive_cont_box table th {
          width: 180px;
          padding: 30px 0;
          font-weight: bold; }
        #job-archive .job_archive_cont_box table td {
          padding: 10px 0; }
          #job-archive .job_archive_cont_box table td p {
            padding: 20px;
            border-left: 1px #ddd solid; }
        #job-archive .job_archive_cont_box table .company_name.no-member .caption {
          display: block;
          font-size: 0.85em; }
          #job-archive .job_archive_cont_box table .company_name.no-member .caption:before {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url(../images/miraimo/job/ico-caption.png) no-repeat;
            background-size: contain;
            margin-right: 5px;
            vertical-align: middle; }
          #job-archive .job_archive_cont_box table .company_name.no-member .caption a {
            color: #ff9537; }
      #job-archive .job_archive_cont_box .btn_detail {
        margin-bottom: 40px; }
        #job-archive .job_archive_cont_box .btn_detail a {
          font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-size: 1.28em;
          letter-spacing: .1em;
          text-decoration: none;
          text-align: center;
          display: block;
          background: #fff;
          line-height: 66px;
          border-radius: 35px;
          margin: 0 auto; }
          #job-archive .job_archive_cont_box .btn_detail a:hover {
            color: #fff; } }
        @media (min-width: 768px) and (max-width: 767px) {
          #job-archive .job_archive_cont_box .btn_detail a {
            font-size: 0.85em;
            line-height: 46px;
            border-radius: 25px; } }

@media (min-width: 768px) {
        #job-archive .job_archive_cont_box .btn_detail a {
          width: 350px;
          font-size: 1.07em;
          color: #ff9537;
          border: 2px #ff9537 solid; }
          #job-archive .job_archive_cont_box .btn_detail a:hover {
            background: #ff9537; }
      #job-archive .job_archive_cont_box > a {
        display: block;
        text-decoration: none; }
    #job-archive .job_archive_cont_dtl {
      padding: 25px; }
    #job-archive .job_archive_cont .job_dtl_company {
      width: 100%;
      margin-bottom: 15px;
      letter-spacing: -.4em; }
      #job-archive .job_archive_cont .job_dtl_company dt, #job-archive .job_archive_cont .job_dtl_company dd {
        display: inline-block;
        letter-spacing: normal; }
      #job-archive .job_archive_cont .job_dtl_company dt {
        font-weight: bold; }
      #job-archive .job_archive_cont .job_dtl_company dd {
        padding-left: 1em; }
        #job-archive .job_archive_cont .job_dtl_company dd.caption {
          margin-top: .5em;
          padding-left: 1.8em;
          font-size: 0.85em;
          position: relative; }
          #job-archive .job_archive_cont .job_dtl_company dd.caption:before {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url(../images/miraimo/job/ico-caption.png) no-repeat;
            background-size: contain;
            position: absolute;
            top: .25em;
            left: 0; }
          #job-archive .job_archive_cont .job_dtl_company dd.caption a {
            color: #ff9537; }
    #job-archive .job_archive_cont .job_dtl_info {
      padding-top: 15px;
      font-size: 0.85em;
      color: #8a8a8a;
      letter-spacing: -.4em;
      background-image: linear-gradient(to right, #C6C6C6, #C6C6C6 1px, transparent 1px, transparent 3px);
      background-size: 4px 1px;
      background-position: top;
      background-repeat: repeat-x; }
      #job-archive .job_archive_cont .job_dtl_info li {
        font-style: italic;
        display: inline-block;
        letter-spacing: normal; }
        #job-archive .job_archive_cont .job_dtl_info li:not(:last-child)::after {
          content: '/';
          margin-right: .25em;
          padding-right: .25em; }
  #job-archive .page-nation {
    margin-bottom: 80px;
    letter-spacing: normal; }
    #job-archive .page-nation ul li a {
      border: 1px #ff9537 solid;
      color: #ff9537; }
    #job-archive .page-nation ul li.current a, #job-archive .page-nation ul li:hover a {
      color: #fff;
      background: #ff9537; }
  /*
  job-single
--------------------------------------------- */
  #single-job .area_key .area_tit {
    padding: 90px 0 0; }
    #single-job .area_key .area_tit span {
      color: #ff9537; }
  #single-job .job_archive_cont {
    max-width: 1000px;
    margin: 0 auto; }
    #single-job .job_archive_cont h3.tit {
      font-size: 24px;
      padding-bottom: 30px; }
    #single-job .job_archive_cont .tag_list {
      display: table-cell; }
      #single-job .job_archive_cont .tag_list li {
        display: inline-block;
        margin: 0 8px 9px 0; }
        #single-job .job_archive_cont .tag_list li a {
          background: #fff;
          display: inline-block;
          padding: 0 20px;
          line-height: 26px;
          font-size: 0.92em;
          text-decoration: none;
          text-align: center;
          border-radius: 14px;
          border: 2px #ffd200 solid; }
          #single-job .job_archive_cont .tag_list li a:hover {
            background: #ffd200;
            color: #fff; }
    #single-job .job_archive_cont_box {
      margin: 40px auto 60px; }
      #single-job .job_archive_cont_box_inner {
        background: #f9f9f9;
        border-top: 3px #ff9537 solid;
        padding: 58px 40px;
        border-bottom: 1px #ddd solid; }
      #single-job .job_archive_cont_box table {
        height: 100%;
        margin: 30px 0;
        font-size: 1.14em; }
        #single-job .job_archive_cont_box table tr {
          height: 100%;
          border-bottom: 1px #ddd solid; }
        #single-job .job_archive_cont_box table th {
          width: 210px;
          padding: 15px 0;
          font-weight: bold; }
        #single-job .job_archive_cont_box table td {
          padding: 10px 0; }
          #single-job .job_archive_cont_box table td p, #single-job .job_archive_cont_box table td > div {
            padding: 10px 20px 10px 40px;
            border-left: 1px #ddd solid; }
          #single-job .job_archive_cont_box table td dl {
            font-size: 0; }
            #single-job .job_archive_cont_box table td dl dt, #single-job .job_archive_cont_box table td dl dd {
              font-size: 16px;
              display: inline-block; }
            #single-job .job_archive_cont_box table td dl dt {
              font-weight: bold; }
            #single-job .job_archive_cont_box table td dl dd {
              padding-left: .3em; }
        #single-job .job_archive_cont_box table .company_name.no-member .caption {
          display: block;
          font-size: 0.85em;
          color: #8a8a8a; }
          #single-job .job_archive_cont_box table .company_name.no-member .caption:before {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url(../images/miraimo/job/ico-caption.png) no-repeat;
            background-size: contain;
            margin-right: 5px;
            vertical-align: middle; }
          #single-job .job_archive_cont_box table .company_name.no-member .caption a {
            color: #ff9537; }
      #single-job .job_archive_cont_box .btn_detail {
        padding: 33px 0 30px; }
        #single-job .job_archive_cont_box .btn_detail button[type="submit"],
        #single-job .job_archive_cont_box .btn_detail input[type="submit"],
        #single-job .job_archive_cont_box .btn_detail input[type="button"],
        #single-job .job_archive_cont_box .btn_detail a {
          cursor: pointer;
          color: #fff;
          background-color: #ef717d;
          background-image: linear-gradient(90deg, #ef717d 25%, #f2986e 100%);
          font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-size: 1.07em;
          font-weight: bold;
          width: 400px !important;
          text-align: center;
          text-decoration: none;
          display: block;
          line-height: 70px;
          border-radius: 35px;
          margin: 15px auto 0;
          border: none;
          cursor: pointer; }
          #single-job .job_archive_cont_box .btn_detail button[type="submit"]:hover,
          #single-job .job_archive_cont_box .btn_detail input[type="submit"]:hover,
          #single-job .job_archive_cont_box .btn_detail input[type="button"]:hover,
          #single-job .job_archive_cont_box .btn_detail a:hover {
            transition: .8s;
            opacity: .6; }
          #single-job .job_archive_cont_box .btn_detail button[type="submit"].textlink,
          #single-job .job_archive_cont_box .btn_detail input[type="submit"].textlink,
          #single-job .job_archive_cont_box .btn_detail input[type="button"].textlink,
          #single-job .job_archive_cont_box .btn_detail a.textlink {
            display: inline;
            width: auto;
            font-size: inherit;
            font-weight: normal;
            text-decoration: underline;
            margin: 0;
            color: #ff9537;
            background: none;
            background-image: none;
            border: none;
            line-height: 1; }
            #single-job .job_archive_cont_box .btn_detail button[type="submit"].textlink:hover,
            #single-job .job_archive_cont_box .btn_detail input[type="submit"].textlink:hover,
            #single-job .job_archive_cont_box .btn_detail input[type="button"].textlink:hover,
            #single-job .job_archive_cont_box .btn_detail a.textlink:hover {
              background: none;
              color: inherit;
              text-decoration: none;
              opacity: 1; }
        #single-job .job_archive_cont_box .btn_detail > p {
          margin-top: 20px;
          text-align: center; }
  #single-job .page-nation {
    letter-spacing: normal; }
    #single-job .page-nation ul li a {
      border: 1px #ff9537 solid;
      color: #ff9537; }
    #single-job .page-nation ul li.current a, #single-job .page-nation ul li:hover a {
      color: #fff;
      background: #ff9537; }
  /*
  member
--------------------------------------------- */
  #member .area_tit span {
    color: #f85550; }
  #member .container {
    width: 1000px;
    margin: 0 auto; }
    #member .container .top_txt h2 {
      font-size: 20px;
      text-align: center;
      margin-bottom: 20px; }
    #member .container .top_txt p {
      text-align: center;
      font-size: 15px; }
    #member .container form#member button.button1 {
      font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
      font-size: 15px;
      font-weight: bold;
      width: 400px;
      text-align: center;
      text-decoration: none;
      display: block;
      color: #000;
      background: #ffc000;
      background: linear-gradient(to right, #ffc000 0%, #ffea00 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc000', endColorstr='#ffea00', GradientType=1);
      line-height: 70px;
      border-radius: 35px;
      margin: 0 auto;
      border: none; }
  /*
  registnow
--------------------------------------------- */
  .form_cont .area_key {
    padding-bottom: 60px; }
    .form_cont .area_key .area_tit span {
      color: #f85550; }
    .form_cont .area_key .txt {
      text-align: center;
      width: 800px;
      margin: auto; }
      .form_cont .area_key .txt + .btn_normal {
        margin: 80px 0 100px; }
  .miraimo_flow {
    width: 100%;
    margin-bottom: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .miraimo_flow .miraimo_flow_item {
      padding: 60px 1em;
      line-height: 1; }
    .miraimo_flow .flow_step_01 {
      width: 340px;
      font-size: 28px;
      color: #fff;
      text-align: center;
      border-radius: 10px;
      background-color: #ef717d;
      background-image: linear-gradient(90deg, #ef717d 25%, #f2986e 100%); }
      .miraimo_flow .flow_step_01 span {
        margin-bottom: 1.2em;
        font-size: 20px;
        font-weight: bold;
        display: block; }
    .miraimo_flow .flow_step_join {
      line-height: 1.4;
      color: #e0787f;
      text-align: center; }
      .miraimo_flow .flow_step_join span {
        padding-top: 30px;
        display: block;
        position: relative; }
        .miraimo_flow .flow_step_join span::before {
          content: '';
          background: url("/assets/images/miraimo/img_register_arw.png") center center no-repeat;
          background-size: contain;
          width: 81px;
          height: 15px;
          margin-left: -40.5px;
          position: absolute;
          left: 50%;
          top: 0;
          z-index: 1; }
    .miraimo_flow .flow_step_02 {
      width: 340px;
      font-size: 28px;
      color: #e0787f;
      text-align: center;
      border-radius: 10px;
      border-image: linear-gradient(90deg, #ef717d 25%, #f2986e 100%);
      border: 2px solid;
      border-image-slice: 20;
      border-image-repeat: round;
      background-color: #fff; }
      .miraimo_flow .flow_step_02 span {
        margin-bottom: 1.2em;
        font-size: 20px;
        font-weight: bold;
        display: block; }
  .emphasis {
    font-weight: bold;
    background: linear-gradient(transparent 40%, #ffff00 0%);
    display: inline;
    padding-bottom: 6px; }
  .entry_list .entry_list_item {
    margin-bottom: 10px;
    font-weight: bold; }
  .entry_list .entry_list_detail {
    padding-left: 1.8em;
    font-size: 13px; }
    .entry_list .entry_list_detail + .entry_list_item {
      margin-top: 20px; }
  .other_answer {
    margin-top: 10px; }
    .other_answer label {
      margin-bottom: 5px;
      display: block;
      cursor: default; }
  .caution_txt {
    padding-left: 1em;
    text-indent: -1em; }
    .caution_txt + .caution_txt {
      margin-top: 10px; }
  /*
 cont
--------------------------------------------- */
  .form_cont {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 100px; }
    .page-template-template-miraimo-lp-thankyou .form_cont {
      margin-bottom: 0; }
    .form_cont h3 {
      padding: 16px;
      font-weight: bold; }
    .form_cont table {
      width: 100%;
      border: 1px solid #ddd; }
      .form_cont table tr {
        border-bottom: 1px solid #ddd; }
      .form_cont table th {
        display: table-cell !important;
        background: #f9f9f9;
        padding: 20px 15px;
        width: 200px !important;
        border-right: 1px solid #ddd;
        line-height: 1.3;
        position: relative;
        vertical-align: top;
        z-index: -1; }
        .form_cont table th span {
          font-size: 12px; }
        .form_cont table th span.required {
          position: absolute;
          right: 15px;
          top: 20px;
          color: #fff;
          border-radius: 5px;
          background: #cd1212;
          padding: 3px 10px;
          font-size: 11px; }
      .form_cont table td {
        display: table-cell !important;
        background: #fff;
        font-size: 15px;
        padding: 10px 20px; }
      .form_cont table td input[type="text"],
      .form_cont table td input[type="password"],
      .form_cont table td input[type="email"],
      .form_cont table td input[type="tel"],
      .form_cont table td input[type="number"],
      .form_cont table td textarea,
      .form_cont table td select {
        font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
        border-radius: 5px;
        padding: 13px 10px;
        border: 1px #ccc solid;
        background: #f5f5f5;
        width: 100%;
        font-size: 1em; }
      .form_cont table td input,
      .form_cont table td textarea {
        box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1) inset; }
      .form_cont table td input[type="radio"] {
        box-shadow: none; }
      .form_cont table td input[readonly] {
        box-shadow: none;
        background: #fff; }
      .form_cont table td textarea {
        min-height: 300px; }
      .form_cont table td select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: #f5f5f5 url("//cdn2.iconfinder.com/data/icons/picol-vector/32/arrow_sans_down-32.png") no-repeat 98% center;
        background-size: 12px 12px; }
        .form_cont table td select::-ms-expand {
          display: none; }
    .form_cont .submit_btn {
      text-align: center; }
    .form_cont .error_msg {
      font-weight: bold;
      color: #cd1212; }
    .form_cont input::-webkit-input-placeholder, .form_cont textarea::-webkit-input-placeholder {
      color: #999; }
    .form_cont input::-moz-placeholder, .form_cont textarea::-moz-placeholder {
      color: #999; }
    .form_cont input:-ms-input-placeholder, .form_cont textarea:-ms-input-placeholder {
      color: #999; }
    .form_cont input:focus::-webkit-input-placeholder, .form_cont textarea:focus::-webkit-input-placeholder {
      color: transparent; }
    .form_cont input:focus::-moz-placeholder, .form_cont textarea:focus::-moz-placeholder {
      color: transparent; }
    .form_cont input:focus:-ms-input-placeholder, .form_cont textarea:focus:-ms-input-placeholder {
      color: transparent; }
    .form_cont button[type="submit"],
    .form_cont input[type="submit"],
    .form_cont input[type="button"] {
      cursor: pointer;
      border: 2px #ffd200 solid;
      color: #ffd200;
      font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
      font-size: 1.07em;
      font-weight: bold;
      width: 400px !important;
      text-align: center;
      text-decoration: none;
      display: block;
      line-height: 70px;
      border-radius: 35px;
      margin: 50px auto;
      cursor: pointer;
      background: #fff; }
      .form_cont button[type="submit"]:hover,
      .form_cont input[type="submit"]:hover,
      .form_cont input[type="button"]:hover {
        color: #000;
        background: #ffc000;
        background: linear-gradient(to right, #ffc000 0%, #ffea00 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc000', endColorstr='#ffea00', GradientType=1);
        border: 2px #ffd200 solid; }
  /*
 tml
--------------------------------------------- */
  .tml {
    max-width: 100% !important; }
    .tml .submit_btn {
      margin: 50px auto 10px; }
    .tml p {
      margin: 0 auto 1.5em !important; }
    .tml .error {
      color: #cd1212;
      font-weight: bold; }
  .tml-login .tml-rememberme-submit-wrap {
    display: block !important; }
  .tml-login .tml-submit-wrap {
    display: block !important; }
    .tml-login .tml-submit-wrap input {
      margin: 0 auto !important; }
  .tml-login .tml-rememberme-wrap {
    display: block !important;
    text-align: center;
    margin: 30px 0 0 !important; }
  .tml .tml-action-links {
    width: 100%; }
  .tml .tml-action-links li {
    float: none !important;
    display: inline-block; }
  p.tml-user-pass1-wrap,
  p.tml-user-pass2-wrap {
    display: table;
    width: 100%;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin: 0 !important; }
    p.tml-user-pass1-wrap label,
    p.tml-user-pass2-wrap label {
      display: table-cell !important;
      background: #f9f9f9;
      padding: 20px 15px;
      width: 200px !important;
      border-right: 1px solid #ddd;
      line-height: 1.3;
      position: relative;
      vertical-align: top; }
      p.tml-user-pass1-wrap label:after,
      p.tml-user-pass2-wrap label:after {
        content: "必須";
        position: absolute;
        right: 15px;
        top: 20px;
        color: #fff;
        border-radius: 5px;
        background: #cd1212;
        padding: 3px 10px;
        font-size: 11px; }
    p.tml-user-pass1-wrap input,
    p.tml-user-pass2-wrap input {
      display: table-cell !important;
      background: #fff;
      font-size: 15px;
      margin: 10px 20px !important; }
    p.tml-user-pass1-wrap input[type="text"],
    p.tml-user-pass1-wrap input[type="password"],
    p.tml-user-pass1-wrap input[type="email"],
    p.tml-user-pass1-wrap textarea,
    p.tml-user-pass1-wrap select,
    p.tml-user-pass2-wrap input[type="text"],
    p.tml-user-pass2-wrap input[type="password"],
    p.tml-user-pass2-wrap input[type="email"],
    p.tml-user-pass2-wrap textarea,
    p.tml-user-pass2-wrap select {
      font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
      border-radius: 5px;
      padding: 13px 10px;
      border: 1px #ccc solid;
      background: #f5f5f5;
      width: calc(100% - 40px) !important; }
  .tml-user-pass1-wrap #pass1 + span {
    margin: -5px 20px 5px;
    font-size: .85em;
    display: block; }
  td label {
    cursor: pointer; }
  td select {
    -webkit-appearance: menulist-button;
    -webkit-appearance: textfield; }
  .wpcf7-form p {
    display: none; }
  .wpcf7-mail-sent-ok {
    display: none !important; }
  /*
 reset password
--------------------------------------------- */
  #resetpassform .wp-pwd .password-input-wrapper #pass1-text {
    border: none;
    background: #ffffdf;
    box-shadow: none;
    margin: 5px 0;
    padding: 5px 10px; }
  #resetpassform .wp-pwd #pass-strength-result {
    font-weight: bold; }
    #resetpassform .wp-pwd #pass-strength-result.short {
      color: #f85550; }
    #resetpassform .wp-pwd #pass-strength-result.bad {
      color: #ff9537; }
    #resetpassform .wp-pwd #pass-strength-result.bad {
      color: #ff9537; }
    #resetpassform .wp-pwd #pass-strength-result.good {
      color: #000; }
    #resetpassform .wp-pwd #pass-strength-result.strong {
      color: #02c255; }
  /*
  interview
--------------------------------------------- */
  .interview_cont .interviewee {
    border: 1px #ddd solid;
    font-size: 1.2em;
    margin-bottom: 3em;
    padding: 20px 20px 15px; }
    .interview_cont .interviewee .interviewee_lead {
      margin-top: -40px;
      font-size: 1.3em; }
      .interview_cont .interviewee .interviewee_lead span {
        background: #FFF;
        display: inline-block;
        padding: 0 .5em; }
    .interview_cont .interviewee p {
      padding-bottom: 0 !important; }
  .interview_cont p img {
    width: 100%;
    height: auto; }
  .interview_cont p .interview_caption {
    display: block;
    background: #efefef;
    padding: 10px; }
  #single-miraimoblog .single_cont_txt .interview_cont .sub-tit:before {
    background: #00b9d8; }
  .interview_cont .list-hl {
    display: block;
    font-weight: bold;
    padding-bottom: .5em;
    text-decoration: underline; }
  .single-interview .single_cont_txt .interview_cont ol li:before,
  .single-interview .single_cont_txt .interview_cont ol li:after {
    border-color: #00b9d8;
    color: #00b9d8; }
  /*
	ご利用者の声 voice
--------------------------------------------- */
  .p-voice-headline-l1 {
    font-size: 1.71em;
    padding-bottom: 20px;
    line-height: 1.5; }
    .p-voice-headline-l1:before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      width: 30px;
      height: 2px;
      background: #00b9d8;
      margin-right: 20px; }
  .p-voice-list-nbr {
    counter-reset: number;
    padding-bottom: 30px; }
    .p-voice-list-nbr li {
      position: relative;
      line-height: 22px;
      padding: 0 0 10px 42px; }
      .p-voice-list-nbr li:before {
        content: counter(number);
        counter-increment: number;
        position: absolute;
        left: 0;
        display: inline-block;
        vertical-align: top;
        color: #00b9d8;
        width: 22px;
        line-height: 22px;
        text-align: center;
        border: 1px #00b9d8 solid;
        border-radius: 4px;
        margin: 0 20px 15px 0; }
  .p-voice-list-mrk {
    padding-bottom: 30px; }
    .p-voice-list-mrk li {
      padding: 0 0 10px 42px;
      position: relative;
      z-index: 1;
      line-height: 22px; }
      .p-voice-list-mrk li:before {
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        border: 1px solid #00b9d8;
        border-radius: 3px; }
      .p-voice-list-mrk li:after {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        z-index: 1;
        top: 4px;
        left: 4px;
        background: #00b9d8;
        border-radius: 4px; }
  .p-voice-caption {
    display: inline-block;
    position: relative;
    padding: 0 42px; }
    .p-voice-caption:before {
      position: absolute;
      left: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
      padding-left: 11px; }
    .p-voice-caption p {
      display: inline-block;
      vertical-align: top;
      line-height: 21px;
      padding-bottom: 0 !important;
      color: #666;
      font-style: oblique; }
    .p-voice-caption:after {
      position: absolute;
      right: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
      padding-right: 11px; }
  .single-voice .single_cont_txt h3 {
    font-size: 1.71em;
    padding-bottom: 20px;
    line-height: 1.5;
    padding-left: 50px;
    text-indent: -50px; }
    .single-voice .single_cont_txt h3:before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      width: 30px;
      height: 2px;
      background: #00b9d8;
      margin-right: 20px; }
  .single-voice .single_cont_txt ol {
    counter-reset: number;
    padding-bottom: 30px; }
    .single-voice .single_cont_txt ol li {
      position: relative;
      line-height: 22px;
      padding: 0 0 10px 42px; }
      .single-voice .single_cont_txt ol li:before {
        content: counter(number);
        counter-increment: number;
        position: absolute;
        left: 0;
        display: inline-block;
        vertical-align: top;
        color: #00b9d8;
        width: 22px;
        line-height: 22px;
        text-align: center;
        border: 1px #00b9d8 solid;
        border-radius: 4px;
        margin: 0 20px 15px 0; }
  .single-voice .single_cont_txt ul {
    padding-bottom: 30px; }
    .single-voice .single_cont_txt ul li {
      padding: 0 0 10px 42px;
      position: relative;
      z-index: 1;
      line-height: 22px; }
      .single-voice .single_cont_txt ul li:before {
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        border: 1px solid #00b9d8;
        border-radius: 3px; }
      .single-voice .single_cont_txt ul li:after {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        z-index: 1;
        top: 4px;
        left: 4px;
        background: #00b9d8;
        border-radius: 4px; }
  .single-voice .single_cont_txt blockquote {
    display: inline-block;
    position: relative;
    padding: 0 42px; }
    .single-voice .single_cont_txt blockquote:before {
      position: absolute;
      left: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
      padding-left: 11px; }
    .single-voice .single_cont_txt blockquote p {
      display: inline-block;
      vertical-align: top;
      line-height: 21px;
      padding-bottom: 0 !important;
      color: #666;
      font-style: oblique; }
    .single-voice .single_cont_txt blockquote:after {
      position: absolute;
      right: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
      padding-right: 11px; }
  /*
	人事担当者インタビュー interview
--------------------------------------------- */
  .p-interview-headline-l1 {
    font-size: 1.71em;
    padding-bottom: 20px;
    line-height: 1.5; }
    .p-interview-headline-l1:before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      width: 30px;
      height: 2px;
      background: #ffd200;
      margin-right: 20px; }
  .p-interview-list-nbr {
    counter-reset: number;
    padding-bottom: 30px; }
    .p-interview-list-nbr li {
      position: relative;
      line-height: 22px;
      padding: 0 0 10px 42px; }
      .p-interview-list-nbr li:before {
        content: counter(number);
        counter-increment: number;
        position: absolute;
        left: 0;
        display: inline-block;
        vertical-align: top;
        color: #ffd200;
        width: 22px;
        line-height: 22px;
        text-align: center;
        border: 1px #ffd200 solid;
        border-radius: 4px;
        margin: 0 20px 15px 0; }
  .p-interview-list-mrk {
    padding-bottom: 30px; }
    .p-interview-list-mrk li {
      padding: 0 0 10px 42px;
      position: relative;
      z-index: 1;
      line-height: 22px; }
      .p-interview-list-mrk li:before {
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        border: 1px solid #ffd200;
        border-radius: 3px; }
      .p-interview-list-mrk li:after {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        z-index: 1;
        top: 4px;
        left: 4px;
        background: #ffd200;
        border-radius: 4px; }
  .p-interview-caption {
    display: inline-block;
    position: relative;
    padding: 0 42px; }
    .p-interview-caption:before {
      position: absolute;
      left: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
      padding-left: 11px; }
    .p-interview-caption p {
      display: inline-block;
      vertical-align: top;
      line-height: 21px;
      padding-bottom: 0 !important;
      color: #666;
      font-style: oblique; }
    .p-interview-caption:after {
      position: absolute;
      right: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
      padding-right: 11px; }
  .single-interview .single_cont_txt h3 {
    font-size: 1.71em;
    padding-bottom: 20px;
    line-height: 1.5;
    padding-left: 50px;
    text-indent: -50px; }
    .single-interview .single_cont_txt h3:before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      width: 30px;
      height: 2px;
      background: #ffd200;
      margin-right: 20px; }
  .single-interview .single_cont_txt ol {
    counter-reset: number;
    padding-bottom: 30px; }
    .single-interview .single_cont_txt ol li {
      position: relative;
      line-height: 22px;
      padding: 0 0 10px 42px; }
      .single-interview .single_cont_txt ol li:before {
        content: counter(number);
        counter-increment: number;
        position: absolute;
        left: 0;
        display: inline-block;
        vertical-align: top;
        color: #ffd200;
        width: 22px;
        line-height: 22px;
        text-align: center;
        border: 1px #ffd200 solid;
        border-radius: 4px;
        margin: 0 20px 15px 0; }
  .single-interview .single_cont_txt ul {
    padding-bottom: 30px; }
    .single-interview .single_cont_txt ul li {
      padding: 0 0 10px 42px;
      position: relative;
      z-index: 1;
      line-height: 22px; }
      .single-interview .single_cont_txt ul li:before {
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        border: 1px solid #ffd200;
        border-radius: 3px; }
      .single-interview .single_cont_txt ul li:after {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        z-index: 1;
        top: 4px;
        left: 4px;
        background: #ffd200;
        border-radius: 4px; }
  .single-interview .single_cont_txt blockquote {
    display: inline-block;
    position: relative;
    padding: 0 42px; }
    .single-interview .single_cont_txt blockquote:before {
      position: absolute;
      left: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
      padding-left: 11px; }
    .single-interview .single_cont_txt blockquote p {
      display: inline-block;
      vertical-align: top;
      line-height: 21px;
      padding-bottom: 0 !important;
      color: #666;
      font-style: oblique; }
    .single-interview .single_cont_txt blockquote:after {
      position: absolute;
      right: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
      padding-right: 11px; }
  /*
	miraimoブログ miraimoblog
--------------------------------------------- */
  .p-miraimoblog-headline-l1 {
    font-size: 1.71em;
    line-height: 1.5;
    padding-bottom: 10px;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px; }
    .p-miraimoblog-headline-l1:before {
      content: '';
      width: 50px;
      height: 1px;
      background: #02c255;
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0; }
  .p-miraimoblog-list-nbr {
    counter-reset: number;
    padding-bottom: 30px; }
    .p-miraimoblog-list-nbr li {
      position: relative;
      line-height: 22px;
      padding: 0 0 10px 42px; }
      .p-miraimoblog-list-nbr li:before {
        content: counter(number);
        counter-increment: number;
        position: absolute;
        left: 0;
        display: inline-block;
        vertical-align: top;
        color: #02c255;
        width: 22px;
        line-height: 22px;
        text-align: center;
        border: 1px #02c255 solid;
        border-radius: 4px;
        margin: 0 20px 15px 0; }
  .p-miraimoblog-list-mrk {
    padding-bottom: 30px; }
    .p-miraimoblog-list-mrk li {
      padding: 0 0 10px 42px;
      position: relative;
      z-index: 1;
      line-height: 22px; }
      .p-miraimoblog-list-mrk li:before {
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        border: 1px solid #02c255;
        border-radius: 3px; }
      .p-miraimoblog-list-mrk li:after {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        z-index: 1;
        top: 4px;
        left: 4px;
        background: #02c255;
        border-radius: 4px; }
  .p-miraimoblog-caption {
    display: inline-block;
    position: relative;
    padding: 0 42px; }
    .p-miraimoblog-caption:before {
      position: absolute;
      left: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
      padding-left: 11px; }
    .p-miraimoblog-caption p {
      display: inline-block;
      vertical-align: top;
      line-height: 21px;
      padding-bottom: 0 !important;
      color: #666;
      font-style: oblique; }
    .p-miraimoblog-caption:after {
      position: absolute;
      right: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
      padding-right: 11px; }
  .single-miraimoblog .single_cont_txt h3 {
    font-size: 1.71em;
    line-height: 1.5;
    padding-bottom: 10px;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px; }
    .single-miraimoblog .single_cont_txt h3:before {
      content: '';
      width: 50px;
      height: 1px;
      background: #02c255;
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0; }
  .single-miraimoblog .single_cont_txt ol {
    counter-reset: number;
    padding-bottom: 30px; }
    .single-miraimoblog .single_cont_txt ol li {
      position: relative;
      line-height: 22px;
      padding: 0 0 10px 42px; }
      .single-miraimoblog .single_cont_txt ol li:before {
        content: counter(number);
        counter-increment: number;
        position: absolute;
        left: 0;
        display: inline-block;
        vertical-align: top;
        color: #02c255;
        width: 22px;
        line-height: 22px;
        text-align: center;
        border: 1px #02c255 solid;
        border-radius: 4px;
        margin: 0 20px 15px 0; }
  .single-miraimoblog .single_cont_txt ul {
    padding-bottom: 30px; }
    .single-miraimoblog .single_cont_txt ul li {
      padding: 0 0 10px 42px;
      position: relative;
      z-index: 1;
      line-height: 22px; }
      .single-miraimoblog .single_cont_txt ul li:before {
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        border: 1px solid #02c255;
        border-radius: 3px; }
      .single-miraimoblog .single_cont_txt ul li:after {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        z-index: 1;
        top: 4px;
        left: 4px;
        background: #02c255;
        border-radius: 4px; }
  .single-miraimoblog .single_cont_txt blockquote {
    display: inline-block;
    position: relative;
    padding: 0 42px; }
    .single-miraimoblog .single_cont_txt blockquote:before {
      position: absolute;
      left: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
      padding-left: 11px; }
    .single-miraimoblog .single_cont_txt blockquote p {
      display: inline-block;
      vertical-align: top;
      line-height: 21px;
      padding-bottom: 0 !important;
      color: #666;
      font-style: oblique; }
    .single-miraimoblog .single_cont_txt blockquote:after {
      position: absolute;
      right: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
      padding-right: 11px; }
  /*
	求人情報用検索ブロック
--------------------------------------------- */
  .search_block {
    background: none; }
    .search_block .search_block_inbox {
      background: #F9F9F9;
      border: 1px solid #DDD;
      border-top: none;
      border-bottom: none;
      display: none;
      padding: 0 30px 30px; }
      .search_block .search_block_inbox.search_job {
        display: block;
        padding-top: 30px; }
    .search_block .area_search_cont {
      padding: 0; }
      .search_block .area_search_cont.freeword {
        background: #F9F9F9;
        border-radius: 5px 5px 0 0;
        border: 1px solid #DDD;
        padding: 30px; }
        .search_block .area_search_cont.freeword .area_search_cont_input {
          padding: 0; }
      .search_block .area_search_cont .tit {
        background-position: right 6px;
        padding: 0;
        width: 160px; }
      .search_block .area_search_cont .area_search_cont_list,
      .search_block .area_search_cont .area_search_cont_box {
        vertical-align: top;
        padding: 0 25px; }
      .search_block .area_search_cont.job-area {
        margin: 0 0 40px; }
    .search_block .search_block_btn {
      background: #F9F9F9;
      border-radius: 0 0 5px 5px;
      border: 1px solid #DDD;
      cursor: pointer;
      font-size: 1.14em;
      line-height: 73px;
      text-align: center; }
      .search_block .search_block_btn span {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        line-height: 1; }
        .search_block .search_block_btn span:after {
          content: "";
          width: 6px;
          height: 6px;
          border-top: 1px solid #000;
          border-right: 1px solid #000;
          display: inline-block;
          margin-left: 8px;
          vertical-align: middle;
          -webkit-transform: rotateZ(135deg);
          -ms-transform: rotateZ(135deg);
          transform: rotateZ(135deg);
          margin-top: -4px; }
      .search_block .search_block_btn.is-active span:after {
        -webkit-transform: rotateZ(-45deg);
        -ms-transform: rotateZ(-45deg);
        transform: rotateZ(-45deg);
        margin-top: 1px; }
  /*
	add 22.03
--------------------------------------------- */
  .detail {
    width: 100%;
    margin-top: 10px; }
    .detail th,
    .detail td {
      vertical-align: top;
      padding: 5px 0; }
    .detail th {
      width: 120px; }
  /* ==================================================
  sp LP1
================================================== */
  /*
  common
--------------------------------------------- */
  .lp1 img {
    max-width: 100%;
    vertical-align: top; }
  .lp1-button {
    display: block;
    margin: 0 auto;
    width: 77.0667%; }
  .lp1-container {
    margin: 0 auto;
    max-width: 750px; }
  /*
  intro
--------------------------------------------- */
  .lp1-intro {
    position: relative; }
    .lp1-intro .lp1-container {
      position: relative;
      padding: 350px 0 60px; }
  .lp1-intro_logo {
    margin: 9px 0 0 15px;
    width: 192px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3; }
  .lp1-intro_balloon {
    width: 500px;
    position: absolute;
    top: -60px;
    right: 10px;
    z-index: 2; }
  .lp1-intro_desc {
    width: 84.2667%;
    margin-left: 12.8%;
    position: relative;
    z-index: 3; }
  .lp1-intro_button {
    position: relative;
    z-index: 3; }
  .lp1-intro_person {
    width: 58%;
    position: absolute;
    bottom: 0;
    left: -8.8%;
    z-index: 1; }
  /*
  user
--------------------------------------------- */
  .lp1-user {
    background: #f16f7b;
    /* Old browsers */
    background: -moz-linear-gradient(left, #f16f7b 0%, #f4986b 100%);
    background: -webkit-linear-gradient(left, #f16f7b 0%, #f4986b 100%);
    background: linear-gradient(to right, #f16f7b 0%, #f4986b 100%);
    padding-top: 60px; }
  .lp1-user_title {
    margin: 0 auto 5px;
    width: 70.4%; }
  .lp1-user_merit {
    margin: 0 0 11px -2.667%;
    width: 100%; }
  .lp1-user_reason {
    margin-left: -13.0667%;
    width: 113.0667%; }
  /*
  jpb
--------------------------------------------- */
  .lp1-job {
    padding: 60px 0; }
  .lp1-job_title {
    margin: 0 auto 40px;
    width: 69.0667%; }
  .lp1-job_list {
    margin: 0 auto 9px;
    width: 89.333%; }
  .lp1-job_item {
    margin-bottom: 40px; }
  .lp1-job_desc {
    margin: 0 auto 12px;
    width: 76%; }
  /*
  voice
--------------------------------------------- */
  .lp1-voice {
    background: #4490cd;
    /* Old browsers */
    background: -moz-linear-gradient(left, #4490cd 0%, #60b3b1 100%);
    background: -webkit-linear-gradient(left, #4490cd 0%, #60b3b1 100%);
    background: linear-gradient(to right, #4490cd 0%, #60b3b1 100%);
    padding: 60px 0; }
  .lp1-voice_title {
    margin: 0 auto 40px;
    width: 76%; }
  .lp1-voice_list {
    margin: 0 auto; }
  .lp1-voice_item {
    margin-bottom: 40px;
    width: 84%; }
    .lp1-voice_item:nth-child(odd) {
      margin-left: 4%; }
    .lp1-voice_item:nth-child(even) {
      margin-right: 4%;
      margin-left: auto; }
  /*
  careeradvisor
 --------------------------------------------- */
  .lp1-careeradvisor {
    padding: 60px 0; }
  .lp1-careeradvisor_title {
    margin: 0 auto 40px;
    width: 73.6%; }
  .lp1-careeradvisor_list {
    margin-bottom: 40px; }
  .lp1-careeradvisor_item {
    margin-bottom: 10px;
    width: 92%; }
    .lp1-careeradvisor_item:nth-child(odd) {
      margin-left: 4%; }
    .lp1-careeradvisor_item:nth-child(even) {
      margin-right: 4%;
      margin-left: auto; }
  /*
  flow
--------------------------------------------- */
  .lp1-flow {
    background: #5abe61;
    /* Old browsers */
    background: -moz-linear-gradient(left, #5abe61 0%, #edfa97 100%);
    background: -webkit-linear-gradient(left, #5abe61 0%, #edfa97 100%);
    background: linear-gradient(to right, #5abe61 0%, #edfa97 100%);
    padding: 60px 0;
    position: relative; }
    .lp1-flow .lp1-container {
      position: relative; }
  .lp1-flow_title {
    margin: 0 auto 40px;
    width: 34.2%; }
  .lp1-flow_image {
    margin: 0 auto;
    width: 89.333%; }
  .lp1-flow_person {
    width: 45.333%;
    position: absolute;
    right: -17%;
    bottom: -40%; }
  /*
  faq
--------------------------------------------- */
  .lp1-faq {
    padding: 60px 0; }
  .lp1-faq_title {
    margin: 0 auto 40px;
    width: 32%; }
  .lp1-faq_list {
    margin: 0 auto;
    width: 89.333%; }
  .lp1-faq_item {
    border-top: 1px solid #e5e5e5;
    margin-bottom: 29px;
    padding: 20px 3% 0 0; }
  /*
  form
--------------------------------------------- */
  .lp1-form {
    background: #f16f7b;
    /* Old browsers */
    background: -moz-linear-gradient(left, #f16f7b 0%, #f4986b 100%);
    background: -webkit-linear-gradient(left, #f16f7b 0%, #f4986b 100%);
    background: linear-gradient(to right, #f16f7b 0%, #f4986b 100%);
    padding: 60px 0; }
    .lp1-form .form_cont {
      background: #fff;
      border-radius: 10px;
      margin: 0 auto;
      padding: 50px 50px 30px;
      width: 89.333%; }
      .lp1-form .form_cont table {
        border: none; }
        .lp1-form .form_cont table tr {
          border-bottom: none;
          display: block;
          margin-bottom: 20px; }
        .lp1-form .form_cont table th {
          background: none;
          margin-bottom: 7px;
          padding: 0;
          display: block !important;
          width: 100% !important;
          border-right: none;
          z-index: 1; }
          .lp1-form .form_cont table th label {
            font-size: 14px;
            font-weight: bold; }
          .lp1-form .form_cont table th .required {
            background: #f64a4a;
            border-radius: 2px;
            float: right;
            font-size: 10px;
            font-weight: bold;
            position: static; }
        .lp1-form .form_cont table td {
          background: none;
          padding: 0;
          display: block !important; }
          .lp1-form .form_cont table td input {
            background: #f5f5f5;
            border-color: #e5e5e5;
            border-radius: 5px;
            font-size: 16px;
            padding: 10px 15px; }
            .lp1-form .form_cont table td input::-webkit-input-placeholder {
              color: #ccc; }
            .lp1-form .form_cont table td input::-moz-placeholder {
              color: #ccc; }
            .lp1-form .form_cont table td input:-ms-input-placeholder {
              color: #ccc; }
          .lp1-form .form_cont table td select {
            background-color: #f5f5f5;
            border-color: #e5e5e5;
            font-size: 16px;
            padding: 10px 15px; }
        .lp1-form .form_cont table .error_msg {
          font-size: 12px; }
      .lp1-form .form_cont .lp1-submit_btn input[type="submit"] {
        background: url(/assets/images/miraimo/lp1/btn_lp1_02.png) center center no-repeat;
        background-size: 400px 88px;
        margin: 50px auto;
        padding: 0;
        line-height: 1;
        border: none;
        border-radius: 0;
        text-indent: -9999px;
        height: 88px; }
  .lp1-form_title {
    margin: 0 auto 30px;
    width: 41.4%; }
  .lp1-footer {
    padding: 40px 0 98px; }
  .lp1-footer_list {
    margin-bottom: 35px; }
  .lp1-footer_item {
    margin-bottom: 15px;
    text-align: center;
    line-height: 1; }
    .lp1-footer_item .lp1-footer_link {
      color: #000;
      font-size: 12px;
      text-decoration: none; }
  .lp1-footer_logo {
    margin: 0 auto;
    width: 192px; }
    .lp1-footer_logo img {
      width: 100%; } }

@media (max-width: 767px) {
  /*
  layout
--------------------------------------------- */
  body {
    line-height: 1.8;
    font-size: 14px;
    height: 100%;
    font-family: Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
    position: relative;
    font-kerning: normal;
    box-sizing: border-box; }
  html {
    height: 100%; }
  .sp_only {
    display: block; }
  .pc_only {
    display: none !important; }
  img {
    max-width: 100%; }
  .inner {
    padding: 0 10px;
    box-sizing: content-box;
    max-width: 1200px;
    margin: 0 auto;
    position: relative; }
  .inner_min {
    padding: 0 10px;
    box-sizing: content-box;
    max-width: 1040px;
    margin: 0 auto;
    position: relative; }
  .container {
    *zoom: 1;
    padding: 0;
    box-sizing: content-box;
    max-width: 1200px;
    margin: 0 auto;
    position: relative; }
    .container:after {
      content: ".";
      display: block;
      clear: both;
      height: 0;
      visibility: hidden; }
  .error404 .btn_normal {
    margin: 60px 0; }
  /*
  header
--------------------------------------------- */
  /*
  global nav
--------------------------------------------- */
  .g_nav {
    background: #333;
    height: 100vh;
    top: 30px;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    width: 100%;
    z-index: 999;
    transition: all 0.5s ease-in-out;
    overflow-y: scroll;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch; }
    .g_nav.in {
      top: 0;
      visibility: visible;
      opacity: 1; }
    .g_nav p.menu_heading {
      padding: 15px 5%;
      margin-bottom: 15px; }
      .g_nav p.menu_heading span {
        width: 30%;
        display: inline-block;
        vertical-align: middle; }
        .g_nav p.menu_heading span img {
          max-width: 100%; }
      .g_nav p.menu_heading a.close {
        width: 40px;
        height: 40px;
        display: block;
        color: #fff;
        text-align: center;
        text-decoration: none;
        float: right;
        clear: both;
        border: 0;
        padding: 0; }
    .g_nav ul {
      padding: 0px 14px; }
      .g_nav ul li {
        height: 50px;
        line-height: 50px;
        margin-bottom: 10px; }
        .g_nav ul li:nth-child(1) a {
          border-left: 6px #9d56e4 solid;
          background: #fff url(../images/miraimo/common/ico-arw-purple.png) no-repeat 96% center; }
        .g_nav ul li:nth-child(2) a {
          border-left: 6px #00b9d8 solid;
          background: #fff url(../images/miraimo/common/ico-arw-blue.png) no-repeat 96% center; }
        .g_nav ul li:nth-child(3) a {
          background: #fff url(../images/miraimo/common/ico-arw-green.png) no-repeat 96% center;
          border-left: 6px #32cca6 solid; }
        .g_nav ul li:nth-child(4) a {
          border-left: 6px #02c255 solid;
          background: #fff url(../images/miraimo/common/ico-arw-lg.png) no-repeat 96% center; }
        .g_nav ul li:nth-child(5) a {
          border-left: 6px #ff9537 solid;
          background: #fff url(../images/miraimo/common/ico-arw-orange.png) no-repeat 96% center; }
        .g_nav ul li:nth-child(6) {
          border-right: none; }
          .g_nav ul li:nth-child(6) a {
            border-left: 6px #f85550 solid;
            background: #fff url(../images/miraimo/common/ico-arw-red.png) no-repeat 96% center; }
    .g_nav a {
      background: #fff;
      display: block;
      font-size: 1.07em;
      padding: 0px 13px;
      color: #000;
      text-align: left;
      text-decoration: none; }
      .g_nav a:hover {
        color: #0c4f93;
        background: #fff; }
    .g_nav a.close {
      background: transparent; }
  a#open_menu {
    width: 40px;
    height: 40px;
    background: transparent;
    position: absolute;
    top: 11px;
    right: 5%;
    display: block;
    color: #fff;
    text-align: center;
    text-decoration: none;
    border: 0px;
    z-index: 999; }
    .page-template-template-miraimo-lp-thankyou a#open_menu {
      display: none; }
  /*
  footer
--------------------------------------------- */
  .footer {
    text-align: center;
    margin: 0 auto; }
    .footer_sns ul {
      text-align: center; }
      .footer_sns ul li {
        display: inline-block;
        padding: 0;
        margin: 20px 4px; }
        .footer_sns ul li a {
          width: 40px;
          height: 40px;
          display: block;
          text-align: center;
          margin: 0 auto; }
          .footer_sns ul li a img {
            text-align: center;
            margin: 0 auto;
            padding: 10px 0; }
    .footer_nav {
      border-bottom: 1px solid #e5e5e5; }
      .footer_nav ul {
        text-align: center; }
        .footer_nav ul li {
          padding: 1.5em 0;
          font-size: 0.85em;
          display: inline-block; }
          .footer_nav ul li:after {
            content: "　｜　";
            color: #e5e5e5; }
          .footer_nav ul li:last-child:after {
            content: none; }
          .footer_nav ul li a {
            font-size: 12px;
            text-decoration: none; }
            .footer_nav ul li a:hover {
              text-decoration: underline; }
    .footer .footer_logo {
      margin: 0 auto; }
      .footer .footer_logo a {
        display: inline-block; }
      .footer .footer_logo img {
        width: 150px; }
    .footer .copyright {
      color: #8a8a8a;
      font-size: 0.71em;
      padding: 30px 0 20px; }
  /*
  common area
--------------------------------------------- */
  .area_tit {
    text-align: center;
    font-size: 1.42em;
    font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
    letter-spacing: .075em;
    line-height: 1.6;
    padding: 20px 0 14px; }
    .area_tit span {
      display: block;
      font-size: 12px;
      font-family: "PT Sans", sans-serif;
      letter-spacing: .1em; }
  /* ---------- area guide ---------- */
  .area_guide {
    background: #cdeedc;
    background: -moz-linear-gradient(left, #cdeedc 0%, #f2f3cc 100%);
    background: -webkit-linear-gradient(left, #cdeedc 0%, #f2f3cc 100%);
    background: linear-gradient(to right, #cdeedc 0%, #f2f3cc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cdeedc', endColorstr='#f2f3cc', GradientType=1); }
    .area_guide .area_tit span {
      color: #02c255; }
    .area_guide_cont {
      font-size: 0; }
    .area_guide_step {
      width: 100%;
      display: inline-block;
      margin: 0 1% 12px 0; }
      .area_guide_step a {
        display: block;
        background: #fff;
        border-radius: 4px;
        padding: 10px 4%;
        font-size: 14px;
        text-decoration: none;
        border: 2px #fff solid; }
        .area_guide_step a:hover {
          color: #02c255;
          border: 2px #02c255 solid;
          background: rgba(255, 255, 255, 0.7); }
      .area_guide_step:nth-child(even) {
        margin-right: 0; }
      .area_guide_step_point {
        color: #02c255;
        font-family: "PT Sans", sans-serif;
        display: block;
        border-bottom: 1px #02c255 solid;
        line-height: 23px;
        padding-bottom: 4px;
        margin-bottom: 4px; }
      .area_guide_step_txt {
        font-size: 0.85em;
        display: inline-block; }
      .area_guide_step_btn {
        padding: 10px 0 30px; }
        .area_guide_step_btn a {
          font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          width: 88%;
          font-size: 0.85em;
          text-align: center;
          text-decoration: none;
          display: block;
          color: #fff;
          background: #34c272;
          background: -moz-linear-gradient(left, #34c272 0%, #0bd05f 100%);
          background: -webkit-linear-gradient(left, #34c272 0%, #0bd05f 100%);
          background: linear-gradient(to right, #34c272 0%, #0bd05f 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#34c272', endColorstr='#0bd05f', GradientType=1);
          border-radius: 25px;
          margin: 0 auto;
          line-height: 1;
          padding: 6px 0px 7px; }
          .area_guide_step_btn a:hover {
            opacity: .6; }
          .area_guide_step_btn a img {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px; }
  /* ---------- area job-information ---------- */
  .area_job-information .area_tit span {
    color: #ff9537; }
  .area_job-information_cont {
    padding: 10px 0 50px; }
    .area_job-information_cont_box {
      width: 100%;
      padding: 10px 4%;
      border: 1px #ddd solid;
      display: block; }
      .area_job-information_cont_box .tit {
        font-size: 0.85em;
        line-height: 1.5;
        font-weight: bold; }
        .area_job-information_cont_box .tit a {
          text-decoration: none; }
          .area_job-information_cont_box .tit a:hover {
            color: #ff9537; }
      .area_job-information_cont_box .category {
        padding: 6px 10px 0 0;
        display: inline-block; }
        .area_job-information_cont_box .category a {
          display: inline-block;
          font-size: 0.71em;
          border: 1px #00b9d8 solid;
          line-height: 18px;
          padding: 0 1em;
          border-radius: 13px;
          min-width: 70px;
          text-align: center;
          text-decoration: none; }
          .area_job-information_cont_box .category a:hover {
            background: #00b9d8;
            color: #fff; }
      .area_job-information_cont_box .tag {
        display: inline-block; }
        .area_job-information_cont_box .tag ul li {
          display: inline-block;
          line-height: 18px;
          padding-right: 1em; }
          .area_job-information_cont_box .tag ul li a {
            display: inline-block;
            font-size: 0.78em;
            border: 1px #ffd200 solid;
            line-height: 18px;
            border-radius: 13px;
            padding: 0 1em;
            min-width: 70px;
            text-align: center;
            text-decoration: none; }
            .area_job-information_cont_box .tag ul li a:hover {
              background: #ffd200;
              color: #fff; }
  /* brand_logo
--------------------------------------------- */
  .area_brand_logo .inner {
    padding-bottom: 40px; }
    .area_brand_logo .inner ul {
      font-size: 0;
      border-top: 1px solid #eee;
      border-left: 1px solid #eee; }
      .area_brand_logo .inner ul li {
        text-align: left;
        display: inline-block;
        width: calc(100% / 2);
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee; }
        .area_brand_logo .inner ul li img {
          min-width: 100%;
          width: 100%; }
  /* ---------- area search ---------- */
  .area_search {
    background: #f9f9f9;
    padding-bottom: 30px; }
    .area_search .area_sub-tit {
      font-size: 1.42em;
      text-align: center;
      font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
      letter-spacing: .075em;
      position: relative;
      top: -20px; }
    .area_search_cont {
      width: 100%; }
      .area_search_cont .tit {
        width: 100%;
        font-weight: bold;
        display: block;
        vertical-align: top;
        padding-bottom: 10px; }
      .area_search_cont_input {
        display: block;
        padding-bottom: 20px;
        *zoom: 1; }
        .area_search_cont_input:after {
          content: ".";
          display: block;
          clear: both;
          height: 0;
          visibility: hidden; }
      .area_search_cont_list {
        font-size: 0;
        display: block;
        padding-bottom: 10px; }
        .area_search_cont_list li {
          font-size: 14px;
          min-width: 48%;
          display: inline-block;
          margin: 0 1% 9px; }
          .area_search_cont_list li a {
            width: 100%;
            background: #fff;
            display: inline-block;
            padding: 0 20px;
            line-height: 28px;
            font-size: 0.85em;
            text-decoration: none;
            text-align: center;
            border-radius: 14px; }
      .area_search_cont_box {
        display: block;
        padding-bottom: 10px; }
      .area_search_cont_sub-tit {
        min-width: 4em;
        font-weight: bold;
        font-size: 0.92em;
        padding-bottom: 10px;
        display: table;
        white-space: nowrap;
        width: 100%; }
        .area_search_cont_sub-tit a {
          background: #00C44F;
          border-radius: 3px;
          color: #FFF;
          display: block;
          line-height: 29px;
          text-decoration: none;
          text-align: center; }
      .area_search_cont_list-min {
        font-size: 0;
        margin-bottom: 10px; }
        .area_search_cont_list-min li {
          font-size: 14px;
          min-width: 48%;
          display: inline-block;
          margin: 0 1% 9px; }
          .area_search_cont_list-min li a {
            width: 100%;
            background: #fff;
            display: inline-block;
            padding: 0 20px;
            line-height: 28px;
            font-size: 0.92em;
            text-decoration: none;
            text-align: center;
            border-radius: 14px; }
    .area_search_cont.freeword {
      padding-top: 20px; }
      .area_search_cont.freeword input[type="text"], .area_search_cont.freeword button {
        height: 50px; }
      .area_search_cont.freeword input[type="text"] {
        height: 50px;
        font-family: Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
        padding: 10px 15px;
        border-radius: 4px 0 0 4px;
        border-top: 1px #ddd solid;
        border-bottom: 1px #ddd solid;
        border-left: 1px #ddd solid;
        border-right: none;
        width: 80%;
        font-size: 0.85em; }
        .area_search_cont.freeword input[type="text"]:focus::-webkit-input-placeholder {
          color: transparent; }
        .area_search_cont.freeword input[type="text"]:focus::-moz-placeholder {
          color: transparent; }
        .area_search_cont.freeword input[type="text"]:focus:-ms-input-placeholder {
          color: transparent; }
      .area_search_cont.freeword button {
        height: 50px;
        background: #ff9537;
        background: -moz-linear-gradient(left, #ff9537 0%, #ff6845 100%);
        background: -webkit-linear-gradient(left, #ff9537 0%, #ff6845 100%);
        background: linear-gradient(to right, #ff9537 0%, #ff6845 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9537', endColorstr='#ff6845', GradientType=1);
        text-align: center;
        margin: 0 auto;
        border-radius: 0 4px 4px 0;
        padding: 11px 0;
        border: none;
        float: right;
        width: 20%; }
        .area_search_cont.freeword button img {
          text-align: center;
          margin: 0 auto; }
    .area_search_cont.job-category .area_search_cont_list li a {
      border: 1px #00b9d8 solid; }
      .area_search_cont.job-category .area_search_cont_list li a:hover {
        background: #00b9d8;
        color: #fff; }
    .area_search_cont.job-area .area_search_cont_list-min li a {
      border: 1px #02c255 solid; }
      .area_search_cont.job-area .area_search_cont_list-min li a:hover {
        background: #02c255;
        color: #fff; }
    .area_search_cont.job-tag .area_search_cont_list li a {
      border: 1px #ffd200 solid; }
      .area_search_cont.job-tag .area_search_cont_list li a:hover {
        background: #ffd200;
        color: #fff; }
  /* ---------- area other ---------- */
  .area_other {
    font-size: 0;
    padding: 24px 0; }
    .area_other .area_other_btn {
      width: 50%;
      padding: 0 1%;
      display: inline-block;
      font-size: 14px; }
      .area_other .area_other_btn a {
        font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
        font-size: 1.28em;
        letter-spacing: .1em;
        text-decoration: none;
        text-align: center;
        display: block;
        background: #fff;
        line-height: 66px;
        border-radius: 35px;
        margin: 0 auto; }
        .area_other .area_other_btn a:hover {
          color: #fff; } }
      @media (max-width: 767px) and (max-width: 767px) {
        .area_other .area_other_btn a {
          font-size: 0.85em;
          line-height: 46px;
          border-radius: 25px; } }

@media (max-width: 767px) {
      .area_other .area_other_btn a {
        width: 100%;
        font-size: 14px;
        max-width: 400px;
        color: #ff9537;
        font-size: 0.85em;
        border: 2px #ff9537 solid; }
        .area_other .area_other_btn a:hover {
          background: #ff9537; }
  /* ---------- area detail ---------- */
  .area_detail {
    background: #b7ddf1;
    background: -moz-linear-gradient(left, #b7ddf1 0%, #fad4d8 100%);
    background: -webkit-linear-gradient(left, #b7ddf1 0%, #fad4d8 100%);
    background: linear-gradient(to right, #b7ddf1 0%, #fad4d8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b7ddf1', endColorstr='#fad4d8', GradientType=1);
    padding: 16px 0 6px; }
    .area_detail_cont {
      display: block;
      vertical-align: top;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 4px;
      padding: 20px 4%;
      margin-bottom: 10px;
      width: 100%;
      height: inherit !important;
      position: relative; }
      .area_detail_cont .icon img {
        text-align: center;
        margin: 0 auto; }
      .area_detail_cont .tit {
        font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
        font-size: 1.42em;
        letter-spacing: .05em;
        text-align: center;
        padding: 14px 0 26px; }
      .area_detail_cont .sub-tit {
        font-size: 0.85em;
        font-weight: bold;
        text-align: center;
        padding-bottom: 4px; }
      .area_detail_cont .txt {
        font-size: 0.85em;
        padding-bottom: 16px; }
      .area_detail_cont .btn a {
        width: 80%;
        font-size: 0.85em;
        display: block;
        line-height: 50px;
        border-radius: 25px;
        text-align: center;
        color: #fff;
        text-decoration: none;
        margin: 0 auto; }
        .area_detail_cont .btn a:hover {
          opacity: .6; }
      .area_detail_cont .btn span {
        width: 80%;
        font-size: 0.85em;
        display: block;
        line-height: 50px;
        border-radius: 25px;
        text-align: center;
        color: #fff;
        text-decoration: none;
        margin: 0 auto; }
    .area_detail_cont.career-advice .btn a {
      background: #1090d2;
      background: -moz-linear-gradient(left, #1090d2 0%, #36b7b2 100%);
      background: -webkit-linear-gradient(left, #1090d2 0%, #36b7b2 100%);
      background: linear-gradient(to right, #1090d2 0%, #36b7b2 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1090d2', endColorstr='#36b7b2', GradientType=1); }
    .area_detail_cont.member .btn a {
      background: #ef707d;
      background: -moz-linear-gradient(left, #ef707d 0%, #f2986f 100%);
      background: -webkit-linear-gradient(left, #ef707d 0%, #f2986f 100%);
      background: linear-gradient(to right, #ef707d 0%, #f2986f 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef707d', endColorstr='#f2986f', GradientType=1); }
    .area_detail_cont.member .btn span {
      background: #ddd; }
  /* ---------- area access ---------- */
  .area_access {
    background: #f1f1f1;
    padding: 16px 0 6px; }
    .area_access_cont {
      display: block;
      vertical-align: top;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 4px;
      padding: 20px 4%;
      margin-bottom: 10px;
      width: 100%;
      height: inherit !important;
      position: relative; }
      .area_access_cont .icon img {
        text-align: center;
        margin: 0 auto; }
      .area_access_cont .tit {
        font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
        font-size: 1.42em;
        letter-spacing: .05em;
        text-align: center;
        padding: 14px 0 26px; }
      .area_access_cont .sub-tit {
        font-size: 0.85em;
        font-weight: bold;
        text-align: center;
        padding-bottom: 4px; }
      .area_access_cont .txt {
        font-size: 0.85em;
        padding-bottom: 16px; }
      .area_access_cont .btn a {
        width: 80%;
        font-size: 0.85em;
        display: block;
        line-height: 50px;
        border-radius: 25px;
        text-align: center;
        color: #fff;
        text-decoration: none;
        margin: 0 auto; }
        .area_access_cont .btn a:hover {
          opacity: .6; }
      .area_access_cont .btn span {
        width: 80%;
        font-size: 0.85em;
        display: block;
        line-height: 50px;
        border-radius: 25px;
        text-align: center;
        color: #fff;
        text-decoration: none;
        margin: 0 auto; }
    .area_access_cont.osaka .btn a {
      border: 2px solid #110051;
      color: #110051; }
    .area_access_cont.tokyo .btn a {
      border: 2px solid #110051;
      color: #110051; }
    .area_access_cont.full {
      width: 100%; }
      .area_access_cont.full .btn {
        margin: 25px auto 0;
        position: static; }
        .area_access_cont.full .btn a {
          border: 2px solid #110051;
          color: #110051; }
      .area_access_cont.full .detail {
        width: 100%;
        margin: 0 auto; }
  /* ---------- area btn_more ---------- */
  .area_btn_more {
    display: block;
    padding: 20px 0;
    margin: 0 auto;
    text-align: center; }
    .area_btn_more a {
      width: 90%;
      display: block;
      text-align: center;
      font-size: 0.85em;
      padding-right: 40px;
      background: #fff url(../images/miraimo/common/ico-arw.png) no-repeat 92% center;
      background-size: 20px auto;
      text-decoration: none;
      line-height: 32px;
      border: 1px #2d2d2d solid;
      border-radius: 17px;
      margin: 0 auto; }
      .area_btn_more a:hover {
        text-decoration: underline; }
  /* ---------- area blog ---------- */
  .area_blog {
    padding-bottom: 10px; }
    .area_blog .area_tit span {
      color: #32cca6; }
    .area_blog_cont {
      font-size: 0;
      margin: 0 -10px; }
      .area_blog_cont_box {
        font-size: 14px;
        vertical-align: top;
        text-align: left;
        display: inline-block;
        margin: 0 2% 20px;
        width: 46%;
        max-width: 292px; }
        .area_blog_cont_box .img {
          padding-bottom: 6px; }
          .area_blog_cont_box .img img {
            width: 100%; }
        .area_blog_cont_box .date {
          font-size: 0.71em;
          font-weight: bold;
          line-height: 14px; }
        .area_blog_cont_box .tag {
          display: inline-block;
          font-size: 0.71em;
          text-align: center;
          padding: 0 8px;
          color: #32cca6;
          line-height: 18px;
          border: 1px #32cca6 solid;
          border-radius: 9px; }
        .area_blog_cont_box .tit {
          font-size: 0.85em;
          padding: 8px 0;
          line-height: 1.5; }
        .area_blog_cont_box a {
          text-decoration: none; }
          .area_blog_cont_box a:hover {
            color: #32cca6; }
  .single-voice .area_blog_cont_box a:hover {
    color: #00b9d8; }
  /*
  page
--------------------------------------------- */
  .pages {
    border-top: 1px #ddd solid; }
    .pages .area_key {
      padding-bottom: 40px; }
      .page-template-template-miraimo-lp-thankyou .pages .area_key {
        padding-bottom: 50px;
        border-bottom: 1px solid #dcdcdc; }
  .btn_normal {
    margin: 20px auto 60px;
    text-align: center; }
    .btn_normal a {
      min-width: 180px;
      display: inline-block;
      text-align: center;
      background: #fff;
      line-height: 26px;
      border-radius: 15px;
      text-decoration: none;
      border: 2px #ff9537 solid;
      color: #ff9537; }
      .btn_normal a:hover {
        color: #fff;
        background: #ff9537; }
  /*
  index header
--------------------------------------------- */
  .header_btn {
    width: 90%;
    position: absolute;
    padding: 20px 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    text-align: center;
    z-index: 10; }
    .header_btn ul li {
      display: inline-block; }
      .header_btn ul li a {
        width: 80px;
        text-align: center;
        display: block;
        font-size: 0.78em;
        background: #fff;
        line-height: 25px;
        border-radius: 15px;
        text-decoration: none; }
    .header_btn ul .sign-up a {
      border: 2px #f85550 solid;
      color: #f85550; }
      .header_btn ul .sign-up a:hover {
        color: #fff;
        background: #f85550; }
  /*
  hero
--------------------------------------------- */
  .hero {
    background: url(../images/miraimo/common/hero_bg.png) center top;
    background-size: contain;
    height: 100vh;
    position: relative;
    min-height: 500px; }
    .hero video {
      /* 	position: absolute;
	 	top: 0;
	 	left: 0;
	 	width: auto;
	 	height: 100%;
	 	z-index: -1;
	 	min-width: 100%;
	 	min-height: 100%; */
      display: none; }
    .hero .hero_inner {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    .hero_logo {
      width: 50%;
      text-align: center;
      margin: 0 auto; }
      .hero_logo img {
        text-align: center;
        margin: 0 auto; }
    .hero_copy {
      font-size: 1.57em;
      font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
      text-align: center;
      color: #393939;
      padding-top: 20px; }
    .hero_sub-copy {
      font-size: 0.71em;
      font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
      text-align: center;
      line-height: 1.1;
      color: #393939;
      padding-bottom: 30px; }
      .hero_sub-copy span {
        font-weight: bold; }
    .hero_txt {
      display: none; }
    .hero_btn {
      position: relative;
      margin: 20px auto 0;
      text-align: center;
      width: 98%; }
      .hero_btn_about {
        width: 46%;
        display: inline-block;
        margin: 0 1%; }
        .hero_btn_about a {
          font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-size: 1.28em;
          letter-spacing: .1em;
          text-decoration: none;
          text-align: center;
          display: block;
          background: #fff;
          line-height: 66px;
          border-radius: 35px;
          margin: 0 auto; }
          .hero_btn_about a:hover {
            color: #fff; } }
        @media (max-width: 767px) and (max-width: 767px) {
          .hero_btn_about a {
            font-size: 0.85em;
            line-height: 46px;
            border-radius: 25px; } }

@media (max-width: 767px) {
        .hero_btn_about a {
          width: 100%;
          color: #9d56e4;
          border: 2px #9d56e4 solid; }
          .hero_btn_about a:hover {
            background: #9d56e4; }
      .hero_btn_job {
        width: 46%;
        display: inline-block;
        margin: 0 1%; }
        .hero_btn_job a {
          font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          font-size: 1.28em;
          letter-spacing: .1em;
          text-decoration: none;
          text-align: center;
          display: block;
          background: #fff;
          line-height: 66px;
          border-radius: 35px;
          margin: 0 auto; }
          .hero_btn_job a:hover {
            color: #fff; } }
        @media (max-width: 767px) and (max-width: 767px) {
          .hero_btn_job a {
            font-size: 0.85em;
            line-height: 46px;
            border-radius: 25px; } }

@media (max-width: 767px) {
        .hero_btn_job a {
          width: 100%;
          color: #ff9537;
          border: 2px #ff9537 solid; }
          .hero_btn_job a:hover {
            background: #ff9537; }
  /*
  index area about
--------------------------------------------- */
  .area_about {
    background: #fff;
    padding: 35px 0 40px;
    text-align: center; }
    .area_about_tit {
      width: 50%;
      margin: 0 auto;
      padding-bottom: 30px; }
      .area_about_tit img {
        width: 100%;
        height: auto;
        text-align: center;
        margin: 0 auto; }
    .area_about p {
      font-size: 0.85em;
      margin-bottom: 1.6em; }
    .area_about_btn a {
      font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
      font-size: 1.28em;
      letter-spacing: .1em;
      text-decoration: none;
      text-align: center;
      display: block;
      background: #fff;
      line-height: 66px;
      border-radius: 35px;
      margin: 0 auto; }
      .area_about_btn a:hover {
        color: #fff; } }
    @media (max-width: 767px) and (max-width: 767px) {
      .area_about_btn a {
        font-size: 0.85em;
        line-height: 46px;
        border-radius: 25px; } }

@media (max-width: 767px) {
    .area_about_btn a {
      width: 80%;
      color: #0c4f93;
      border: 2px #0c4f93 solid; }
      .area_about_btn a:hover {
        background: #0c4f93; }
    .area_about_btn.flow {
      margin-bottom: 15px; }
      .area_about_btn.flow a {
        color: #ff9537;
        border: 2px #ff9537 solid; }
        .area_about_btn.flow a:hover {
          background: #ff9537;
          color: #FFF; }
    .area_about .inner_min:last-child {
      padding-top: 20px; }
  /*
  index area interview
--------------------------------------------- */
  .area_interview {
    background: #c5f3e2;
    background: -moz-linear-gradient(left, #c5f3e2 0%, #d4e0fc 100%);
    background: -webkit-linear-gradient(left, #c5f3e2 0%, #d4e0fc 100%);
    background: linear-gradient(to right, #c5f3e2 0%, #d4e0fc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c5f3e2', endColorstr='#d4e0fc', GradientType=1);
    padding-bottom: 10px; }
    .area_interview .area_tit span {
      color: #00b9d8; }
    .area_interview_tablist {
      overflow: hidden;
      margin-bottom: 40px; }
    .area_interview_tab {
      cursor: pointer;
      float: left;
      width: 50%;
      box-sizing: border-box;
      text-align: center;
      font-size: 0.85em;
      font-weight: bold;
      padding: 15px 0;
      border-bottom: 2px solid #00b9d8; }
      .area_interview_tab.is-active {
        color: #00b9d8;
        border-top: 2px solid #00b9d8;
        border-right: 2px solid #00b9d8;
        border-left: 2px solid #00b9d8;
        border-bottom: 0;
        border-radius: 10px 10px 0 0;
        padding: 13px 0 17px; }
    .area_interview_wrap {
      display: none; }
      .area_interview_wrap.is-visible {
        display: block; }
    .area_interview_cont {
      text-align: center;
      margin: 0 -10px; }
      .area_interview_cont_box {
        *zoom: 1;
        width: 96%;
        background: #fff;
        display: block;
        letter-spacing: normal;
        text-align: left;
        padding: 10px;
        margin: 0 2% 10px; }
        .area_interview_cont_box:after {
          content: ".";
          display: block;
          clear: both;
          height: 0;
          visibility: hidden; }
        .area_interview_cont_box .img {
          float: left;
          overflow: hidden;
          width: 45%; }
          .area_interview_cont_box .img img {
            width: auto;
            height: auto;
            max-height: 110px; }
        .area_interview_cont_box .number {
          margin-left: 10px;
          display: inline;
          color: #00b9d8;
          font-family: "PT Sans", sans-serif;
          font-weight: bold;
          font-size: 0.71em; }
        .area_interview_cont_box .tit {
          width: 51%;
          float: right;
          font-size: 0.71em;
          padding: 8px 0;
          line-height: 1.5; }
        .area_interview_cont_box .company {
          width: 51%;
          float: right;
          font-size: 0.71em;
          color: #9d9d9d;
          font-style: italic;
          background-image: linear-gradient(to right, #C6C6C6, #C6C6C6 1px, transparent 1px, transparent 3px);
          background-size: 4px 1px;
          background-position: top;
          background-repeat: repeat-x;
          padding-top: 5px; }
        .area_interview_cont_box a {
          text-decoration: none; }
          .area_interview_cont_box a:hover {
            color: #00b9d8; }
    .area_interview_btn {
      padding: 10px 0; }
      .area_interview_btn a {
        font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
        font-size: 1.28em;
        letter-spacing: .1em;
        text-decoration: none;
        text-align: center;
        display: block;
        background: #fff;
        line-height: 66px;
        border-radius: 35px;
        margin: 0 auto; }
        .area_interview_btn a:hover {
          color: #fff; } }
      @media (max-width: 767px) and (max-width: 767px) {
        .area_interview_btn a {
          font-size: 0.85em;
          line-height: 46px;
          border-radius: 25px; } }

@media (max-width: 767px) {
      .area_interview_btn a {
        width: 80%;
        color: #00b9d8;
        border: 2px #00b9d8 solid; }
        .area_interview_btn a:hover {
          color: #00b9d8; }
  /*
  archive blog
--------------------------------------------- */
  #miraimoblog .area_key .area_tit span {
    color: #32cca6; }
  #miraimoblog .area_blog_cont_box {
    width: 92%;
    max-width: inherit;
    margin: 0 auto 20px;
    display: block; }
    #miraimoblog .area_blog_cont_box .date {
      display: inline-block;
      padding-right: 10px; }
  #miraimoblog.post-type-archive-voice .area_key .area_tit span,
  #miraimoblog.post-type-archive-interview .area_key .area_tit span {
    color: #00b9d8; }
  #miraimoblog.page-template-page-interview-archive .area_tit span {
    color: #00b9d8; }
  #miraimoblog.page-template-page-interview-archive .archive_sidebar_cont {
    border-color: #00b9d8; }
    #miraimoblog.page-template-page-interview-archive .archive_sidebar_cont .tit {
      color: #00b9d8; }
  #miraimoblog.page-template-page-interview-archive .archive_sidebar_cont ul li a {
    color: #00b9d8; }
    #miraimoblog.page-template-page-interview-archive .archive_sidebar_cont ul li a:hover {
      background: #00b9d8; }
  #miraimoblog.page-template-page-interview-archive .area_blog_cont_box .number {
    border-bottom: 0; }
  #miraimoblog.page-template-page-interview-archive .area_blog_cont_box a:hover {
    color: #00b9d8; }
  #miraimoblog.page-template-page-interview-archive .page-nation ul li a {
    border-color: #00b9d8;
    color: #00b9d8; }
  #miraimoblog.page-template-page-interview-archive .page-nation ul li:hover a {
    background: #00b9d8;
    color: #FFF; }
  #miraimoblog.page-template-page-interview-archive .page-nation ul li span.current {
    background: #00b9d8;
    border: 1px solid #00b9d8;
    color: #FFF;
    display: block;
    text-align: center;
    width: 43px;
    line-height: 46px;
    font-size: 0.85em;
    text-decoration: none; }
  .archive_category {
    width: 90%;
    line-height: 30px;
    border-radius: 15px;
    border: 1px #32cca6 solid;
    text-align: center;
    color: #32cca6;
    letter-spacing: .075em;
    margin: 0 auto; }
  #miraimoblog.post-type-archive-voice .archive_category,
  #miraimoblog.post-type-archive-interview .archive_category {
    border: 1px #00b9d8 solid;
    color: #00b9d8; }
  .archive_cont {
    width: 100%;
    margin-bottom: 30px; }
  #miraimoblog.post-type-archive-voice .area_blog_cont_box a,
  #miraimoblog.post-type-archive-interview .area_blog_cont_box a {
    text-decoration: none; }
    #miraimoblog.post-type-archive-voice .area_blog_cont_box a:hover,
    #miraimoblog.post-type-archive-interview .area_blog_cont_box a:hover {
      color: #00b9d8; }
  .area_blog_cont_box .number {
    display: inline;
    color: #00b9d8;
    font-family: "PT Sans", sans-serif;
    font-weight: bold;
    font-size: 0.71em;
    border-bottom: 2px #00b9d8 solid; }
  .area_blog_cont_box .company {
    font-size: 0.71em;
    color: #9d9d9d;
    font-style: italic;
    background-image: linear-gradient(to right, #C6C6C6, #C6C6C6 1px, transparent 1px, transparent 3px);
    background-size: 4px 1px;
    background-position: top;
    background-repeat: repeat-x;
    padding-top: 5px; }
  /* ---------- sidebar ---------- */
  .archive_sidebar {
    width: 98%;
    margin: 0 auto; }
    .archive_sidebar_cont {
      background: #f9f9f9;
      border: 1px #32cca6 solid;
      padding: 10px 10px 5px;
      margin-bottom: 20px; }
      .archive_sidebar_cont .tit {
        text-align: center;
        color: #32cca6;
        padding: 0 0 10px; }
      .archive_sidebar_cont ul li a {
        display: block;
        text-decoration: none;
        font-size: 0.85em;
        color: #32cca6;
        background: #fff url(../images/miraimo/common/ico-arw-green.png) no-repeat 96% center;
        line-height: 40px;
        padding: 0 18px;
        margin-bottom: 5px; }
        .archive_sidebar_cont ul li a:hover {
          color: #fff;
          background: #32cca6; }
  #miraimoblog.post-type-archive-interview .archive_sidebar_cont,
  #miraimoblog.post-type-archive-voice .archive_sidebar_cont {
    border: 1px #00b9d8 solid; }
    #miraimoblog.post-type-archive-interview .archive_sidebar_cont .tit,
    #miraimoblog.post-type-archive-voice .archive_sidebar_cont .tit {
      color: #00b9d8; }
    #miraimoblog.post-type-archive-interview .archive_sidebar_cont ul li a,
    #miraimoblog.post-type-archive-voice .archive_sidebar_cont ul li a {
      color: #00b9d8; }
      #miraimoblog.post-type-archive-interview .archive_sidebar_cont ul li a:hover,
      #miraimoblog.post-type-archive-voice .archive_sidebar_cont ul li a:hover {
        color: #fff;
        background: #00b9d8; }
  /* ---------- page nation ---------- */
  .page-nation {
    letter-spacing: normal;
    margin: 0 auto;
    text-align: center; }
    .page-nation ul li {
      padding: 0 1px;
      display: inline-block; }
      .page-nation ul li:nth-child(5) {
        display: none; }
      .page-nation ul li a {
        display: block;
        text-align: center;
        width: 43px;
        line-height: 46px;
        border: 1px #32cca6 solid;
        background: #fff;
        font-size: 0.85em;
        color: #32cca6;
        text-decoration: none; }
      .page-nation ul li.current a, .page-nation ul li:hover a {
        color: #fff;
        background: #32cca6; }
  /*
  single blog
--------------------------------------------- */
  #single-miraimoblog .single_cont {
    width: 94%;
    margin: 0 auto;
    padding-top: 40px; }
    #single-miraimoblog .single_cont strong {
      font-weight: bold; }
    #single-miraimoblog .single_cont_sub {
      padding-bottom: 10px;
      *zoom: 1; }
      #single-miraimoblog .single_cont_sub:after {
        content: ".";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden; }
    #single-miraimoblog .single_cont_date {
      line-height: 40px;
      display: inline-block;
      font-weight: bold;
      font-size: 0.85em;
      padding-right: 10px; }
    #single-miraimoblog .single_cont_report {
      display: inline-block;
      line-height: 26px;
      border: 1px #32cca6 solid;
      border-radius: 14px;
      font-size: 0.78em;
      text-align: center;
      padding: 0 10px;
      color: #32cca6; }
    #single-miraimoblog .single_cont_sns {
      text-align: center; }
      #single-miraimoblog .single_cont_sns ul {
        padding: 20px 0; }
        #single-miraimoblog .single_cont_sns ul li {
          width: 38px;
          display: inline-block;
          padding-left: 6px;
          vertical-align: middle; }
    #single-miraimoblog .single_cont_tit {
      font-size: 1.28em;
      padding-bottom: 30px; }
    #single-miraimoblog .single_cont_tag {
      padding-bottom: 10px;
      border-bottom: 1px #ddd solid; }
      #single-miraimoblog .single_cont_tag li {
        display: inline-block;
        margin-right: 10px; }
        #single-miraimoblog .single_cont_tag li a {
          display: inline-block;
          line-height: 23px;
          border: 1px #ddd solid;
          border-radius: 14px;
          font-size: 0.71em;
          text-align: center;
          padding: 0 20px;
          background: #f9f9f9;
          color: #666; }
      #single-miraimoblog .single_cont_tag .company {
        color: #9d9d9d; }
    #single-miraimoblog .single_cont_img {
      padding-bottom: 20px; }
      #single-miraimoblog .single_cont_img img {
        width: 100%;
        height: auto; }
    #single-miraimoblog .single_cont_txt {
      padding-bottom: 30px; }
      #single-miraimoblog .single_cont_txt p {
        font-size: 0.85em;
        padding-bottom: 2em;
        word-wrap: break-word; }
      #single-miraimoblog .single_cont_txt img {
        height: auto; }
      #single-miraimoblog .single_cont_txt .sub-tit {
        font-size: 1.21em;
        padding-bottom: 20px;
        line-height: 1.5;
        padding-left: 40px;
        text-indent: -40px; }
        #single-miraimoblog .single_cont_txt .sub-tit:before {
          display: inline-block;
          vertical-align: middle;
          content: "";
          width: 30px;
          height: 2px;
          background: #32cca6;
          margin-right: 10px; }
      #single-miraimoblog .single_cont_txt .list_number {
        padding-bottom: 30px; }
        #single-miraimoblog .single_cont_txt .list_number li {
          line-height: 22px;
          padding-bottom: 10px;
          text-indent: -35px;
          padding-left: 34px; }
          #single-miraimoblog .single_cont_txt .list_number li:nth-child(1):before {
            display: inline-block;
            vertical-align: top;
            color: #32cca6;
            width: 22px;
            line-height: 22px;
            text-align: center;
            text-indent: initial;
            content: "1";
            border: 1px #32cca6 solid;
            border-radius: 4px;
            margin: 0 10px 0 0; }
          #single-miraimoblog .single_cont_txt .list_number li:nth-child(2):before {
            display: inline-block;
            vertical-align: top;
            color: #32cca6;
            width: 22px;
            line-height: 22px;
            text-align: center;
            text-indent: initial;
            content: "2";
            border: 1px #32cca6 solid;
            border-radius: 4px;
            margin: 0 10px 0 0; }
          #single-miraimoblog .single_cont_txt .list_number li:nth-child(3):before {
            display: inline-block;
            vertical-align: top;
            color: #32cca6;
            width: 22px;
            line-height: 22px;
            text-align: center;
            text-indent: initial;
            content: "3";
            border: 1px #32cca6 solid;
            border-radius: 4px;
            margin: 0 10px 0 0; }
      #single-miraimoblog .single_cont_txt .list_mark {
        padding-bottom: 30px; }
        #single-miraimoblog .single_cont_txt .list_mark li {
          line-height: 22px;
          margin-bottom: 10px;
          text-indent: -32px;
          padding-left: 32px; }
          #single-miraimoblog .single_cont_txt .list_mark li:before {
            display: inline-block;
            vertical-align: top;
            width: 22px;
            height: 22px;
            content: " ";
            padding: 0 10px 2px 0;
            background: url(../images/miraimo/common/ico-mark.png) no-repeat left top; }
      #single-miraimoblog .single_cont_txt .caption:before {
        display: inline-block;
        width: 21px;
        height: 21px;
        content: " ";
        background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
        padding-left: 11px; }
      #single-miraimoblog .single_cont_txt .caption p {
        display: inline;
        vertical-align: top;
        line-height: 21px;
        padding-bottom: 0;
        color: #666;
        font-style: oblique; }
      #single-miraimoblog .single_cont_txt .caption:after {
        display: inline-block;
        width: 21px;
        height: 21px;
        content: " ";
        background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
        padding-right: 11px; }
      #single-miraimoblog .single_cont_txt img {
        width: 100%;
        height: auto; }
    #single-miraimoblog .single_cont_sns-bot {
      border-top: 1px #ddd solid;
      border-bottom: 1px #ddd solid;
      text-align: center; }
      #single-miraimoblog .single_cont_sns-bot ul {
        padding: 20px 0; }
        #single-miraimoblog .single_cont_sns-bot ul li {
          width: 38px;
          display: inline-block;
          padding-left: 6px;
          vertical-align: middle; }
    #single-miraimoblog .single_cont_word {
      padding: 20px 6% 24px;
      background: #f9f9f9; }
      #single-miraimoblog .single_cont_word ul li {
        display: inline-block;
        margin-right: 1em; }
        #single-miraimoblog .single_cont_word ul li a {
          color: #32cca6; }
    #single-miraimoblog .single_cont_author {
      padding-top: 30px; }
      #single-miraimoblog .single_cont_author_img {
        margin: 0 auto 10px; }
        #single-miraimoblog .single_cont_author_img img {
          border-radius: 60px;
          text-align: center;
          margin: 0 auto; }
      #single-miraimoblog .single_cont_author_box {
        width: 100%; }
        #single-miraimoblog .single_cont_author_box_tit {
          background: url(../images/miraimo/common/ico-pen.png) no-repeat;
          min-height: 30px;
          padding: 4px 34px 6px;
          color: #32cca6;
          border-bottom: 1px #32cca6 solid;
          margin-bottom: 20px;
          text-align: center; }
        #single-miraimoblog .single_cont_author_box_name {
          text-align: center;
          font-size: 1.28em;
          padding-bottom: 20px; }
          #single-miraimoblog .single_cont_author_box_name span {
            padding-left: 20px;
            font-size: 12px; }
        #single-miraimoblog .single_cont_author_box_txt {
          font-size: 0.85em;
          color: #666; }
        #single-miraimoblog .single_cont_author_box_link {
          margin: 0 auto;
          padding-top: 20px;
          text-align: center; }
          #single-miraimoblog .single_cont_author_box_link a {
            margin: 0 auto;
            width: 90%;
            display: block;
            text-decoration: none;
            font-size: 0.85em;
            border: 1px #32cca6 solid;
            border-radius: 22.5px;
            line-height: 43px;
            color: #32cca6; }
        #single-miraimoblog .single_cont_author_box .career_area {
          margin-bottom: 1em; }
          #single-miraimoblog .single_cont_author_box .career_area h4 {
            color: #32cca6;
            font-size: 1.07em;
            font-weight: bold; }
        #single-miraimoblog .single_cont_author_box .message_area h4 {
          color: #32cca6;
          font-size: 1.07em;
          font-weight: bold; }
  #single-miraimoblog .area_blog {
    padding-top: 40px; }
    #single-miraimoblog .area_blog .sub-tit {
      font-size: 1.42em;
      font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
      letter-spacing: .075em;
      text-align: center;
      padding: 20px 0;
      border-top: 1px #ddd solid; }
  #single-miraimoblog.single.voice .area_blog {
    padding-bottom: 0; }
  #single-miraimoblog.single-voice .single_cont_report {
    border: 1px #00b9d8 solid;
    color: #00b9d8; }
  #single-miraimoblog.single-voice .single_cont_txt .sub-tit:before {
    background: #00b9d8; }
  #single-miraimoblog.single-voice .single_cont_txt .list_number li:nth-child(1):before {
    color: #00b9d8;
    border: 1px #00b9d8 solid; }
  #single-miraimoblog.single-voice .single_cont_txt .list_number li:nth-child(2):before {
    color: #00b9d8;
    border: 1px #00b9d8 solid; }
  #single-miraimoblog.single-voice .single_cont_txt .list_number li:nth-child(3):before {
    color: #00b9d8;
    border: 1px #00b9d8 solid; }
  #single-miraimoblog.single-voice .single_cont_word ul li a {
    color: #00b9d8; }
  #single-miraimoblog.single-voice .single_cont_author_box_tit {
    color: #00b9d8;
    border-bottom: 1px #00b9d8 solid; }
  #single-miraimoblog.single-voice .single_cont_author_box_link a {
    border: 1px #00b9d8 solid;
    color: #00b9d8; }
  /*
  careeradvisor
--------------------------------------------- */
  #careeradvisor .area_key .area_tit span {
    color: #0c4f93; }
  #careeradvisor .container {
    margin-bottom: 35px; }
    #careeradvisor .container .area_advisor {
      width: 100%;
      margin: 0 auto; }
      #careeradvisor .container .area_advisor .area_advisor_cont {
        width: 100%;
        display: inline-block;
        border: solid 1px #8a8a8a;
        margin: 0 0 15px 0; }
        #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box {
          border-top: solid 4px #0c4f93; }
          #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box img {
            border-radius: 50%;
            margin: 25px auto 35px; }
          #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .name {
            text-align: center;
            margin: auto;
            font-size: 1.42em;
            border-top: 1px solid black;
            margin: 15px 0 0;
            text-align: center;
            padding: 0;
            margin: 0 10px;
            height: 24px; }
          #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .btn {
            padding-top: 6px;
            margin: 0 auto; }
            #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .btn a {
              margin: 0 auto;
              width: 60%;
              display: block;
              font-size: 0.78em;
              border: 1px #0c4f93 solid;
              color: #0c4f93;
              line-height: 38px;
              padding: 0 1em;
              border-radius: 19px;
              min-width: 70px;
              text-align: center;
              text-decoration: none; }
              #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .btn a:hover {
                background: #0c4f93;
                color: #fff; }
          #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box span {
            position: relative;
            top: -20px;
            padding: 0 20px;
            margin: 40px 10px 0;
            background: white; }
          #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .career_area {
            padding: 10px 10px 25px; }
            #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .career_area .career {
              color: #0c4f93; }
            #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .career_area p {
              font-size: 0.85em; }
          #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .message_area {
            padding: 30px 10px 25px;
            border-top: solid 1px #8a8a8a;
            background-color: #ebf4fc; }
            #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .message_area .message {
              color: #0c4f93; }
            #careeradvisor .container .area_advisor .area_advisor_cont .area_advisor_cont_box .message_area p {
              font-size: 0.85em; }
  /*
  flow
--------------------------------------------- */
  #flow .area_key .area_tit span {
    color: #ff9537; }
  .flow_cont {
    margin: 0 auto; }
    .flow_cont .flow_box {
      border: solid 1px #ddd;
      background-color: #fff;
      margin-bottom: 30px; }
      .flow_cont .flow_box .float,
      .flow_cont .flow_box .overflow {
        box-sizing: border-box; }
      .flow_cont .flow_box .float {
        clear: both; }
      .flow_cont .flow_box .flow_box_step {
        color: #ff9537;
        font-family: "PT Sans", sans-serif;
        border-top: solid 5px #ff9537;
        text-align: center;
        padding: 0;
        padding-top: 14px; }
        .flow_cont .flow_box .flow_box_step .step {
          font-size: 0.92em; }
        .flow_cont .flow_box .flow_box_step .count {
          font-size: 2.28em;
          border-top: solid 2px #ff9537;
          line-height: normal;
          display: inline; }
      .flow_cont .flow_box .overflow {
        overflow: hidden; }
      .flow_cont .flow_box .flow_box_text {
        border-top: none; }
        .flow_cont .flow_box .flow_box_text .text_area {
          border-left: none;
          border-top: solid 1px #ddd;
          padding: 0;
          padding-top: 14px;
          margin: 14px 4%; }
          .flow_cont .flow_box .flow_box_text .text_area h3 {
            color: #ff9537;
            font-weight: bold;
            font-size: 1.28em;
            text-align: center; }
          .flow_cont .flow_box .flow_box_text .text_area .sub_title {
            font-weight: bold;
            font-size: 1.07em; }
          .flow_cont .flow_box .flow_box_text .text_area p {
            font-size: 0.85em;
            margin-top: 10px; }
            .flow_cont .flow_box .flow_box_text .text_area p a {
              color: #ff9537; }
    .flow_cont .flow_box:after {
      display: block;
      content: "";
      clear: both; }
    .flow_cont .flow_box:last-child {
      margin-bottom: 0; }
    .flow_cont .text_box {
      text-align: center;
      margin-bottom: 30px; }
      .flow_cont .text_box p {
        margin-top: 10px; }
      .flow_cont .text_box .sub_title {
        font-weight: bold;
        font-size: 1.07em; }
      .flow_cont .text_box .sub_txt {
        font-size: 0.85em; }
  /* ---------- flow btn ---------- */
  .flow_btn_box {
    font-size: 0;
    margin: 30px 0 50px; }
    .flow_btn_box .flow_btn {
      padding: 0 1%;
      font-size: 14px; }
      .flow_btn_box .flow_btn a {
        font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
        font-size: 1.28em;
        letter-spacing: .1em;
        text-decoration: none;
        text-align: center;
        display: block;
        background: #fff;
        line-height: 66px;
        border-radius: 35px;
        margin: 0 auto; }
        .flow_btn_box .flow_btn a:hover {
          color: #fff; } }
      @media (max-width: 767px) and (max-width: 767px) {
        .flow_btn_box .flow_btn a {
          font-size: 0.85em;
          line-height: 46px;
          border-radius: 25px; } }

@media (max-width: 767px) {
      .flow_btn_box .flow_btn a {
        width: 100%;
        font-size: 14px;
        max-width: 400px;
        color: #0c4f93;
        font-size: 0.85em;
        border: 2px #0c4f93 solid; }
        .flow_btn_box .flow_btn a:hover {
          background: #0c4f93; }
      .flow_btn_box .flow_btn.about {
        margin-bottom: 15px; }
        .flow_btn_box .flow_btn.about a {
          color: #0c4f93;
          border: 2px #0c4f93 solid; }
          .flow_btn_box .flow_btn.about a:hover {
            background: #0c4f93;
            color: #FFF; }
  /*
  faq
--------------------------------------------- */
  #faq .area_key .area_tit span {
    color: #ff9537; }
  #faq .pages {
    background: none; }
  .faq_cont {
    margin: 0 auto;
    margin-bottom: 70px; }
    .faq_cont .faq_box {
      margin-bottom: 25px; }
      .faq_cont .faq_box dt {
        margin-bottom: 10px;
        border-bottom: solid 1px #ddd;
        padding-bottom: 10px;
        color: #ff9537;
        padding-left: 45px;
        text-indent: -45.5px; }
        .faq_cont .faq_box dt:before {
          content: "Q";
          padding: 5px 10px;
          border-radius: 4px;
          color: #fff;
          background-color: #ff9537;
          font-size: 1.35em;
          margin-right: 10px; }
      .faq_cont .faq_box dd p {
        font-size: 0.85em;
        padding-left: 45px;
        text-indent: -45.5px; }
      .faq_cont .faq_box dd p:before {
        content: "A";
        padding: 5px 10px;
        border-radius: 4px;
        color: #ff9537;
        font-size: 1.35em;
        border: solid 2px #ff9537;
        margin-right: 10px; }
  /*
  perfectguide-archive
--------------------------------------------- */
  #perfectguide .area_key .area_tit span {
    color: #02c255; }
  #perfectguide .area_key p {
    width: 100%;
    margin: auto; }
  #perfectguide .area_key .txt {
    font-size: 0.85em; }
  #perfectguide .area_guide-book .area_guide_cont {
    font-size: 0; }
  #perfectguide .area_guide-book .area_guide_step {
    width: 100%;
    display: inline-block;
    margin: 0 1% 12px 0; }
    #perfectguide .area_guide-book .area_guide_step a {
      display: block;
      background: #fff;
      border-radius: 4px;
      padding: 10px 4%;
      font-size: 14px;
      text-decoration: none;
      border: 2px #02c255 solid; }
      #perfectguide .area_guide-book .area_guide_step a:hover {
        color: #02c255;
        border: 2px #02c255 solid;
        background: rgba(255, 255, 255, 0.7); }
    #perfectguide .area_guide-book .area_guide_step:nth-child(even) {
      margin-right: 0; }
    #perfectguide .area_guide-book .area_guide_step_point {
      color: #02c255;
      font-family: "PT Sans", sans-serif;
      display: block;
      border-bottom: 1px #02c255 solid;
      line-height: 23px;
      padding-bottom: 4px;
      margin-bottom: 4px; }
    #perfectguide .area_guide-book .area_guide_step_txt {
      font-size: 0.85em;
      display: inline-block; }
  /*
  perfectguide-single
--------------------------------------------- */
  #perfectguide-single .area_key .area_tit span {
    color: #32cca6; }
  #perfectguide-single .area_guide-book {
    width: 100%;
    padding-bottom: 40px;
    margin: auto; }
    #perfectguide-single .area_guide-book .inner {
      padding: 0; }
    #perfectguide-single .area_guide-book h3 {
      display: table;
      color: #fff;
      background-color: #02c255;
      width: 100%;
      margin: auto; }
      #perfectguide-single .area_guide-book h3 span {
        display: table-cell;
        width: 82%;
        font-size: 1.14em;
        font-weight: bold;
        padding: 10px; }
    #perfectguide-single .area_guide-book h3:before {
      display: table-cell;
      font-family: "PT Sans", sans-serif;
      content: "Chapter.1";
      background-color: #000;
      font-size: 0.92em;
      color: #fff;
      padding: 10px;
      width: 18%;
      text-align: center;
      vertical-align: middle; }
    #perfectguide-single .area_guide-book h4 {
      font-size: 1.14em;
      color: #02c255;
      padding: 30px 10px 40px;
      margin: auto; }
    #perfectguide-single .area_guide-book .area_guide-book_cont {
      margin: auto; }
      #perfectguide-single .area_guide-book .area_guide-book_cont dl {
        margin: 0 10px;
        border-top: 1px #ddd solid;
        padding-bottom: 20px; }
        #perfectguide-single .area_guide-book .area_guide-book_cont dl dt {
          font-weight: bold;
          padding: 20px 0 15px;
          padding-left: 40px;
          text-indent: -40px; }
          #perfectguide-single .area_guide-book .area_guide-book_cont dl dt:before {
            display: inline-block;
            vertical-align: middle;
            content: "";
            width: 30px;
            height: 2px;
            background: #02c255;
            margin-right: 10px; }
      #perfectguide-single .area_guide-book .area_guide-book_cont .cont_last {
        border-bottom: 1px solid #ddd; }
  #perfectguide-single .ordered_list {
    list-style-type: decimal;
    margin-left: 1.5em; }
  #perfectguide-single .unordered_list li {
    padding-left: 1em;
    text-indent: -1em; }
    #perfectguide-single .unordered_list li:before {
      content: "・"; }
  #perfectguide-single .square_list li {
    padding-left: 1em;
    text-indent: -1em; }
    #perfectguide-single .square_list li:before {
      content: "■";
      color: #02c255; }
  #perfectguide-single .notes_list li {
    padding-left: 1em;
    text-indent: -1em; }
    #perfectguide-single .notes_list li:before {
      content: "※"; }
  #perfectguide-single .link_wrap a {
    display: inline-block; }
  .count2:before {
    content: "Chapter.2" !important; }
  .count3:before {
    content: "Chapter.3" !important; }
  .count4:before {
    content: "Chapter.4" !important; }
  .count5:before {
    content: "Chapter.5" !important; }
  .count6:before {
    content: "Chapter.6" !important; }
  .count7:before {
    content: "Chapter.7" !important; }
  .count8:before {
    content: "Chapter.8" !important; }
  /*
  job-archive
--------------------------------------------- */
  #job-archive .area_key .area_tit span {
    color: #ff9537; }
  #job-archive .area_key .job_category {
    border-top: 1px #ddd solid;
    margin-top: 10px;
    padding-top: 10px;
    text-align: center;
    margin: 0 auto; }
    #job-archive .area_key .job_category span {
      font-weight: bold; }
  #job-archive .job_archive_cont_box {
    border: 1px #ddd solid;
    border-top: none;
    margin-bottom: 30px; }
    #job-archive .job_archive_cont_box_inner {
      background: #f9f9f9;
      border-top: 1.5px #ff9537 solid;
      padding: 20px 4% 10px;
      border-bottom: 1px #ddd solid; }
    #job-archive .job_archive_cont_box .tit {
      padding-bottom: 10px; }
    #job-archive .job_archive_cont_box .tag_list {
      display: table-cell; }
      #job-archive .job_archive_cont_box .tag_list li {
        display: inline-block;
        margin: 0 4px 4px 0; }
        #job-archive .job_archive_cont_box .tag_list li a {
          width: 100%;
          background: #fff;
          display: inline-block;
          padding: 0 10px;
          line-height: 20px;
          font-size: 0.71em;
          text-decoration: none;
          text-align: center;
          border-radius: 14px;
          border: 1px #ffd200 solid; }
    #job-archive .job_archive_cont_box table {
      font-size: 0.85em;
      width: 92%;
      height: 100%;
      margin: 10px auto 20px; }
      #job-archive .job_archive_cont_box table tr {
        height: 100%;
        border-bottom: 1px #ddd solid; }
      #job-archive .job_archive_cont_box table th,
      #job-archive .job_archive_cont_box table td {
        display: block; }
      #job-archive .job_archive_cont_box table th {
        width: 100%;
        padding: 10px 0 5px;
        font-weight: bold; }
      #job-archive .job_archive_cont_box table td {
        padding: 0 0 10px; }
      #job-archive .job_archive_cont_box table .company_name.no-member .caption {
        display: block;
        font-size: 0.85em; }
        #job-archive .job_archive_cont_box table .company_name.no-member .caption:before {
          content: "";
          display: inline-block;
          width: 16px;
          height: 16px;
          background: url(../images/miraimo/job/ico-caption.png) no-repeat;
          background-size: contain;
          margin-right: 5px;
          vertical-align: middle; }
        #job-archive .job_archive_cont_box table .company_name.no-member .caption a {
          color: #ff9537; }
    #job-archive .job_archive_cont_box .btn_detail {
      margin-bottom: 40px; }
      #job-archive .job_archive_cont_box .btn_detail a {
        font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
        font-size: 1.28em;
        letter-spacing: .1em;
        text-decoration: none;
        text-align: center;
        display: block;
        background: #fff;
        line-height: 66px;
        border-radius: 35px;
        margin: 0 auto; }
        #job-archive .job_archive_cont_box .btn_detail a:hover {
          color: #fff; } }
      @media (max-width: 767px) and (max-width: 767px) {
        #job-archive .job_archive_cont_box .btn_detail a {
          font-size: 0.85em;
          line-height: 46px;
          border-radius: 25px; } }

@media (max-width: 767px) {
      #job-archive .job_archive_cont_box .btn_detail a {
        width: 80%;
        font-size: 0.85em;
        color: #ff9537;
        border: 2px #ff9537 solid; }
        #job-archive .job_archive_cont_box .btn_detail a:hover {
          background: #ff9537; }
    #job-archive .job_archive_cont_box > a {
      display: block;
      text-decoration: none; }
  #job-archive .job_archive_cont_dtl {
    padding: 15px 4%; }
  #job-archive .job_archive_cont .job_dtl_company {
    width: 100%;
    margin-bottom: 10px;
    letter-spacing: -.4em; }
    #job-archive .job_archive_cont .job_dtl_company dt, #job-archive .job_archive_cont .job_dtl_company dd {
      display: inline-block;
      letter-spacing: normal; }
    #job-archive .job_archive_cont .job_dtl_company dt {
      width: 3.5em;
      font-weight: bold; }
    #job-archive .job_archive_cont .job_dtl_company dd {
      padding-left: 1em; }
      #job-archive .job_archive_cont .job_dtl_company dd.caption {
        margin-top: .5em;
        padding-left: 1.8em;
        font-size: 0.85em;
        position: relative; }
        #job-archive .job_archive_cont .job_dtl_company dd.caption:before {
          content: "";
          display: inline-block;
          width: 16px;
          height: 16px;
          background: url(../images/miraimo/job/ico-caption.png) no-repeat;
          background-size: contain;
          position: absolute;
          top: .25em;
          left: 0; }
        #job-archive .job_archive_cont .job_dtl_company dd.caption a {
          color: #ff9537; }
  #job-archive .job_archive_cont .job_dtl_info {
    padding-top: 10px;
    font-size: 0.85em;
    color: #8a8a8a;
    letter-spacing: -.4em;
    background-image: linear-gradient(to right, #C6C6C6, #C6C6C6 1px, transparent 1px, transparent 3px);
    background-size: 4px 1px;
    background-position: top;
    background-repeat: repeat-x; }
    #job-archive .job_archive_cont .job_dtl_info li {
      font-style: italic;
      display: inline-block;
      letter-spacing: normal; }
      #job-archive .job_archive_cont .job_dtl_info li:not(:last-child)::after {
        content: '/';
        margin-right: .25em;
        padding-right: .25em; }
  #job-archive .page-nation {
    margin-bottom: 40px;
    letter-spacing: normal; }
    #job-archive .page-nation ul li a {
      border: 1px #ff9537 solid;
      color: #ff9537; }
    #job-archive .page-nation ul li.current a, #job-archive .page-nation ul li:hover a {
      color: #fff;
      background: #ff9537; }
  /*
  job-single
--------------------------------------------- */
  #single-job .area_key {
    padding-bottom: 30px; }
    #single-job .area_key .area_tit {
      padding: 30px 0 0; }
      #single-job .area_key .area_tit span {
        color: #ff9537; }
  #single-job .job_archive_cont {
    max-width: 1000px;
    margin: 0 auto; }
    #single-job .job_archive_cont .tit {
      padding-bottom: 10px; }
    #single-job .job_archive_cont_box {
      margin-bottom: 30px; }
      #single-job .job_archive_cont_box_inner {
        background: #f9f9f9;
        border-top: 1.5px #ff9537 solid;
        padding: 20px 4% 10px;
        border-bottom: 1px #ddd solid; }
        #single-job .job_archive_cont_box_inner .tag_list {
          display: table-cell; }
          #single-job .job_archive_cont_box_inner .tag_list li {
            display: inline-block;
            margin: 0 8px 8px 0; }
            #single-job .job_archive_cont_box_inner .tag_list li a {
              width: 100%;
              background: #fff;
              display: inline-block;
              padding: 0 10px;
              line-height: 20px;
              font-size: 0.71em;
              text-decoration: none;
              text-align: center;
              border-radius: 14px;
              border: 1px #ffd200 solid; }
      #single-job .job_archive_cont_box table {
        font-size: 0.85em;
        width: 98%;
        height: 100%;
        margin: 10px auto 40px; }
        #single-job .job_archive_cont_box table tr {
          height: 100%;
          border-bottom: 1px #ddd solid; }
        #single-job .job_archive_cont_box table th,
        #single-job .job_archive_cont_box table td {
          display: block;
          padding: 0 0 10px; }
          #single-job .job_archive_cont_box table th dl,
          #single-job .job_archive_cont_box table td dl {
            font-size: 0; }
            #single-job .job_archive_cont_box table th dl dt, #single-job .job_archive_cont_box table th dl dd,
            #single-job .job_archive_cont_box table td dl dt,
            #single-job .job_archive_cont_box table td dl dd {
              font-size: 12px;
              display: inline-block; }
            #single-job .job_archive_cont_box table th dl dt,
            #single-job .job_archive_cont_box table td dl dt {
              font-weight: bold; }
            #single-job .job_archive_cont_box table th dl dd,
            #single-job .job_archive_cont_box table td dl dd {
              padding-left: .3em; }
        #single-job .job_archive_cont_box table th {
          width: 100%;
          padding: 10px 0 5px;
          font-weight: bold; }
        #single-job .job_archive_cont_box table .company_name.no-member .caption {
          display: block;
          font-size: 0.85em; }
          #single-job .job_archive_cont_box table .company_name.no-member .caption:before {
            content: "";
            display: inline-block;
            width: 16px;
            height: 16px;
            background: url(../images/miraimo/job/ico-caption.png) no-repeat;
            background-size: contain;
            margin-right: 5px;
            vertical-align: middle; }
          #single-job .job_archive_cont_box table .company_name.no-member .caption a {
            color: #ff9537; }
      #single-job .job_archive_cont_box .btn_detail {
        padding: 10px 0 30px; }
        #single-job .job_archive_cont_box .btn_detail button[type="submit"],
        #single-job .job_archive_cont_box .btn_detail input[type="submit"],
        #single-job .job_archive_cont_box .btn_detail input[type="button"],
        #single-job .job_archive_cont_box .btn_detail a {
          font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
          width: 88%;
          font-size: 0.85em;
          text-align: center;
          text-decoration: none;
          display: block;
          color: #fff;
          background-color: #ef717d;
          background-image: linear-gradient(90deg, #ef717d 25%, #f2986e 100%);
          line-height: 50px;
          border-radius: 25px;
          margin: 0 auto;
          border: none; }
          #single-job .job_archive_cont_box .btn_detail button[type="submit"]:hover,
          #single-job .job_archive_cont_box .btn_detail input[type="submit"]:hover,
          #single-job .job_archive_cont_box .btn_detail input[type="button"]:hover,
          #single-job .job_archive_cont_box .btn_detail a:hover {
            opacity: .6; }
          #single-job .job_archive_cont_box .btn_detail button[type="submit"].textlink,
          #single-job .job_archive_cont_box .btn_detail input[type="submit"].textlink,
          #single-job .job_archive_cont_box .btn_detail input[type="button"].textlink,
          #single-job .job_archive_cont_box .btn_detail a.textlink {
            background: none;
            color: #ff9537;
            display: inline;
            width: auto;
            font-size: inherit;
            font-weight: normal;
            text-decoration: underline;
            border: none;
            line-height: 1; }
            #single-job .job_archive_cont_box .btn_detail button[type="submit"].textlink:hover,
            #single-job .job_archive_cont_box .btn_detail input[type="submit"].textlink:hover,
            #single-job .job_archive_cont_box .btn_detail input[type="button"].textlink:hover,
            #single-job .job_archive_cont_box .btn_detail a.textlink:hover {
              background: none;
              color: inherit;
              text-decoration: none; }
        #single-job .job_archive_cont_box .btn_detail > p {
          margin: 15px 0 0;
          line-height: 1.5;
          text-align: center; }
  #single-job .page-nation {
    letter-spacing: normal; }
    #single-job .page-nation ul li a {
      border: 1px #ff9537 solid;
      color: #ff9537; }
    #single-job .page-nation ul li.current a, #single-job .page-nation ul li:hover a {
      color: #fff;
      background: #ff9537; }
  /*
  member
--------------------------------------------- */
  #member .area_tit span {
    color: #f85550; }
  #member .pages {
    background: none; }
  #member .container {
    padding: 0;
    width: 100%; }
    #member .container .top_txt {
      width: 90%;
      margin: 0 auto; }
      #member .container .top_txt h2 {
        font-size: 20px;
        text-align: center; }
      #member .container .top_txt p {
        text-align: center;
        font-size: 15px; }
    #member .container form#member button.button1 {
      font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
      width: 88%;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      text-decoration: none;
      display: block;
      color: #000;
      background: #ffc000;
      background: linear-gradient(to right, #ffc000 0%, #ffea00 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc000', endColorstr='#ffea00', GradientType=1);
      line-height: 50px;
      border-radius: 25px;
      margin: 0 auto;
      border: none; }
  /*
  registnow
--------------------------------------------- */
  .form_cont .area_key .area_tit span {
    color: #f85550; }
  .form_cont .area_key .txt {
    width: 100%;
    margin: auto; }
    .form_cont .area_key .txt + .btn_normal {
      margin: 60px 0; }
      .page-template-template-miraimo-lp-thankyou .form_cont .area_key .txt + .btn_normal {
        margin-bottom: 0; }
  .miraimo_flow {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .miraimo_flow .miraimo_flow_item {
      padding: 30px 1em;
      line-height: 1; }
    .miraimo_flow .flow_step_01 {
      width: 35%;
      font-size: 18px;
      color: #fff;
      text-align: center;
      border-radius: 10px;
      background-color: #ef717d;
      background-image: linear-gradient(90deg, #ef717d 25%, #f2986e 100%); }
      .miraimo_flow .flow_step_01 span {
        margin-bottom: 1.2em;
        font-size: 14px;
        font-weight: bold;
        display: block; }
    .miraimo_flow .flow_step_join {
      line-height: 1.4;
      color: #e0787f;
      text-align: center; }
      .miraimo_flow .flow_step_join span {
        padding-top: 15px;
        display: block;
        position: relative; }
        .miraimo_flow .flow_step_join span::before {
          content: '';
          background: url("/assets/images/miraimo/img_register_arw.png") center center no-repeat;
          background-size: contain;
          width: 40.5px;
          height: 7.5px;
          margin-left: -20.25px;
          position: absolute;
          left: 50%;
          top: 0;
          z-index: 1; }
    .miraimo_flow .flow_step_02 {
      width: 35%;
      font-size: 18px;
      color: #e0787f;
      text-align: center;
      border-radius: 10px;
      border-image: linear-gradient(90deg, #ef717d 25%, #f2986e 100%);
      border: 2px solid;
      border-image-slice: 20;
      border-image-repeat: round;
      background-color: #fff; }
      .miraimo_flow .flow_step_02 span {
        margin-bottom: 1.2em;
        font-size: 14px;
        font-weight: bold;
        display: block; }
  .emphasis {
    font-weight: bold;
    background: linear-gradient(transparent 40%, #ffff00 0%);
    display: inline;
    padding-bottom: 6px; }
  .entry_list .entry_list_item {
    margin-bottom: 10px;
    font-size: 14px; }
  .entry_list .entry_list_detail {
    padding-left: 1.8em;
    font-size: 12px; }
    .entry_list .entry_list_detail + .entry_list_item {
      margin-top: 15px; }
  .other_answer {
    margin-top: 10px; }
    .other_answer label {
      font-size: 13px;
      margin-bottom: 2.5px;
      display: block;
      cursor: default; }
  .form_caution {
    padding: 0 15px; }
  .caution_txt {
    padding-left: 1em;
    text-indent: -1em; }
    .caution_txt + .caution_txt {
      margin-top: 5px; }
  /*
 cont
--------------------------------------------- */
  .form_cont {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 50px; }
    .page-template-template-miraimo-lp-thankyou .form_cont {
      margin-bottom: 0; }
    .form_cont h3 {
      padding: 16px;
      font-weight: bold; }
    .form_cont table {
      width: 100%;
      border: 1px solid #ddd; }
      .form_cont table tr {
        border-bottom: 1px solid #ddd; }
      .form_cont table th {
        display: block;
        background: #f9f9f9;
        padding: 20px 15px 5px;
        width: 100%;
        line-height: 1.3;
        position: relative;
        vertical-align: top; }
        .form_cont table th span {
          font-size: 12px; }
        .form_cont table th span.required {
          color: #fff;
          background: #cd1212;
          border-radius: 5px;
          font-size: 0.71em;
          padding: 3px 10px;
          margin-left: 20px; }
      .form_cont table td {
        display: block;
        width: 100%;
        background: #f9f9f9;
        font-size: 1.07em;
        padding: 10px 15px 30px; }
      .form_cont table td input[type="text"],
      .form_cont table td input[type="email"],
      .form_cont table td input[type="password"],
      .form_cont table td input[type="tel"],
      .form_cont table td textarea,
      .form_cont table td select {
        font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
        padding: 13px 10px;
        background: #fff;
        width: 100%;
        border: 1px #ccc solid; }
      .form_cont table td textarea {
        min-height: 200px; }
      .form_cont table td .description {
        display: block;
        font-size: 0.85em;
        width: 94%;
        margin: 0 auto; }
      .form_cont table td button {
        display: block;
        width: 94%;
        margin: 0 auto; }
      .form_cont table td select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: #fff url("//cdn2.iconfinder.com/data/icons/picol-vector/32/arrow_sans_down-32.png") no-repeat 98% center;
        background-size: 12px 12px; }
        .form_cont table td select::-ms-expand {
          display: none; }
    .form_cont .submit_btn {
      text-align: center; }
    .form_cont .error_msg {
      font-weight: bold;
      color: #cd1212; }
    .form_cont button[type="submit"], .form_cont input[type="submit"], .form_cont input[type="button"] {
      font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
      font-size: 1.07em;
      font-weight: bold;
      width: 95%;
      text-align: center;
      text-decoration: none;
      display: block;
      color: #000;
      background: #ffc000;
      background: linear-gradient(to right, #ffc000 0%, #ffea00 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc000', endColorstr='#ffea00', GradientType=1);
      line-height: 70px;
      border-radius: 35px;
      margin: 50px auto;
      border: none; }
  /*
 tml
--------------------------------------------- */
  .tml {
    max-width: 100% !important;
    padding-top: 60px; }
    .tml .submit_btn {
      margin: 50px auto 10px; }
    .tml p {
      margin: 0 auto 1.5em !important; }
    .tml .error {
      color: #cd1212;
      font-weight: bold;
      padding: 0 10px; }
  .tml-login .tml-rememberme-submit-wrap {
    display: block !important; }
  .tml-login .tml-submit-wrap {
    display: block !important; }
    .tml-login .tml-submit-wrap input {
      margin: 0 auto !important; }
  .tml-login .tml-rememberme-wrap {
    display: block !important;
    text-align: center;
    margin: 30px 0 0 !important; }
  .tml .tml-action-links {
    width: 100%; }
  .tml .tml-action-links li {
    float: none !important;
    display: inline-block; }
  p.tml-user-pass1-wrap,
  p.tml-user-pass2-wrap {
    display: table;
    width: 100%;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin: 0 !important;
    background: #f9f9f9; }
    p.tml-user-pass1-wrap label,
    p.tml-user-pass2-wrap label {
      display: block;
      background: #f9f9f9;
      padding: 20px 15px 5px;
      width: 100%;
      line-height: 1.3;
      position: relative;
      vertical-align: top; }
      p.tml-user-pass1-wrap label:after,
      p.tml-user-pass2-wrap label:after {
        content: "必須";
        color: #fff;
        background: #cd1212;
        border-radius: 5px;
        font-size: 0.71em;
        padding: 3px 10px;
        margin-left: 20px; }
    p.tml-user-pass1-wrap input,
    p.tml-user-pass2-wrap input {
      display: block;
      width: 100%;
      background: #f9f9f9;
      font-size: 1.07em;
      margin: 10px 15px 30px; }
    p.tml-user-pass1-wrap input[type="text"],
    p.tml-user-pass1-wrap input[type="password"],
    p.tml-user-pass1-wrap input[type="email"],
    p.tml-user-pass1-wrap textarea,
    p.tml-user-pass1-wrap select,
    p.tml-user-pass2-wrap input[type="text"],
    p.tml-user-pass2-wrap input[type="password"],
    p.tml-user-pass2-wrap input[type="email"],
    p.tml-user-pass2-wrap textarea,
    p.tml-user-pass2-wrap select {
      font-family: Hiragino Sans, "ヒラギノ角ゴシック", Hiragino Kaku Gothic ProN, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", YuGothic, "游ゴシック", Meiryo, "メイリオ", Verdana, "ＭＳ Ｐゴシック", sans-serif;
      padding: 13px 10px;
      background: #fff;
      width: 100%;
      border: 1px #ccc solid;
      width: calc(100% - 30px) !important; }
  .tml-user-pass1-wrap #pass1 + span {
    margin: -25px 20px 20px;
    font-size: .85em;
    display: block; }
  td label {
    cursor: pointer; }
  td select {
    -webkit-appearance: menulist-button;
    -webkit-appearance: textfield; }
  .wpcf7-form p {
    display: none; }
  .wpcf7-mail-sent-ok {
    display: none !important; }
  /*
 reset password
--------------------------------------------- */
  #resetpassform .wp-pwd .password-input-wrapper #pass1-text {
    border: none;
    background: #ffffdf;
    box-shadow: none;
    font-size: 0.92em;
    margin: 5px 0;
    padding: 5px 10px; }
  #resetpassform .wp-pwd #pass-strength-result {
    font-weight: bold; }
    #resetpassform .wp-pwd #pass-strength-result.short {
      color: #f85550; }
    #resetpassform .wp-pwd #pass-strength-result.bad {
      color: #ff9537; }
    #resetpassform .wp-pwd #pass-strength-result.bad {
      color: #ff9537; }
    #resetpassform .wp-pwd #pass-strength-result.good {
      color: #000; }
    #resetpassform .wp-pwd #pass-strength-result.strong {
      color: #02c255; }
  /*
  interview
--------------------------------------------- */
  .interview_cont .interviewee {
    border: 1px #ddd solid;
    font-size: 1.2em;
    margin-bottom: 3em;
    padding: 20px 15px 10px; }
    .interview_cont .interviewee .interviewee_lead {
      margin-top: -33px;
      font-size: 1.3em; }
      .interview_cont .interviewee .interviewee_lead span {
        background: #FFF;
        display: inline-block;
        padding: 0 .5em; }
    .interview_cont .interviewee p {
      padding-bottom: 0 !important; }
  .interview_cont p img {
    width: 100%;
    height: auto; }
  .interview_cont p .interview_caption {
    display: block;
    background: #efefef;
    padding: 10px; }
  #single-miraimoblog .single_cont_txt .interview_cont .sub-tit:before {
    background: #00b9d8; }
  .interview_cont .list-hl {
    display: block;
    font-weight: bold;
    padding-bottom: .5em;
    text-decoration: underline; }
  .single-interview .single_cont_txt .interview_cont ol li {
    font-size: 0.85em; }
  .single-interview .single_cont_txt .interview_cont ol li:before,
  .single-interview .single_cont_txt .interview_cont ol li:after {
    border-color: #00b9d8;
    color: #00b9d8; }
  /*
	ご利用者の声 voice
--------------------------------------------- */
  .single-voice .single_cont_txt h3 {
    font-size: 1.21em;
    padding-bottom: 20px;
    line-height: 1.5;
    padding-left: 40px;
    text-indent: -40px; }
    .single-voice .single_cont_txt h3:before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      width: 30px;
      height: 2px;
      margin-right: 10px;
      background: #00b9d8; }
  .single-voice .single_cont_txt ol {
    counter-reset: number;
    padding-bottom: 30px; }
    .single-voice .single_cont_txt ol li {
      position: relative;
      line-height: 22px;
      padding: 0 0 10px 34px; }
      .single-voice .single_cont_txt ol li:before {
        content: counter(number);
        counter-increment: number;
        position: absolute;
        left: 0;
        display: inline-block;
        vertical-align: top;
        color: #00b9d8;
        text-indent: italic;
        width: 22px;
        line-height: 22px;
        text-align: center;
        border: 1px #00b9d8 solid;
        border-radius: 4px;
        margin-bottom: 10px; }
  .single-voice .single_cont_txt ul {
    padding-bottom: 30px; }
    .single-voice .single_cont_txt ul li {
      padding-left: 32px;
      position: relative;
      z-index: 1;
      line-height: 22px;
      margin-bottom: 10px; }
      .single-voice .single_cont_txt ul li:before {
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        border: 1px solid #00b9d8;
        border-radius: 3px; }
      .single-voice .single_cont_txt ul li:after {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        z-index: 1;
        top: 4px;
        left: 4px;
        background: #00b9d8;
        border-radius: 4px; }
  .single-voice .single_cont_txt blockquote {
    display: inline-block;
    position: relative;
    padding: 0 42px; }
    .single-voice .single_cont_txt blockquote:before {
      position: absolute;
      left: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
      padding-left: 11px; }
    .single-voice .single_cont_txt blockquote p {
      display: inline-block;
      vertical-align: top;
      line-height: 21px;
      padding-bottom: 0 !important;
      color: #666;
      font-style: oblique;
      font-size: 0.85em; }
    .single-voice .single_cont_txt blockquote:after {
      position: absolute;
      right: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
      padding-right: 11px; }
  /*
	人事担当者インタビュー interview
--------------------------------------------- */
  .single-interview .single_cont_txt h3 {
    font-size: 1.21em;
    padding-bottom: 20px;
    line-height: 1.5;
    padding-left: 40px;
    text-indent: -40px; }
    .single-interview .single_cont_txt h3:before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      width: 30px;
      height: 2px;
      margin-right: 10px;
      background: #ffd200; }
  .single-interview .single_cont_txt ol {
    counter-reset: number;
    padding-bottom: 30px; }
    .single-interview .single_cont_txt ol li {
      position: relative;
      line-height: 22px;
      padding: 0 0 10px 34px; }
      .single-interview .single_cont_txt ol li:before {
        content: counter(number);
        counter-increment: number;
        position: absolute;
        left: 0;
        display: inline-block;
        vertical-align: top;
        color: #ffd200;
        text-indent: italic;
        width: 22px;
        line-height: 22px;
        text-align: center;
        border: 1px #ffd200 solid;
        border-radius: 4px;
        margin-bottom: 10px; }
  .single-interview .single_cont_txt ul {
    padding-bottom: 30px; }
    .single-interview .single_cont_txt ul li {
      padding-left: 32px;
      position: relative;
      z-index: 1;
      line-height: 22px;
      margin-bottom: 10px; }
      .single-interview .single_cont_txt ul li:before {
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        border: 1px solid #ffd200;
        border-radius: 3px; }
      .single-interview .single_cont_txt ul li:after {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        z-index: 1;
        top: 4px;
        left: 4px;
        background: #ffd200;
        border-radius: 4px; }
  .single-interview .single_cont_txt blockquote {
    display: inline-block;
    position: relative;
    padding: 0 42px; }
    .single-interview .single_cont_txt blockquote:before {
      position: absolute;
      left: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
      padding-left: 11px; }
    .single-interview .single_cont_txt blockquote p {
      display: inline-block;
      vertical-align: top;
      line-height: 21px;
      padding-bottom: 0 !important;
      color: #666;
      font-style: oblique;
      font-size: 0.85em; }
    .single-interview .single_cont_txt blockquote:after {
      position: absolute;
      right: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
      padding-right: 11px; }
  /*
	miraimoブログ miraimoblog
--------------------------------------------- */
  .single-miraimoblog .single_cont_txt h3 {
    font-size: 1.21em;
    line-height: 1.5;
    padding-bottom: 10px;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px; }
    .single-miraimoblog .single_cont_txt h3:before {
      content: '';
      width: 50px;
      height: 1px;
      background: #02c255;
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0; }
  .single-miraimoblog .single_cont_txt ol {
    counter-reset: number;
    padding-bottom: 30px; }
    .single-miraimoblog .single_cont_txt ol li {
      position: relative;
      line-height: 22px;
      padding: 0 0 10px 34px; }
      .single-miraimoblog .single_cont_txt ol li:before {
        content: counter(number);
        counter-increment: number;
        position: absolute;
        left: 0;
        display: inline-block;
        vertical-align: top;
        color: #02c255;
        text-indent: italic;
        width: 22px;
        line-height: 22px;
        text-align: center;
        border: 1px #02c255 solid;
        border-radius: 4px;
        margin-bottom: 10px; }
  .single-miraimoblog .single_cont_txt ul {
    padding-bottom: 30px; }
    .single-miraimoblog .single_cont_txt ul li {
      padding-left: 32px;
      position: relative;
      z-index: 1;
      line-height: 22px;
      margin-bottom: 10px; }
      .single-miraimoblog .single_cont_txt ul li:before {
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        border: 1px solid #02c255;
        border-radius: 3px; }
      .single-miraimoblog .single_cont_txt ul li:after {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        z-index: 1;
        top: 4px;
        left: 4px;
        background: #02c255;
        border-radius: 4px; }
  .single-miraimoblog .single_cont_txt blockquote {
    display: inline-block;
    position: relative;
    padding: 0 42px; }
    .single-miraimoblog .single_cont_txt blockquote:before {
      position: absolute;
      left: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
      padding-left: 11px; }
    .single-miraimoblog .single_cont_txt blockquote p {
      display: inline-block;
      vertical-align: top;
      line-height: 21px;
      padding-bottom: 0 !important;
      color: #666;
      font-style: oblique;
      font-size: 0.85em; }
    .single-miraimoblog .single_cont_txt blockquote:after {
      position: absolute;
      right: 0;
      display: inline-block;
      width: 21px;
      height: 21px;
      content: " ";
      background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
      padding-right: 11px; }
  /*
	求人情報用検索ブロック
--------------------------------------------- */
  .search_block {
    background: none; }
    .search_block .search_block_inbox {
      background: #F9F9F9;
      border: 1px solid #DDD;
      border-top: none;
      border-bottom: none;
      display: none;
      padding: 0 10px 10px; }
      .search_block .search_block_inbox.search_job {
        display: block;
        padding-top: 10px; }
    .search_block .area_search_cont.freeword {
      background: #F9F9F9;
      border-radius: 5px 5px 0 0;
      border: 1px solid #DDD;
      padding: 10px; }
      .search_block .area_search_cont.freeword .area_search_cont_input {
        padding: 0; }
    .search_block .search_block_btn {
      background: #F9F9F9;
      border-radius: 0 0 5px 5px;
      border: 1px solid #DDD;
      cursor: pointer;
      line-height: 44px;
      text-align: center; }
      .search_block .search_block_btn span {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        line-height: 1; }
        .search_block .search_block_btn span:after {
          content: "";
          width: 6px;
          height: 6px;
          border-top: 1px solid #000;
          border-right: 1px solid #000;
          display: inline-block;
          margin-left: 8px;
          vertical-align: middle;
          -webkit-transform: rotateZ(135deg);
          -ms-transform: rotateZ(135deg);
          transform: rotateZ(135deg);
          margin-top: -4px; }
      .search_block .search_block_btn.is-active span:after {
        -webkit-transform: rotateZ(-45deg);
        -ms-transform: rotateZ(-45deg);
        transform: rotateZ(-45deg);
        margin-top: 1px; }
  /*
	add 22.03
--------------------------------------------- */
  .detail {
    width: 100%;
    margin-top: 20px; }
    .detail th,
    .detail td {
      vertical-align: top;
      display: block; }
    .detail th {
      width: 120px; }
    .detail td {
      padding-bottom: 10px; }
  /* ==================================================
  sp LP1
================================================== */
  /*
  common
--------------------------------------------- */
  .lp1 {
    overflow-x: hidden; }
    .lp1 img {
      max-width: 100%;
      vertical-align: top; }
  .lp1-button {
    display: block;
    margin: 0 auto;
    width: 77.0667%; }
  /*
  intro
--------------------------------------------- */
  .lp1-intro {
    position: relative;
    padding: 54.1333% 0 4.667%; }
  .lp1-intro_logo {
    margin: 9px 0 0 15px;
    width: 22.667%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3; }
  .lp1-intro_balloon {
    width: 83.8667%;
    position: absolute;
    top: -8.49257%;
    right: -13.8667%;
    z-index: 2; }
  .lp1-intro_desc {
    width: 84.2667%;
    margin-left: 12.8%;
    position: relative;
    z-index: 3; }
  .lp1-intro_button {
    position: relative;
    z-index: 3; }
  .lp1-intro_person {
    width: 60.2667%;
    position: absolute;
    bottom: 0;
    left: -8.8%;
    z-index: 1; }
  /*
  user
--------------------------------------------- */
  .lp1-user {
    background: #f16f7b;
    /* Old browsers */
    background: -moz-linear-gradient(left, #f16f7b 0%, #f4986b 100%);
    background: -webkit-linear-gradient(left, #f16f7b 0%, #f4986b 100%);
    background: linear-gradient(to right, #f16f7b 0%, #f4986b 100%);
    padding-top: 20px; }
  .lp1-user_title {
    margin: 0 auto 5px;
    width: 70.4%; }
  .lp1-user_merit {
    margin: 0 0 11px -2.667%;
    width: 100%; }
  .lp1-user_reason {
    margin-left: -13.0667%;
    width: 113.0667%; }
  /*
  jpb
--------------------------------------------- */
  .lp1-job {
    padding: 20px 0 37px; }
  .lp1-job_title {
    margin: 0 auto 30px;
    width: 69.0667%; }
  .lp1-job_list {
    margin: 0 auto 9px;
    width: 89.333%; }
  .lp1-job_item {
    margin-bottom: 20px; }
  .lp1-job_desc {
    margin: 0 auto 12px;
    width: 76%; }
  /*
  voice
--------------------------------------------- */
  .lp1-voice {
    background: #4490cd;
    /* Old browsers */
    background: -moz-linear-gradient(left, #4490cd 0%, #60b3b1 100%);
    background: -webkit-linear-gradient(left, #4490cd 0%, #60b3b1 100%);
    background: linear-gradient(to right, #4490cd 0%, #60b3b1 100%);
    padding: 40px 0 20px; }
  .lp1-voice_title {
    margin: 0 auto 27px;
    width: 76%; }
  .lp1-voice_list {
    margin: 0 auto; }
  .lp1-voice_item {
    margin-bottom: 20px;
    width: 96%; }
    .lp1-voice_item:nth-child(odd) {
      margin-left: 2%; }
    .lp1-voice_item:nth-child(even) {
      margin-right: 2%;
      margin-left: auto; }
  /*
  careeradvisor
 --------------------------------------------- */
  .lp1-careeradvisor {
    padding: 20px 0 37px; }
  .lp1-careeradvisor_title {
    margin: 0 auto 29px;
    width: 73.6%; }
  .lp1-careeradvisor_list {
    margin-bottom: 12px; }
  .lp1-careeradvisor_item {
    margin-bottom: 3px;
    width: 96%; }
    .lp1-careeradvisor_item:nth-child(odd) {
      margin-left: 2%; }
    .lp1-careeradvisor_item:nth-child(even) {
      margin-right: 2%;
      margin-left: auto; }
  /*
  flow
--------------------------------------------- */
  .lp1-flow {
    background: #5abe61;
    /* Old browsers */
    background: -moz-linear-gradient(left, #5abe61 0%, #edfa97 100%);
    background: -webkit-linear-gradient(left, #5abe61 0%, #edfa97 100%);
    background: linear-gradient(to right, #5abe61 0%, #edfa97 100%);
    padding: 40px 0;
    position: relative; }
  .lp1-flow_title {
    margin: 0 auto 29px;
    width: 34.2%; }
  .lp1-flow_image {
    margin: 0 auto;
    width: 89.333%; }
  .lp1-flow_person {
    width: 45.333%;
    position: absolute;
    right: -6.4%;
    bottom: -30.4%; }
  /*
  faq
--------------------------------------------- */
  .lp1-faq {
    padding: 39px 0 23px; }
  .lp1-faq_title {
    margin: 0 auto 29px;
    width: 32%; }
  .lp1-faq_list {
    margin: 0 auto;
    width: 89.333%; }
  .lp1-faq_item {
    border-top: 1px solid #e5e5e5;
    margin-bottom: 29px;
    padding: 20px 3% 0 0; }
  /*
  form
--------------------------------------------- */
  .lp1-form {
    background: #f16f7b;
    /* Old browsers */
    background: -moz-linear-gradient(left, #f16f7b 0%, #f4986b 100%);
    background: -webkit-linear-gradient(left, #f16f7b 0%, #f4986b 100%);
    background: linear-gradient(to right, #f16f7b 0%, #f4986b 100%);
    padding: 11px 0 40px; }
    .lp1-form .form_cont {
      background: #fff;
      border-radius: 10px;
      margin: 0 auto;
      padding: 22px 4%;
      width: 89.333%; }
      .lp1-form .form_cont table {
        border: none; }
        .lp1-form .form_cont table tr {
          border-bottom: none;
          display: block;
          margin-bottom: 20px; }
        .lp1-form .form_cont table th {
          background: none;
          margin-bottom: 7px;
          padding: 0; }
          .lp1-form .form_cont table th label {
            font-size: 12px;
            font-weight: bold; }
          .lp1-form .form_cont table th .required {
            background: #f64a4a;
            border-radius: 2px;
            float: right;
            font-size: 10px;
            font-weight: bold; }
        .lp1-form .form_cont table td {
          background: none;
          padding: 0; }
          .lp1-form .form_cont table td input {
            background: #f5f5f5;
            border-color: #e5e5e5;
            border-radius: 5px;
            font-size: 16px;
            padding: 9px 15px; }
            .lp1-form .form_cont table td input::-webkit-input-placeholder {
              color: #ccc; }
            .lp1-form .form_cont table td input::-moz-placeholder {
              color: #ccc; }
            .lp1-form .form_cont table td input:-ms-input-placeholder {
              color: #ccc; }
          .lp1-form .form_cont table td select {
            background-color: #f5f5f5;
            border-color: #e5e5e5;
            font-size: 16px;
            padding: 9px 15px; }
        .lp1-form .form_cont table .error_msg {
          font-size: 12px; }
      .lp1-form .form_cont .lp1-submit_btn input[type="submit"] {
        background: url(/assets/images/miraimo/lp1/btn_lp1_02.png) center center no-repeat;
        background-size: 100% auto;
        margin: 0;
        width: 100%;
        padding: 0;
        line-height: 1;
        border-radius: 0;
        text-indent: -9999px;
        padding-top: 21.97232%;
        height: 0; }
  .lp1-form_title {
    margin: 0 auto 30px;
    width: 41.4%; }
  .lp1-footer {
    padding: 40px 0 98px; }
  .lp1-footer_list {
    margin-bottom: 35px; }
  .lp1-footer_item {
    margin-bottom: 15px;
    text-align: center;
    line-height: 1; }
    .lp1-footer_item .lp1-footer_link {
      color: #000;
      font-size: 12px;
      text-decoration: none; }
  .lp1-footer_logo {
    margin: 0 auto;
    width: 22.667%; } }
