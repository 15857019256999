/*
  perfectguide-archive
--------------------------------------------- */

#perfectguide {
	.area_key {
		.area_tit {
			span {
				color: $cl-green;
			}
		}
		p {
			width: 100%;
			margin: auto;
		}
		.txt {
			font-size: $fs-12;
		}
	}
	.area_guide-book {
		.area_guide_cont {
			font-size: 0;
		}
		.area_guide_step {
			width: 100%;
			display: inline-block;
			margin: 0 1% 12px 0;
			a {
				display: block;
				background: #fff;
				border-radius: 4px;
				padding: 10px 4%;
				font-size: $font-size;
				text-decoration: none;
				border: 2px $cl-green solid;
				&:hover {
					color: $cl-green;
					border: 2px $cl-green solid;
					background: rgba(255, 255, 255, .7);
				}
			}
			&:nth-child(even) {
				margin-right: 0;
			}
			&_point {
				color: $cl-green;
				font-family: $font-family-pt-sans;
				display: block;
				border-bottom: 1px $cl-green solid;
				line-height: 23px;
				padding-bottom: 4px;
				margin-bottom: 4px;
			}
			&_txt {
				font-size: $fs-12;
				display: inline-block;
			}
		}
	}
}
