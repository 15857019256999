/*
  registnow
--------------------------------------------- */

.form_cont {
  .area_key {
    padding-bottom: 60px;

    .area_tit {
      span {
        color: $cl-red;
      }
    }
    .txt {
      text-align: center;
      width: 800px;
      margin: auto;

      + .btn_normal {
        margin: 80px 0 100px;
      }
    }
  }
}

.miraimo_flow {
  width: 100%;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .miraimo_flow_item {
    padding: 60px 1em;
    line-height: 1;
  }
  .flow_step_01 {
    width: 340px;
    font-size: 28px;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    background-color: #ef717d;
    background-image: linear-gradient(90deg, #ef717d 25%, #f2986e 100%);
    
    span {
      margin-bottom: 1.2em;
      font-size: 20px;
      font-weight: bold;
      display: block;
    }
  }
  .flow_step_join {
    line-height: 1.4;
    color: #e0787f;
    text-align: center;

    span {
      padding-top: 30px;
      display: block;
      position: relative;

      &::before {
        content: '';
        background: url('/assets/images/miraimo/img_register_arw.png') center center no-repeat;
        background-size: contain;
        width: 81px;
        height: 15px;
        margin-left: -40.5px;
        position: absolute;
        left: 50%;
        top: 0;
        z-index: 1;
      }
    }
  }
  .flow_step_02 {
    width: 340px;
    font-size: 28px;
    color: #e0787f;
    text-align: center;
    border-radius: 10px;
    border-image: linear-gradient(90deg, #ef717d 25%, #f2986e 100%);
    border: 2px solid;
    border-image-slice: 20;
    border-image-repeat: round;
    background-color: #fff;

    span {
      margin-bottom: 1.2em;
      font-size: 20px;
      font-weight: bold;
      display: block;
    }
  }
}

.txt_01 {

}

.emphasis {
  font-weight: bold;
  background: linear-gradient(transparent 40%, #ffff00 0%);
  display: inline;
  padding-bottom: 6px;
}

.entry_list {

  .entry_list_item {
    margin-bottom: 10px;
    font-weight: bold;
  }
  .entry_list_detail {
    padding-left: 1.8em;
    font-size: 13px;

    & + .entry_list_item {
      margin-top: 20px;
    }
  }
}

.other_answer {
  margin-top: 10px;
  
  label {
    margin-bottom: 5px;
    display: block;
    cursor: default;
  }
}

.form_caution {

}
.caution_txt {
  padding-left: 1em;
  text-indent: -1em;

  & + & {
    margin-top: 10px;
  }
}



/*
 cont
--------------------------------------------- */

.form_cont {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 100px;

  .page-template-template-miraimo-lp-thankyou & {
	  margin-bottom: 0;
  }

  h3 {
    padding: 16px;
    font-weight: bold;
  }
  table {
    width: 100%;
    border: 1px solid #ddd;
    tr {
      border-bottom: 1px solid #ddd;
    }
    th {
      display: table-cell !important;
      background: #f9f9f9;
      padding: 20px 15px;
      width: 200px !important;
      border-right: 1px solid #ddd;
      line-height: 1.3;
      position: relative;
      vertical-align: top;
      z-index: -1;
      span {
        font-size: 12px;
      }
      span.required {
        position: absolute;
        right: 15px;
        top: 20px;
        color: #fff;
//        border: 1px solid #cd1212;
        border-radius: 5px;
		background: #cd1212;
        padding: 3px 10px;
        font-size: 11px;
      }
    }
    td {
      display: table-cell !important;
      background: #fff;
      font-size: 15px;
      padding: 10px 20px;
    }
    td input[type="text"],
    td input[type="password"],
    td input[type="email"],
    td input[type="tel"],
    td input[type="number"],
    td textarea,
    td select {
      font-family: $font-family-sans;
      border-radius: 5px;
      padding: 13px 10px;
      border: 1px #ccc solid;
      background: #f5f5f5;
      width: 100%;
      font-size: 1em;
    }
    td input,
    td textarea {
      box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1) inset;
    }
    td input[type="radio"] {
      box-shadow: none;
    }
    td input[readonly] {
      box-shadow: none;
      background: #fff;
    }
    td textarea {
      min-height: 300px;
    }
    td select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: #f5f5f5 url("//cdn2.iconfinder.com/data/icons/picol-vector/32/arrow_sans_down-32.png") no-repeat 98% center;
      background-size: 12px 12px;

      &::-ms-expand {
        display: none;
      }
    }
  }
  .submit_btn {
    text-align: center;
  }
  .error_msg {
    font-weight: bold;
    color: #cd1212;
  }

  input, textarea {
    @include placeholder(#999);
    &:focus {
      @include placeholder(transparent);
    }
  }

  button[type="submit"],
  input[type="submit"],
  input[type="button"] {
    cursor: pointer;
    border: 2px $cl-yellow solid;
    color: $cl-yellow;
    font-family: $font-family-sans;
    font-size: $fs-15;
    font-weight: bold;
    width: 400px !important;
    text-align: center;
    text-decoration: none;
    display: block;
    line-height: 70px;
    border-radius: 35px;
    margin: 50px auto;
    cursor: pointer;
    background: #fff;
    &:hover {
      color: #000;
      background: #ffc000;
      background: linear-gradient(to right, #ffc000 0%, #ffea00 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc000', endColorstr='#ffea00', GradientType=1);
      border: 2px $cl-yellow solid;
    }
  }
}


/*
 tml
--------------------------------------------- */

.tml {
  max-width: 100% !important;
//  padding-top: 60px;
  .submit_btn {
    margin: 50px auto 10px;
  }
  p {
    margin: 0 auto 1.5em !important;
  }

  .error {
    color: #cd1212;
    font-weight: bold;
  }
}

.tml-login .tml-rememberme-submit-wrap {
  display: block !important;
}

.tml-login .tml-submit-wrap {
  display: block !important;
  input {
    margin: 0 auto !important;
  }
}

.tml-login .tml-rememberme-wrap {
  display: block !important;
  text-align: center;
  margin: 30px 0 0 !important;
}

.tml .tml-action-links {
  width: 100%;
}

.tml .tml-action-links li {
  float: none !important;
  display: inline-block;
}

p.tml-user-pass1-wrap,
p.tml-user-pass2-wrap {
  display: table;
  width: 100%;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 0 !important;
  label {
    display: table-cell !important;
    background: #f9f9f9;
    padding: 20px 15px;
    width: 200px !important;
    border-right: 1px solid #ddd;
    line-height: 1.3;
    position: relative;
    vertical-align: top;
    &:after {
      content: "必須";
      position: absolute;
      right: 15px;
      top: 20px;
      color: #fff;
      border-radius: 5px;
      background: #cd1212;
      padding: 3px 10px;
      font-size: 11px;
    }
  }
  input {
    display: table-cell !important;
    background: #fff;
    font-size: 15px;
    margin: 10px 20px !important;
  }
  input[type="text"],
  input[type="password"],
  input[type="email"],
  textarea,
  select {
    font-family: $font-family-sans;
    border-radius: 5px;
    padding: 13px 10px;
    border: 1px #ccc solid;
    background: #f5f5f5;
    width: calc(100% - 40px) !important;
  }
}

.tml-user-pass1-wrap {
	#pass1 + span {
		margin: -5px 20px 5px;
		font-size: .85em;
		display: block;
	}
}

td {
  label {
    cursor: pointer;
  }
  select {
    -webkit-appearance: menulist-button;
    -webkit-appearance: textfield;
  }
}

.wpcf7-form {
  p {
    display: none;
  }
}

.wpcf7-mail-sent-ok {
  display: none !important;
}


/*
 reset password
--------------------------------------------- */

#resetpassform {

  .wp-pwd {

    .password-input-wrapper {

      #pass1-text {
        border: none;
        background: #ffffdf;
        box-shadow: none;
        margin: 5px 0;
        padding: 5px 10px;
      }
    }

    #pass-strength-result {
      font-weight: bold;

      &.short {
        color: $cl-red;
      }

      &.bad {
        color: $cl-orange;
      }

      &.bad {
        color: $cl-orange;
      }

      &.good {
        color: #000;
      }

      &.strong {
        color: $cl-green;
      }
    }
  }
}
