/*
	ご利用者の声 voice
--------------------------------------------- */

//ヴィジュアルエディター用
.p-voice {

	//見出し
	&-headline-l1 {
		font-size: $fs-24;
		padding-bottom: 20px;
		line-height: 1.5;
		&:before {
			display: inline-block;
			vertical-align: middle;
			content: "";
			width: 30px;
			height: 2px;
			background: $cl-lightBlue;
			margin-right: 20px;
		}
	}

		//リスト番号有り
		&-list-nbr {
			counter-reset: number;
			padding-bottom: 30px;
			li {
				position: relative;
				line-height: 22px;
				padding: 0 0 10px 42px;
				&:before {
					content: counter(number);
					counter-increment: number;
					position: absolute;
					left: 0;
					display: inline-block;
					vertical-align: top;
					color: $cl-lightBlue;
					width: 22px;
					line-height: 22px;
					text-align: center;
					border: 1px $cl-lightBlue solid;
					border-radius: 4px;
					margin: 0 20px 15px 0;
				}
			}
		}

		//リスト番号なし
		&-list-mrk {
			padding-bottom: 30px;
			li {
				padding: 0 0 10px 42px;
				position: relative;
				z-index: 1;
				line-height: 22px;
				&:before {
					content: '';
					width: 22px;
					height: 22px;
					position: absolute;
					z-index: 1;
					top: 0;
					left: 0;
					border: 1px solid $cl-lightBlue;
					border-radius: 3px;
				}
				&:after {
					content: '';
					width: 16px;
					height: 16px;
					position: absolute;
					z-index: 1;
					top: 4px;
					left: 4px;
					background: $cl-lightBlue;
					border-radius: 4px;
				}
			}
		}

	//引用
	&-caption {
			display: inline-block;
			position: relative;
			padding: 0 42px;
			&:before {
				position: absolute;
				left: 0;
				display: inline-block;
				width: 21px;
				height: 21px;
				content: " ";
				background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
				padding-left: 11px;
			}
			p {
				display: inline-block;
				vertical-align: top;
				line-height: 21px;
				padding-bottom: 0!important;
				color: $cl-lightGray;
				font-style: oblique;
			}
			&:after {
				position: absolute;
				right: 0;
				display: inline-block;
				width: 21px;
				height: 21px;
				content: " ";
				background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
				padding-right: 11px;
			}
		}
}

//実ページ用
.single-voice .single_cont_txt{

	//見出し
	h3 {
		font-size: $fs-24;
		padding-bottom: 20px;
		line-height: 1.5;
		padding-left: 50px;
		text-indent: -50px;
		&:before {
			display: inline-block;
			vertical-align: middle;
			content: "";
			width: 30px;
			height: 2px;
			background: $cl-lightBlue;
			margin-right: 20px;
		}
	}

		//リスト番号有り
		ol {
			counter-reset: number;
			padding-bottom: 30px;
			li {
				position: relative;
				line-height: 22px;
				padding: 0 0 10px 42px;
				&:before {
					content: counter(number);
					counter-increment: number;
					position: absolute;
					left: 0;
					display: inline-block;
					vertical-align: top;
					color: $cl-lightBlue;
					width: 22px;
					line-height: 22px;
					text-align: center;
					border: 1px $cl-lightBlue solid;
					border-radius: 4px;
					margin: 0 20px 15px 0;
				}
			}
		}

		//リスト番号なし
		ul {
			padding-bottom: 30px;
			li {
				padding: 0 0 10px 42px;
				position: relative;
				z-index: 1;
				line-height: 22px;
				&:before {
					content: '';
					width: 22px;
					height: 22px;
					position: absolute;
					z-index: 1;
					top: 0;
					left: 0;
					border: 1px solid $cl-lightBlue;
					border-radius: 3px;
				}
				&:after {
					content: '';
					width: 16px;
					height: 16px;
					position: absolute;
					z-index: 1;
					top: 4px;
					left: 4px;
					background: $cl-lightBlue;
					border-radius: 4px;
				}
			}
		}

	//引用
	blockquote {
			display: inline-block;
			position: relative;
			padding: 0 42px;
			&:before {
				position: absolute;
				left: 0;
				display: inline-block;
				width: 21px;
				height: 21px;
				content: " ";
				background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
				padding-left: 11px;
			}
			p {
				display: inline-block;
				vertical-align: top;
				line-height: 21px;
				padding-bottom: 0!important;
				color: $cl-lightGray;
				font-style: oblique;
			}
			&:after {
				position: absolute;
				right: 0;
				display: inline-block;
				width: 21px;
				height: 21px;
				content: " ";
				background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
				padding-right: 11px;
			}
		}
}



/*
	人事担当者インタビュー interview
--------------------------------------------- */

//ヴィジュアルエディター用
.p-interview {
	//見出し
	&-headline-l1 {
		font-size: $fs-24;
		padding-bottom: 20px;
		line-height: 1.5;
		&:before {
			display: inline-block;
			vertical-align: middle;
			content: "";
			width: 30px;
			height: 2px;
			background: $cl-yellow;
			margin-right: 20px;
		}
	}

	//リスト番号有り
	&-list-nbr {
		counter-reset: number;
		padding-bottom: 30px;
		li {
			position: relative;
			line-height: 22px;
			padding: 0 0 10px 42px;
			&:before {
				content: counter(number);
				counter-increment: number;
				position: absolute;
				left: 0;
				display: inline-block;
				vertical-align: top;
				color: $cl-yellow;
				width: 22px;
				line-height: 22px;
				text-align: center;
				border: 1px $cl-yellow solid;
				border-radius: 4px;
				margin: 0 20px 15px 0;
			}
		}
	}

	//リスト番号なし
	&-list-mrk {
		padding-bottom: 30px;
		li {
			padding: 0 0 10px 42px;
			position: relative;
			z-index: 1;
			line-height: 22px;
			&:before {
				content: '';
				width: 22px;
				height: 22px;
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				border: 1px solid $cl-yellow;
				border-radius: 3px;
			}
			&:after {
				content: '';
				width: 16px;
				height: 16px;
				position: absolute;
				z-index: 1;
				top: 4px;
				left: 4px;
				background: $cl-yellow;
				border-radius: 4px;
			}
		}
	}

//引用
&-caption {
		display: inline-block;
		position: relative;
		padding: 0 42px;
		&:before {
			position: absolute;
			left: 0;
			display: inline-block;
			width: 21px;
			height: 21px;
			content: " ";
			background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
			padding-left: 11px;
		}
		p {
			display: inline-block;
			vertical-align: top;
			line-height: 21px;
			padding-bottom: 0!important;
			color: $cl-lightGray;
			font-style: oblique;
		}
		&:after {
			position: absolute;
			right: 0;
			display: inline-block;
			width: 21px;
			height: 21px;
			content: " ";
			background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
			padding-right: 11px;
		}
	}
}

//実ページ用
.single-interview .single_cont_txt{

	//見出し
	h3 {
		font-size: $fs-24;
		padding-bottom: 20px;
		line-height: 1.5;
		padding-left: 50px;
		text-indent: -50px;
		&:before {
			display: inline-block;
			vertical-align: middle;
			content: "";
			width: 30px;
			height: 2px;
			background: $cl-yellow;
			margin-right: 20px;
		}
	}

		//リスト番号有り
		ol {
			counter-reset: number;
			padding-bottom: 30px;
			li {
				position: relative;
				line-height: 22px;
				padding: 0 0 10px 42px;
				&:before {
					content: counter(number);
					counter-increment: number;
					position: absolute;
					left: 0;
					display: inline-block;
					vertical-align: top;
					color: $cl-yellow;
					width: 22px;
					line-height: 22px;
					text-align: center;
					border: 1px $cl-yellow solid;
					border-radius: 4px;
					margin: 0 20px 15px 0;
				}
			}
		}

		//リスト番号なし
		ul {
			padding-bottom: 30px;
			li {
				padding: 0 0 10px 42px;
				position: relative;
				z-index: 1;
				line-height: 22px;
				&:before {
					content: '';
					width: 22px;
					height: 22px;
					position: absolute;
					z-index: 1;
					top: 0;
					left: 0;
					border: 1px solid $cl-yellow;
					border-radius: 3px;
				}
				&:after {
					content: '';
					width: 16px;
					height: 16px;
					position: absolute;
					z-index: 1;
					top: 4px;
					left: 4px;
					background: $cl-yellow;
					border-radius: 4px;
				}
			}
		}

	//引用
	blockquote {
			display: inline-block;
			position: relative;
			padding: 0 42px;
			&:before {
				position: absolute;
				left: 0;
				display: inline-block;
				width: 21px;
				height: 21px;
				content: " ";
				background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
				padding-left: 11px;
			}
			p {
				display: inline-block;
				vertical-align: top;
				line-height: 21px;
				padding-bottom: 0!important;
				color: $cl-lightGray;
				font-style: oblique;
			}
			&:after {
				position: absolute;
				right: 0;
				display: inline-block;
				width: 21px;
				height: 21px;
				content: " ";
				background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
				padding-right: 11px;
			}
		}
}



/*
	miraimoブログ miraimoblog
--------------------------------------------- */

//ビジュアルエディタ用
.p-miraimoblog {

	//見出し
	&-headline-l1 {
		font-size: $fs-24;
		line-height: 1.5;
		padding-bottom: 10px;
		position: relative;
		z-index: 1;
		border-bottom: 1px solid #eee;
		margin-bottom: 20px;
		&:before {
			content: '';
			width: 50px;
			height: 1px;
			background: $cl-green;
			position: absolute;
			z-index: 1;
			bottom: 0;
			left: 0;
		}
	}

		//リスト番号有り
		&-list-nbr {
			counter-reset: number;
			padding-bottom: 30px;
			li {
				position: relative;
				line-height: 22px;
				padding: 0 0 10px 42px;
				&:before {
					content: counter(number);
					counter-increment: number;
					position: absolute;
					left: 0;
					display: inline-block;
					vertical-align: top;
					color: $cl-green;
					width: 22px;
					line-height: 22px;
					text-align: center;
					border: 1px $cl-green solid;
					border-radius: 4px;
					margin: 0 20px 15px 0;
				}
			}
		}

		//リスト番号なし
		&-list-mrk {
			padding-bottom: 30px;
			li {
				padding: 0 0 10px 42px;
				position: relative;
				z-index: 1;
				line-height: 22px;
				&:before {
					content: '';
					width: 22px;
					height: 22px;
					position: absolute;
					z-index: 1;
					top: 0;
					left: 0;
					border: 1px solid $cl-green;
					border-radius: 3px;
				}
				&:after {
					content: '';
					width: 16px;
					height: 16px;
					position: absolute;
					z-index: 1;
					top: 4px;
					left: 4px;
					background: $cl-green;
					border-radius: 4px;
				}
			}
		}

	//引用
	&-caption {
			display: inline-block;
			position: relative;
			padding: 0 42px;
			&:before {
				position: absolute;
				left: 0;
				display: inline-block;
				width: 21px;
				height: 21px;
				content: " ";
				background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
				padding-left: 11px;
			}
			p {
				display: inline-block;
				vertical-align: top;
				line-height: 21px;
				padding-bottom: 0!important;
				color: $cl-lightGray;
				font-style: oblique;
			}
			&:after {
				position: absolute;
				right: 0;
				display: inline-block;
				width: 21px;
				height: 21px;
				content: " ";
				background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
				padding-right: 11px;
			}
		}
}


//実ページ用
.single-miraimoblog .single_cont_txt{

	//見出し
	h3 {
		font-size: $fs-24;
		line-height: 1.5;
		padding-bottom: 10px;
		position: relative;
		z-index: 1;
		border-bottom: 1px solid #eee;
		margin-bottom: 20px;
		&:before {
			content: '';
			width: 50px;
			height: 1px;
			background: $cl-green;
			position: absolute;
			z-index: 1;
			bottom: 0;
			left: 0;
		}
	}

		//リスト番号有り
		ol {
			counter-reset: number;
			padding-bottom: 30px;
			li {
				position: relative;
				line-height: 22px;
				padding: 0 0 10px 42px;
				&:before {
					content: counter(number);
					counter-increment: number;
					position: absolute;
					left: 0;
					display: inline-block;
					vertical-align: top;
					color: $cl-green;
					width: 22px;
					line-height: 22px;
					text-align: center;
					border: 1px $cl-green solid;
					border-radius: 4px;
					margin: 0 20px 15px 0;
				}
			}
		}

		//リスト番号なし
		ul {
			padding-bottom: 30px;
			li {
				padding: 0 0 10px 42px;
				position: relative;
				z-index: 1;
				line-height: 22px;
				&:before {
					content: '';
					width: 22px;
					height: 22px;
					position: absolute;
					z-index: 1;
					top: 0;
					left: 0;
					border: 1px solid $cl-green;
					border-radius: 3px;
				}
				&:after {
					content: '';
					width: 16px;
					height: 16px;
					position: absolute;
					z-index: 1;
					top: 4px;
					left: 4px;
					background: $cl-green;
					border-radius: 4px;
				}
			}
		}

	//引用
	blockquote {
			display: inline-block;
			position: relative;
			padding: 0 42px;
			&:before {
				position: absolute;
				left: 0;
				display: inline-block;
				width: 21px;
				height: 21px;
				content: " ";
				background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
				padding-left: 11px;
			}
			p {
				display: inline-block;
				vertical-align: top;
				line-height: 21px;
				padding-bottom: 0!important;
				color: $cl-lightGray;
				font-style: oblique;
			}
			&:after {
				position: absolute;
				right: 0;
				display: inline-block;
				width: 21px;
				height: 21px;
				content: " ";
				background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
				padding-right: 11px;
			}
		}
}


/*
	求人情報用検索ブロック
--------------------------------------------- */
.search_block {
	background: none;

	// その他検索ボックス
	.search_block_inbox {
		background: #F9F9F9;
		border: 1px solid #DDD;
		border-top: none;
		border-bottom: none;
		display: none;
		padding: 0 30px 30px;

		&.search_job {
			display: block;
			padding-top: 30px;
		}
	}

	.area_search_cont {
		padding: 0;

		// フリーワード
		&.freeword {
			background: #F9F9F9;
			border-radius: 5px 5px 0 0;
			border: 1px solid #DDD;
			padding: 30px;

			.area_search_cont_input {
				padding: 0;
			}
		}

		.tit {
			background-position: right 6px;
			padding: 0;
			width: 160px;
		}

		.area_search_cont_list,
		.area_search_cont_box {
			vertical-align: top;
			padding: 0 25px;
		}

		&.job-area {
			margin: 0 0 40px;
		}
	}

	// アコーディオンボタン
	.search_block_btn {
		background: #F9F9F9;
		border-radius: 0 0 5px 5px;
		border: 1px solid #DDD;
		cursor: pointer;
		font-size: $fs-16;
		line-height: 73px;
		text-align: center;

		span {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			line-height: 1;

			&:after {
				content: "";
				width: 6px;
				height: 6px;
				border-top: 1px solid #000;
				border-right: 1px solid #000;
				display: inline-block;
			    margin-left: 8px;
				vertical-align: middle;
				-webkit-transform: rotateZ(135deg);
				-ms-transform: rotateZ(135deg);
				transform: rotateZ(135deg);
			    margin-top: -4px;
			}
		}

		&.is-active {

			span {

				&:after {
					-webkit-transform: rotateZ(-45deg);
					-ms-transform: rotateZ(-45deg);
					transform: rotateZ(-45deg);
				    margin-top: 1px;
				}
			}
		}
	}
}



/*
	add 22.03
--------------------------------------------- */
// access map
.detail {
  width: 100%;
  margin-top: 10px;
  th,
  td {
    vertical-align: top;
    padding: 5px 0;
  }
  th {
    width: 120px;
  }
}