/*
  faq
--------------------------------------------- */
#faq{
	.area_key{
		.area_tit{
			span{
				color: $cl-orange;
			}
		}
	}
}


.faq_cont {
	max-width: 1000px;
	margin: 0 auto;
	margin-bottom: 70px;
	.faq_box {
		margin-top: -200px;
		margin-bottom: 50px;
		padding-top: 200px;
		dt {
			margin-bottom: 20px;
			border-bottom: solid 1px #ddd;
			padding-bottom: 20px;
			color: $cl-orange;
			font-size: $fs-18;
			&:before {
				content: "Q";
				padding: 6px 12px;
				border-radius: 4px;
				color: #fff;
				background-color: $cl-orange;
				font-size: $fs-18;
				margin-right: 0.6em;
			}
			// h3 {
			// 	color: $cl-orange;
			// 	font-size: $fs-18;
			// }
			// h3:before {
			// 	content: "Q";
			// 	padding: 6px 12px;
			// 	border-radius: 4px;
			// 	color: #fff;
			// 	background-color: $cl-orange;
			// 	font-size: $fs-18;
			// 	margin-right: 0.6em;
			// }
		}
		dd {
			p {
				padding-left: 4.3em;
				text-indent: -4.27em;
			}
			p:before {
				content: "A";
				padding: 6px 12px;
				border-radius: 4px;
				color: $cl-orange;
				border: solid 2px $cl-orange;
				font-size: $fs-18;
				margin-right: 1em;
			}
		}
	}
}
