/*
  flow
--------------------------------------------- */
#flow{
	.area_key{
		.area_tit{
			span{
				color: $cl-orange;
			}
		}
	}
}


.flow_cont {
	max-width: 1000px;
	margin: 0 auto;
	.flow_box{
		border: solid 1px #ddd;
		background-color: #fff;
		margin-bottom: 50px;
		.float, .overflow{
		box-sizing: border-box;
		}
		.float{
			float:left;
		}
		.flow_box_step{
			color: $cl-orange;
			font-family:$font-family-pt-sans;
			border-top: solid 5px $cl-orange;
			text-align: center;
			padding:25px;
			.step{
			  font-size: $fs-20;
			  transform: scale( 1 , 1.2 );
			}
			.count{
			  font-size: $fs-48;
			  border-top: solid 2px $cl-orange;
			  line-height: normal;
			  font-family: $font-family-pt-sans;
			  transform: scale( 1 , 1.2 );
			}
		}

		.overflow{
			overflow:hidden;
		}
		.flow_box_text {
			border-top: solid 5px $cl-gray;
			.text_area{
			  border-left: solid 1px #ddd;
			  padding-left: 25px;
			  margin:25px 25px 25px 0;
			  h3{
			    color: $cl-orange;
			    font-weight: bold;
				font-size: $fs-18;
			  }
		 	  .sub_title{
			    font-weight: bold;
				font-size: $fs-18;
		 	  }
			  p{
			    margin-top: 10px;
				a{
				  color: $cl-orange;
				}
			  }
			}
		}
	}
	.flow_box:after {
	display: block;
	content: "";
	clear: both;
	}
	.flow_box:last-child{
	  margin-bottom: 0;
	}

	.text_box{
	  text-align: center;
	  margin-bottom: 50px;
	  p{
	    margin-top:10px;
	  }
	  .sub_title{
	    font-weight: bold;
		font-size: $fs-18;
	  }
	}
}

/* ---------- flow btn ---------- */
.flow_btn_box {
	font-size: 0;
	// padding: 0 0 70px;
	margin: 70px 0;
	.flow_btn {
		margin: 0 1%;
		display: inline-block;
		font-size: $font-size;
		width: 48%;
		@include btn-70;
		a {
			max-width: 400px;
			color: $cl-blue;
			font-size: $fs-15;
			border: 2px $cl-blue solid;
			&:hover {
				background: $cl-blue;
				color: #FFF;
			}
		}
		&.about {
			a {
				color: $cl-blue;
				border: 2px $cl-blue solid;
				&:hover {
					background: $cl-blue;
					color: #FFF;
				}
			}
		}
	}
}

