/*
  layout
--------------------------------------------- */

body {
	line-height: $line-height;
	font-size: $font-size;
	height: 100%;
	font-family: $font-family;
	position: relative;
	font-kerning: normal;
	box-sizing: border-box;
}

html {
	height: 100%;
}

.sp_only {
	display: block;
}

.pc_only {
	display: none!important;
}

img {
	max-width: 100%;
}

.inner {
	padding: 0 10px;
	box-sizing: content-box;
	max-width: $max-width;
	margin: 0 auto;
	position: relative;
}

.inner_min {
	padding: 0 10px;
	box-sizing: content-box;
	max-width: $min-width;
	margin: 0 auto;
	position: relative;
}

.container {
	@include clearfix;
	padding: 0;
	box-sizing: content-box;
	max-width: $max-width;
	margin: 0 auto;
	position: relative;
}
.error404 {
	.btn_normal {
		margin: 60px 0;
	}
}

// .wrapper {
	// &:before {
	// 	display: block;
	// 	content: "";
	// 	width: 100%;
	// 	height: 3px;
		// background: url(../images/miraimo/common/header-line.png) no-repeat top;
		// background-size: cover;
	//
	// 	.page-template-template-miraimo-lp & {
	// 		content: none;
	// 	}
	// }
// }


/*
  header
--------------------------------------------- */


/*
  global nav
--------------------------------------------- */

.g_nav {
	background: #333;
    height: 100vh;
    top:30px;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    width: 100%;
    z-index: 999;
    transition: all 0.5s ease-in-out;
    overflow-y: scroll;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    &.in{
    	top: 0;
	    visibility: visible;
	    opacity: 1;
    }
    & p.menu_heading{
	   	padding: 15px 5%;
	   	margin-bottom: 15px;
    	& span{
    		width: 30%;
    		display: inline-block;
    		vertical-align: middle;
    		& img{
    			max-width: 100%;
    		}
    	}
    	& a.close{
				width: 40px;
				height: 40px;
		    display: block;
		    color: #fff;
		    text-align: center;
		    text-decoration: none;
		    float: right;
		    clear: both;
		    border: 0;
		    padding: 0;
    	}
    }
    & ul {
		padding: 0px 14px;
		& li{
			height: 50px;
			line-height: 50px;
			margin-bottom: 10px;
			&:nth-child(1) {
				a {
					border-left: 6px $cl-purple solid;
					background: #fff url(../images/miraimo/common/ico-arw-purple.png) no-repeat 96% center;
				}
			}
			&:nth-child(2) {
				a {
					border-left: 6px $cl-lightBlue solid;
					background: #fff url(../images/miraimo/common/ico-arw-blue.png) no-repeat 96% center;
				}
			}
			&:nth-child(3) {
				a {
					background: #fff url(../images/miraimo/common/ico-arw-green.png) no-repeat 96% center;
					border-left: 6px $cl-lightGreen solid;
				}
			}
			&:nth-child(4) {
				a {
					border-left: 6px $cl-green solid;
					background: #fff url(../images/miraimo/common/ico-arw-lg.png) no-repeat 96% center;
				}
			}
			&:nth-child(5) {
				a {
					border-left: 6px $cl-orange solid;
					background: #fff url(../images/miraimo/common/ico-arw-orange.png) no-repeat 96% center;
				}
			}
			&:nth-child(6) {
				border-right: none;
				a {
					border-left: 6px $cl-red solid;
					background: #fff url(../images/miraimo/common/ico-arw-red.png) no-repeat 96% center;
				}
			}
		}
	}
	a {
		background: #fff;
		display: block;
		font-size: $fs-15;
		padding: 0px 13px;
		color: #000;
		text-align: left;
		text-decoration: none;
		&:hover {
			color: $cl-blue;
			background: #fff;
		}
	}
	a.close{
		background: transparent;
	}
}

a#open_menu{
	width: 40px;
	height: 40px;
	background: transparent;
	position: absolute;
	top: 11px;
	right: 5%;
	display: block;
	color: #fff;
	text-align: center;
	text-decoration: none;
	border: 0px;
	z-index: 999;

	.page-template-template-miraimo-lp-thankyou & {
		display: none;
	}
}


/*
  footer
--------------------------------------------- */

.footer {
	text-align: center;
	margin: 0 auto;
	&_sns {
		ul {
			text-align: center;
			li {
				display: inline-block;
				padding: 0;
				margin: 20px 4px;
				a {
					width: 40px;
					height: 40px;
					display: block;
					text-align: center;
					margin: 0 auto;
					img {
						text-align: center;
						margin: 0 auto;
						padding: 10px 0;
					}
				}
			}
		}
	}
	&_nav {
		border-bottom: 1px solid #e5e5e5;
		ul {
			text-align: center;
			li {
				padding: 1.5em 0;
				font-size: $fs-12;
				display: inline-block;
				&:after {
					content: "　｜　";
					color: #e5e5e5;
				}
				&:last-child:after {
					content: none;
				}
				a {
					@include fontsize(12);
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
	.footer_logo{
		margin: 0 auto;
		a{
			display: inline-block;
		}
		img{
			width: 150px;
		}
	}
	.copyright {
		color: $cl-gray;
		font-size: $fs-10;
		padding: 30px 0 20px;
	}
}


/*
  common area
--------------------------------------------- */

.area_tit {
	text-align: center;
	font-size: $fs-20;
	font-family: $font-family-sans;
	letter-spacing: .075em;
	line-height: 1.6;
	padding: 20px 0 14px;
	span {
		display: block;
		font-size: 12px;
		font-family: $font-family-pt-sans;
		letter-spacing: .1em;
	}
}


/* ---------- area guide ---------- */

.area_guide {
	@include gr-green;
	.area_tit {
		span {
			color: $cl-green;
		}
	}
	&_cont {
		font-size: 0;
	}
	&_step {
		width: 100%;
		display: inline-block;
		margin: 0 1% 12px 0;
		a {
			display: block;
			background: #fff;
			border-radius: 4px;
			padding: 10px 4%;
			font-size: $font-size;
			text-decoration: none;
			border: 2px #fff solid;
			&:hover {
				color: $cl-green;
				border: 2px $cl-green solid;
				background: rgba(255, 255, 255, .7);
			}
		}
		&:nth-child(even) {
			margin-right: 0;
		}
		&_point {
			color: $cl-green;
			font-family: $font-family-pt-sans;
			display: block;
			border-bottom: 1px $cl-green solid;
			line-height: 23px;
			padding-bottom: 4px;
			margin-bottom: 4px;
		}
		&_txt {
			font-size: $fs-12;
			display: inline-block;
		}
		&_btn {
			padding: 10px 0 30px;
			a {
				font-family: $font-family-sans;
				width: 88%;
				font-size: $fs-12;
				text-align: center;
				text-decoration: none;
				display: block;
				color: #fff;
				@include gr-btn-green;
				// line-height: 50px;
				border-radius: 25px;
				margin: 0 auto;
				line-height: 1;
				padding: 6px 0px 7px;
				&:hover {
					opacity: .6;
				}
				img{
					display: inline-block;
					vertical-align: middle;
					margin-right: 10px;
				}
			}
		}
	}
}


/* ---------- area job-information ---------- */

.area_job-information {
	.area_tit {
		span {
			color: $cl-orange;
		}
	}
	&_cont {
		padding: 10px 0 50px;
		&_box {
			width: 100%;
			padding: 10px 4%;
			border: 1px #ddd solid;
			display: block;
			.tit {
				font-size: $fs-12;
				line-height: 1.5;
				font-weight: bold;
				a {
					text-decoration: none;
					&:hover {
						color: $cl-orange;
					}
				}
			}
			.category {
				padding: 6px 10px 0 0;
				display: inline-block;
				a {
					display: inline-block;
					font-size: $fs-10;
					border: 1px $cl-lightBlue solid;
					// color: $cl-lightBlue;
					line-height: 18px;
					padding: 0 1em;
					border-radius: 13px;
					min-width: 70px;
					text-align: center;
					text-decoration: none;
					&:hover {
						background: $cl-lightBlue;
						color: #fff;
					}
				}
			}
			.tag {
				display: inline-block;
				ul {
					li {
						display: inline-block;
						line-height: 18px;
						padding-right: 1em;
					a {
						display: inline-block;
						font-size: $fs-11;
						border: 1px $cl-yellow solid;
						line-height: 18px;
						border-radius: 13px;
						padding: 0 1em;
						min-width: 70px;
						text-align: center;
						text-decoration: none;
					&:hover {
						background: $cl-yellow;
						color: #fff;
						}
					}
					}
				}
			}
		}
	}
}

/* brand_logo
--------------------------------------------- */

.area_brand_logo {
    .inner {
        padding-bottom:40px;
        ul{
            font-size: 0;
            border-top: 1px solid #eee;
            border-left: 1px solid #eee;
            li{
                text-align: left;
                display: inline-block;
                width:calc(100% / 2) ;
                border-right: 1px solid #eee;
                border-bottom: 1px solid #eee;
				img{
					min-width: 100%;
					width: 100%;
				}
            }
        }
    }
}

/* ---------- area search ---------- */

.area_search {
	background: #f9f9f9;
	padding-bottom: 30px;
	.area_sub-tit {
		font-size: $fs-20;
		text-align: center;
		font-family: $font-family-sans;
		letter-spacing: .075em;
		position: relative;
		top: -20px;
	}
	&_cont {
		width: 100%;
		.tit {
			width: 100%;
			font-weight: bold;
			display: block;
			vertical-align: top;
			padding-bottom: 10px;
		}
		&_input {
			display: block;
			padding-bottom: 20px;
			@include clearfix;
		}
		&_list {
			font-size: 0;
			display: block;
			padding-bottom: 10px;
			li {
				font-size: $font-size;
				min-width: 48%;
				display: inline-block;
				margin: 0 1% 9px;
				a {
					width: 100%;
					background: #fff;
					display: inline-block;
					padding: 0 20px;
					line-height: 28px;
					font-size: $fs-12;
					text-decoration: none;
					text-align: center;
					border-radius: 14px;
				}
			}
		}
		&_box {
			display: block;
			padding-bottom: 10px;
		}
		&_sub-tit {
			min-width: 4em;
			font-weight: bold;
			font-size: $fs-13;
			padding-bottom: 10px;
			display: table;
			white-space: nowrap;
			width: 100%;
			// opacity: 0.6;

			a {
				background: #00C44F;
		        border-radius: 3px;
				color: #FFF;
				display: block;
				line-height: 29px;
				text-decoration: none;
				text-align: center;
			}
			// &:after{
			// 	 content: '';
			// 	 display: table-cell;
			// 	 width: 94%;
			// 	 background: linear-gradient(transparent 50%, currentColor 50%, currentColor calc(50%), transparent calc(50% + 1px));
			// 	 background-clip: padding;
			// 	 border-right: 1px solid transparent;
			// }
		}
		&_list-min {
			font-size: 0;
			margin-bottom: 10px;
			li {
				font-size: $font-size;
				min-width: 48%;
				display: inline-block;
				margin: 0 1% 9px;
				a {
					width: 100%;
					background: #fff;
					display: inline-block;
					padding: 0 20px;
					line-height: 28px;
					font-size: $fs-13;
					text-decoration: none;
					text-align: center;
					border-radius: 14px;
				}
			}
		}
	}
	&_cont.freeword {
		padding-top: 20px;
		input[type="text"],button {
			height: 50px;
		}
		input[type="text"] {
			height: 50px;
			font-family: $font-family;
			padding: 10px 15px;
			border-radius: 4px 0 0 4px;
			border-top: 1px #ddd solid;
			border-bottom: 1px #ddd solid;
			border-left: 1px #ddd solid;
			border-right: none;
			width: 80%;
			font-size: $fs-12;
		    &:focus {
		      @include placeholder(transparent);
		    }
		}
		button {
			height: 50px;
			@include gr-btn-orange;
			text-align: center;
			margin: 0 auto;
			border-radius: 0 4px 4px 0;
			padding: 11px 0;
			border: none;
			float: right;
			width: 20%;
			img {
				text-align: center;
				margin: 0 auto;
			}
		}
	}
	&_cont.job-category {
		.area_search_cont_list {
			li {
				a {
					border: 1px $cl-lightBlue solid;
					&:hover {
						background: $cl-lightBlue;
						color: #fff;
					}
				}
			}
		}
	}
	&_cont.job-area {
		.area_search_cont_list-min {
			li {
				a {
					border: 1px $cl-green solid;
					&:hover {
						background: $cl-green;
						color: #fff;
					}
				}
			}
		}
	}
	&_cont.job-tag {
		.area_search_cont_list {
			li {
				a {
					border: 1px $cl-yellow solid;
					&:hover {
						background: $cl-yellow;
						color: #fff;
					}
				}
			}
		}
	}
}


/* ---------- area other ---------- */

.area_other {
	font-size: 0;
	padding: 24px 0;
	.area_other_btn {
		width: 50%;
		padding: 0 1%;
		display: inline-block;
		font-size: $font-size;
		@include btn-70;
		a {
			width: 100%;
			font-size: $font-size;
			max-width: 400px;
			color: $cl-orange;
			font-size: $fs-12;
			border: 2px $cl-orange solid;
			&:hover {
				background: $cl-orange;
			}
		}
	}
}


/* ---------- area detail ---------- */

.area_detail {
	@include gr-pink;
	padding: 16px 0 6px;
	&_cont {
		display: block;
		vertical-align: top;
		background: rgba(255, 255, 255, .9);
		border-radius: 4px;
		padding: 20px 4%;
		margin-bottom: 10px;
		width: 100%;
		height: inherit !important;
		position: relative;
		.icon {
			img {
				text-align: center;
				margin: 0 auto;
			}
		}
		.tit {
			font-family: $font-family-sans;
			font-size: $fs-20;
			letter-spacing: .05em;
			text-align: center;
			padding: 14px 0 26px;
		}
		.sub-tit {
			font-size: $fs-12;
			font-weight: bold;
			text-align: center;
			padding-bottom: 4px;
		}
		.txt {
			font-size: $fs-12;
			padding-bottom: 16px;
		}
		.btn {
			a {
				width: 80%;
				font-size: $fs-12;
				display: block;
				line-height: 50px;
				border-radius: 25px;
				text-align: center;
				color: #fff;
				text-decoration: none;
				margin: 0 auto;
				&:hover {
					opacity: .6;
				}
			}
			span {
				width: 80%;
				font-size: $fs-12;
				display: block;
				line-height: 50px;
				border-radius: 25px;
				text-align: center;
				color: #fff;
				text-decoration: none;
				margin: 0 auto;
			}
		}
	}
	&_cont.career-advice {
		.btn {
			a {
				@include gr-btn-blue;
			}
		}
	}
	&_cont.member {
		.btn {
			a {
				@include gr-btn-pink;
			}
			span {
				background: #ddd;
			}
		}
	}
}


/* ---------- area access ---------- */

.area_access {
	background: #f1f1f1;
	padding: 16px 0 6px;
	&_cont {
		display: block;
		vertical-align: top;
		background: rgba(255, 255, 255, .9);
		border-radius: 4px;
		padding: 20px 4%;
		margin-bottom: 10px;
		width: 100%;
		height: inherit !important;
		position: relative;
		.icon {
			img {
				text-align: center;
				margin: 0 auto;
			}
		}
		.tit {
			font-family: $font-family-sans;
			font-size: $fs-20;
			letter-spacing: .05em;
			text-align: center;
			padding: 14px 0 26px;
		}
		.sub-tit {
			font-size: $fs-12;
			font-weight: bold;
			text-align: center;
			padding-bottom: 4px;
		}
		.txt {
			font-size: $fs-12;
			padding-bottom: 16px;
		}
		.btn {
			a {
				width: 80%;
				font-size: $fs-12;
				display: block;
				line-height: 50px;
				border-radius: 25px;
				text-align: center;
				color: #fff;
				text-decoration: none;
				margin: 0 auto;
				&:hover {
					opacity: .6;
				}
			}
			span {
				width: 80%;
				font-size: $fs-12;
				display: block;
				line-height: 50px;
				border-radius: 25px;
				text-align: center;
				color: #fff;
				text-decoration: none;
				margin: 0 auto;
			}
		}
	}
	&_cont.osaka {
		.btn {
			a {
				border: 2px solid #110051;
				color: #110051;
			}
		}
	}
	&_cont.tokyo {
		.btn {
			a {
				border: 2px solid #110051;
				color: #110051;
			}
		}
	}
	&_cont.full {
		width: 100%;
		
		.btn {
			margin: 25px auto 0;
			position: static;

			a {
				border: 2px solid #110051;
				color: #110051;
			}
		}

		.detail {
			width: 100%;
			margin: 0 auto;
		}
	}
}


/* ---------- area btn_more ---------- */

.area_btn_more {
	display: block;
	padding: 20px 0;
	margin: 0 auto;
	text-align: center;
	a {
		width: 90%;
		display: block;
		text-align: center;
		font-size: $fs-12;
		padding-right: 40px;
		background: #fff url(../images/miraimo/common/ico-arw.png) no-repeat 92% center;
		background-size: 20px auto;
		text-decoration: none;
		line-height: 32px;
		border: 1px $color solid;
		border-radius: 17px;
		margin: 0 auto;
		&:hover {
			text-decoration: underline;
		}
	}
}


/* ---------- area blog ---------- */

.area_blog {
	padding-bottom: 10px;
	.area_tit {
		span {
			color: $cl-lightGreen;
		}
	}
	&_cont {
		font-size: 0;
		margin: 0 -10px;
		&_box {
			font-size: $font-size;
      vertical-align: top;
			text-align: left;
			display: inline-block;
			margin: 0 2% 20px;
			width: 46%;
			max-width: 292px;
			.img {
				padding-bottom: 6px;
				img {
					width: 100%;
				}
			}
			.date {
				font-size: $fs-10;
				font-weight: bold;
				line-height: 14px;
			}
			.tag {
				display: inline-block;
				font-size: $fs-10;
				text-align: center;
				padding: 0 8px;
				color: $cl-lightGreen;
				line-height: 18px;
				border: 1px $cl-lightGreen solid;
				border-radius: 9px;
			}
			.tit {
				font-size: $fs-12;
				padding: 8px 0;
				line-height: 1.5;
			}
			a {
				text-decoration: none;
				&:hover {
					color: $cl-lightGreen;
				}
			}
		}
	}
}

.single-voice {
  .area_blog {
    &_cont {
      &_box {
        a {
          &:hover {
            color: $cl-lightBlue;
          }
        }
      }
    }
  }
}


/*
  page
--------------------------------------------- */

.pages {
	border-top: 1px #ddd solid;
	.area_key {
		padding-bottom: 40px;

		.page-template-template-miraimo-lp-thankyou & {
			padding-bottom: 50px;
			border-bottom: 1px solid #dcdcdc;
		}
	}
}

.btn_normal {
	margin: 20px auto 60px;
	text-align: center;
	a {
		min-width: 180px;
		display: inline-block;
		text-align: center;
		background: #fff;
		line-height: 26px;
		border-radius: 15px;
		text-decoration: none;
		border: 2px $cl-orange solid;
		color: $cl-orange;
		&:hover {
			color: #fff;
			background: $cl-orange;
		}
	}
}
