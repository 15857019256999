@charset "utf-8";

$logo_width: 118;
$logo_height: 31;
$logo_sp_width: 100;

.header{
  .container img {
    max-width: none;
  }
}

.hdr_signup{
  a{
    font-family: $font-family-yugothic;
  }
}

.hdr_login{
  @media ( max-width : 767px ) {
    display: none;
  }
}

.list_login,
.list_logout{
  @media ( min-width : $header_break_point+px ) {
    display: none;
  }
  @media ( max-width : $header_break_point - 1px ) {
    span{
      position: relative;
      padding-left: 1.7em;
    }
  }
}
.list_login{
  span{
    &:before{
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 17px;
      height: 17px;
      margin: auto;
      background-image: url(../images/miraimo/common/icon_login.png);
      background-repeat: no-repeat;
      background-position: center 0;
    }
  }
}
.list_logout{
  span{
    &:before{
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 17px;
      height: 20px;
      margin: auto;
      background-image: url(../images/miraimo/common/icon_logout.png);
      background-repeat: no-repeat;
      background-position: center 0;
      background-size: 17px auto;
    }
  }
}

.home{
  .header{
    background-repeat: no-repeat;
    @media ( min-width : $header_break_point+px ) {
      background-image: url(../images/miraimo/common/hdr_bg.jpg);
      background-position: 90% 0;
    }
  }
}

@media ( min-width : $header_break_point+px ) {
  .global_nav_list,
  .global_nav_sns{
    display: none;
  }
  .menu_icon{
    display: none;
  }

  .header {
    height: 70px;
    .row{
      display: table;
      margin: 0 2%;
      width: 96%;
      height: 70px;
      overflow: inherit;
    }
    .header_logo{
      padding: 0 0 10px;
      display: table-cell;
      width: $logo_width+px;
      vertical-align: middle;
      img{
        pointer-events: none;
        width: $logo_width+px;
        height: $logo_height+px;
        // @media(max-width: 768px) {
        // 	width: $logo_sp_width+px;
        // 	height: ($logo_height/$logo_width)*$logo_sp_width+px;
        // 	min-height: 45px;
        // }
      }
    }

    .global_nav{
      display: table-cell;
      $val: $logo_width+px;
      width: calc(94vw - #{$val});
      vertical-align: middle;
      > ul{
        margin-left: 1em;
        padding: 0;
        li{
          float: left;
          + li{
            position: relative;
            &:before{
              display: block;
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              width: 1px;
              height: 20px;
              margin: auto;
              background-color: #e5e5e5;
            }
          }
        }
      }
      a{
        display: block;
        padding: 1em 1.5em;
        @media ( max-width : 1300px ) {
          padding: 1em .7em;
        }
      }
    }

    .hdr_signup{
      position: fixed;
      top: 0;
      right: 0;
      height: auto;
      @include bg_gradation_pink2();
      z-index: 1111;
      a{
        position: relative;
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 160px;
        @include text-writing();
        color: $color_white;
        @include fontsize(16);
        &:before{
          display: block;
          content: '';
          width: 27px;
          height: 27px;
          background-image: url(../images/miraimo/common/icon_signup.png);
          background-repeat: no-repeat;
          background-position: center 0;
          background-size: 27px auto;
        }
        span{
          // display: block;
          line-height: 1;
          margin-top: 1em;
          writing-mode: vertical-rl;
          white-space: nowrap;
        }
      }
    }

  }
}
@media all and (-ms-high-contrast:none) and ( min-width : $header_break_point+px ) { /* IE11 */
  *::-ms-backdrop, .header .hdr_signup a span {
    position: relative;
    right: 3px;
  }
}


.hdr_logout,
.hdr_login{
  position: absolute;
  top: 50%;
  left: auto;
  right: 20px;
  transform: translateY(-50%);
  margin: auto;
  z-index: 1;
  @media ( max-width : $header_break_point - 1px ) {
    display: none;
  }
  span{
    position: relative;
    padding-left: 1.7em;
    &:before{
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      background-image: url(../images/miraimo/common/icon_login.png);
      background-repeat: no-repeat;
      background-position: center 0;
    }
  }
}

.hdr_login{
  span{
    &:before{
      width: 17px;
      height: 17px;
      background-image: url(../images/miraimo/common/icon_login.png);
    }
  }
}
.hdr_logout{
  span{
    &:before{
      width: 17px;
      height: 20px;
      background-image: url(../images/miraimo/common/icon_logout.png);
      background-size: 17px auto;
    }
  }
}


@media ( max-width : $header_break_point - 1px ) {
  body{
    &.menu_open{
      position: fixed;
      width: 100%;
      top: 0px;

      .global_nav{
        top: 0;
        visibility: visible;
        opacity: 1;
        z-index: 1000;
        transform: translateX(0);
        @media ( min-width : 768px ) {
          width: calc(100% + 80px);
        }
      }
    }
  }

  .global_nav_list{
  	padding-top: 2em;
  	border-top: 1px solid #e5e5e5;
  }

  .header{
    position: relative;
    .container{
      >.row{
        position: relative;
        &:before{
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          margin: auto;
          background-color: $color_white;
          z-index: 2;
        }
      }
    }

    .hdr_signup{
      position: absolute;
      top: 0;
      right: 0;
      @include bg_gradation_pink2();
      z-index: 1111;
      a{
        display: block;
        padding: 1.35em 1em;
        color: $color_white;
        @include fontsize(12);
        letter-spacing: .1em;
      }
      i{
        display: none;
      }
    }
  }

  .header_logo {
    position: relative;
    margin: 0 auto;
    width: 150px;
    padding: 10px 1.3em;
    z-index: 99;
    // background-color: #fff;
    img {
      width: 100%;
      height: auto;
      text-align: center;
      margin: 0 auto;
    }
  }

  .global_nav{
    transform: translateX(-20%);
    background: #fff;
    height: 100vh;
    top: 0px;
    visibility: hidden;
    opacity: 0;
    position: fixed;
    width: 100%;
    z-index: 1;
    text-align: center;
    transition: all .3s ease-in-out;
    overflow-y: scroll;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    opacity: 0;
    ul{
      text-align: center;
      li{
        line-height: 2.5;
      }
    }
  }

  .global_nav_menu{
    margin: 100px 0 2em;
    a{
      font-weight: bold;
      @include fontsize(15);
    }
  }

  .global_nav_list{
    margin-bottom: 2em;
  }

  .global_nav_sns{
    display: inline-block;
    clear: both;
    li{
      margin-left: 15px;
      float: left;
    }
  }

  .menu_icon {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    z-index: 1001;
    a {
      position: relative;
      display: block;
      width: 22px;
      height: 16px;
      padding: 0;
      transition: transform .3s;
      overflow: hidden;
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 2px;
        background: #000;
        transition: transform .5s;
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
      span {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 2px;
        background: #000;
        transition: transform .5s;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
      }

      &.close{
        height: 22px;
        &:before {
          bottom: 0;
          transform: translate(0, 0) rotate(45deg);
        }
        &:after {
          top: 0;
          transform: translate(0, 0) rotate(-45deg);
        }
        span{
          display: none;
        }
      }

    }
  }
}
