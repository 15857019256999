/*
  index header
--------------------------------------------- */

.header{
	&_btn {
		position: absolute;
		top: 40px;
		right: 40px;
		ul {
			li {
				display: inline-block;
				margin-left: 10px;
				a {
					width: 114px;
					text-align: center;
					display: block;
					background: #fff;
					line-height: 26px;
					border-radius: 15px;
					text-decoration: none;
				}
			}
			.sign-up {
				a {
					border: 2px $cl-red solid;
					color: $cl-red;
					&:hover {
						color: #fff;
						background: $cl-red;
					}
				}
			}
			.login {
				a {
					border: 2px $cl-orange solid;
					color: $cl-orange;
					&:hover {
						color: #fff;
						background: $cl-orange;
					}
				}
			}
		}
	}
}


/*
  hero
--------------------------------------------- */

.hero {
	background: url(../images/miraimo/common/hero_bg.png) no-repeat;
	background-size: cover;
	height: 100vh;
	height: calc(100vh - 86px);
	overflow: hidden;
	position: relative;
	min-height: 900px;
	& .hero_inner {
    min-width: $max-width;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	& video{
	 	position: absolute;
	 	top: 0;
	 	left: 0;
		width: auto;
		height: auto;
		min-width: 100%;
		min-height: 100%;
		max-width: inherit;
	 	z-index: -1;
 	}
	&_logo {
		padding: 0 10px 40px 0;
		text-align: center;
		margin: 0 auto;
		img {
			text-align: center;
			margin: 0 auto;
		}
	}
	&_copy {
		font-size: $fs-48;
		font-family: $font-family-sans;
		letter-spacing: .25em;
		text-align: center;
		color: #393939;
	}
	&_sub-copy {
		font-size: $fs-20;
		font-family: $font-family-sans;
		letter-spacing: .2em;
		text-align: center;
		color: #393939;
		span {
			font-weight: bold;
		}
	}
	&_txt {
		font-size: $fs-16;
		letter-spacing: .1em;
		text-align: center;
		padding: 40px 0 60px;
	}
	&_btn {
		text-align: center;
		margin: 0 auto;
		&_about {
			display: inline-block;
			margin: 0 15px 15px;
			@include btn-70;
			a {
				width: 300px;
				color: $cl-purple;
				border: 2px $cl-purple solid;
				&:hover {
					background: $cl-purple;
				}
			}
		}
		&_job {
			display: inline-block;
			margin: 0 15px 15px;
			@include btn-70;
			a {
				width: 300px;
				color: $cl-orange;
				border: 2px $cl-orange solid;
				&:hover {
					background: $cl-orange;
				}
			}
		}
	}
}


/*
  index area about
--------------------------------------------- */

.area_about {
	margin-top: -100px;
	padding: 205px 0 140px;
	text-align: center;
	&_tit {
		padding-bottom: 60px;
		width: 230px;
		margin: 0 auto;
		img {
			width: 100%;
			height: auto;
			text-align: center;
			margin: 0 auto;
		}
	}
	p {
		font-size: $fs-16;
		letter-spacing: .1em;
		margin-bottom: 1.6em;
	}
	&_btn {
		margin: 0 1%;
		display: inline-block;
		@include btn-70;
		width: 48%;
		letter-spacing: normal;
		// padding-top: 40px;
		a {
			// width: 350px;
			width: 400px;
			color: $cl-blue;
			border: 2px $cl-blue solid;
			&:hover {
				background: $cl-blue;
			}
		}
		&.flow {
			a {
				color: $cl-orange;
				border: 2px $cl-orange solid;
				&:hover {
					background: $cl-orange;
					color: #FFF;
				}
			}
		}
	}
	.inner_min {
		&:last-child {
			padding-top: 40px;
			letter-spacing: -0.4em;
		}
	}
}


/*
  index area interview
--------------------------------------------- */

.area_interview {
	@include gr-blue;
	padding-bottom: 85px;
	.area_tit {
		span {
			color: $cl-lightBlue;
		}
	}
	&_tablist {
		overflow: hidden;
		margin-bottom: 40px;
	}
	&_tab {
		cursor: pointer;
		float: left;
		width: 50%;
		box-sizing: border-box;
		text-align: center;
		font-size: $fs-16;
		font-weight: bold;
		padding: 15px 0;
		border-bottom: 2px solid $cl-lightBlue;
		&.is-active {
			color: $cl-lightBlue;
			border-top: 2px solid $cl-lightBlue;
			border-right: 2px solid $cl-lightBlue;
			border-left: 2px solid $cl-lightBlue;
			border-bottom: 0;
			border-radius: 10px 10px 0 0;
			padding: 13px 0 17px;
		}
	}
	&_wrap {
		display: none;
		&.is-visible {
			display: block;
		}
	}
	&_cont {
		font-size: 0;
		// text-align: center;
		// margin: 0 -10px;
		margin: 0 -5px;
		&_box {
			width: calc(25% - 10px);
			max-width: 292px;
			background: #fff;
			display: inline-block;
			font-size: $font-size;
			text-align: left;
			margin: 0 5px 10px;
		    vertical-align: top;
			.img {
				padding-bottom: 20px;
				img {
					width: 100%;
				}
			}
			.number {
				margin: 0 24px;
				display: inline;
				color: $cl-lightBlue;
				font-family: $font-family-pt-sans;
				font-weight: bold;
				font-size: $fs-12;
				// border-bottom: 2px $cl-lightBlue solid;
			}
			.tit {
				padding: 10px 24px;
				line-height: 1.5;
				// height: 100px;
			}
			.company {
				font-size: $fs-12;
				padding: 10px 0 20px;
				color: #9d9d9d;
				font-style: italic;
				margin: 0 24px;
				// border-top: 1px dotted #C6C6C6;
				background-image: linear-gradient(to right, #C6C6C6, #C6C6C6 1px, transparent 1px, transparent 3px);
				background-size: 4px 1px;
				background-position: top;
				background-repeat: repeat-x;
			}
			a {
				text-decoration: none;
				&:hover {
					color: $cl-lightBlue;
				}
			}
		}
	}
	&_btn {
		padding-top: 30px;
		text-align: center;
		@include btn-70;
		a {
			color: $cl-lightBlue;
		    border: 2px $cl-lightBlue solid;
		    width: 346px;

		    &:hover {
		    	background: $cl-lightBlue;
		    	color: #FFF;
		    }
		}
	}
}
