/*
  job-single
--------------------------------------------- */

#single-job {
	.area_key {
		padding-bottom: 30px;
		.area_tit {
			padding: 30px 0 0;
			span {
				color: $cl-orange;
			}
		}
	}
	.job_archive_cont {
		max-width: 1000px;
		margin: 0 auto;
		.tit {
			padding-bottom: 10px;
		}
		&_box {
			margin-bottom: 30px;
			&_inner {
				background: #f9f9f9;
				border-top: 1.5px $cl-orange solid;
				padding: 20px 4% 10px;
				border-bottom: 1px #ddd solid;
				.tag_list {
					display: table-cell;
					li {
						display: inline-block;
						margin: 0 8px 8px 0;
						a {
							width: 100%;
							background: #fff;
							display: inline-block;
							padding: 0 10px;
							line-height: 20px;
							font-size: $fs-10;
							text-decoration: none;
							text-align: center;
							border-radius: 14px;
							border: 1px $cl-yellow solid;
						}
					}
				}
			}
			table {
				font-size: $fs-12;
				width: 98%;
				height: 100%;
				margin: 10px auto 40px;
				tr {
					height: 100%;
					border-bottom: 1px #ddd solid;
				}
				th,
				td {
					display: block;
					padding: 0 0 10px;

					dl {
						font-size: 0;

						dt, dd {
							font-size: 12px;
							display: inline-block;
						}
						dt {
							font-weight: bold;
						}
						dd {
							padding-left: .3em;
						}
					}
				}
				th {
					width: 100%;
					padding: 10px 0 5px;
					font-weight: bold;
				}

				.company_name.no-member {
					p {}
					.caption {
						display: block;
						font-size: $fs-12;
						&:before {
							content: "";
							display: inline-block;
							width: 16px;
							height: 16px;
							background: url(../images/miraimo/job/ico-caption.png) no-repeat;
							background-size: contain;
							margin-right: 5px;
							vertical-align: middle;
						}
						a {
							color: $cl-orange;
						}
					}
				}
			}
			.btn_detail {
				padding: 10px 0 30px;
				button[type="submit"],
				input[type="submit"],
				input[type="button"],
				a {
					font-family: $font-family-sans;
					width: 88%;
					font-size: $fs-12;
					text-align: center;
					text-decoration: none;
					display: block;
					color: #fff;
					// @include gr-btn-orange;
					background-color: #ef717d;
					background-image: linear-gradient(90deg, #ef717d 25%, #f2986e 100%);
					line-height: 50px;
					border-radius: 25px;
					margin: 0 auto;
					// border: 1px #ff9537 solid;
					border: none;
					&:hover {
						opacity: .6;
					}
					&.textlink {
						background: none;
						color: $cl-orange;
						display: inline;
						width: auto;
						font-size: inherit;
						font-weight: normal;
						text-decoration: underline;
						border: none;
						line-height: 1;
						&:hover {
							background: none;
							color: inherit;
							text-decoration: none;
						}
					}
				}
				> p {
					margin: 15px 0 0;
					line-height: 1.5;
					text-align: center;
				}
			}
		}
	}
	.page-nation {
		letter-spacing: normal;
		ul {
			li {
				a {
					border: 1px $cl-orange solid;
					color: $cl-orange;
				}
				&.current,
				&:hover {
					a {
						color: #fff;
						background: $cl-orange;
					}
				}
			}
		}
	}
}
