/*
  perfectguide-single
--------------------------------------------- */

#perfectguide-single {
	.area_key {
		.area_tit {
			span {
				color: $cl-lightGreen;
			}
		}
	}
	.area_guide-book {
		width: 100%;
		padding-bottom: 40px;
		margin: auto;
		.inner {
			padding: 0;
		}
		h3 {
			display: table;
			color: #fff;
			background-color: $cl-green;
			width: 100%;
			margin: auto;
			& span {
				display: table-cell;
				width: 82%;
				font-size: $fs-16;
				font-weight: bold;
				padding: 10px;
			}
		}
		h3:before {
			display: table-cell;
			font-family: $font-family-pt-sans;
			content: "Chapter.1";
			background-color: #000;
			font-size: $fs-13;
			color: #fff;
			padding: 10px;
			width: 18%;
			text-align: center;
			vertical-align: middle;
		}
		h4 {
			font-size: $fs-16;
			color: $cl-green;
			padding: 30px 10px 40px;
			margin: auto;
		}
		.area_guide-book_cont {
			margin: auto;
			dl {
				margin: 0 10px;
				border-top: 1px #ddd solid;
				padding-bottom: 20px;
				dt {
					font-weight: bold;
					padding: 20px 0 15px;
					padding-left: 40px;
					text-indent: -40px;

					&:before {
						display: inline-block;
					    vertical-align: middle;
					    content: "";
					    width: 30px;
					    height: 2px;
					    background: $cl-green;
					    margin-right: 10px;
					}
				}
				dd {}
			}
			// dl.cont_02 {
			// 	border-top: none;
			// }
			.cont_last {
				border-bottom: 1px solid #ddd;
			}
		}
	}
	.ordered_list {
		list-style-type: decimal;
		margin-left: 1.5em;

		li {
			// padding-left: 0.5em;
		}
	}
	.unordered_list {

		li {
			padding-left: 1em;
			text-indent: -1em;

			&:before {
				content: "・";
				}
			}
	}
	.square_list {

		li {
			padding-left: 1em;
			text-indent: -1em;

			&:before {
				content: "■";
				color: $cl-green;
			}
		}
	}
	.notes_list {

		li {
			padding-left: 1em;
			text-indent: -1em;

			&:before {
				content: "※";
			}
		}
	}

	.link_wrap {

		a {
			display: inline-block;
		}
	}
}

.count2:before {
	content: "Chapter.2" !important;
}
.count3:before {
	content: "Chapter.3" !important;
}
.count4:before {
	content: "Chapter.4" !important;
}
.count5:before {
	content: "Chapter.5" !important;
}
.count6:before {
	content: "Chapter.6" !important;
}
.count7:before {
	content: "Chapter.7" !important;
}
.count8:before {
	content: "Chapter.8" !important;
}
