/*
  job-single
--------------------------------------------- */

#single-job {
	.area_key {
		.area_tit {
			padding: 90px 0 0;
			span {
				color: $cl-orange;
			}
		}
	}
	.job_archive_cont {
		max-width: 1000px;
		margin: 0 auto;
		h3.tit {
			font-size: 24px;
			padding-bottom: 30px;
		}

		.tag_list {
			display: table-cell;
			li {
				display: inline-block;
				margin: 0 8px 9px 0;
				a {
					background: #fff;
					display: inline-block;
					padding: 0 20px;
					line-height: 26px;
					font-size: $fs-13;
					text-decoration: none;
					text-align: center;
					border-radius: 14px;
					border: 2px $cl-yellow solid;

					&:hover {
						background: $cl-yellow;
						color: #fff;
					}
				}
			}
		}

		&_box {
			margin: 40px auto 60px;
			&_inner {
				background: #f9f9f9;
				border-top: 3px $cl-orange solid;
				padding: 58px 40px;
				border-bottom: 1px #ddd solid;
			}
			table {
				height: 100%;
				margin: 30px 0;
				font-size: 1.14em;
				tr {
					height: 100%;
					border-bottom: 1px #ddd solid;
				}
				th {
					width: 210px;
					padding: 15px 0;
					font-weight: bold;
				}
				td {
					padding: 10px 0;
					p, & > div {
						padding: 10px 20px 10px 40px;
						border-left: 1px #ddd solid;
					}

					dl {
						font-size: 0;

						dt, dd {
							font-size: 16px;
							display: inline-block;
						}
						dt {
							font-weight: bold;
						}
						dd {
							padding-left: .3em;
						}
					}
				}
				.company_name.no-member {
					p {}
					.caption {
						display: block;
						font-size: $fs-12;
						color: $cl-gray;
						&:before {
							content: "";
							display: inline-block;
							width: 16px;
							height: 16px;
							background: url(../images/miraimo/job/ico-caption.png) no-repeat;
							background-size: contain;
							margin-right: 5px;
							vertical-align: middle;
						}
						a {
							color: $cl-orange;
						}
					}
				}
			}
			.btn_detail {
				padding: 33px 0 30px;
				button[type="submit"],
				input[type="submit"],
				input[type="button"],
				a {
					cursor: pointer;
					// border: 2px $cl-orange solid;
					// color: $cl-orange;
					color: #fff;
					background-color: #ef717d;
					background-image: linear-gradient(90deg, #ef717d 25%, #f2986e 100%);
					font-family: $font-family-sans;
					font-size: $fs-15;
					font-weight: bold;
					width: 400px !important;
					text-align: center;
					text-decoration: none;
					display: block;
					line-height: 70px;
					border-radius: 35px;
					margin: 15px auto 0;
					border: none;
					cursor: pointer;
					// background: #fff;
					&:hover {
						transition: .8s;
						opacity: .6;
						// color: #fff;
						// @include gr-btn-orange;
						// border: 2px $cl-orange solid;
					}
					&.textlink {
						display: inline;
						width: auto;
						font-size: inherit;
						font-weight: normal;
						text-decoration: underline;
						margin: 0;
						color: $cl-orange;
						background: none;
						background-image: none;
						border: none;
						line-height: 1;
						&:hover {
							background: none;
							color: inherit;
							text-decoration: none;
							opacity: 1;
						}
					}
				}
				> p {
					margin-top: 20px;
					text-align: center;
				}
			}
		}
	}
	.page-nation {
		letter-spacing: normal;
		ul {
			li {
				a {
					border: 1px $cl-orange solid;
					color: $cl-orange;
				}
				&.current,
				&:hover {
					a {
						color: #fff;
						background: $cl-orange;
					}
				}
			}
		}
	}
}
