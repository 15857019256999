/*
  member
--------------------------------------------- */

#member {
	.area_tit {
		span {
			color: $cl-red;
		}
	}
	.pages {
		background: none;
	}
	.container {
		padding: 0;
		width: 100%;
		.top_txt {
			width: 90%;
			margin: 0 auto;
			h2 {
				font-size: 20px;
				text-align: center;
			}
			p {
				text-align: center;
				font-size: 15px;
			}
		}
		form#member {
			button.button1 {
				font-family: $font-family-sans;
				width: 88%;
				font-size: 15px;
				font-weight: bold;
				text-align: center;
				text-decoration: none;
				display: block;
				color: #000;
				background: #ffc000;
				background: linear-gradient(to right, #ffc000 0%, #ffea00 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc000', endColorstr='#ffea00', GradientType=1);
				line-height: 50px;
				border-radius: 25px;
				margin: 0 auto;
				border: none;
			}
		}
	}
}
