/*
  interview
--------------------------------------------- */
.interview_cont {

	.interviewee {
		border: 1px #ddd solid;
		font-size: 1.2em;
		margin-bottom: 3em;
		padding: 20px 15px 10px;

		.interviewee_lead {
			margin-top: -33px;
			font-size: 1.3em;

		    span {
				background: #FFF;
				display: inline-block;
		    	padding: 0 .5em;

		    }
		}

		p {
			padding-bottom: 0 !important;
		}
	}

	p {

		img {
			width: 100%;
			height: auto;
		}

		.interview_caption {
		    display: block;
		    background: #efefef;
		    padding: 10px;
		}
	}

	#single-miraimoblog .single_cont_txt & {

		.sub-tit {

			&:before {
				background: $cl-lightBlue;
			}
		}
	}

	.list-hl {
		display: block;
		font-weight: bold;
		padding-bottom: .5em;
		text-decoration: underline;
	}
}

.single-interview .single_cont_txt .interview_cont ol li {
    font-size: 0.85em;
}

.single-interview .single_cont_txt .interview_cont ol li:before,
.single-interview .single_cont_txt .interview_cont ol li:after {
	border-color: #00b9d8;
	color: #00b9d8;
}
