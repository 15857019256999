@charset "utf-8";

.home{
	section{
		padding: 6em 0;
		@media ( max-width : 767px ) {
			padding: 3em 0;
		}
	}
}

.page_top{
	position: fixed;
	right: 1.1%;
	bottom: 260px;
	width: 30px;
	height: 38px;
	background-image: url(../images/miraimo/common/page_top.png);
	background-repeat: no-repeat;
	background-position: center 0;
	display: none;
	z-index: 1000;
	@media ( max-width : 767px ) {
		bottom: 6em;
	}
	a{
		display: block;
		height: 40px;
		cursor: pointer;
		overflow: hidden;
	}
	span{
		opacity: 0;
	}
}

.main_visual{
	position: relative;
	overflow: hidden;
	&::before {
		content: "";
		display: block;
		padding-top: calc(650 / 1360 * 100%);
	}
	@media ( max-width : 767px ) {
		&::before {
			padding-top: calc(909 / 750 * 100%);
		}
	}
	.slick-slider {
		position: relative;

		display: block;
		box-sizing: border-box;

		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}

	.slick-list {
		position: relative;

		display: block;
		overflow: hidden;

		margin: 0;
		padding: 0;
	}

	.slick-list:focus {
		outline: none;
	}

	.slick-list.dragging {
		cursor: pointer;
		cursor: hand;
	}

	.slick-slider .slick-track,
	.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		top: 0;
		left: 0;

		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	.slick-track:before,
	.slick-track:after {
		display: table;

		content: '';
	}

	.slick-track:after {
		clear: both;
	}

	.slick-loading .slick-track {
		visibility: hidden;
	}

	.slick-slide {
		display: none;
		float: left;

		height: 100%;
		min-height: 1px;
	}

	[dir='rtl'] .slick-slide {
		float: right;
	}

	.slick-slide img {
		display: block;
	}

	.slick-slide.slick-loading img {
		display: none;
	}

	.slick-slide.dragging img {
		pointer-events: none;
	}

	.slick-initialized .slick-slide {
		display: block;
	}

	.slick-loading .slick-slide {
		visibility: hidden;
	}

	.slick-vertical .slick-slide {
		display: block;

		height: auto;

		border: 1px solid transparent;
	}

	.slick-arrow.slick-hidden {
		display: none;
	}
	.main_visual_slider {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
	.main_visual_slide {
		img{
			width: 100%;
			height: auto;
		}
	}

	.main_visual_copy{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		padding: 0 10px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}

	.main_copy{
		color: #ef7f7f;
		font-size: 52px;
		text-align: center;
		line-height: 1;
		letter-spacing: .05em;
		white-space: nowrap;
		> span {
			font-weight: bold;
		}
		@media ( max-width : 767px ) {
			font-size: 32px;
			line-height: 41px;
		}
	}

	.main_copy_sub {
		color: #fff;
		font-size: 24px;
		line-height: 1.75;
		text-align: center;
		white-space: nowrap;
		margin-top: 1em;
		@media ( max-width : 767px ) {
			font-size: 16px;
			line-height: 1.625;
		}
	}

}

.slider_logo{
	overflow: hidden;
	height: 100px;
	background-color: $color_white;
	.row{
		clear: both;
		.col{
			float: left;
			text-align: center;
		}
	}

	img{
		width: 100%;
		height: auto;
	}
}

.sec_job{
	overflow: hidden;
	h3{
		margin-top: 2em;
	}
}

.sec_job_search_hotword{
	font-family: $font-family-yugothic;
	dt,
	dd{
		display: inline-block;
		@media ( max-width : 767px ) {
			@include fontsize(14);
		}
	}
	dt{
		position: relative;
		padding: 0 1em 0 1.6em;
		@include fontsize(18);
		&:before{
			display: block;
			content: '';
			position: absolute;
			top: auto;
			left: 0;
			bottom: 0;
			width: 22px;
			height: 28px;
			margin: auto;
			background-image: url(../images/miraimo/hot_word.png);
			background-repeat: no-repeat;
			background-position: center 0;
			background-size: 16px auto;
		}
	}
	dd{
		@include fontsize(16);
		color: $color_blue;
		a{
			color: $color_blue;
			@include fontsize(14);
			>span{
				text-decoration: underline;
			}
			+ a{
				position: relative;
				margin-left: 10px;
				&:before{
					display: block;
					content: ',';
					position: absolute;
					top: -6px;
					left: -10px;
					bottom: 0;
					margin: auto;
				}
			}
		}
	}
	a + a,
	a + u,
	u + a,
	u + u{
		margin-left: .5em;
	}
}

.sec_job_news,
.sec_job_search{
	padding: 0 !important;
}

.sec_job_search{
	.row{
		overflow: hidden;
		@media ( min-width : 768px ) {
			margin-left: -20px;
		}
		.col{
			position: relative;
			background-color: $color_white;
			border: 1px solid #e5e5e5;
			border-radius: 4px;
			@media ( min-width : 768px ) {
				margin-top: 2em;
				margin-bottom: 1.5em;
			}
			&:not(.sec_job_search_text){
				&:after{
					display: block;
					content: '';
					position: absolute;
					top: 0;
					right: 1em;
					bottom: 0;
					width: 5px;
					height: 5px;
					margin: auto;
					border-top: 2px solid #1299c3;
					border-right: 2px solid #1299c3;
					transform: translate(0, 0) rotate(45deg);
					transition: .5s;
					@media ( max-width : 767px ) {
						right: 1.2em;
						width: 7px;
						height: 7px;
						transform: translate(0, 0) rotate(135deg);
					}
				}
			}
		}
	}

	.box{
		@media ( min-width : 768px ) {
			margin: 0 auto;
			padding: 0 2em 1.5em;
		}
	}

	.highlight{
		@media ( max-width : 767px ) {
			position: relative;
			padding: 7% 0;
			&:before{
				display: block;
				content: '';
				position: absolute;
				top: 0;
				left: -2em;
				width: 150%;
				height: 100%;
				margin: auto;
				background-color: $color_sub;
				z-index: -1;
			}
		}
	}

	select{
		padding: 1em;
	}

	select,
	input{
		width: 100%;
		font-family: $font-family-yugothic;
		@include fontsize(16);
		@media ( max-width : 767px ) {
			@include fontsize(14);
		}
		cursor: pointer;
		text-indent: 0.01px;
		text-overflow: ellipsis;
		border: none;
		outline: none;
		background: transparent;
		background-image: none;
		box-shadow: none;
		-webkit-appearance: none;
		appearance: none;
	}

	.sec_job_search_text{
		// position: relative;
		// &:before{
		// 	display: block;
		// 	content: '';
		// 	position: absolute;
		// 	top: 0;
		// 	right: 3%;
		// 	bottom: 0;
		// 	width: 20px;
		// 	height: 20px;
		// 	margin: auto;
		// 	background-image: url(../images/miraimo/icon_search.png);
		// 	background-repeat: no-repeat;
		// 	background-position: center 0;
		// 	background-size: 20px auto;
		// }

		input{
			padding: 1.1em 2em 1.1em 1em;
		}
		button{
			position: absolute;
			top: 0;
			right: 1%;
			bottom: 0;
			border: 0;
			background-color: #fff;
			img{
				width: 20px;
				height: 20px;
			}
		}
	}

}

.sec_job_news{
	padding: 0;
	.ul{
		margin-bottom: 4em;
		border-top: 1px solid #e5e5e5;
		border-bottom: 1px solid #e5e5e5;
		overflow: hidden;
		@media ( max-width : 767px ) {
			margin-bottom: 2em;
		}
		li{
			position: relative;
			>a{
				display: block;
				padding: 2em;
				@media ( max-width : 767px ) {
					padding: 1em;
				}

				.sec_job_news_title,
				.sec_job_news_more{
					color: $color_blue;
					transition: .5s;
				}
				.sec_job_news_title_about{
					color: $color_text;
				}

				&:hover{
					.sec_job_news_title,
					.sec_job_news_more{
						color: $color_text;
						transition: .5s;
					}
					.sec_job_news_more{
						span{
							&:after{
								right: -1em;
								border-color: $color_text;
								transition: .22s;
							}
						}
					}
				}
			}

			+ li{
				border-top: 1px solid #e5e5e5;
			}
		}
	}

	.underline{
		span{
			text-decoration: underline;
			+ span{
				position: relative;
				margin-left: 10px;
				&:before{
					display: block;
					content: '、';
					position: absolute;
					top: -5px;
					left: -10px;
					bottom: 0;
					margin: auto;
				}
			}
		}
	}

	.sec_job_news_title{
		@include fontsize(18);
		font-family: $font-family-sans;
		font-weight: 500;
		@media ( min-width : 768px ) {
			width: calc(100% - 120px);
		}
		@media ( max-width : 767px ) {
			@include fontsize(16);
		}
		>a{
			color: $color_blue;
			transition: .5s;
			&:hover{
				color: $color_text;
				transition: .5s;
			}
		}
		.news{
			margin-left: 1em;
			padding: .3em .8em;
			color: $color_white;
			font-weight: normal;
			@include bg_gradation_pink();
			border-radius: 20px;
			@include fontsize(12);
			vertical-align: text-top;
			@media ( max-width : 767px ) {
				@include fontsize(8.4);
			}
		}
	}

	.sec_job_news_title_about{
		margin-top: .7em;
		@include fontsize(14);
		font-family: $font-family-yugothic;
		font-weight: normal;
		@media ( max-width : 767px ) {
			@include fontsize(12);
		}
	}

	.sec_job_news_more{
		position: absolute;
		top: 0;
		left: auto;
		right: 3.5em;
		bottom: 0;
		margin: auto;
		height: 15px;
		@media ( max-width : 767px ) {
			display: none;
		}
		span{
			position: relative;
			@include fontsize(14);
			font-family: $font-family-pt-sans;
			font-weight: bold;
			&:after{
				display: block;
				content: '';
				position: absolute;
				top: 0;
				right: -1.2em;
				bottom: 0;
				width: 5px;
				height: 5px;
				margin: auto;
				border-top: 2px solid $color_blue;
				border-right: 2px solid $color_blue;
				transform: translate(0, 0) rotate(45deg);
				transition: .22s;
			}
		}
	}
}

.sec_blog{
	position: relative;
	&:before{
		display: block;
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		margin: auto;
		background-image: url(../images/miraimo/bg_02.png);
		background-repeat: no-repeat;
		background-position: right 45px;
	}

	.col{
		margin-bottom: 3.5%;
		> a{
			display: block;

			.sec_blog_text_title{
				color: $color_blue;
				transition: .5s;
			}
			&:hover{
				.sec_blog_text_title{
					color: $color_text;
					transition: .5s;
				}
			}
		}
	}

	.sec_blog_img,
	.sec_blog_text{
		float: left;
	}
	.sec_blog_img{
		width: 40%;
		.ofi{
			img{
				max-width: 130%;
		    max-height: 130%;
			}
		}
	}
	.sec_blog_text{
		padding: 0 1em;
		width: 60%;
		font-family: $font-family-sans;
	}

	.sec_blog_text_data{
		display: block;
		margin-bottom: .7em;
		font-family: $font-family-yugothic;
		@include fontsize(14);
		@media ( max-width : 767px ) {
			margin-bottom: .3em;
			@include fontsize(12);
		}
	}

	.sec_blog_text_title{
		line-height: 1.4;
		@include fontsize(18);
		font-weight: 500;
		@media ( max-width : 767px ) {
			@include fontsize(16);
		}
	}
}

.sec_guide{
	.text-center {
		font-size: 16px;
		line-height: 26px;
		@media ( max-width : 767px ) {
			font-size: 13px;
			line-height: 24px;
		}
	}
	
	$radius: 12; //角丸

	.row{
		margin-top: 3em;
		> .col{
			// @media ( min-width : 768px ) {
			// 	@media ( max-width : 1000px ) {
			// 		width: calc((100% / 2) - 20px);
			// 	}
			// }
			@media ( min-width : 768px ) {
				@media ( max-width : 1200px ) {
					width: calc((100% / 3) - 20px);
				}
			}
		}

	}
	.sec_guide_link{
		position: relative;
		display: block;
		transition: .5s;
		background-color: $color_sub;
		border-radius: $radius+px;
		@media ( min-width : 768px ) {
			height: 100%;
			min-height: 190px;
			// @include list-center();
		}
		&:hover{
			box-shadow: 0 0 15px rgba(0,0,0,.3);
			border-radius: $radius+px;
			.sec_guide_box_text{
				color: $color_text;
				transition: .5s;
				&::after {
					border-color: $color_text;
					transition: .5s;
				}
			}
		}
	}

	.sec_guide_box{
		width: 100%;
		@media ( min-width : 768px ) {
			padding: 2.8em 2em;
			text-align: center;
		}
	}

	.sec_guide_box_title{
		@include fontsize(20);
		font-weight: bold;
		line-height: 1.3;
		text-align: center;
		font-family: $font-family-sans;
		@media ( max-width : 767px ) {
			font-size: 16px;
		}
	}

	.sec_guide_box_text{
		color: $color_blue;
		@include fontsize(16);
		line-height: 1.5;
		font-family: $font-family-sans;
		margin-top: .75em;
		&::after {
			content: "";
			display: inline-block;
			width: 6px;
			height: 6px;
			border-right: 2px solid $color_blue;
			border-bottom: 2px solid $color_blue;
			transform: rotate(-45deg);
			position: relative;
			bottom: 2px;
		}
		@media ( max-width : 767px ) {
			@include fontsize(16);
			line-height: 1.4;
		}
	}

	@media ( max-width : 767px ) {
		.sec_guide_box{
			padding: 1.5em .5em;
		}
		.sec_guide_box_title {
			font-size: 14px;
			line-height: 1.3;
			height: auto;
		}
		.sec_guide_box_text{
			font-size: 12px;
			text-align: center;
			margin-top: .5em;
			&::after {
				width: 4px;
				height: 4px;
			}
		}
	}
}

.sec_intro {
	background-image: url(../images/miraimo/bg_01.png);
	background-repeat: no-repeat;
	background-position: right bottom -60px;
	p {
		font-family: $font-family-yugothic;
		font-size: 18px;
		line-height: 42px;
		text-align: center;
	}
	@media ( max-width : 767px ) {
		margin: auto;
    width: 90%;
		p {
			font-size: 13px;
			line-height: 24px;
		}
	}
}

.sec_advice{
	position: relative;
	&:before{
		display: block;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: auto;
		background-image: url(../images/miraimo/bg_03.png);
		background-repeat: no-repeat;
		background-position: 0 bottom;
	}

	@include bg_gradation_pink();
	p,
	a{
		color: $color_white;
	}

	h2{
		position: relative;
		span{
			display: inline-block;
			color: $color_white;
			font-weight: bold;
		}
	}
	p{
		&:not(.more){
			font-family: $font-family-yugothic;
			@include fontsize(16);
			line-height: 2;
			@media ( max-width : 767px ) {
				@include fontsize(12);
			}
		}
	}

	.more{
		a{
			background: inherit;
			border: 1px solid $color_white;
			&:hover{
				background-color: $color_sub;
			}
		}
	}

	.sec_advice_img{
		margin: .7em 0;
		width: 100%;
		text-align: center;
		letter-spacing: -.4em;
		// clear: both;
		overflow: hidden;
		@media ( max-width : 767px ) {
			margin: 1em 0;
		}
		li{
			// float: left;
			display: inline-block;
			letter-spacing: normal;
			text-align: center;
			// width: calc(100% / 4);

			&:last-child {
				margin-right: 0;
			}

			@media ( max-width : 767px ) {
				width: 25%;

				figure {
					width: 90%;
				}

				&:nth-child(even) {
					margin-right: 0;
				}
			}

			@media ( min-width : 768px ) {
				margin-right: 20px;
				// width: calc((100% - 120px) / 6);
			}
			// + li{
			// 	@media ( min-width : 768px ) {
			// 		margin-left: 20px;
			// 	}
			// }
		}

		figure{
			@media ( max-width : 767px ) {
				margin: 0 auto;
				width: 90%;
			}
		}
	}
}

.sec_interview{
	position: relative;
	&:before{
		display: block;
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: auto;
		background-image: url(../images/miraimo/bg_01.png);
		background-repeat: no-repeat;
		background-position: 0 0;
	}
	.col{
		> a{
			display: block;
			height: 100%;
			transition: .5s;
			.sec_interview_text{
				color: $color_blue;
				transition: .5s;
			}
			&:hover{
				background-color: #fff;
				box-shadow: 0 0 15px rgba(0,0,0,.3);
				transition: .5s;
				.sec_interview_text{
					color: $color_text;
					transition: .5s;
				}
			}
		}
	}

	.sec_interview_img{
		position: relative;
		img{
			width: 100%;
		}
		.sec_interview_img_tag{
			position: absolute;
			top: 12px;
			right: 12px;
			padding: .2em .8em;
			color: $color_white;
			background-color: $color_blue;
			border-radius: 20px;
			@include fontsize(10);
		}
	}

	.sec_interview_text{
		padding: 1em;
		line-height: 1.4;
		font-weight: 500;
		@include fontsize(18);
		font-family: $font-family-sans;
		@media ( max-width : 767px ) {
			@include fontsize(14);
		}
	}
	.sec_interview_text_category{
		display: block;
		margin-top: .7em;
		color: $color_text;
		font-family: $font-family-yugothic;
		@include fontsize(14);
		letter-spacing: - 0.08em;
		@media ( max-width : 767px ) {
			@include fontsize(12);
			letter-spacing: - 0.06em;
		}
	}

	@media ( max-width : 767px ) {
		.row{
			border-top: 1px solid #e5e5e5;
			border-bottom: 1px solid #e5e5e5;
			.col{
				margin-bottom: 0;
				padding: 1.5em 0;
				+ .col{
					border-top: 1px solid #e5e5e5;
				}
			}

			&.sp_left_img{
				margin-bottom: 2.5em;
				clear: both;
				.sec_interview_img,
				.sec_interview_text{
					float: left;
				}
				.sec_interview_img{
					width: 45%;
				}
				.sec_interview_text{
					margin-left: .6em;
					padding: 1em 0;
					width: calc(55% - .6em);
				}
			}
		}
	}
}


/*
*	add 2019.08
*/
.sec_access {
	background-color: #f5f5f5;

	@media ( max-width : 767px ) {
		.row .col {
			margin-bottom: 40px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
.access_head {
	margin-bottom: 10px;
}
.home h3.access_ttl {
	margin-bottom: .4em;
	padding-left: 1.5rem;
	font-size: 21px;
	// font-weight: bold;

	@media ( max-width : 767px ) {
		padding-left: 20px;
		font-size: 16px;
		font-weight: bold;
	}

	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		border-radius: inherit;
	}

	&::before {
		left: 0;
		width: 15px;
		height: 22px;
		margin: auto;
		background-color: inherit;
		background-image: inherit;
		background: url('../images/miraimo/common/ico_plot.png') left center no-repeat;
		background-size: contain;
	}

	&::after {
		content: none;
	}
}
.access_text {
	font-size: 16px;

	@media ( max-width : 767px ) {
		font-size: 14px;
	}
}
.access_map {
	width: 100%;
	height: 0;
	padding-top: 40.2%;
	position: relative;
	overflow: hidden;
	z-index: 1;

	@media ( max-width : 767px ) {
		padding-top: 56.25%;
	}

	iframe {
		width: 100%;
		height: 100%;
		margin: auto;
		position: absolute;
		top: -100%;
		right: -100%;
		bottom: -100%;
		left: -100%;
		z-index: 1;
	}
}
.access_map_link {
	font-size: 11px;
	text-align: right;
	
	a {
		padding-right: 32px;
		position: relative;

		&::before {
			content: '';
			margin: auto;
			width: 27px;
			height: 27px;
			background-color: #ef717d;
			background-image: linear-gradient(90deg, #ef717d 25%, #f2986e 100%);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
		}
		&::after {
			content: '';
			margin: auto;
			width: 15px;
			height: 15px;
			margin: auto;
			background: url('../images/miraimo/common/ico_zoom.png') left center no-repeat;
			background-size: contain;
			position: absolute;
			top: 0;
			right: 5px;
			bottom: 0;
			z-index: 2;
		}
	}
}
.access_notes {
	margin-top: 15px;
	font-size: 12px;

	.caution_txt + .caution_txt {
		margin-top: 0;
	}
}


.footer_subscribe {
	width: 100%;
	padding: 5px 0;
	background-color: rgba(0, 0, 0, .6);
	position: fixed;
	transition: all .3s ease-in-out;
	left: 0;
	bottom: -100%;
	z-index: 1000;

	&.is-active {
		bottom: 0;
		display: block;

		@media ( min-width : 768px ) {
			display: none;
		}
	}

	a {
		width: 100%;
		max-width: 260px;
		margin: 0 auto;
		padding: 16px 0;
		color: #fff;
		font-size: 16px;
		white-space: nowrap;
		text-align: center;
		background-color: #ef717d;
		background-image: linear-gradient(90deg, #ef717d 25%, #f2986e 100%);
		z-index: 1111;
		border-radius: 8px;

		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		align-items: center;
		-webkit-justify-content: center;
		justify-content: center;

		span {
			position: relative;
			padding-left: 30px;

			&:before {
				display: block;
				content: '';
				position: absolute;
				top: 0;
				left: 0px;
				right: auto;
				bottom: 0;
				width: 25px;
				height: 25px;
				margin: auto;
				background-image: url(../images/miraimo/common/icon_signup.png);
				background-repeat: no-repeat;
				background-position: center 0;
				background-size: contain;
			}
		}
	}
}


/*
*	add 2021.01
*/

// パスワード保護
.post-password-form {
	input{
		// width: 100%;
		font-family: $font-family-yugothic;
		@include fontsize(16);
		@media ( max-width : 767px ) {
			@include fontsize(14);
		}
		cursor: pointer;
		text-indent: 0.01px;
		text-overflow: ellipsis;
		border: none;
		outline: none;
		background: transparent;
		background-image: none;
		box-shadow: none;
		-webkit-appearance: none;
		appearance: none;
	}

	input[type="password"] {
		width: 16em;
		height: 40px;
		margin-left: 10px;
		font-family: $font-family;
		padding: 18px 30px;
		border-radius: 4px 0 0 4px;
		border: 1px #ddd solid;
		&:focus {
			@include placeholder(transparent);
		}

		@media ( max-width : 767px ) {
			width: 55%;
			margin-left: 5px;
			padding: 10px 15px;
			font-size: $fs-12;
		}
	}

	input[type="submit"] {
		width: auto;
		padding: 0 32px;
		border: 1px $cl-yellow solid;
		color: $cl-yellow;
		font-family: $font-family-sans;
		font-size: $fs-15;
		font-weight: bold;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		vertical-align: bottom;
		line-height: 38px;
		cursor: pointer;
		// background: #fff;
		color: #000;
		background: #ffc000;
		&:hover {
			opacity: .6;
			// background: linear-gradient(to right, #ffc000 0%, #ffea00 100%);
			// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc000', endColorstr='#ffea00', GradientType=1);
			// border: 2px $cl-yellow solid;
		}

		@media ( max-width : 767px ) {
			padding: 0 16px;
		}
	}
}

.custom-password-message {
	color: #cd1212;
	font-size: 12px;
	font-weight: bold;
}