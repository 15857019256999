/* ==================================================
  sp LP1
================================================== */

/*
  common
--------------------------------------------- */
.lp1 {
	overflow-x: hidden;

	img {
		max-width: 100%;
		vertical-align: top;
	}
}

.lp1-button {
	display: block;
	margin: 0 auto;
	width: 77.0667%;
}


/*
  intro
--------------------------------------------- */
.lp1-intro {
	position: relative;
	padding: 54.1333% 0 4.667%;
}

.lp1-intro_logo {
	margin: 9px 0 0 15px;
	width: 22.667%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 3;
}

.lp1-intro_balloon {
	width: 83.8667%;
	position: absolute;
	top: -8.49257%;
	right: -13.8667%;
	z-index: 2;
}

.lp1-intro_desc {
	width: 84.2667%;
	margin-left: 12.8%;
	position: relative;
	z-index: 3;
}

.lp1-intro_button {
	position: relative;
	z-index: 3;
}

.lp1-intro_person {
	width: 60.2667%;
	position: absolute;
	bottom: 0;
	left: -8.8%;
	z-index: 1;
}


/*
  user
--------------------------------------------- */
.lp1-user {
	background: #f16f7b; /* Old browsers */
	background: -moz-linear-gradient(left, #f16f7b 0%, #f4986b 100%);
	background: -webkit-linear-gradient(left, #f16f7b 0%,#f4986b 100%);
	background: linear-gradient(to right, #f16f7b 0%,#f4986b 100%);
	padding-top: 20px;
}

.lp1-user_title {
	margin: 0 auto 5px;
	width: 70.4%;
}

.lp1-user_merit {
	margin: 0 0 11px -2.667%;
	width: 100%;
}

.lp1-user_reason {
	margin-left: -13.0667%;
	width: 113.0667%;
}


/*
  jpb
--------------------------------------------- */
.lp1-job {
	padding: 20px 0 37px;
}

.lp1-job_title {
	margin: 0 auto 30px;
	width: 69.0667%;
}

.lp1-job_list {
	margin: 0 auto 9px;
	width: 89.333%;
}

.lp1-job_item {
	margin-bottom: 20px;
}

.lp1-job_desc {
	margin: 0 auto 12px;
	width: 76%;
}


/*
  voice
--------------------------------------------- */
.lp1-voice {
	background: #4490cd; /* Old browsers */
	background: -moz-linear-gradient(left, #4490cd 0%, #60b3b1 100%);
	background: -webkit-linear-gradient(left, #4490cd 0%,#60b3b1 100%);
	background: linear-gradient(to right, #4490cd 0%,#60b3b1 100%);
	padding: 40px 0 20px;
}

.lp1-voice_title {
	margin: 0 auto 27px;
	width: 76%;
}

.lp1-voice_list {
	margin: 0 auto;
}

.lp1-voice_item {
	margin-bottom: 20px;
	width: 96%;

	&:nth-child(odd) {
		margin-left: 2%;
	}

	&:nth-child(even) {
		margin-right: 2%;
		margin-left: auto;
	}
}

/*
  careeradvisor
 --------------------------------------------- */
.lp1-careeradvisor {
	padding: 20px 0 37px;
}

.lp1-careeradvisor_title {
	margin: 0 auto 29px;
	width: 73.6%;
}

.lp1-careeradvisor_list {
	margin-bottom: 12px;
}

.lp1-careeradvisor_item {
	margin-bottom: 3px;
	width: 96%;

	&:nth-child(odd) {
		margin-left: 2%;
	}

	&:nth-child(even) {
		margin-right: 2%;
		margin-left: auto;
	}
}


/*
  flow
--------------------------------------------- */
.lp1-flow {
	background: #5abe61; /* Old browsers */
	background: -moz-linear-gradient(left, #5abe61 0%, #edfa97 100%);
	background: -webkit-linear-gradient(left, #5abe61 0%,#edfa97 100%);
	background: linear-gradient(to right, #5abe61 0%,#edfa97 100%);
	padding: 40px 0;
	position: relative;
}

.lp1-flow_title {
	margin: 0 auto 29px;
	width: 34.2%;
}

.lp1-flow_image {
	margin: 0 auto;
	width: 89.333%;
}

.lp1-flow_person {
	width: 45.333%;
	position: absolute;
	right: -6.4%;
	bottom: -30.4%;
}


/*
  faq
--------------------------------------------- */
.lp1-faq {
	padding: 39px 0 23px;
}

.lp1-faq_title {
	margin: 0 auto 29px;
	width: 32%;
}

.lp1-faq_list {
	margin: 0 auto;
	width: 89.333%;
}

.lp1-faq_item {
	border-top: 1px solid #e5e5e5;
	margin-bottom: 29px;
	padding: 20px 3% 0 0;
}


/*
  form
--------------------------------------------- */
.lp1-form {
	background: #f16f7b; /* Old browsers */
	background: -moz-linear-gradient(left, #f16f7b 0%, #f4986b 100%);
	background: -webkit-linear-gradient(left, #f16f7b 0%,#f4986b 100%);
	background: linear-gradient(to right, #f16f7b 0%,#f4986b 100%);
	padding: 11px 0 40px;

	.form_cont {
		background: #fff;
		border-radius: 10px;
		margin: 0 auto;
		// padding: 22px 7.4627%;
		padding: 22px 4%;
		width: 89.333%;

		table {
			border: none;

			tr {
				border-bottom: none;
				display: block;
				margin-bottom: 20px;
			}

			th {
				background: none;
				margin-bottom: 7px;
				padding: 0;

				label {
					font-size: 12px;
					font-weight: bold;
				}

				.required {
					background: #f64a4a;
					border-radius: 2px;
					float: right;
					font-size: 10px;
					font-weight: bold;
				}
			}

			td {
				background: none;
				padding: 0;

				input {
					background: #f5f5f5;
					border-color: #e5e5e5;
					border-radius: 5px;
					font-size: 16px;
					padding: 9px 15px;
					@include placeholder(#ccc);
				}

				select {
					background-color: #f5f5f5;
					border-color: #e5e5e5;
					font-size: 16px;
					padding: 9px 15px;
				}
			}

			.error_msg {
				font-size: 12px;
			}
		}

		.lp1-submit_btn {

			input[type="submit"] {
				background: url(/assets/images/miraimo/lp1/btn_lp1_02.png) center center no-repeat;
				background-size: 100% auto;
				margin: 0;
				width: 100%;
				padding: 0;
				line-height: 1;
				border-radius: 0;
				text-indent: -9999px;
				padding-top: 21.97232%;
				height: 0;
			}
		}
	}
}

.lp1-form_title {
	margin: 0 auto 30px;
	width: 41.4%;
}

.lp1-footer {
	padding: 40px 0 98px;
}

.lp1-footer_list {
	margin-bottom: 35px;
}

.lp1-footer_item {
	margin-bottom: 15px;
	text-align: center;
	line-height: 1;

	.lp1-footer_link {
		color: #000;
		font-size: 12px;
		text-decoration: none;
	}
}

.lp1-footer_logo {
	margin: 0 auto;
	width: 22.667%;
}
