@charset "utf-8";

header,
footer{
  font-family: $font-family-yugothic;
}

header{
  position: relative;
  &.mr80{
		@media ( min-width : 1024px ) {
			margin-right: 80px;
		}
	}

  > .container{
    position: relative;
    padding: 0;
  }
}

.hdr_login{
  &.mr80{
    @media ( min-width : 768px ) {
      @media ( max-width : 1023px ) {
        margin-right: 80px;
      }
    }
  }
}

main{
	&.mr80{
		@media ( min-width : 1024px ) {
			margin-right: 80px;
			@media ( max-width : 1350px ) {
			 section > .container{
				 width: 94% !important;
			 }
		 }
		}
	}
}

ul{
 &.list-unstyled{
	 padding-left: 0;
   list-style: none;
	 ol,
	 ul {
		 padding-left: 2em;
	 }
 }
}

a{
  &.underline{
    text-decoration: underline;
  }
}

figure {
  margin: 0;
  text-align: center;

  figcaption {
    margin-top: 0.5em;
    text-align: left;
  }
}

section{
	> .container{
    padding: 0;
    @media ( max-width : $header_break_point - 1px ) {
      margin: auto;
      width: 90%;
    }
  }
}

.container{
	margin: 0 auto;
	width: 100%;
	@media ( min-width : 768px ) {
    width: 100%;
    max-width: $container+px;
		// width: $container+px;
		// min-width: $container+px;
	}
}

img {
  vertical-align: middle;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;

  &.circle {
    border-radius: 50%;
  }
}

.mb0{
	margin-bottom: 0;
}
.pa0{
  padding: 0;
}

.text-center{
	text-align: center;
}
.text-left{
	text-align: left;
}
.text-right{
	text-align: right;
}
.pc-text-center{
  @media ( min-width : 768px ) {
    text-align: center;
  }
}

.container{
	img{
		max-width: 100%;
	}
}

.highlight{
  background-color: $color_sub;
}

.clearfix{
  clear: both;
}
.pull-left{
  float: left;
}
.pc-only{
  @media ( max-width : 767px ) {
    display: none !important;
  }
}
.sp-only{
  @media ( min-width : 768px ) {
    display: none !important;
  }
}

// .underline{
//   text-decoration: underline;
// }

span.ofi{
  display: block;
  position: relative;
  overflow: hidden;
  padding-top: 75%;
}

.ofi img{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}