/*
  faq
--------------------------------------------- */
#faq{
	.area_key{
		.area_tit{
			span{
				color: $cl-orange;
			}
		}
	}
}


#faq .pages {
	background: none;
}

.faq_cont {
	margin: 0 auto;
	margin-bottom: 70px;
	.faq_box {
		margin-bottom: 25px;
		dt {
			margin-bottom: 10px;
			border-bottom: solid 1px #ddd;
			padding-bottom: 10px;
			color: $cl-orange;
			padding-left: 45px;
			text-indent: -45.5px;
			&:before {
				content: "Q";
				padding: 5px 10px;
				border-radius: 4px;
				color: #fff;
				background-color: $cl-orange;
				font-size: $fs-19;
				margin-right: 10px;
			}
			// h3 {
			// 	color: $cl-orange;
			// 	padding-left: 45px;
			// 	text-indent: -45.5px;
			// }
			// h3:before {
			// 	content: "Q";
			// 	padding: 5px 10px;
			// 	border-radius: 4px;
			// 	color: #fff;
			// 	background-color: $cl-orange;
			// 	font-size: $fs-19;
			// 	margin-right: 10px;
			// }
		}
		dd {
			p {
				font-size: $fs-12;
				padding-left: 45px;
				text-indent: -45.5px;
			}
			p:before {
				content: "A";
				padding: 5px 10px;
				border-radius: 4px;
				color: $cl-orange;
				font-size: $fs-19;
				border: solid 2px $cl-orange;
				margin-right: 10px;
			}
		}
	}
}
