/*
  careeradvisor
--------------------------------------------- */

#careeradvisor {
	.area_key {
		.area_tit {
			span {
				color: $cl-blue;
			}
		}
	}
	.container {
		margin-bottom: 35px;
		.area_advisor {
			width: 100%;
			margin: 0 auto;
			.area_advisor_cont {
				width: 100%;
				display: inline-block;
				border: solid 1px $cl-gray;
				margin: 0 0 15px 0;
				.area_advisor_cont_box {
					border-top: solid 4px $cl-blue;
					img {
						border-radius: 50%;
						margin: 25px auto 35px;
					}
					.name {
						text-align: center;
						margin: auto;
						font-size: $fs-20;
						border-top: 1px solid black;
						margin: 15px 0 0;
						text-align: center;
						padding: 0;
						margin: 0 10px;
						height: 24px;
					}
          .btn {
            padding-top: 6px;
            margin: 0 auto;
            a {
              margin: 0 auto;
              width: 60%;
              display: block;
              font-size: $fs-11;
              border: 1px $cl-blue solid;
              color: $cl-blue;
              line-height: 38px;
              padding: 0 1em;
              border-radius: 19px;
              min-width: 70px;
              text-align: center;
              text-decoration: none;
              &:hover {
                background: $cl-blue;
                color: #fff;
              }
            }
          }
					& span {
						position: relative;
						top: -20px;
						padding: 0 20px;
						margin: 40px 10px 0;
						background: white;
					}
					.career_area {
						padding: 10px 10px 25px;
						.career {
							color: $cl-blue;
						}
						p {
							font-size: $fs-12;
						}
					}
					.message_area {
						padding: 30px 10px 25px;
						border-top: solid 1px $cl-gray;
						background-color: #ebf4fc;
						.message {
							color: $cl-blue;
						}
						p {
							font-size: $fs-12;
						}
					}
				}
			}
		}
	}
}
