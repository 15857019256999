/*
  archive blog
--------------------------------------------- */

#miraimoblog {
  .area_key {
		.area_tit {
			span {
				color: $cl-lightGreen;
			}
		}
	}
	.area_blog_cont_box {
		width: 92%;
		max-width: inherit;
		margin: 0 auto 20px;
		display: block;
		.date {
			display: inline-block;
			padding-right: 10px;
		}
	}
}

#miraimoblog.post-type-archive-voice,
#miraimoblog.post-type-archive-interview {
	.area_key {
		.area_tit {
			span {
				color: $cl-lightBlue;
			}
		}
	}
}

#miraimoblog.page-template-page-interview-archive {
	.area_tit {
		span {
			color: $cl-lightBlue;
		}
	}
	.archive_sidebar_cont {
		border-color: $cl-lightBlue;
		.tit {
			color: $cl-lightBlue;
		}
	}
	.archive_sidebar_cont ul li a {
		color: $cl-lightBlue;
		&:hover {
			background: $cl-lightBlue;
		}
	}
	.area_blog_cont_box {
		.number {
			border-bottom: 0;
		}
		a {
		   &:hover {
			   color: $cl-lightBlue;
		   }
	   }
	}
	.page-nation ul li {
		a {
			border-color: $cl-lightBlue;
			color: $cl-lightBlue;
		}
		&:hover a {
			background: $cl-lightBlue;
			color: #FFF;
		}
	}
	.page-nation ul li span.current {
		background: $cl-lightBlue;
		border: 1px solid $cl-lightBlue;
		color: #FFF;
		display: block;
		text-align: center;
		width: 43px;
		line-height: 46px;
		font-size: 0.85em;
		text-decoration: none;
	}
}

.archive_category {
	width: 90%;
	line-height: 30px;
	border-radius: 15px;
	border: 1px $cl-lightGreen solid;
	text-align: center;
	color: $cl-lightGreen;
	letter-spacing: .075em;
	margin: 0 auto;
}
#miraimoblog.post-type-archive-voice,
#miraimoblog.post-type-archive-interview {
	.archive_category {
		border: 1px $cl-lightBlue solid;
		color: $cl-lightBlue;
	}
}

.archive_cont {
	width: 100%;
	margin-bottom: 30px;
}

#miraimoblog.post-type-archive-voice,
#miraimoblog.post-type-archive-interview {
	.area_blog_cont_box {
		a {
			text-decoration: none;
			&:hover {
				color: $cl-lightBlue;
			}
		}
	}
}

.area_blog_cont_box {
	.number {
		display: inline;
		color: $cl-lightBlue;
		font-family: $font-family-pt-sans;
		font-weight: bold;
		font-size: $fs-10;
		border-bottom: 2px $cl-lightBlue solid;
	}
	.company {
		font-size: $fs-10;
		color: #9d9d9d;
		font-style: italic;
		background-image: linear-gradient(to right, #C6C6C6, #C6C6C6 1px, transparent 1px, transparent 3px);
		background-size: 4px 1px;
		background-position: top;
		background-repeat: repeat-x;
		padding-top: 5px;
	}
}



/* ---------- sidebar ---------- */

.archive_sidebar {
	width: 98%;
	margin: 0 auto;
	&_cont {
		background: #f9f9f9;
		border: 1px $cl-lightGreen solid;
		padding: 10px 10px 5px;
		margin-bottom: 20px;
		.tit {
			text-align: center;
			color: $cl-lightGreen;
			padding: 0 0 10px;
		}
		ul {
			li {
				a {
					display: block;
					text-decoration: none;
					font-size: $fs-12;
					color: $cl-lightGreen;
					background: #fff url(../images/miraimo/common/ico-arw-green.png) no-repeat 96% center;
					line-height: 40px;
					padding: 0 18px;
					margin-bottom: 5px;
					&:hover {
						color: #fff;
						background: $cl-lightGreen;
					}
				}
			}
		}
	}
}
#miraimoblog.post-type-archive-interview,
#miraimoblog.post-type-archive-voice {

	.archive_sidebar {
		&_cont {
			border: 1px $cl-lightBlue solid;
			.tit {
				color: $cl-lightBlue;
			}
			ul {
				li {
					a {
						color: $cl-lightBlue;
						&:hover {
							color: #fff;
							background: $cl-lightBlue;
						}
					}
				}
			}
		}
	}
}



/* ---------- page nation ---------- */

.page-nation {
	letter-spacing: normal;
	margin: 0 auto;
	text-align: center;
	ul {
		li {
			padding: 0 1px;
			display: inline-block;
			&:nth-child(5) {
				display: none;
			}
			a {
				display: block;
				text-align: center;
				width: 43px;
				line-height: 46px;
				border: 1px $cl-lightGreen solid;
				background: #fff;
				font-size: $fs-12;
				color: $cl-lightGreen;
				text-decoration: none;
			}
			&.current,
			&:hover {
				a {
					color: #fff;
					background: $cl-lightGreen;
				}
			}
		}
	}
}


/*
  single blog
--------------------------------------------- */

#single-miraimoblog {
	.single_cont {
		width: 94%;
		margin: 0 auto;
		padding-top: 40px;
		
		strong {
			font-weight: bold;
		}
		
		&_sub {
			padding-bottom: 10px;
			@include clearfix;
		}
		&_date {
			line-height: 40px;
			display: inline-block;
			font-weight: bold;
			font-size: $fs-12;
			padding-right: 10px;
		}
		&_report {
			display: inline-block;
			line-height: 26px;
			border: 1px $cl-lightGreen solid;
			border-radius: 14px;
			font-size: $fs-11;
			text-align: center;
			padding: 0 10px;
			color: $cl-lightGreen;
		}
		&_sns {
			text-align: center;
			ul {
				padding: 20px 0;
				li {
					width: 38px;
					display: inline-block;
					padding-left: 6px;
					vertical-align: middle;
				}
			}
		}
		&_tit {
			font-size: $fs-18;
			padding-bottom: 30px;
		}
		&_tag {
			padding-bottom: 10px;
			border-bottom: 1px #ddd solid;
			li {
				display: inline-block;
        margin-right: 10px;
				a {
					display: inline-block;
					line-height: 23px;
					border: 1px #ddd solid;
					border-radius: 14px;
					font-size: $fs-10;
					text-align: center;
					padding: 0 20px;
					background: #f9f9f9;
					color: $cl-lightGray;
				}
			}
			.company {
			    color: #9d9d9d;
			}
		}
		&_img {
			padding-bottom: 20px;
			img {
				width: 100%;
				height: auto;
			}
		}
		&_txt {
			padding-bottom: 30px;
			p {
				font-size: $fs-12;
				padding-bottom: 2em;
			    word-wrap: break-word;
			}
			img {
				height: auto;
			}
			.sub-tit {
				font-size: $fs-17;
				padding-bottom: 20px;
				line-height: 1.5;
				padding-left: 40px;
			    text-indent: -40px;
				&:before {
					display: inline-block;
					vertical-align: middle;
					content: "";
					width: 30px;
					height: 2px;
					background: $cl-lightGreen;
					margin-right: 10px;
				}
			}
			.list_number {
				padding-bottom: 30px;
				li {
					line-height: 22px;
					padding-bottom: 10px;
					text-indent: -35px;
					padding-left: 34px;
					&:nth-child(1):before {
						display: inline-block;
						vertical-align: top;
						color: $cl-lightGreen;
						width: 22px;
						line-height: 22px;
						text-align: center;
						text-indent: initial;
						content: "1";
						border: 1px $cl-lightGreen solid;
						border-radius: 4px;
						margin: 0 10px 0 0;
					}
					&:nth-child(2):before {
						display: inline-block;
						vertical-align: top;
						color: $cl-lightGreen;
						width: 22px;
						line-height: 22px;
						text-align: center;
						text-indent: initial;
						content: "2";
						border: 1px $cl-lightGreen solid;
						border-radius: 4px;
						margin: 0 10px 0 0;
					}
					&:nth-child(3):before {
						display: inline-block;
						vertical-align: top;
						color: $cl-lightGreen;
						width: 22px;
						line-height: 22px;
						text-align: center;
						text-indent: initial;
						content: "3";
						border: 1px $cl-lightGreen solid;
						border-radius: 4px;
						margin: 0 10px 0 0;
					}
				}
			}
			.list_mark {
				padding-bottom: 30px;
				li {
					line-height: 22px;
					margin-bottom: 10px;
					text-indent: -32px;
					padding-left: 32px;
					&:before {
						display: inline-block;
						vertical-align: top;
						width: 22px;
						height: 22px;
						content: " ";
						padding: 0 10px 2px 0;
						background: url(../images/miraimo/common/ico-mark.png) no-repeat left top;
					}
				}
			}
			.caption {
				&:before {
					display: inline-block;
					width: 21px;
					height: 21px;
					content: " ";
					background: url(../images/miraimo/common/ico-caption.png) no-repeat left top;
					padding-left: 11px;
				}
				p {
					display: inline;
					vertical-align: top;
					line-height: 21px;
					padding-bottom: 0;
					color: $cl-lightGray;
					font-style: oblique;
				}
				&:after {
					display: inline-block;
					width: 21px;
					height: 21px;
					content: " ";
					background: url(../images/miraimo/common/ico-caption-end.png) no-repeat right top;
					padding-right: 11px;
				}
			}

			img {
				width: 100%;
				height: auto;
			}
		}
		&_sns-bot {
			border-top: 1px #ddd solid;
			border-bottom: 1px #ddd solid;
			text-align: center;
			ul {
				padding: 20px 0;
				li {
					width: 38px;
					display: inline-block;
					padding-left: 6px;
					vertical-align: middle;
				}
			}
		}
		&_word {
			padding: 20px 6% 24px;
			background: #f9f9f9;
			ul {
				li {
					display: inline-block;
					margin-right: 1em;
					a {
						color: $cl-lightGreen;
					}
				}
			}
		}
		&_author {
			padding-top: 30px;
			&_img {
				margin: 0 auto 10px;
				img {
					border-radius: 60px;
					text-align: center;
					margin: 0 auto;
				}
			}
			&_box {
				width: 100%;
				&_tit {
					background: url(../images/miraimo/common/ico-pen.png) no-repeat;
					min-height: 30px;
					padding: 4px 34px 6px;
					color: $cl-lightGreen;
					border-bottom: 1px $cl-lightGreen solid;
					margin-bottom: 20px;
					text-align: center;
				}
				&_name {
					text-align: center;
					font-size: $fs-18;
					padding-bottom: 20px;
					span {
						padding-left: 20px;
						font-size: 12px;
					}
				}
				&_txt {
					font-size: $fs-12;
					color: $cl-lightGray;
				}
				&_link {
					margin: 0 auto;
					padding-top: 20px;
					text-align: center;
					a {
						margin: 0 auto;
						width: 90%;
						display: block;
						text-decoration: none;
						font-size: $fs-12;
						border: 1px $cl-lightGreen solid;
						border-radius: 22.5px;
						line-height: 43px;
						color: $cl-lightGreen;
					}
				}
				.career_area {
					margin-bottom: 1em;
					h4 {
						color: $cl-lightGreen;
						font-size: $fs-15;
						font-weight: bold;
					}
				}
				.message_area {
					h4 {
						color: $cl-lightGreen;
						font-size: $fs-15;
						font-weight: bold;
					}
				}
			}
		}
	}
	.area_blog {
		padding-top: 40px;
		.sub-tit {
			font-size: $fs-20;
			font-family: $font-family-sans;
			letter-spacing: .075em;
			text-align: center;
			padding: 20px 0;
			border-top: 1px #ddd solid;
		}
	}
	&.single.voice {

		.area_blog {
			padding-bottom: 0;
		}
	}
}

#single-miraimoblog.single-voice {
	.single_cont {
		&_report {
			border: 1px $cl-lightBlue solid;
			color: $cl-lightBlue;
		}
		&_txt {
			.sub-tit {
				&:before {
					background: $cl-lightBlue;
				}
			}
			.list_number {
				li {
					&:nth-child(1):before {
						color: $cl-lightBlue;
						border: 1px $cl-lightBlue solid;
					}
					&:nth-child(2):before {
						color: $cl-lightBlue;
						border: 1px $cl-lightBlue solid;
					}
					&:nth-child(3):before {
						color: $cl-lightBlue;
						border: 1px $cl-lightBlue solid;
					}
				}
			}
		}
		&_word {
			ul {
				li {
					a {
						color: $cl-lightBlue;
					}
				}
			}
		}
		&_author {
			&_box {
				&_tit {
					color: $cl-lightBlue;
					border-bottom: 1px $cl-lightBlue solid;
				}
				&_link {
					a {
						border: 1px $cl-lightBlue solid;
						color: $cl-lightBlue;
					}
				}
			}
		}
	}
}
