@charset "utf-8";

// 矢印
.add_arrow{
	position: relative;
	display: block;
	&:after{
		display: block;
		content: '';
		position: absolute;
		top: 0;
		right: 1.2em;
		bottom: 0;
		width: 11px;
		height: 11px;
		margin: auto;
		border-top: 1px solid $color_button_arrow;
		border-right: 1px solid $color_button_arrow;
		transform: translate(0, 0) rotate(45deg);
	}
}

.more{
	margin: 0 auto;
	text-align: center;
	clear: both;
	a{
		display: inline-block;
		margin: auto;
		padding: 1.1em;
		width: 100%;
		max-width: 390px;
		color: $color_more_text;
		@include bg_gradation_blue();
		border-radius: $button_radius+px;
		transition: .5s;
		font-family: $font-family-sans;
		// font-weight: 500;
		@include fontsize(18);
		@media ( max-width : 767px ) {
			margin: 0 auto;
			min-width: 90%;
			@include fontsize(14);
		}
		&:hover{
			color: $color_text;
			background: inherit;
			box-shadow: 0 0 15px rgba(0,0,0,.3);
			transition: .5s;
			span{
				&:after{
					border-color: $color_text;
					transition: .5s;
				}
			}
		}
	}

	span{
		position: relative;
		&:after{
			display: block;
			content: '';
			position: absolute;
			top: 0;
			right: -1.2em;
			bottom: 0;
			width: 5px;
			height: 5px;
			margin: auto;
			border-top: 2px solid $color_button_arrow;
			border-right: 2px solid $color_button_arrow;
			transform: translate(0, 0) rotate(45deg);
			transition: .5s;
		}
	}
}
