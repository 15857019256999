/*
  perfectguide-archive
--------------------------------------------- */

#perfectguide {
	.area_key {
		p {
			width: 800px;
			margin: auto;
		}
		.area_tit {
			span {
				color: $cl-green;
			}
		}
	}
	.area_guide-book {
		margin-bottom: 100px;
		.area_guide_cont {
			font-size: 0;
		}
		.area_guide_step {
			width: 49.5%;
			display: inline-block;
			margin: 0 1% 12px 0;
			a {
				display: block;
				background: #fff;
				border-radius: 4px;
				padding: 20px 16px;
				font-size: $font-size;
				text-decoration: none;
				border: 2px $cl-green solid;
				&:hover {
					color: $cl-green;
					border: 2px $cl-green solid;
					background: rgba(255, 255, 255, .7);
				}
			}
			&:nth-child(even) {
				margin-right: 0;
			}
			&_point {
				color: $cl-green;
				font-family: $font-family-pt-sans;
				display: inline-block;
				background: url(../images/miraimo/common/step-line.png) no-repeat right center;
				line-height: 23px;
				padding-right: 24px;
				margin-right: 20px;
			}
			&_txt {
				display: inline-block;
			}
			&_btn {
				padding: 33px 0 85px;
				a {
					font-family: $font-family-sans;
					font-size: $fs-15;
					width: 450px;
					text-align: center;
					text-decoration: none;
					display: block;
					color: #fff;
					@include gr-btn-green;
					line-height: 70px;
					border-radius: 35px;
					margin: 0 auto;
					&:hover {
						opacity: .6;
					}
				}
			}
		}
	}
}
