/*
  job-archive
--------------------------------------------- */

#job-archive {
	.area_key {
    .area_tit {
			span {
				color: $cl-orange;
			}
		}
		.job_category {
			border-top: 1px #ddd solid;
			margin-top: 10px;
			padding-top: 10px;
			text-align: center;
			margin: 0 auto;
			span {
				font-weight: bold;
			}
		}
	}
	.job_archive_cont {
//		max-width: 1000px;
//		margin: 0 auto 20px;
		&_box {
			border: 1px #ddd solid;
			border-top: none;
			margin-bottom: 30px;
			&_inner {
				background: #f9f9f9;
				border-top: 1.5px $cl-orange solid;
				padding: 20px 4% 10px;
				border-bottom: 1px #ddd solid;
			}
			.tit {
				padding-bottom: 10px;
			}
			.tag_list {
				display: table-cell;
				li {
					display: inline-block;
					margin: 0 4px 4px 0;
					a {
						width: 100%;
						background: #fff;
						display: inline-block;
						padding: 0 10px;
						line-height: 20px;
						font-size: $fs-10;
						text-decoration: none;
						text-align: center;
						border-radius: 14px;
						border: 1px $cl-yellow solid;
					}
				}
			}
			table {
				font-size: $fs-12;
				width: 92%;
				height: 100%;
				margin: 10px auto 20px;
				tr {
					height: 100%;
					border-bottom: 1px #ddd solid;
				}
				th,
				td {
					display: block;
				}
				th {
					width: 100%;
					padding: 10px 0 5px;
					font-weight: bold;
				}
				td {
					padding: 0 0 10px;
				}
				.company_name.no-member {
					p {}
					.caption {
						display: block;
						font-size: $fs-12;
						&:before {
							content: "";
							display: inline-block;
							width: 16px;
							height: 16px;
							background: url(../images/miraimo/job/ico-caption.png) no-repeat;
							background-size: contain;
							margin-right: 5px;
							vertical-align: middle;
						}
						a {
							color: $cl-orange;
						}
					}
				}
			}
			.btn_detail {
				@include btn-70;
				margin-bottom: 40px;
				a {
					width: 80%;
					font-size: $fs-12;
					color: $cl-orange;
					border: 2px $cl-orange solid;
					&:hover {
						background: $cl-orange;
					}
				}
			}
			
			& > a {
				display: block;
				text-decoration: none;
			}
		}
		
		&_dtl {
			padding: 15px 4%;
		}

		.job_dtl_company {
			width: 100%;
			margin-bottom: 10px;
			letter-spacing: -.4em;

			dt,dd {
				display: inline-block;
				letter-spacing: normal;
			}

			dt {
				width: 3.5em;
				font-weight: bold;
			}

			dd {
				padding-left: 1em;

				&.caption {
					margin-top: .5em;
					padding-left: 1.8em;
					font-size: $fs-12;
					position: relative;
					&:before {
						content: "";
						display: inline-block;
						width: 16px;
						height: 16px;
						background: url(../images/miraimo/job/ico-caption.png) no-repeat;
						background-size: contain;
						position: absolute;
						top: .25em;
						left: 0;
					}
					a {
						color: $cl-orange;
					}
				}
			}
		}

		.job_dtl_info {
			padding-top: 10px;
			font-size: $fs-12;
			color: $cl-gray;
			letter-spacing: -.4em;
			background-image: linear-gradient(to right, #C6C6C6, #C6C6C6 1px, transparent 1px, transparent 3px);
			background-size: 4px 1px;
			background-position: top;
			background-repeat: repeat-x;

			li {
				font-style: italic;
				display: inline-block;
				letter-spacing: normal;

				&:not(:last-child)::after {
					content: '/';
					margin-right: .25em;
					padding-right: .25em;
				}
			}
		}
	}
	.page-nation {
		margin-bottom: 40px;
		letter-spacing: normal;
		ul {
			li {
				a {
					border: 1px $cl-orange solid;
					color: $cl-orange;
				}
				&.current,
				&:hover {
					a {
						color: #fff;
						background: $cl-orange;
					}
				}
			}
		}
	}
}
