/*
  registnow
--------------------------------------------- */

.form_cont {
	.area_key {
		.area_tit {
			span {
				color: $cl-red;
			}
		}
		.txt {
			width: 100%;
			margin: auto;

			+ .btn_normal {
				margin: 60px 0;

				.page-template-template-miraimo-lp-thankyou & {
					margin-bottom: 0;
				}
			}
		}
	}
}


.miraimo_flow {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .miraimo_flow_item {
    padding: 30px 1em;
    line-height: 1;
  }
  .flow_step_01 {
    width: 35%;
    font-size: 18px;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    background-color: #ef717d;
    background-image: linear-gradient(90deg, #ef717d 25%, #f2986e 100%);
    
    span {
      margin-bottom: 1.2em;
      font-size: 14px;
      font-weight: bold;
      display: block;
    }
  }
  .flow_step_join {
    line-height: 1.4;
    color: #e0787f;
    text-align: center;

    span {
      padding-top: 15px;
      display: block;
      position: relative;

      &::before {
        content: '';
        background: url('/assets/images/miraimo/img_register_arw.png') center center no-repeat;
        background-size: contain;
        width: 40.5px;
        height: 7.5px;
        margin-left: -20.25px;
        position: absolute;
        left: 50%;
        top: 0;
        z-index: 1;
      }
    }
  }
  .flow_step_02 {
    width: 35%;
    font-size: 18px;
    color: #e0787f;
    text-align: center;
    border-radius: 10px;
    border-image: linear-gradient(90deg, #ef717d 25%, #f2986e 100%);
    border: 2px solid;
    border-image-slice: 20;
    border-image-repeat: round;
    background-color: #fff;

    span {
      margin-bottom: 1.2em;
      font-size: 14px;
      font-weight: bold;
      display: block;
    }
  }
}

.txt_01 {

}

.emphasis {
  font-weight: bold;
  background: linear-gradient(transparent 40%, #ffff00 0%);
  display: inline;
  padding-bottom: 6px;
}

.entry_list {

  .entry_list_item {
		margin-bottom: 10px;
		font-size: 14px;
  }
  .entry_list_detail {
    padding-left: 1.8em;
    font-size: 12px;

    & + .entry_list_item {
      margin-top: 15px;
    }
  }
}

.other_answer {
  margin-top: 10px;
  
  label {
		font-size: 13px;
    margin-bottom: 2.5px;
    display: block;
    cursor: default;
  }
}

.form_caution {
	padding: 0 15px;
}
.caution_txt {
  padding-left: 1em;
  text-indent: -1em;

  & + & {
    margin-top: 5px;
  }
}


/*
 cont
--------------------------------------------- */

.form_cont {
	width: 100%;
	margin: 0 auto;
	margin-bottom: 50px;

	.page-template-template-miraimo-lp-thankyou & {
		margin-bottom: 0;
	}

	h3 {
		padding: 16px;
		font-weight: bold;
	}
	table {
		width: 100%;
		border: 1px solid #ddd;
		tr {
			border-bottom: 1px solid #ddd;
		}
		th {
			display: block;
			background: #f9f9f9;
			padding: 20px 15px 5px;
			width: 100%;
			line-height: 1.3;
			position: relative;
			vertical-align: top;
			span {
				font-size: 12px;
			}
			span.required {
				color: #fff;
				// border: 1px solid #cd1212;
				background: #cd1212;
				border-radius: 5px;
				// background: #fff;
				// padding: 2px 10px;
				font-size: $fs-10;
			    padding: 3px 10px;
				margin-left: 20px;
			}
		}
		td {
			display: block;
			width: 100%;
			background: #f9f9f9;
			font-size: $fs-15;
			padding: 10px 15px 30px;
		}
		td input[type="text"],
		td input[type="email"],
		td input[type="password"],
		td input[type="tel"],
		td textarea,
		td select {
			font-family: $font-family-sans;
			padding: 13px 10px;
			background: #fff;
			width: 100%;
			border: 1px #ccc solid;
		}
		td textarea {
			min-height: 200px;
		}
		td .description {
			display: block;
			font-size: $fs-12;
			width: 94%;
			margin: 0 auto;
		}
		td button {
			display: block;
			width: 94%;
			margin: 0 auto;
		}
	    td select {
	      -webkit-appearance: none;
	      -moz-appearance: none;
	      appearance: none;
	      background: #fff url("//cdn2.iconfinder.com/data/icons/picol-vector/32/arrow_sans_down-32.png") no-repeat 98% center;
	      background-size: 12px 12px;

	      &::-ms-expand {
	        display: none;
	      }
	    }
	}
	.submit_btn {
		text-align: center;
	}
	.error_msg {
	    font-weight: bold;
	    color: #cd1212;
	}
	button[type="submit"], input[type="submit"], input[type="button"] {
		font-family: $font-family-sans;
		font-size: $fs-15;
		font-weight: bold;
		width: 95%;
		text-align: center;
		text-decoration: none;
		display: block;
		color: #000;
		background: #ffc000;
		background: linear-gradient(to right, #ffc000 0%, #ffea00 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc000', endColorstr='#ffea00', GradientType=1);
		line-height: 70px;
		border-radius: 35px;
		margin: 50px auto;
		border: none;
	}
}

/*
 tml
--------------------------------------------- */

.tml {
	max-width: 100% !important;
	padding-top: 60px;
	.submit_btn {
		margin: 50px auto 10px;
	}
	p {
		margin: 0 auto 1.5em !important;
	}
	.error {
		color: #cd1212;
	    font-weight: bold;
	    padding: 0 10px;
	}
}

.tml-login .tml-rememberme-submit-wrap {
	display: block !important;
}

.tml-login .tml-submit-wrap {
	display: block !important;
	input {
		margin: 0 auto !important;
	}
}

.tml-login .tml-rememberme-wrap {
	display: block !important;
	text-align: center;
	margin: 30px 0 0 !important;

}

.tml .tml-action-links {
	width: 100%;
}

.tml .tml-action-links li {
	float: none !important;
	display: inline-block;
}

p.tml-user-pass1-wrap,
p.tml-user-pass2-wrap {
	display: table;
	width: 100%;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	margin: 0 !important;
	background: #f9f9f9;
	label {
		display: block;
		background: #f9f9f9;
		padding: 20px 15px 5px;
		width: 100%;
		line-height: 1.3;
		position: relative;
		vertical-align: top;
		&:after {
			content: "必須";
		    color: #fff;
		    background: #cd1212;
		    border-radius: 5px;
		    font-size: 0.71em;
		    padding: 3px 10px;
		    margin-left: 20px;
		}
	}
	input {
		display: block;
		width: 100%;
		background: #f9f9f9;
		font-size: $fs-15;
		margin: 10px 15px 30px;
	}
	input[type="text"],
	input[type="password"],
	input[type="email"],
	textarea,
	select {
		font-family: $font-family-sans;
		padding: 13px 10px;
		background: #fff;
		width: 100%;
		border: 1px #ccc solid;
		width: calc(100% - 30px) !important;
	}
}

.tml-user-pass1-wrap {
	#pass1 + span {
		margin: -25px 20px 20px;
		font-size: .85em;
		display: block;
	}
}

td {
  label {
    cursor: pointer;
  }
  select {
    -webkit-appearance: menulist-button;
    -webkit-appearance: textfield;
  }
}


.wpcf7-form {
  p {
    display: none;
  }
}
.wpcf7-mail-sent-ok {
	display: none !important;
}

/*
 reset password
--------------------------------------------- */

#resetpassform {

  .wp-pwd {

    .password-input-wrapper {

      #pass1-text {
        border: none;
        background: #ffffdf;
        box-shadow: none;
        font-size: $fs-13;
        margin: 5px 0;
        padding: 5px 10px;
      }
    }

    #pass-strength-result {
      font-weight: bold;

      &.short {
        color: $cl-red;
      }

      &.bad {
        color: $cl-orange;
      }

      &.bad {
        color: $cl-orange;
      }

      &.good {
        color: #000;
      }

      &.strong {
        color: $cl-green;
      }
    }
  }
}
