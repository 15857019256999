/*
  index header
--------------------------------------------- */

.header {
	// position: relative;
	// background-image: url(../images/miraimo/sp_hdr_bg.jpg);
	// background-repeat: no-repeat;
	// background-position: 0 0;
	// background-size: 45% auto;

	&_btn {
		width: 90%;
		position: absolute;
		padding: 20px 0;
		left: 50%;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		text-align: center;
		z-index: 10;
		ul {
			li {
				display: inline-block;
				a {
					width: 80px;
					text-align: center;
					display: block;
					font-size: $fs-11;
					background: #fff;
					line-height: 25px;
					border-radius: 15px;
					text-decoration: none;
				}
			}
			.sign-up {
				a {
					border: 2px $cl-red solid;
					color: $cl-red;
					&:hover {
						color: #fff;
						background: $cl-red;
					}
				}
			}
			// .login {
			// 	a {
			// 		border: 2px $cl-orange solid;
			// 		color: $cl-orange;
			// 		&:hover {
			// 			color: #fff;
			// 			background: $cl-orange;
			// 		}
			// 	}
			// }
		}
	}
}


/*
  hero
--------------------------------------------- */

.hero {
	background: url(../images/miraimo/common/hero_bg.png) center top;
	background-size: contain;
	height: 100vh;
	position: relative;
	min-height: 500px;
	& video{
	/* 	position: absolute;
	 	top: 0;
	 	left: 0;
	 	width: auto;
	 	height: 100%;
	 	z-index: -1;
	 	min-width: 100%;
	 	min-height: 100%; */
		display: none;
 	}
	.hero_inner {
		width: 100%;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	&_logo {
		width: 50%;
		text-align: center;
		margin: 0 auto;
		img {
			text-align: center;
			margin: 0 auto;
		}
	}
	&_copy {
		font-size: $fs-22;
		font-family: $font-family-sans;
		text-align: center;
		color: #393939;
		padding-top: 20px;
	}
	&_sub-copy {
		font-size: $fs-10;
		font-family: $font-family-sans;
		text-align: center;
		line-height: 1.1;
		color: #393939;
		padding-bottom: 30px;
		span {
			font-weight: bold;
		}
	}
	&_txt {
		display: none;
	}
	&_btn {
		position: relative;
		margin: 20px auto 0;
		text-align: center;
		width: 98%;
		&_about {
			width: 46%;
			display: inline-block;
			margin: 0 1%;
			@include btn-70;
			a {
				width: 100%;
				color: $cl-purple;
				border: 2px $cl-purple solid;
				&:hover {
					background: $cl-purple;
				}
			}
		}
		&_job {
			width: 46%;
			display: inline-block;
			margin: 0 1%;
			@include btn-70;
			a {
				width: 100%;
				color: $cl-orange;
				border: 2px $cl-orange solid;
				&:hover {
					background: $cl-orange;
				}
			}
		}
	}
}


/*
  index area about
--------------------------------------------- */

.area_about {
	background: #fff;
	padding: 35px 0 40px;
	text-align: center;
	&_tit {
		width: 50%;
		margin: 0 auto;
		padding-bottom: 30px;
		img {
			width: 100%;
			height: auto;
			text-align: center;
			margin: 0 auto;
		}
	}
	p {
		font-size: $fs-12;
		margin-bottom: 1.6em;
	}
	&_btn {
		@include btn-70;
		// padding-top: 20px;
		a {
			width: 80%;
			color: $cl-blue;
			border: 2px $cl-blue solid;
			&:hover {
				background: $cl-blue;
			}
		}
		&.flow {
			margin-bottom: 15px;
			a {
				color: $cl-orange;
				border: 2px $cl-orange solid;
				&:hover {
					background: $cl-orange;
					color: #FFF;
				}
			}
		}
	}
	.inner_min {
		&:last-child {
			padding-top: 20px;
		}
	}
}


/*
  index area interview
--------------------------------------------- */

.area_interview {
	@include gr-blue;
	padding-bottom: 10px;
	.area_tit {
		span {
			color: $cl-lightBlue;
		}
	}
	&_tablist {
		overflow: hidden;
		margin-bottom: 40px;
	}
	&_tab {
		cursor: pointer;
		float: left;
		width: 50%;
		box-sizing: border-box;
		text-align: center;
		font-size: $fs-12;
		font-weight: bold;
		padding: 15px 0;
		border-bottom: 2px solid $cl-lightBlue;
		&.is-active {
			color: $cl-lightBlue;
			border-top: 2px solid $cl-lightBlue;
			border-right: 2px solid $cl-lightBlue;
			border-left: 2px solid $cl-lightBlue;
			border-bottom: 0;
			border-radius: 10px 10px 0 0;
			padding: 13px 0 17px;
		}
	}
	&_wrap {
		display: none;
		&.is-visible {
			display: block;
		}
	}
	&_cont {
		text-align: center;
		margin: 0 -10px;
		&_box {
			@include clearfix;
			width: 96%;
			background: #fff;
			display: block;
			letter-spacing: normal;
			text-align: left;
			padding: 10px;
			margin: 0 2% 10px;
			.img {
				float: left;
				overflow: hidden;
				width: 45%;
				img {
					width: auto;
					// height: 100%;
					height: auto;
					max-height: 110px;
				}
			}
			.number {
				margin-left: 10px;
				display: inline;
				color: $cl-lightBlue;
				font-family: $font-family-pt-sans;
				font-weight: bold;
				font-size: $fs-10;
				// border-bottom: 2px $cl-lightBlue solid;
			}
			.tit {
				width: 51%;
				float: right;
				font-size: $fs-10;
				padding: 8px 0;
				line-height: 1.5;
			}
			.company {
				width: 51%;
				float: right;
				font-size: $fs-10;
				color: #9d9d9d;
				font-style: italic;
				background-image: linear-gradient(to right, #C6C6C6, #C6C6C6 1px, transparent 1px, transparent 3px);
				background-size: 4px 1px;
				background-position: top;
				background-repeat: repeat-x;
				padding-top: 5px;

			}
			a {
				text-decoration: none;
				&:hover {
					color: $cl-lightBlue;
				}
			}
		}
	}
	&_btn {
		padding: 10px 0;
		@include btn-70;

		a {
			width: 80%;
			color: $cl-lightBlue;
		    border: 2px $cl-lightBlue solid;

		    &:hover {
		    	color: $cl-lightBlue;
		    }
		}
	}
}
