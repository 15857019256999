@charset "utf-8";

$gutter: 20px; //grid間の隙間 px, % にも対応
$gutter_sp: 1.2em; //grid間の隙間 sp
$val: $header_break_point+px;

// レスポンシブ(%)
$grid_span_pc:(
  '1': 8.33333333333%,
  '2': 16.6666666667%,
  '3': 25%,
  '4': 33.3333333333%,
  '5': 41.6666666667%,
  '6': 50%,
  '7': 58.3333333333%,
  '8': 66.6666666667%,
  '9': 75%,
  '10': 83.3333333333%,
  '11': 90.666667%,
  '12': 100%
);

.row{
  // overflow: hidden;
  .col{
    margin-bottom: 2%;
    @media ( max-width : 767px ) {
      margin-bottom: 4%;
      width: 100%;
    }
  }
}

[class*="span_"] {
  float: left;
  min-height: 1px;
}

.container{
  &:not(.gutter){
    @media ( min-width : 768px ) {
      @each $grid, $width in $grid_span_pc {
        .span_#{$grid}{
          width: calc(#{$width});
        }
      }

      @each $grid, $width in $grid_span_pc {
        .offset_#{$grid}{
          margin-left: calc(#{$width});
        }
      }
    }

    .row{
      @media ( max-width : 767px ) {
        &.sp_column2{
          .col {
            width: 50%;
          }
        }
        &.sp_column3{
          .col {
            width: 33.33333%;
          }
        }
      }
    }

  }
}

.gutter{
  > .row {
    @media ( min-width : 768px ) {
      margin-left: - $gutter;
    }
    @media ( max-width : 767px ) {
      &.sp_column2,
      &.sp_column3{
        margin-left: - $gutter_sp;

        [class*="span_"] {
          margin-left: $gutter_sp;
        }
      }
    }
  }

  [class*="span_"] {
    @media ( min-width : 768px ) {
      margin-left: $gutter;
    }
  }

  @media ( min-width : 768px ) {
    @each $grid, $width in $grid_span_pc {
      .span_#{$grid}{
        width: calc(#{$width} - #{$gutter});
      }
    }
    @each $grid, $width in $grid_span_pc {
      .offset_#{$grid}{
        margin-left: calc(#{$width} + #{$gutter});
      }
    }
  }

  .row{
    @media ( max-width : 767px ) {
      &.sp_column2{
        .col {
          width: calc(50% - #{$gutter_sp});
        }
      }
      &.sp_column3{
        .col {
          width: calc(33.33333% - #{$gutter_sp});
        }
      }
    }
  }

}
