/*
  layout
--------------------------------------------- */

body {
	line-height: $line-height;
	font-size: $font-size;
	height: 100%;
	font-family: $font-family;
	position: relative;
	font-kerning: normal;
	box-sizing: border-box;
	min-width: 1024px;
}

html {
	height: 100%;
}

.sp_only {
	display: none;
}

.inner {
	padding: 0 10px;
	box-sizing: content-box;
	max-width: $max-width;
	margin: 0 auto;
	position: relative;
}

.inner_min {
	padding: 0 10px;
	box-sizing: content-box;
	max-width: $min-width;
	margin: 0 auto;
	position: relative;
}

.container {
	@include clearfix;
	padding: 0 10px;
	box-sizing: content-box;
	max-width: $max-width;
	margin: 0 auto;
	position: relative;
}
.error404 {
	.btn_normal {
		margin: 80px 0 100px;
	}
}


/*
  header
--------------------------------------------- */

// .header_logo {
// 	display: inline-block;
// 	width: 118px;
// 	padding: 20px 0 16px;
// 	margin: 0;
// 	img {
// 		width: 100%;
// 		height: auto;
// 		text-align: left;
// 		margin: 0 auto;
// 	}
// }


/*
  global nav
--------------------------------------------- */

.global_nav_menu{
	li{
		a{
			transition: .5s;
			&:hover{
				color: #aaaa;
				transition: .3s;
			}
		}
	}


}

.header {
	// min-width: $container+px;
	.g_nav {
		border-bottom: 1px #ddd solid;
	}
}

.g_nav {
	position: relative;
	width: 100%;
	background: #fff;
	z-index: 999999;
	ul {
		width: 100%;
		display: table;
		table-layout: fixed;
		li {
			display: table-cell;
			text-align: center;
			border-right: 1px #fff solid;
			a {
				display: block;
				line-height: 74px;
				text-decoration: none;
				font-weight: bold;
			}
			&:nth-child(1) {
				a {
					border-top: 6px $cl-purple solid;
					&:hover {
						color: #fff;
						background: $cl-purple;
					}
				}
			}
			&:nth-child(2) {
				a {
					border-top: 6px $cl-lightBlue solid;
					&:hover {
						color: #fff;
						background: $cl-lightBlue;
					}
				}
			}
			&:nth-child(3) {
				a {
					border-top: 6px $cl-lightGreen solid;
					&:hover {
						color: #fff;
						background: $cl-lightGreen;
					}
				}
			}
			&:nth-child(4) {
				a {
					border-top: 6px $cl-green solid;
					&:hover {
						color: #fff;
						background: $cl-green;
					}
				}
			}
			&:nth-child(5) {
				a {
					border-top: 6px $cl-orange solid;
					&:hover {
						color: #fff;
						background: $cl-orange;
					}
				}
			}
			&:nth-child(6) {
				border-right: none;
				a {
					border-top: 6px $cl-red solid;
					&:hover {
						color: #fff;
						background: $cl-red;
					}
				}
			}
		}
	}
}

.nav_fix {
	position: absolute;
	top: 0;
}


/*
  footer
--------------------------------------------- */

.footer {
	text-align: center;
	margin: 0 auto;
	&_sns {
		width: 50%;
		float: left;
		ul {
			margin-right: 1em;
			text-align: right;
			li {
				display: inline-block;
				padding: 1em 10px;
				// width: 59px;
			    text-align: center;
			    img {
			    	margin: 0 auto;
			    }
			}
		}
	}
	&_nav {
		display: inline-block;
		width: 50%;
		float: left;
		ul {
			margin: 1em;
			text-align: left;
			li {
				font-size: $fs-13;
				display: inline-block;
				&:after {
					content: "　｜　";
					color: #e5e5e5;
				}
				&:last-child:after {
					content: none;
				}
				a {
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.footer_logo{
		margin: 0 auto;
		text-align: center;
		clear: both;
		a{
			display: inline-block;
			text-align: center;
		}
		img{
			width: 178px;
		}
	}

	.copyright {
		color: $cl-gray;
		font-size: $fs-12;
		padding: 40px 0 30px;
	}
}


/*
  common area
--------------------------------------------- */

.area_tit {
	text-align: center;
	font-size: $fs-32;
	font-family: $font-family-sans;
	letter-spacing: .075em;
	line-height: 1.6;
	padding: 70px 0 60px;
	span {
		display: block;
		font-size: 16px;
		font-family: $font-family-pt-sans;
		letter-spacing: .1em;
	}
}


/* ---------- area guide ---------- */

.area_guide {
	@include gr-green;
	.area_tit {
		span {
			color: $cl-green;
		}
	}
	&_cont {
		font-size: 0;
	}
	&_step {
		width: 49.5%;
		display: inline-block;
		margin: 0 1% 12px 0;
		a {
			display: block;
			background: #fff;
			border-radius: 4px;
			padding: 20px 16px;
			font-size: $font-size;
			text-decoration: none;
			border: 2px #fff solid;
			&:hover {
				color: $cl-green;
				border: 2px $cl-green solid;
				background: rgba(255, 255, 255, .7);
			}
		}
		&:nth-child(even) {
			margin-right: 0;
		}
		&_point {
			color: $cl-green;
			font-family: $font-family-pt-sans;
			display: inline-block;
			background: url(../images/miraimo/common/step-line.png) no-repeat right center;
			line-height: 23px;
			padding-right: 24px;
			margin-right: 20px;
		}
		&_txt {
			display: inline-block;
		}
		&_btn {
			padding: 33px 0 85px;
			a {
				font-family: $font-family-sans;
				font-size: $fs-15;
				width: 450px;
				text-align: center;
				text-decoration: none;
				display: block;
				color: #fff;
				@include gr-btn-green;
				line-height: 70px;
				border-radius: 35px;
				margin: 0 auto;
				&:hover {
					opacity: .6;
				}
				img {
					display: inline-block;
					vertical-align: middle;
					margin-right: 10px;
				}
			}
		}
	}
}


/* ---------- area job-information ---------- */

.area_job-information {
	.area_tit {
		span {
			color: $cl-orange;
		}
	}
	&_cont {
		font-size: 0;
		margin: 20px 0 100px;
		border-left: 1px #ddd solid;
		border-right: 1px #ddd solid;
		&_box {
			width: 50%;
			max-width: 520px;
			display: inline-block;
			font-size: $font-size;
			vertical-align: top;
			&:nth-child(odd) {
				padding-right: 80px;
				border-right: 1px #ddd solid;
			}
			&:nth-child(even) {
				padding-left: 80px;
			}
			&:nth-child(1),
			&:nth-child(2) {
				padding-bottom: 40px;
			}
			.tit {
				font-size: $fs-16;
				line-height: 1.5;
				font-weight: bold;
				a {
					text-decoration: none;
					&:hover {
						color: $cl-orange;
					}
				}
			}
			.category {
				padding: 20px 20px 0 0;
				display: inline-block;
				margin-bottom: 0.5em;
				a {
					display: inline-block;
					font-size: $fs-11;
					border: 1px $cl-lightBlue solid;
					// color: $cl-lightBlue;
					line-height: 26px;
					border-radius: 13px;
					padding: 0 1em;
					min-width: 70px;
					text-align: center;
					text-decoration: none;
					&:hover {
						background: $cl-lightBlue;
						color: #fff;
					}
				}
			}
			.tag {
				display: inline-block;
				margin-bottom: 0.5em;
				ul {
					li {
						display: inline-block;
						line-height: 26px;
						padding-right: 1em;
						a {
							display: inline-block;
							font-size: $fs-11;
							border: 1px $cl-yellow solid;
							line-height: 26px;
							border-radius: 13px;
							padding: 0 1em;
							min-width: 70px;
							text-align: center;
							text-decoration: none;
							&:hover {
								background: $cl-yellow;
								color: #fff;
							}
						}
					}
				}
			}
		}
	}
}


/*
  brand_logo
--------------------------------------------- */

.area_brand_logo {
	.inner {
		padding-bottom: 100px;
		ul {
			text-align: center;
			margin: 0 auto;
			font-size: 0;
			li {
				display: inline-block;
				width: calc(100% / 5);
				border: 1px solid #eee;
				margin-left: -1px;
				margin-top: -1px;
				img {
					max-width: 100%;
					height: auto;
				}
			}
		}
	}
}


/* ---------- area search ---------- */

.area_search {
	background: #f9f9f9;
	padding-bottom: 60px;
	.area_sub-tit {
		font-size: $fs-24;
		text-align: center;
		font-family: $font-family-sans;
		letter-spacing: .075em;
		position: relative;
		top: -22px;
	}
	&_cont {
		width: 100%;
		display: table;
		.tit {
			width: 170px;
			font-weight: bold;
			display: table-cell;
			vertical-align: top;
			background: url(../images/miraimo/common/ico-arw-right-gray.png) no-repeat right 24px;
			padding: 17px 27px 16px 10px;
		}
		&_input {
			display: table-cell;
			padding: 0 25px 30px;
			@include clearfix;
		}
		&_list {
			display: table-cell;
			padding: 16px 25px;
			li {
				display: inline-block;
				margin: 0 8px 9px 0;
				a {
					background: #fff;
					display: inline-block;
					padding: 0 30px;
					line-height: 28px;
					font-size: $fs-13;
					text-decoration: none;
					text-align: center;
					border-radius: 14px;
				}
			}
		}
		&_box {
			@include clearfix;
			display: table-cell;
			padding: 16px 25px;
		}
		&_sub-tit {
			min-width: 8em;
			font-weight: bold;
			font-size: $fs-13;
			float: left;
			// padding: 20px 23px 0 0;
			padding: 0 20px 0 0;
			width: 124px;

			a {
				background: #00C44F;
		        border-radius: 3px;
				color: #FFF;
				display: block;
				line-height: 30px;
				text-decoration: none;
				text-align: center;

				&:hover {
					opacity: .6;
				}
			}
		}
		// &_sub-tit:first-child {
		// 	padding: 5px 23px 0 0;
		// }
		&_list-min {
			float: left;
			// width: 95%;
			// width: calc(100% - 8em);
			width: calc(100% - 124px);
			// margin-top: 15px;
			margin-bottom: 15px;
			li {
				display: inline-block;
				margin: 0 8px 9px 0;
				a {
					background: #fff;
					display: inline-block;
					padding: 0 30px;
					line-height: 28px;
					font-size: $fs-13;
					text-decoration: none;
					text-align: center;
					border-radius: 14px;
				}
			}
		}
		// &_list-min:first-of-type {
		// 	margin-top: 0;
		// }
		&_list-min:last-of-type {
			margin-bottom: 0;
		}
	}
	&_cont.freeword {
		padding-top: 40px;
		input[type="text"] {
			height: 58px;
			font-family: $font-family;
			padding: 18px 30px;
			border-radius: 4px 0 0 4px;
			border-top: 1px #ddd solid;
			border-bottom: 1px #ddd solid;
			border-left: 1px #ddd solid;
			width: 86%;
			box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.1)inset;
		    &:focus {
		      @include placeholder(transparent);
		    }
		}
		button {
			height: 58px;
			@include gr-btn-orange;
			text-align: center;
			margin: 0 auto;
			border-radius: 0 4px 4px 0;
			padding: 20px 0;
			border: none;
			float: right;
			width: 14%;
			cursor: pointer;
			img {
				text-align: center;
				margin: 0 auto;
			}
		}
	}
	&_cont.job-category {
		.area_search_cont_list {
			li {
				a {
					border: 1px $cl-lightBlue solid;
					&:hover {
						background: $cl-lightBlue;
						color: #fff;
					}
				}
			}
		}
	}
	&_cont.job-area {
		.area_search_cont_list-min {
			li {
				a {
					border: 1px $cl-green solid;
					&:hover {
						background: $cl-green;
						color: #fff;
					}
				}
			}
		}
	}
	&_cont.job-tag {
		.area_search_cont_list {
			li {
				a {
					border: 1px $cl-yellow solid;
					&:hover {
						background: $cl-yellow;
						color: #fff;
					}
				}
			}
		}
	}
}


/* ---------- area other ---------- */

.area_other {
	font-size: 0;
	padding: 56px 0;
	.area_other_btn {
		margin: 0 1%;
		display: inline-block;
		font-size: $font-size;
		width: 48%;
		@include btn-70;
		a {
			max-width: 400px;
			color: $cl-orange;
			font-size: $fs-15;
			border: 2px $cl-orange solid;
			&:hover {
				background: $cl-orange;
			}
		}
	}
}


/* ---------- area detail ---------- */

.area_detail {
	@include gr-pink;
	font-size: 0;
	padding: 70px 0;
	&_cont {
		font-size: $font-size;
		display: inline-block;
		vertical-align: top;
		background: rgba(255, 255, 255, .9);
		border-radius: 4px;
		padding: 40px 50px;
		width: 49%;
		position: relative;
		.icon {
			img {
				text-align: center;
				margin: 0 auto;
			}
		}
		.tit {
			font-family: $font-family-sans;
			font-size: $fs-24;
			letter-spacing: .05em;
			text-align: center;
			padding: 20px 0 40px;
		}
		.sub-tit {
			font-weight: bold;
			text-align: center;
			padding-bottom: 4px;
		}
		.txt {
			padding-bottom: 106px;
			&.pb0 {
				padding-bottom: 0;
			}
		}
		.btn {
			position: absolute;
			bottom: 40px;
			left: 50%;
			margin-left: -150px;
			// padding-top: 106px;
			a {
				width: 300px;
				display: block;
				line-height: 70px;
				border-radius: 35px;
				text-align: center;
				color: #fff;
				text-decoration: none;
				margin: 0 auto;
				&:hover {
					opacity: .6;
				}
			}
			span {
				width: 300px;
				display: block;
				line-height: 70px;
				border-radius: 35px;
				text-align: center;
				color: #fff;
				text-decoration: none;
				margin: 0 auto;
			}
		}
	}
	&_cont.career-advice {
		margin-right: 1%;
		.btn {
			a {
				@include gr-btn-blue;
			}
		}
	}
	&_cont.member {
		margin-left: 1%;
		.btn {
			a {
				@include gr-btn-pink;
			}
			span {
				background: #ddd;
			}
		}
	}
}

/* ---------- area access ---------- */

.area_access {
	background: #f1f1f1;
	font-size: 0;
	padding: 70px 0;
	&_cont {
		font-size: $font-size;
		display: inline-block;
		vertical-align: top;
		background: rgba(255, 255, 255, .9);
		border-radius: 4px;
		padding: 40px 50px;
		width: 49%;
		position: relative;
		.icon {
			img {
				text-align: center;
				margin: 0 auto;
			}
		}
		.tit {
			font-family: $font-family-sans;
			font-size: $fs-24;
			letter-spacing: .05em;
			text-align: center;
			padding: 20px 0 40px;
		}
		.sub-tit {
			font-weight: bold;
			text-align: center;
			padding-bottom: 4px;
		}
		.txt {
			padding-bottom: 106px;
			&.pb0 {
				padding-bottom: 0;
			}
		}
		.btn {
			position: absolute;
			bottom: 40px;
			left: 50%;
			margin-left: -150px;
			// padding-top: 106px;
			a {
				width: 300px;
				display: block;
				line-height: 70px;
				border-radius: 35px;
				text-align: center;
				color: #fff;
				text-decoration: none;
				margin: 0 auto;
			}
			span {
				width: 300px;
				display: block;
				line-height: 70px;
				border-radius: 35px;
				text-align: center;
				color: #fff;
				text-decoration: none;
				margin: 0 auto;
			}
		}
	}
	&_cont.osaka {
		margin-top: 20px;
		margin-right: 1%;
		.btn {
			a {
				border: 2px solid #110051;
				color: #110051;
				&:hover {
					background: #110051;
					color: #FFF;
				}
			}
		}
	}
	&_cont.tokyo {
		margin-top: 20px;
		margin-left: 1%;
		.btn {
			a {
				border: 2px solid #110051;
				color: #110051;
				&:hover {
					background: #110051;
					color: #FFF;
				}
			}
		}
	}
	&_cont.full {
		width: 100%;
		
		.btn {
			margin: 50px auto 0;
			position: static;

			a {
				border: 2px solid #110051;
				color: #110051;
			}
		}

		.detail {
			width: 80%;
			margin: 0 auto;
		}
	}
}


/* ---------- area btn_more ---------- */

.area_btn_more {
	position: absolute;
	top: 100px;
	right: 10px;
	a {
		font-size: $fs-13;
		padding-right: 40px;
		background: url(../images/miraimo/common/ico-arw.png) no-repeat right center;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}


/* ---------- area blog ---------- */

.area_blog {
	padding-bottom: 70px;
	.area_tit {
		span {
			color: $cl-lightGreen;
		}
	}
	&_cont {
		font-size: 0;
		margin: 0 -10px;
		&_box {
			vertical-align: top;
			font-size: $font-size;
			text-align: left;
			display: inline-block;
			margin: 0 5px;
			width: calc(25% - 10px);
			max-width: 292px;
			.img {
				padding-bottom: 20px;
				img {
					width: 100%;
				}
			}
			.date {
				font-size: $fs-12;
				font-weight: bold;
				line-height: 28px;
				display: inline-block;
				padding: 0 10px;
			}
			.tag {
				font-size: $fs-11;
				text-align: center;
				padding: 0 12px;
				color: $cl-lightGreen;
				display: inline-block;
				line-height: 26px;
				border: 1px $cl-lightGreen solid;
				border-radius: 14px;
			}
			.tit {
				padding: 16px 10px;
				line-height: 1.5;
			}
			a {
				text-decoration: none;
				&:hover {
					color: $cl-lightGreen;
				}
			}
		}
	}
}

.single-voice {
  .area_blog {
    &_cont {
      &_box {
        a {
          &:hover {
            color: $cl-lightBlue;
          }
        }
      }
    }
  }
}

#miraimoblog {
	.area_blog_cont_box {
		width: calc(33.33% - 10px);
	}
}


/*
  page
--------------------------------------------- */

.pages {
	background: url(../images/miraimo/common/head_bg.png) no-repeat center top;
	background-size: contain;

	.page-template-template-miraimo-lp-thankyou & {
		border-bottom: 1px solid #dcdcdc;
	}

	.area_key {
		padding: 20px 0 100px;
		.area_tit {
			padding: 70px 0 40px;
		}
	}
}

.center {
	text-align: center;
}

.btn_normal {
	margin: 20px auto 100px;
	text-align: center;
	a {
		min-width: 180px;
		display: inline-block;
		text-align: center;
		background: #fff;
		line-height: 26px;
		border-radius: 15px;
		text-decoration: none;
		border: 2px $cl-orange solid;
		color: $cl-orange;
		&:hover {
			color: #fff;
			background: $cl-orange;
		}
	}
}
