/*
  careeradvisor
--------------------------------------------- */

#careeradvisor {
	.area_key {
		.area_tit {
			span {
				color: $cl-blue;
			}
		}
	}
	.container {
		margin-bottom: 70px;
		.area_advisor {
			width: 1000px;
			margin: 0 auto;
			.area_advisor_cont {
				width: 484px;
				display: inline-block;
				border: solid 1px #ddd;
				margin: 0 0 30px 0;
				&:nth-child(odd) {
					float: left;
				}
				&:nth-child(even) {
					float: right;
				}
				.area_advisor_cont_box {
					background: #ebf4fc;
					border-top: solid 4px #0c4f93;
					height: 100%;
					&_upper {
						background: #fff;
						padding-top: 40px;
					}
					.img {
						border-radius: 50%;
						overflow: hidden;
						width: 150px;
						margin: 0 auto 35px;
					}
					img {
						border-radius: 50%;
					}
					.name {
						text-align: center;
						margin: auto;
						font-size: $fs-22;
						border-top: 1px solid black;
						margin: 23px 0 0;
						text-align: center;
						padding: 0;
						margin: 0 40px;
						height: 24px;
					}
          .btn {
            padding-top: 10px;
            margin: 0 auto;
            a {
              display: block;
              font-size: $fs-12;
              border: 1px $cl-blue solid;
              color: $cl-blue;
              line-height: 26px;
              border-radius: 13px;
              padding: 0 1em;
              width: 60%;
              margin: 0 auto 20px;
              text-align: center;
              text-decoration: none;
              &:hover {
                background: $cl-blue;
                color: #fff;
              }
            }
          }
					& span {
						position: relative;
						top: -20px;
						padding: 0 20px;
						margin: 40px 40px 0;
						background: white;
					}
					.career_area {
						padding: 10px 40px 30px;
						p{
							color:$cl-lightGray;
						}
						h4 {
							position: relative;
							color: $cl-blue;
							padding-left: 40px;
							margin-left: -40px;
						}
						h4:before {
							content: "";
							width: 30px;
							height: 1px;
							background: $cl-blue;
							position: absolute;
							left: 0;
							top: 10px;
						}
					}
					.message_area {
						padding: 30px 40px 40px;
						border-top: solid 1px $cl-gray;
						background-color: #ebf4fc;
						.message {
							color: $cl-blue;
						}
						p{
							color:$cl-lightGray;
						}
					}
				}
			}
		}
	}
}
